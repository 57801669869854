import { combineReducers } from 'redux';
import undoableConfigurationViewModelReducer from './undoables/undoableConfigurationViewModel';
import loadingReducer from './loading';
import relationshipsReducer from './relationships';
import testFileReducer from './testfile';

const reducer = combineReducers({
  loading: loadingReducer,
  relationships: relationshipsReducer,
  testFile: testFileReducer,
  undoableConfigurationViewModel: undoableConfigurationViewModelReducer,
});

export default reducer;
