import { RECEIVE_CERTIFICATE_NAMES, RESET_STATE } from '../ConnectionsActionTypes';

const initialState = {
  certificateNames: [],
};

interface State {
  certificateNames: string[];
}

interface Action {
  type: string;
  certificateNames: string[];
}

const certificatesReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case RECEIVE_CERTIFICATE_NAMES: {
      const { certificateNames } = action;
      let displayableCertificateNames = [''];
      if (certificateNames && certificateNames.length) {
        displayableCertificateNames = displayableCertificateNames.concat(certificateNames);
      }
      return {
        ...state,
        certificateNames: displayableCertificateNames,
      };
    }
    case RESET_STATE:
      return initialState;
  }
  return state;
};

export default certificatesReducer;
