import { combineReducers } from 'redux';
import loadingReducer from './loading/loadingReducer';
import connectionsReducer from './connections';
import certificatesReducer from './certificates';

const reducer = combineReducers({
  loading: loadingReducer,
  connections: connectionsReducer,
  certificates: certificatesReducer,
});

export default reducer;
