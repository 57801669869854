export const requestEnvironments = (state) => ({
  ...state,
  isLoading: true,
  environments: [],
});

export function requestEnvironmentsFailed(state, { error }) {
  return {
    ...state,
    isLoading: false,
    error,
  };
}

export function requestEnvironmentsComplete(state) {
  return {
    ...state,
    isLoading: false,
  };
}

export const requestSaveEnvironments = (state) => ({
  ...state,
  isSaving: true,
});

export const completeSave = (state) => ({
  ...state,
  isSaving: false,
});

export function saveFailed(state, { error }) {
  return {
    ...state,
    isSaving: false,
    error,
  };
}
