import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { ChubInput, ChubButton, ChubP } from '../styles/typography';

const AdderBarDiv = glamorous('div', { displayName: 'AdderBarDiv' })({
  display: 'flex',
  maxWidth: '1300px',
  margin: '10px auto',
  flexGrow: '1',
  justifyContent: 'flex-end',
});

const AdderBarSpan = glamorous('span', { displayName: 'AdderBarSpan' })({
  margin: '10px',
});

const AdderBarLabel = glamorous(ChubP, { displayName: 'AdderBarLabel' })({
  fontSize: '18px',
  marginLeft: '15px',
  color: '#535454',
  display: 'inline-block',
});

const AdderBarInput = glamorous(ChubInput, { displayName: 'AdderBarInput' })({
  height: '33px',
  width: '300px',
  marginLeft: '5px',
});

const AdderBarButton = glamorous(ChubButton, { displayName: 'AddItemButton' })({
  marginLeft: '15px',
});

export default class AdderBar extends Component {
  static displayName = 'AdderBar';

  static propTypes = {
    addItem: PropTypes.func,
    itemType: PropTypes.string,
    showGUID: PropTypes.bool,
  };

  static defaultProps = {
    showGUID: false,
  };

  state = {
    newItemName: '',
    newItemGUID: '',
  };

  _addItem = () => {
    const { addItem } = this.props;
    const { newItemName, newItemGUID } = this.state;
    if (newItemName) {
      addItem(newItemName, newItemGUID);
      this.setState({
        newItemName: '',
        newItemGUID: '',
      });
    }
  };

  _onNewItemChange = (event) => {
    this.setState({
      newItemName: event.target.value,
    });
  };

  _onNewItemGUIDChange = (event) => {
    this.setState({
      newItemGUID: event.target.value,
    });
  };

  _handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this._addItem();
    }
  };

  render() {
    const { showGUID, itemType, ...otherProps } = this.props;
    const { newItemName, newItemGUID } = this.state;

    return (
      <AdderBarDiv>
        <AdderBarSpan {...otherProps}>
          <AdderBarLabel>Value:</AdderBarLabel>
          <AdderBarInput
            id='adder-bar-textbox'
            value={newItemName}
            onChange={this._onNewItemChange}
            onKeyPress={this._handleKeyPress}
          />
          {showGUID && (
            <Fragment>
              <AdderBarLabel>GUID:</AdderBarLabel>
              <AdderBarInput
                id='adder-bar-guidbox'
                value={newItemGUID}
                onChange={this._onNewItemGUIDChange}
                onKeyPress={this._handleKeyPress}
              />
            </Fragment>
          )}
          <AdderBarButton id='adder-bar-button' onClick={this._addItem}>
            Add {itemType}
          </AdderBarButton>
        </AdderBarSpan>
      </AdderBarDiv>
    );
  }
}
