import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChubTableBodyRow, ChubTableBodyCell } from '../../../../styles/table';
import glamorous from 'glamorous';
import { colors } from 'react-chui';

const FilePathDataCell = glamorous(ChubTableBodyCell, { displayName: 'SubPathDataCell' })({
  backgroundColor: colors.lightblue,
  border: 'none',
});

export default class FilePaths extends Component {
  static displayName = 'FilePaths';

  static propTypes = {
    direction: PropTypes.string,
    directoryName: PropTypes.string,
    subPaths: PropTypes.object,
  };

  render() {
    const { direction, directoryName, subPaths } = this.props;
    const pathString =
      Object.keys(subPaths).length > 0
        ? `/${Object.keys(subPaths)
            .map((key) => subPaths[key].newValue || subPaths[key].origValue)
            .join('/')}`
        : '';

    return (
      <ChubTableBodyRow>
        <ChubTableBodyCell colSpan='2' rowSpan='1'></ChubTableBodyCell>
        <FilePathDataCell colSpan='5' rowSpan='1'>
          {`${direction.newValue || direction.origValue}/${directoryName}${pathString}`}
        </FilePathDataCell>
      </ChubTableBodyRow>
    );
  }
}
