const COOKIE_EXPIRATION = 2;

export function writeCookie(name, value) {
  const now = new Date();
  const expiration = now.setHours(now.getHours() + COOKIE_EXPIRATION);
  document.cookie = `${name}=${value}; expires=${expiration};path=/`;
}

export function readCookie(name) {
  const nameString = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(nameString) === 0) {
      return c.substring(nameString.length, c.length);
    }
  }
  return '';
}
