import {
  FETCH_CERTIFICATES_FAILED,
  FETCH_CERTIFICATES_LOADING,
  FETCH_CONNECTIONS_COMPLETED,
  FETCH_CONNECTIONS_FAILED,
  FETCH_CONNECTIONS_LOADING,
  RESET_STATE,
} from '../../ConnectionsActionTypes';
import {
  fetchConnectionsCompleted,
  fetchConnectionsFailed,
  connectionsLoading,
  LoadingReducerAction,
  certificatesLoading,
  fetchCertificatesFailed,
} from './loadingReducerMethods';

import { defaultEnvironments, protocols } from '../../dropdownOptions';

const initialState = {
  isLoading: true,
  error: '',
  selectedEnvironment: defaultEnvironments[0],
  selectedProtocol: protocols[0],
  environmentList: defaultEnvironments,
};

const loadingReducer = (state = initialState, action: LoadingReducerAction) => {
  switch (action.type) {
    case FETCH_CONNECTIONS_LOADING:
      return connectionsLoading(state, action);
    case FETCH_CONNECTIONS_COMPLETED:
      return fetchConnectionsCompleted(state);
    case FETCH_CONNECTIONS_FAILED:
      return fetchConnectionsFailed(state, action);
    case FETCH_CERTIFICATES_LOADING:
      return certificatesLoading(state);
    case FETCH_CERTIFICATES_FAILED:
      return fetchCertificatesFailed(state, action);
    case RESET_STATE:
      return initialState;
  }
  return state;
};

export default loadingReducer;
