import React, { Component } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import CollapsedFileRoutes from './CollapsedFileRoutes';
import ExpandedFileRoutes from './ExpandedFileRoutes';

const FileRouteTableDiv = glamorous('div', { displayName: 'FileRouteTable' })({
  padding: '10px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  backgroundColor: 'white',
  borderRadius: '3px',
  zIndex: '0', // This and position: relative so our "Server IPs Info" overlay can sit over this
  position: 'relative',
});

export default class FileRouteTable extends Component {
  static displayName = 'FileRouteTable';
  static propTypes = {
    connectionType: PropTypes.string.isRequired,
    fileRoutes: PropTypes.object,
    fileRoutesVisible: PropTypes.bool,
    readOnly: PropTypes.bool,
    toggleFileRouteVisibility: PropTypes.func,
    onAddFileRoute: PropTypes.func,
    parentId: PropTypes.string,
  };

  static defaultProps = {
    fileRoutes: {},
    fileRoutesVisible: false,
    readOnly: true,
  };

  _toggleExpanded = () => {
    const { connectionType, toggleFileRouteVisibility, parentId } = this.props;
    toggleFileRouteVisibility(connectionType, parentId);
  };

  render() {
    const { connectionType, fileRoutes, fileRoutesVisible, readOnly, parentId, onAddFileRoute, ...otherProps } =
      this.props;
    return (
      <FileRouteTableDiv>
        {!fileRoutesVisible && (
          <CollapsedFileRoutes
            id={`${connectionType}-CollapsedFileRoutes-${parentId}`}
            {...{ connectionType, fileRoutes, readOnly, parentId }}
            onToggleExpanded={this._toggleExpanded}
          />
        )}
        {fileRoutesVisible && (
          <ExpandedFileRoutes
            id={`${connectionType}-ExpandedFileRoutes-${parentId}`}
            onAddFileRoute={onAddFileRoute}
            {...{ ...otherProps, connectionType, fileRoutes, readOnly, parentId }}
            onToggleExpanded={this._toggleExpanded}
          />
        )}
      </FileRouteTableDiv>
    );
  }
}
