import React from 'react';
import PropTypes from 'prop-types';
import { ChubTableBodyCell, ChubTableBodyRow } from '../../../styles/table';
import { ChubA } from '../../../styles/typography';

export const As2ConnectionsTableRow = ({
  client_name,
  client_guid,
  as2_id,
  chub_as2_id,
  certificate_name,
}: {
  client_name: string;
  client_guid: string;
  as2_id: string;
  chub_as2_id: string;
  certificate_name: string;
}) => {
  return (
    <ChubTableBodyRow>
      <ChubTableBodyCell>
        <ChubA to={`/client/clientOverview/${client_guid}`}>{client_name}</ChubA>
      </ChubTableBodyCell>
      <ChubTableBodyCell>{as2_id}</ChubTableBodyCell>
      <ChubTableBodyCell>{chub_as2_id}</ChubTableBodyCell>
      <ChubTableBodyCell>{certificate_name}</ChubTableBodyCell>
    </ChubTableBodyRow>
  );
};

As2ConnectionsTableRow.displayName = 'As2ConnectionsTableRow';
As2ConnectionsTableRow.propTypes = {
  client_name: PropTypes.string,
  client_guid: PropTypes.string,
  as2_id: PropTypes.string,
  chub_as2_id: PropTypes.string,
  certificate_name: PropTypes.string,
};
