import useSWR from 'swr';
import { fetcher } from '../../util/fetchClient';

const useCertificate = (guid: string) => {
  const { data, error } = useSWR(`/config/certificates/${guid}`, fetcher);

  return {
    certificate: data,
    isLoading: !error && !data,
    error,
  };
};

export default useCertificate;
