import configurationViewModelReducer from '../configurationViewModel';
import undoable, { includeAction } from 'redux-undo';
import {
  RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE,
  SET_CHUB_SYSTEM_IDENTIFIER,
  SET_AS2_ID,
  SET_MAILBOX_NAME,
  SET_CURRENT_SECTION_UNDOABLE,
  SET_CONFIGURATION_URL,
  SET_CONFIGURATION_PORT,
  SET_CONFIGURATION_HTTPS,
  SET_CHUB_SYSTEM_CERTIFICATE,
  SET_CONNECTION_ENABLED,
  SET_FILE_ROUTE_KEY,
  SET_FILE_ROUTE_REGEX,
  SET_FILE_ROUTE_EXTENSION,
  SET_FILE_ROUTE_CONTENT_TYPE,
  SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA,
  SET_SFTP_USERNAME,
  SET_FILE_ROUTE_MD5_VERIFIED,
  SET_SUB_PATH,
  ADD_SUB_PATH,
  DELETE_SUB_PATH,
  ADD_NEW_SFTP_FILE_ROUTE,
  ADD_NEW_AS2_FILE_ROUTE,
  DELETE_FILE_ROUTE,
  SET_CONFIGURATION_MICALGORITHM,
  ADD_AS2_CONNECTION,
  ADD_SFTP_CONNECTION,
  ADD_RELATIONSHIP,
  ADD_NEW_PARTNER_SFTP_FILE_ROUTE,
  ADD_PARTNER_SFTP_CONNECTION,
  SET_PARTNER_SFTP_CONFIGURATION_URL,
  SET_PARTNER_SFTP_USERNAME,
  SET_PARTNER_SFTP_CONFIGURATION_PORT,
  SET_PARTNER_SFTP_PASSWORD,
  SET_CLIENT_AUTH_CERT,
  SET_CLIENT_AUTH_CERT_PASSWORD,
  SET_CLIENT_AUTH_PRIVATE_KEY_FILE,
  SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD,
} from '../../ClientConfigurationActionTypes';

const undoableConfigurationViewModel = undoable(configurationViewModelReducer, {
  filter: includeAction([
    RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE,
    SET_CHUB_SYSTEM_IDENTIFIER,
    SET_AS2_ID,
    SET_MAILBOX_NAME,
    SET_CURRENT_SECTION_UNDOABLE,
    SET_CONFIGURATION_URL,
    SET_CONFIGURATION_PORT,
    SET_CONFIGURATION_HTTPS,
    SET_CHUB_SYSTEM_CERTIFICATE,
    SET_CONNECTION_ENABLED,
    SET_FILE_ROUTE_KEY,
    SET_FILE_ROUTE_REGEX,
    SET_FILE_ROUTE_EXTENSION,
    SET_FILE_ROUTE_CONTENT_TYPE,
    SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA,
    SET_SFTP_USERNAME,
    SET_FILE_ROUTE_MD5_VERIFIED,
    SET_SUB_PATH,
    ADD_SUB_PATH,
    DELETE_SUB_PATH,
    SET_PARTNER_SFTP_CONFIGURATION_URL,
    SET_PARTNER_SFTP_USERNAME,
    SET_PARTNER_SFTP_CONFIGURATION_PORT,
    SET_PARTNER_SFTP_PASSWORD,
    SET_CLIENT_AUTH_CERT,
    SET_CLIENT_AUTH_CERT_PASSWORD,
    SET_CLIENT_AUTH_PRIVATE_KEY_FILE,
    SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD,
    ADD_NEW_AS2_FILE_ROUTE,
    ADD_NEW_SFTP_FILE_ROUTE,
    ADD_NEW_PARTNER_SFTP_FILE_ROUTE,
    DELETE_FILE_ROUTE,
    SET_CONFIGURATION_MICALGORITHM,
    ADD_AS2_CONNECTION,
    ADD_SFTP_CONNECTION,
    ADD_PARTNER_SFTP_CONNECTION,
    ADD_RELATIONSHIP,
  ]),
});

export default undoableConfigurationViewModel;
