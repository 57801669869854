import {
  REQUEST_ENVIRONMENTS,
  REQUEST_ENVIRONMENTS_FAILED,
  REQUEST_ENVIRONMENTS_COMPLETE,
  REQUEST_SAVE_ENVIRONMENTS,
  SAVE_COMPLETE,
  SAVE_FAILED,
} from '../../EnvironmentActionTypes';

import {
  requestEnvironments,
  requestEnvironmentsFailed,
  requestEnvironmentsComplete,
  requestSaveEnvironments,
  completeSave,
  saveFailed,
} from './environmentsLoadingReducerMethods';

const initialState = {
  isLoading: false,
  isSaving: false,
  error: '',
};

const environmentsLoadingReducer = (state = initialState, { type, ...actionArgs }) => {
  switch (type) {
    case REQUEST_ENVIRONMENTS:
      return requestEnvironments(state);
    case REQUEST_ENVIRONMENTS_FAILED:
      return requestEnvironmentsFailed(state);
    case REQUEST_ENVIRONMENTS_COMPLETE:
      return requestEnvironmentsComplete(state);
    case REQUEST_SAVE_ENVIRONMENTS:
      return requestSaveEnvironments(state);
    case SAVE_COMPLETE:
      return completeSave(state, actionArgs);
    case SAVE_FAILED:
      return saveFailed(state, actionArgs);
    default:
      break;
  }
  return state;
};

export default environmentsLoadingReducer;
