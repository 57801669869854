import React, { PropsWithChildren } from 'react';
import glamorous from 'glamorous';
import { chubStandards } from '../../styles/typography';
import { useMsal } from '@azure/msal-react';

const LogoutLinkBase = ({ children, ...args }: PropsWithChildren<unknown>): JSX.Element | null => {
  const { instance } = useMsal();

  return (
    <a
      onClick={() => {
        instance.logoutRedirect();
      }}
      {...args}
    >
      {children}
    </a>
  );
};

const LogoutLink = glamorous(LogoutLinkBase, { displayName: 'LogoutLink' })(chubStandards, {
  fontSize: '24px',
  textAlign: 'right',
  paddingRight: '20px',
  color: '#FFFFFF',
  textDecorationLine: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
});

export default LogoutLink;
