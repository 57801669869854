import { combineReducers } from 'redux';
import undoableEnvironmentsReducer from './undoables/undoableEnvironment';
import loadingReducer from './loading/environmentsLoadingReducer';

const reducer = combineReducers({
  loading: loadingReducer,
  undoableEnvironments: undoableEnvironmentsReducer,
});

export default reducer;
