import { connect } from 'react-redux';
import { addEnvironment, updateEnvironment } from '../actions';
import EnvironmentListEditor from '../components/EnvironmentListEditor';

const mapStateToProps = ({
  undoableEnvironments: {
    past,
    present: { environments, readOnly },
  },
}) => {
  return {
    environmentList: environments,
    changesMade: past.length > 0,
    readOnly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEnvironment: (payload) => {
      dispatch(updateEnvironment(payload));
    },
    addEnvironment: (environment) => {
      dispatch(addEnvironment(environment));
    },
  };
};

const EnvironmentListEditorContainer = connect(mapStateToProps, mapDispatchToProps)(EnvironmentListEditor);

export default EnvironmentListEditorContainer;
