import IndexDashboard from './IndexDashboardApp';
import { permissionInfoMock } from './IndexDashboardMockData';

const props = {
  userRoles: permissionInfoMock.userRoles,
};

const IndexDashboardEntry = () => <IndexDashboard {...props} />;

export default IndexDashboardEntry;
