import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import ClientResultRow from './ClientResultRow';
import { chubStandards, ChubH3 } from '../../../styles/typography';

export const OuterDiv = glamorous('div', { displayName: 'OuterDiv' })(chubStandards, {
  padding: '0 40px',
  marginBottom: '40px',
});

const TableName = glamorous(ChubH3, { displayName: 'TableName' })({
  marginBottom: '10px',
});

export const ListBorder = glamorous('div', { displayName: 'ListBorder' })(chubStandards, {
  borderRadius: '3px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
  padding: '20px 20px 12px 20px',
  background: 'white',
});

export default class ClientSearchResultTable extends Component {
  static displayName = 'ClientSearchResultTable';

  static propTypes = {
    resultType: PropTypes.string,
    resultList: PropTypes.object,
  };

  render() {
    const { resultType, resultList } = this.props;

    const tableRows = resultList.records.map((result) => (
      <ClientResultRow
        key={result.clientGUID}
        id={result.clientGUID}
        name={result.clientName}
        searchFieldData={result.searchFieldData}
        environmentName={result.environmentName}
        isAdminRecord={Boolean(result.isAdminRecord)}
      />
    ));
    return (
      <OuterDiv id={`client-search-result-table-${resultType}`}>
        <TableName>{resultType}</TableName>
        <ListBorder>{tableRows}</ListBorder>
      </OuterDiv>
    );
  }
}
