import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { resetStateThenFetchClientVersionData } from './actions';
import { Provider } from 'react-redux';
import VersionDataLoadingStatePageProviderContainer from './containers/VersionDataLoadingStatePageProviderContainer';
import ClientOverview from './ClientOverviewApp';
import { applyMiddleware, createStore } from 'redux';
import reducer from './reducers';
import thunkMiddleware from 'redux-thunk';

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

const ClientOverviewEntry = () => {
  const { clientGUID } = useParams();

  // The page renders before the reset is complete, but the delay has never been enough in practice to matter.
  useEffect(() => {
    store.dispatch(resetStateThenFetchClientVersionData(clientGUID));
  }, [clientGUID]);

  return (
    <Provider store={store}>
      <VersionDataLoadingStatePageProviderContainer>
        {() => <ClientOverview />}
      </VersionDataLoadingStatePageProviderContainer>
    </Provider>
  );
};

export default ClientOverviewEntry;
