import React from 'react';
import glamorous, { GlamorousComponent } from 'glamorous';
import PropTypes from 'prop-types';
import { AS2ForwarderRequest, AS2ForwarderRequestDisplayModel } from '../AS2ForwarderRequest.model';
// @ts-ignore
import { colors } from 'react-chui';
import { ChubH3, chubStandards } from '../../../styles/typography';
import { DateTime } from 'luxon';

const ResponseDisplayBox = glamorous.div({
  width: '80%',
  padding: '12px 20px',
  margin: '8px',
  border: '2px solid',
  borderColor: colors.darkblue,
  overflow: 'scroll',
  fontSize: '16px',
  whiteSpace: 'pre',
  fontFamily: chubStandards.fontFamily,
});

const SectionHeader: GlamorousComponent<any, any> = glamorous(ChubH3, { displayName: 'SectionHeader' })({
  marginLeft: '8px',
});

const NoContentsMessage: GlamorousComponent<any, any> = glamorous.div({
  margin: '0 0 8px 8px',
  fontSize: '16px',
});

const formatResponseBody = (responseBody?: string) => {
  if (responseBody) {
    return responseBody.replaceAll('\r', '');
  } else {
    return undefined;
  }
};

const formatDate = (dateInMillis?: number) => {
  if (dateInMillis) {
    const dateTime = DateTime.fromMillis(dateInMillis, { zone: 'America/New_York' });
    return dateTime.toFormat('EEE, dd MMM yyyy HH:mm:ss ZZZZ');
  }
  return undefined;
};

const AS2ForwarderRequestDisplay = ({ request }: { request: AS2ForwarderRequest }) => {
  const responseBody = formatResponseBody(request.responseBody);

  const as2ForwarderRequestDisplayModel: AS2ForwarderRequestDisplayModel = {
    requestId: request.requestId,
    alias: request.alias,
    forwardURL: request.forwardURL,
    messageId: request.messageId,
    requestType: request.requestType,
    responseCode: request.responseCode,
    status: request.status,
    headers: request.headers,
    responseHeaders: request.responseHeaders,
    startTime: formatDate(request.startTime),
    forwardTime: formatDate(request.forwardTime),
    sendCompleteTime: formatDate(request.sendCompleteTime),
    endTime: formatDate(request.endTime),
    errorMsg: request.errorMsg,
    localIdRemoteId: request.localIdRemoteId,
    eTag: request.eTag,
    storageTime: formatDate(request.storageTime),
  };

  return (
    <>
      <SectionHeader>Details: </SectionHeader>
      <ResponseDisplayBox>{JSON.stringify(as2ForwarderRequestDisplayModel, null, '\t')}</ResponseDisplayBox>
      <SectionHeader>Response Body: </SectionHeader>
      {responseBody && <ResponseDisplayBox>{responseBody}</ResponseDisplayBox>}
      {!responseBody && <NoContentsMessage>No Response Body</NoContentsMessage>}
    </>
  );
};

AS2ForwarderRequestDisplay.displayName = 'AS2ForwarderRequestDisplay';

AS2ForwarderRequestDisplay.propTypes = {
  request: PropTypes.object.isRequired,
};

export default AS2ForwarderRequestDisplay;
