import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import EditableField from '../../../components/EditableField';
import EditableTextInput from '../../../components/EditableTextInput';
import ConfigurationLabel from './ConfigurationLabel';

const EditableFieldRow = glamorous('div', { displayName: 'EditableFieldRow' })({});
const UrlOptions = glamorous('div', { displayName: 'UrlOptions' })({
  display: 'inline-block',
  width: '415px',
});

export default class EditableUrlPartnerSftp extends Component {
  static displayName = 'EditableUrlPartnerSftp';

  static propTypes = {
    url: PropTypes.object,
    onChangeUrl: PropTypes.func,
    onChangePort: PropTypes.func,
    onChangeHttps: PropTypes.func,
    id: PropTypes.string,
    port: PropTypes.object,
    readOnly: PropTypes.bool,
  };

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const { url, port, readOnly, onChangeUrl, onChangePort, id } = this.props;
    return (
      <Fragment>
        <EditableFieldRow>
          <EditableField {...{ ...url, readOnly }} id={`url-field-${id}`}>
            {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`url-${id}`}>URL:</ConfigurationLabel>
                <EditableTextInput
                  debounceWaitMS={1500}
                  id={`url-${id}`}
                  {...editableInputProps}
                  onChange={onChangeUrl}
                  width='850'
                />
              </Fragment>
            )}
          </EditableField>
        </EditableFieldRow>
        <UrlOptions>
          <EditableField {...{ ...port, readOnly }} id={`port-field-${id}`}>
            {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`port-${id}`}>PORT:</ConfigurationLabel>
                <EditableTextInput
                  debounceWaitMS={1500}
                  type='number'
                  id={`port-${id}`}
                  {...editableInputProps}
                  onChange={onChangePort}
                  width='100px'
                />
              </Fragment>
            )}
          </EditableField>
        </UrlOptions>
      </Fragment>
    );
  }
}
