import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';
import { chubStandards, ChubH3, ChubButton } from '../../../styles/typography';
import NavigationWarning from '../../../components/NavigationWarning';
import { fetchClient } from '../../../util/fetchClient';

const TabDiv = glamorous('div', { displayName: 'TabDiv' })({
  display: 'block',
  height: '50px',
  maxWidth: '1520px',
});

const TabGroup = glamorous('div', { displayName: 'TabGroup' })({
  display: 'inline',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
});

const TabGroupPadding = glamorous('div', { displayName: 'TabGroupPadding' })({
  display: 'block',
  paddingTop: '24px',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
});

const VersionName = glamorous(ChubH3, { displayName: 'VersionName' })({
  marginLeft: '20px',
});

const Tab = glamorous('a', { displayName: 'Tab' })(chubStandards, ({ selected }) => {
  const baseStyles = {
    display: 'block',
    color: colors.brightblue,
    cursor: 'pointer',
    float: 'left',
    fontSize: '18px',
    height: '49px',
    lineHeight: '45px',
    marginRight: '20px',
    minWidth: '140px',
    padding: '0 20px 5px',
    position: 'relative',
    textAlign: 'center',
    ':hover': {
      textDecoration: 'underline',
    },
    ':last-child': {
      marginRight: 0,
    },
  };
  if (selected) {
    Object.assign(baseStyles, {
      color: colors.darkgrey,
      backgroundColor: 'white',
      borderRadius: '3px 3px 0 0',
      borderTop: `4px solid ${colors.brightblue}`,
      float: 'left',
      height: '45px',
      lineHeight: '38px',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'none',
      },
    });
  }
  return baseStyles;
});

const TabLabel = glamorous('h2', { displayName: 'TabLabel' })(chubStandards, {
  float: 'left',
  lineHeight: '45px',
  marginRight: '40px',
  maxWidth: '40%',
  margin: '0 20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const HeadVersionStatusLabel = glamorous('h2', { displayName: 'HeadVersionStatusLabel' })(chubStandards, {
  backgroundColor: 'white',
  borderRadius: '3px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  float: 'right',
  fontSize: '20px',
  fontWeight: 'normal',
  marginTop: '12px',
  marginRight: '0',
  marginBottom: '0',
  padding: '2px 15px',
});

const ManualMigrateButton = glamorous(ChubButton, { displayName: 'ManualMigrateButton' })(({ disabled }) => {
  const baseStyles = {
    display: 'block',
    margin: '10px 20px',
    float: 'right',
    textDecoration: 'none',
  };
  if (disabled) {
    Object.assign(baseStyles, {
      disabled,
    });
  }
  return baseStyles;
});

export default class SectionView extends Component {
  static displayName = 'SectionViewer';
  static propTypes = {
    children: PropTypes.func,
    sections: PropTypes.object,
    updateCurrentSection: PropTypes.func,
    currentSection: PropTypes.string,
    showVersionName: PropTypes.bool,
  };
  static defaultProps = {
    showVersionName: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      manualButtonDisabled: false,
    };
  }

  _onTabClick = ({ target: { textContent } }) => {
    const { sections, currentSection, updateCurrentSection } = this.props;
    const currentSectionCanSave = sections[currentSection].canSave;
    if (currentSection !== textContent) {
      if (currentSectionCanSave) {
        window.alert('You have unsaved changes. Please save or cancel them before switching sections.'); // eslint-disable-line
      } else {
        updateCurrentSection(textContent);
      }
    }
  };

  _renderSectionTabs = () => {
    const { sections, currentSection } = this.props;
    const sectionNames = Object.keys(sections).sort((sectionA, sectionB) => {
      const sectionAIgnoreCase = sectionA.toLowerCase();
      const sectionBIgnoreCase = sectionB.toLowerCase();
      if (sectionAIgnoreCase.includes('prod')) {
        return -1;
      }
      if (sectionBIgnoreCase.includes('prod')) {
        return 1;
      }
      if (sectionAIgnoreCase.includes('vi')) {
        return -1;
      }
      if (sectionBIgnoreCase.includes('vi')) {
        return 1;
      }
      return sectionAIgnoreCase > sectionBIgnoreCase ? 1 : -1;
    });
    return sectionNames.map((sectionName) => (
      <Tab onClick={this._onTabClick} selected={sectionName === currentSection} id={sectionName} key={sectionName}>
        {sectionName}
      </Tab>
    ));
  };

  _onManualButtonClick = async () => {
    const { clientName, currentSection, sections } = this.props;
    const section = sections[currentSection];
    const environmentGUID = section.environmentGUID;

    const migrationsUrl = `/commercehub/configuration/migrate/${clientName}/${environmentGUID}`;

    this.setState({ manualButtonDisabled: true });
    await fetchClient
      .post(migrationsUrl)
      .then((resp) => resp.data)
      .then(() => {
        window.location.reload(true);
        this.setState({ manualButtonDisabled: false });
      })
      .catch((e) => {
        throw new Error(e);
      });
  };

  render() {
    const { showVersionName, currentSection, sections, children, ...otherProps } = this.props;
    const { manualButtonDisabled } = this.state;
    let versionName = '';
    if (showVersionName) {
      versionName = sections[currentSection].versionName.origValue;
    }
    const headVersionStatus = sections[currentSection] ? sections[currentSection].headVersionStatus : null;
    const canSave = sections[currentSection] ? sections[currentSection].canSave : false;
    return (
      <Fragment>
        {showVersionName && <VersionName id='version-name'>Version: {versionName}</VersionName>}
        {!showVersionName && <TabGroupPadding />}
        <TabDiv>
          <TabGroup>
            <TabLabel>Sections</TabLabel>
            {this._renderSectionTabs()}
          </TabGroup>
          {headVersionStatus && (
            <HeadVersionStatusLabel id='head-version-status'>
              Version Status: {headVersionStatus}
            </HeadVersionStatusLabel>
          )}
          {headVersionStatus === 'MANUAL' && (
            <ManualMigrateButton
              id='manually-migrate'
              disabled={manualButtonDisabled}
              onClick={() => {
                this._onManualButtonClick();
              }}
            >
              {manualButtonDisabled ? 'Processing...' : 'Move from MANUAL'}
            </ManualMigrateButton>
          )}
        </TabDiv>
        {children(otherProps)}
        <NavigationWarning isEnabled={canSave} />
      </Fragment>
    );
  }
}
