import { addAs2Connection, addSftpConnection, addPartnerSftpConnection } from './configurationReducers';
import { constructNewStateWithFileRoutes } from './addFileRoutes';
import { mergeAs2FileRoutes, mergeSftpFileRoutes, mergePartnerSftpFileRoutes } from '../../util/mergeFileRoutes';

export function addRelationship(state, { relationshipFileRoutes, useAs2ForwarderAsDefault }) {
  if (relationshipFileRoutes.length === 0) {
    return state;
  }
  const {
    currentSection,
    sections: {
      [currentSection]: { as2Connections, sftpConnection, partnerSftpConnections },
    },
  } = state;
  const postAs2MergeState = addAs2RelationshipFileRoutes(
    state,
    as2Connections[0],
    relationshipFileRoutes,
    useAs2ForwarderAsDefault
  );
  const postSftpMergeState = addSftpRelationshipFileRoutes(postAs2MergeState, sftpConnection, relationshipFileRoutes);
  const postPartnerSftpMergeState = addPartnerSftpRelationshipFileRoutes(
    postSftpMergeState,
    partnerSftpConnections[0],
    relationshipFileRoutes
  );

  return { ...postPartnerSftpMergeState };
}

export function addAs2RelationshipFileRoutes(
  state,
  existingAs2Connection,
  relationshipFileRoutes,
  useAs2ForwarderAsDefault
) {
  const existingAs2FileRoutes = existingAs2Connection ? existingAs2Connection.fileRoutes : [];
  const mergedAs2FileRoutes = mergeAs2FileRoutes(
    existingAs2FileRoutes,
    relationshipFileRoutes.filter((fileRoute) => fileRoute.connectionType === 'AS2')
  );

  const hasMergedAs2FileRoutes = !(
    Object.keys(mergedAs2FileRoutes).length === 0 && mergedAs2FileRoutes.constructor === Object
  );
  const postAs2MergeState =
    hasMergedAs2FileRoutes && !existingAs2Connection ? addAs2Connection(state, { useAs2ForwarderAsDefault }) : state;
  return hasMergedAs2FileRoutes
    ? constructNewStateWithFileRoutes(postAs2MergeState, 'as2Connections', mergedAs2FileRoutes)
    : postAs2MergeState;
}

export function addSftpRelationshipFileRoutes(postPostAs2MergeState, existingSftpConnection, relationshipFileRoutes) {
  const existingSftpFileRoutes = existingSftpConnection ? existingSftpConnection.fileRoutes : [];
  const mergedSftpFileRoutes = mergeSftpFileRoutes(
    existingSftpFileRoutes,
    relationshipFileRoutes.filter((fileRoute) => fileRoute.connectionType === 'SFTP')
  );
  const hasMergedSftpFileRoutes = !(
    Object.keys(mergedSftpFileRoutes).length === 0 && mergedSftpFileRoutes.constructor === Object
  );
  const postSftpMergeState =
    hasMergedSftpFileRoutes && !existingSftpConnection
      ? addSftpConnection(postPostAs2MergeState)
      : postPostAs2MergeState;
  return hasMergedSftpFileRoutes
    ? constructNewStateWithFileRoutes(postSftpMergeState, 'sftpConnection', mergedSftpFileRoutes)
    : postSftpMergeState;
}

export function addPartnerSftpRelationshipFileRoutes(state, existingPartnerSftpConnection, relationshipFileRoutes) {
  const existingPartnerSftpFileRoutes = existingPartnerSftpConnection ? existingPartnerSftpConnection.fileRoutes : [];
  const mergedPartnerSftpFileRoutes = mergePartnerSftpFileRoutes(
    existingPartnerSftpFileRoutes,
    relationshipFileRoutes.filter((fileRoute) => fileRoute.connectionType === 'partnerSFTP')
  );

  const hasMergedPartnerSftpFileRoutes = !(
    Object.keys(mergedPartnerSftpFileRoutes).length === 0 && mergedPartnerSftpFileRoutes.constructor === Object
  );
  const postPartnerSftpMergeState =
    hasMergedPartnerSftpFileRoutes && !existingPartnerSftpConnection ? addPartnerSftpConnection(state) : state;
  return hasMergedPartnerSftpFileRoutes
    ? constructNewStateWithFileRoutes(postPartnerSftpMergeState, 'partnerSftpConnections', mergedPartnerSftpFileRoutes)
    : postPartnerSftpMergeState;
}
