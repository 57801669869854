import { connect } from 'react-redux';
import { updateAs2Id, deleteAs2Identifier } from '../actions';
import As2IdRow from '../components/As2IdRow';

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (as2Identifier) => {
      dispatch(updateAs2Id(as2Identifier));
    },
    onDelete: (as2Identifier) => {
      dispatch(deleteAs2Identifier(as2Identifier));
    },
  };
};

const As2IdRowContainer = connect(null, mapDispatchToProps)(As2IdRow);

export default As2IdRowContainer;
