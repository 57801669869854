import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';
import { ChubP } from '../styles/typography';

const ServerMessage = glamorous(ChubP, { displayName: 'ServerMessage' })(({ isError }) => {
  const styles = {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
  };
  if (isError) {
    styles.color = colors.chred;
  }
  return styles;
});

const ServerMessages = ({ serverMessages }) => {
  return serverMessages.map(({ messageType, message }, index) => (
    <ServerMessage key={index} data-message-type='serverMessage' isError={messageType.toLowerCase().includes('error')}>
      {message}
    </ServerMessage>
  ));
};

ServerMessages.displayName = 'ServerMessages';
ServerMessages.propTypes = {
  serverMessages: PropTypes.array,
};
ServerMessages.defaultProps = {
  serverMessages: [],
};

export default ServerMessages;
