export interface ProtocolIpInfo {
  name: string;
  oldSourceIPs?: string[];
  oldChubURLs?: string[];
  newSourceIPs?: string[];
  newChubURLs?: string[];
}

export interface EnvironmentIpInfo {
  environmentName: string;
  environmentGUID: string;
  protocols: ProtocolIpInfo[];
}

export const ipInfoData: EnvironmentIpInfo[] = [
  {
    environmentName: 'ProdEnv',
    environmentGUID: 'fcbb15d8-b0cc-4baf-89e8-1889196c0278',
    protocols: [
      {
        name: 'as2',
        oldSourceIPs: ['12.107.130.0/24'],
        oldChubURLs: ['http://as2.commercehub.com:13180/as2', 'https://as2.commercehub.com:14180/as2'],
        newSourceIPs: [
          '18.206.2.14',
          '3.210.148.240',
          '3.217.98.206',
          '3.232.196.22',
          '34.202.189.137',
          '34.224.129.48',
          '34.231.185.207',
          '34.233.242.144',
          '35.175.24.46',
          '44.195.161.243',
          '50.16.61.64',
          '50.16.65.218',
          '34.210.154.77',
          '34.214.116.160',
          '34.218.38.152',
          '35.83.31.171',
          '44.226.111.58',
          '44.227.222.245',
          '44.240.140.194',
          '44.242.39.205',
          '52.11.118.69',
          '52.42.98.67',
          '52.88.192.207',
          '54.70.117.222',
          '192.64.13.0/24',
        ],
        newChubURLs: ['http://prod.file.commercehub.com:80/as2', 'https://prod.file.commercehub.com:443/as2'],
      },
      {
        name: 'sftp',
        oldChubURLs: ['{username}@{username}.sftp.commercehub.com:22'],
        newChubURLs: ['{username}@{username}.sftp.commercehub.com:22'],
      },
    ],
  },
  {
    environmentName: 'OnbEnv',
    environmentGUID: 'df849862-9de9-412f-823d-433b9b27d00e',
    protocols: [
      {
        name: 'as2',
        oldSourceIPs: ['12.107.130.0/24'],
        oldChubURLs: ['http://ihub.commercehub.com:13180/as2', 'http://ihub.commercehub.com:14180/as2'],
        newSourceIPs: [
          '18.206.2.14',
          '3.210.148.240',
          '3.217.98.206',
          '3.232.196.22',
          '34.202.189.137',
          '34.224.129.48',
          '34.231.185.207',
          '34.233.242.144',
          '35.175.24.46',
          '44.195.161.243',
          '50.16.61.64',
          '50.16.65.218',
          '34.210.154.77',
          '34.214.116.160',
          '34.218.38.152',
          '35.83.31.171',
          '44.226.111.58',
          '44.227.222.245',
          '44.240.140.194',
          '44.242.39.205',
          '52.11.118.69',
          '52.42.98.67',
          '52.88.192.207',
          '54.70.117.222',
          '192.64.13.0/24',
        ],
        newChubURLs: ['http://test.file.commercehub.com:80/as2', 'https://test.file.commercehub.com:443/as2'],
      },
      {
        name: 'sftp',
        oldChubURLs: ['{username}@{username}.sftp-test.commercehub.com:22'],
        newChubURLs: ['{username}@{username}.sftp-test.commercehub.com:22'],
      },
    ],
  },
  {
    environmentName: 'PTLand',
    environmentGUID: '3b273ca8-b6fb-42b7-9857-f3a3a213094d',
    protocols: [
      {
        name: 'as2',
        oldSourceIPs: ['66.195.7.162'],
        oldChubURLs: ['http://ftg.pt.commercehub.com:13180/as2', 'https://ftg.pt.commercehub.com:14180/as2'],
        newSourceIPs: [
          '18.206.2.14',
          '3.210.148.240',
          '3.217.98.206',
          '3.232.196.22',
          '34.202.189.137',
          '34.224.129.48',
          '34.231.185.207',
          '34.233.242.144',
          '35.175.24.46',
          '44.195.161.243',
          '50.16.61.64',
          '50.16.65.218',
          '34.210.154.77',
          '34.214.116.160',
          '34.218.38.152',
          '35.83.31.171',
          '44.226.111.58',
          '44.227.222.245',
          '44.240.140.194',
          '44.242.39.205',
          '52.11.118.69',
          '52.42.98.67',
          '52.88.192.207',
          '54.70.117.222',
          '192.64.13.0/24',
        ],
        newChubURLs: ['http://pt.file.commercehub.com:80/as2', 'https://pt.file.commercehub.com:443/as2'],
      },
      {
        name: 'sftp',
        oldChubURLs: ['{username}@ptsftp.commercehub.com:22'],
        newChubURLs: ['{username}@ptsftp.commercehub.com:22'],
      },
    ],
  },
];
