/**
 * SMOOSHING OF FILE ROUTES!
 *
 * Here is the flow of events as confirmed by Seth on 6/13/18
 * NOTE: Logic is basically the same for 'Add Relationship'
 *
 * User Clicks 'Add Common File Routes' on the pop up common file route view
 *  - AS2
 *      > Iterate through the desired common file routes
 *          - Current File Route is Duplicate Key?
 *              - Ignore
 *          - Else
 *              - add to the list of file routes in the as2Connection
 *  - SFTP
 *      > Iterate through the desired common file routes
 *          - Current File Route is Duplicate Key?
 *               > Iterate through each subpath
 *                  - Subpath exists?
 *                      - Ignore
 *                  - Else
 *                      - Add to existing file route's subpaths
 *          - Else
 *              - add to the list of file routes in the sftpConnection
 */
import { uuidv4 } from '../../../mockable/uuidv4-wrapper';

export function mergeAs2FileRoutes(existingFileRoutes, proposedFileRoutes) {
  const fileRoutesToAdd = Object.values(proposedFileRoutes).reduce((routesToAdd, proposedFileRoute) => {
    const existingFileRoute = Object.values(existingFileRoutes).find((fileRoute) => {
      return proposedFileRoute.applicationKey.origValue === fileRoute.applicationKey.origValue;
    });
    if (!existingFileRoute) {
      routesToAdd[proposedFileRoute.fileRouteGUID] = {
        ...proposedFileRoute,
        added: true,
      };
    } else if (existingFileRoute.deleted) {
      routesToAdd[existingFileRoute.fileRouteGUID] = {
        ...proposedFileRoute,
        fileRouteGUID: existingFileRoute.fileRouteGUID,
        deleted: false,
      };
    }
    return routesToAdd;
  }, {});
  return {
    ...existingFileRoutes,
    ...fileRoutesToAdd,
  };
}

export function mergeSftpFileRoutes(existingFileRoutes, proposedFileRoutes) {
  const existingApplicationKeys = Object.values(existingFileRoutes).map(
    ({ applicationKey: { origValue } }) => origValue
  );
  const fileRoutesToAdd = Object.values(proposedFileRoutes).reduce((routesToAdd, proposedFileRoute) => {
    if (existingApplicationKeys.find((currentKey) => proposedFileRoute.applicationKey.origValue === currentKey)) {
      const matchingExistingFileRoute = Object.values(existingFileRoutes).find(
        (fileRoute) =>
          proposedFileRoute.applicationKey.origValue === fileRoute.applicationKey.newValue ||
          proposedFileRoute.applicationKey.origValue === fileRoute.applicationKey.origValue
      );
      if (matchingExistingFileRoute) {
        const fileRouteToEdit = { ...{ ...matchingExistingFileRoute } };
        if (fileRouteToEdit.deleted) {
          fileRouteToEdit.subPaths = {};
          fileRouteToEdit.deleted = false;
        }
        const matchingExistingSubPath = Object.values(fileRouteToEdit.subPaths).find((subPath) => {
          const newSubPathValue = Object.values(proposedFileRoute.subPaths)[0].origValue;
          const existingSubPathValue = subPath.newValue || subPath.origValue;
          return newSubPathValue === existingSubPathValue;
        });
        const subPaths = { ...fileRouteToEdit.subPaths };
        if (!matchingExistingSubPath) {
          // We need to add a new subpath to the existing fileRoute
          // N.B. copy the subPaths member of the fileRouteToEdit object; it is a reference to the subPaths
          //  member of the matchingExistingFileRoute object.
          if (Object.keys(proposedFileRoute.subPaths).length > 0) {
            subPaths[uuidv4()] = { ...{ ...Object.values(proposedFileRoute.subPaths)[0] }, added: true };
          }
        }
        routesToAdd[fileRouteToEdit.fileRouteGUID] = {
          ...{ ...fileRouteToEdit, subPaths },
        };
      }
    } else {
      routesToAdd[proposedFileRoute.fileRouteGUID] = {
        ...{ ...proposedFileRoute },
        added: true,
      };
    }
    return routesToAdd;
  }, {});
  return {
    ...existingFileRoutes,
    ...fileRoutesToAdd,
  };
}

export function mergePartnerSftpFileRoutes(existingFileRoutes, proposedFileRoutes) {
  const fileRoutesToAdd = Object.values(proposedFileRoutes).reduce((routesToAdd, proposedFileRoute) => {
    const existingFileRoute = Object.values(existingFileRoutes).find((fileRoute) => {
      return proposedFileRoute.applicationKey.origValue === fileRoute.applicationKey.origValue;
    });
    if (!existingFileRoute) {
      routesToAdd[proposedFileRoute.fileRouteGUID] = {
        ...proposedFileRoute,
        added: true,
      };
    } else if (existingFileRoute.deleted) {
      routesToAdd[existingFileRoute.fileRouteGUID] = {
        ...proposedFileRoute,
        fileRouteGUID: existingFileRoute.fileRouteGUID,
        deleted: false,
      };
    }
    return routesToAdd;
  }, {});
  return {
    ...existingFileRoutes,
    ...fileRoutesToAdd,
  };
}
