import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { ChubInput, ChubButton, ChubP, chubStandards } from '../../../styles/typography';
import { useNavigate } from 'react-router-dom';

const BrickLabel = glamorous(ChubP, { displayName: 'BrickLabel' })({
  fontSize: '18px',
  marginLeft: '15px',
  color: '#535454',
  display: 'inline-block',
});

const InputSummaryElement = glamorous('div', { displayName: 'InputSummaryElement' })(chubStandards, {
  backgroundColor: 'white',
  borderRadius: '3px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
  margin: '10px 3% 20px 3%',
  height: 'auto',
  whiteSpace: 'nowrap',
});

const LookupTransfersButton = glamorous(ChubButton, { displayName: 'LookupTransfersButton' })({
  margin: '0 20px',
  width: '175px',
});

const NumberInput = glamorous(ChubInput, { displayName: 'NumberInput' })({
  width: '75px',
});

const SameLineWrapper = glamorous('div', { displayName: 'SameLineWrapper' })({
  display: 'inline-block',
});

const SectionTitleWrapper = glamorous('div', { displayName: 'SectionTitleWrapper' })({
  margin: '0px 3%',
});

const SectionTitle = glamorous('h3', { displayName: 'SectionTitle' })(chubStandards, {
  size: '24px',
  textAlign: 'left',
  margin: '0 20px',
});

const RightJustifyDiv = glamorous('div', { displayName: 'RightJustifyDiv' })({
  display: 'flex',
  flexGrow: '1',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});

const AS2TransfersBrick = ({ numberOfTransfers, onChangeNumberOfRequests, createLookupTransfersButtonUrl }) => {
  const navigate = useNavigate();

  const onLookupTransfersButtonClick = (environment) => {
    navigate(createLookupTransfersButtonUrl(environment));
  };

  const onProdClick = () => {
    onLookupTransfersButtonClick('ProdEnv');
  };

  const onOnbClick = () => {
    onLookupTransfersButtonClick('OnbEnv');
  };

  return (
    <Fragment>
      <SectionTitleWrapper>
        <SectionTitle>AS2</SectionTitle>
      </SectionTitleWrapper>
      <InputSummaryElement>
        <RightJustifyDiv>
          <SameLineWrapper>
            <BrickLabel>Lookup Transfers</BrickLabel>
          </SameLineWrapper>
          <SameLineWrapper>
            <NumberInput onChange={onChangeNumberOfRequests} value={numberOfTransfers} type={'number'} />
          </SameLineWrapper>
          <SameLineWrapper>
            <LookupTransfersButton title='Look Up' id='lookup-prod-as2-transfers-button' onClick={() => onProdClick()}>
              Prod
            </LookupTransfersButton>
          </SameLineWrapper>
          <SameLineWrapper>
            <LookupTransfersButton title='Look Up' id='lookup-onb-as2-transfers-button' onClick={() => onOnbClick()}>
              Onboarding
            </LookupTransfersButton>
          </SameLineWrapper>
        </RightJustifyDiv>
      </InputSummaryElement>
    </Fragment>
  );
};

export default AS2TransfersBrick;

AS2TransfersBrick.displayName = 'AS2TransfersBrick';

AS2TransfersBrick.propTypes = {
  numberOfTransfers: PropTypes.number,
  onChangeNumberOfRequests: PropTypes.func,
  createLookupTransfersButtonUrl: PropTypes.func,
};
