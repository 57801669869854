import React from 'react';
import './App.css';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import IndexDashboardEntry from './pages/application/IndexDashboardEntry';
import EnvironmentsEntry from './pages/admin/environments/EnvironmentsEntry';
import FileRouteTypesEntry from './pages/admin/fileRouteTypes/FileRouteTypesEntry';
import As2IdsEntry from './pages/admin/as2Ids/As2IdsEntry';
import As2LookupEntry from './pages/lookup/lookupAS2MessageId/As2LookupEntry';
import ClientSearchResultsEntry from './pages/search/clientSearch/ClientSearchResultsEntry';
import StoppableFilesEntry from './pages/stoppableFiles/StoppableFilesEntry';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Profile from './components/auth/Profile';
import ClientOverviewEntry from './pages/clientOverview/ClientOverviewEntry';
import ClientConfigurationEntry from './pages/clientConfiguration/ClientConfigurationEntry';
import SFTPTransfersResultsEntry from './pages/sftpTransfersResults/SFTPTransfersResultsEntry';
import PartnerSFTPTransfersResultsEntry from './pages/partnerSftpTransfersResults/PartnerSFTPTransfersResultsEntry';
import AS2TransfersResultsEntry from './pages/as2TransfersResults/AS2TransfersResultsEntry';
import CertificatesEntry from './pages/certificates/CertificatesEntry';
import CertificateInfoEntry from './pages/certificateInfo/CertificateInfoEntry';
import OutgoingBacklogEntry from './pages/outgoingBacklog/OutgoingBacklogEntry';
import HelpEntry from './pages/help/HelpEntry';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import CommonAS2ErrorsEntry from './pages/commonAS2Errors/CommonAS2ErrorsEntry';
import CommonSFTPErrorsEntry from './pages/commonSFTPErrors/CommonSFTPErrorsEntry';
// TODO
// import CommonPartnerSFTPErrorsEntry from './pages/commonSFTPErrors/CommonPartnerSFTPErrorsEntry';
import ConnectionsOverviewEntry from './pages/connectionsOverview/ConnectionsOverviewEntry';
import AS2ForwarderRequestEntry from './pages/as2ForwarderRequest/AS2ForwarderRequestEntry';

function App() {
  return (
    <Routes>
      <Route path='/' element={<ProtectedRoute element={<Outlet />} />}>
        <Route index element={<IndexDashboardEntry />} />

        <Route path='/admin/environments' element={<EnvironmentsEntry />} />
        <Route path='/admin/fileRouteTypes' element={<FileRouteTypesEntry />} />
        <Route path='/admin/as2Ids' element={<As2IdsEntry />} />
        <Route path='/as2Forwarder/request/:requestId' element={<AS2ForwarderRequestEntry />} />
        <Route path='/config/certificates' element={<CertificatesEntry />} />
        <Route path='/config/certificate/:certificateGuid' element={<CertificateInfoEntry />} />
        <Route path='/client/clientOverview/:clientGUID' element={<ClientOverviewEntry />} />
        <Route path='/lookup/lookupAS2MessageId' element={<As2LookupEntry />} />
        <Route path='/lookup/lookupAS2MessageId/:messageId' element={<As2LookupEntry />} />
        <Route path='/search' element={<ClientSearchResultsEntry />} />
        <Route path='/transfers/as2/stoppableFiles' element={<StoppableFilesEntry />} />
        <Route
          path='/transfers/as2/page/:partnerId/:partnerGUID/:numberOfTransfers/:environmentName'
          element={<AS2TransfersResultsEntry />}
        />
        <Route
          path='/transfers/sftp/page/:partnerId/:partnerGUID/:numberOfTransfers/:environmentName'
          element={<SFTPTransfersResultsEntry />}
        />
        <Route
          path='/transfers/partnerSftp/page/:partnerId/:partnerGUID/:numberOfTransfers/:environmentName'
          element={<PartnerSFTPTransfersResultsEntry />}
        />
        <Route path='/commercehub/configuration/:clientGUID' element={<ClientConfigurationEntry />} />
        <Route path='/commercehub/configuration/:clientGUID/:versionGUID' element={<ClientConfigurationEntry />} />
        <Route
          path='/commercehub/configuration/:clientGUID/:versionGUID/:environmentGUID'
          element={<ClientConfigurationEntry />}
        />

        <Route path='/dashboard/outgoingBacklog' element={<OutgoingBacklogEntry />} />
        <Route path='/dashboard/connectionsOverview' element={<ConnectionsOverviewEntry />} />

        <Route path='/help' element={<HelpEntry />} />

        <Route path='/documentation/commonAS2Errors' element={<CommonAS2ErrorsEntry />} />
        <Route path='/documentation/commonSFTPErrors' element={<CommonSFTPErrorsEntry />} />
        {/* TODO */}
        {/*<Route path='/documentation/commonPartnerSFTPErrors' element={<CommonPartnerSFTPErrorsEntry />} />*/}

        <Route path='/auth/profile' element={<Profile />} />

        <Route path='*' element={<Navigate to='/' replace={true} />} />
      </Route>
    </Routes>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENTID as string,
  options: {
    bootstrap: 'localStorage',
  },
})(App);
