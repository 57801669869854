import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { debounce } from '../mockable/debounce-wrapper';
import { ChubInput, ChubP, selectableElement } from '../styles/typography';
import { colors } from 'react-chui';

const ConfigurationInput = glamorous(ChubInput, { displayName: 'ConfigurationInput' })(
  ({ small, medium, large, fontSize, width, hasSpaces, usage }) => {
    const baseStyles = {
      margin: '10px',
      ':disabled': {
        ...selectableElement,
        MozBoxShadow: 'none',
        WebkitBoxShadow: 'none',
        background: 'none',
        boxShadow: 'none',
        margin: '0 0 5px 0',
      },
    };

    if (small) {
      baseStyles.width = '75px';
    } else if (medium) {
      baseStyles.width = '300px';
    } else if (large) {
      baseStyles.width = '400px';
    } else if (width) {
      baseStyles.width = width;
    }

    if (fontSize) {
      baseStyles.fontSize = fontSize;
    }

    if (_as2IdOrSftpUsername(usage) && hasSpaces) {
      baseStyles.backgroundColor = colors.chyellow;
    }

    return baseStyles;
  }
);

const Message = glamorous(ChubP, { displayName: 'Message' })(() => {
  return {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
    display: 'inline-block',
    color: colors.chred,
  };
});

const InputContainer = glamorous('div', { displayName: 'InputContainer' })(() => {
  return {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
    display: 'inline-block',
  };
});

export default class EditableTextInput extends Component {
  static displayName = 'EditableTextInput';
  static propTypes = {
    origValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isModified: PropTypes.bool,
    onChange: PropTypes.func,
    changed: PropTypes.bool,
    readOnly: PropTypes.bool,
    debounceWaitMS: PropTypes.number,
    usage: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => undefined,
    debounceWaitMS: 500,
    type: 'text',
  };

  constructor(props) {
    super(props);
    const { origValue, newValue, debounceWaitMS } = props;
    this.state = {
      inputValue: typeof newValue === 'undefined' ? origValue : newValue,
    };
    this._onChangeCommit = debounce((value, id) => {
      const { onChange } = this.props;
      onChange(value, id);
    }, debounceWaitMS);
  }

  componentWillReceiveProps(nextProps) {
    const { origValue, newValue } = nextProps;
    const { origValue: currentOrigValue, newValue: currentNewValue } = this.props;
    const { inputValue } = this.state;
    if (origValue !== currentOrigValue || newValue !== currentNewValue || newValue !== inputValue) {
      const adjustedNewValue = newValue === null ? '' : newValue;
      this.setState({
        inputValue: typeof newValue === 'undefined' ? origValue : adjustedNewValue,
      });
    }
  }

  _onChange = ({ target: { value, id } }) => {
    this.setState({
      inputValue: value,
    });
    this._onChangeCommit(value, id);
  };

  _beginsOrEndsWithSpaces = (val) => {
    const beginsOrEndsWithSpaces = new RegExp('^\\s+.*$|^.*\\s+$');
    return beginsOrEndsWithSpaces.test(val);
  };

  render() {
    const { readOnly, usage, type, ...otherProps } = this.props;
    const { inputValue } = this.state;
    return (
      <InputContainer>
        {readOnly ? (
          <ConfigurationInput
            title={inputValue}
            type={type}
            {...otherProps}
            value={inputValue}
            onChange={this._onChange}
            usage={usage}
            hasSpaces={this._beginsOrEndsWithSpaces(inputValue)}
            disabled
          />
        ) : (
          <ConfigurationInput
            title={inputValue}
            type={type}
            {...otherProps}
            value={inputValue}
            onChange={this._onChange}
            usage={usage}
            hasSpaces={this._beginsOrEndsWithSpaces(inputValue)}
          />
        )}
        {_as2IdOrSftpUsername(usage) && this._beginsOrEndsWithSpaces(inputValue) && (
          <Message id={'as2id-leading-or-trailing-whitespace'}>Value begins or ends with spaces</Message>
        )}
      </InputContainer>
    );
  }
}

const _as2IdOrSftpUsername = (usage) => {
  return usage === 'as2Id' || usage === 'sftpUsername';
};
