import React from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import glamorous from 'glamorous';
import { ChubH1, chubStandards } from '../../styles/typography';
import { colors } from 'react-chui';

const PageTitle = glamorous(ChubH1)({
  marginBottom: '30px',
  fontSize: '28px',
  fontWeight: '800',
});

const Contents = glamorous.div({
  marginTop: '40px',
  marginLeft: '15%',
  fontFamily: chubStandards.fontFamily,
});

const HelpText = glamorous.p({
  marginBottom: '15px',
  fontSize: '15px',
  color: colors.darkgray,
  fontWeight: 400,
});

const HelpApp = () => {
  return (
    <>
      <DefaultHeader showUndoRedo={false} showSearch={true} />
      <Contents>
        <PageTitle>Help</PageTitle>
        <HelpText>
          If you need help with Smooth Operator, please send a message to @platform-services in #h_platform-engineering on Slack.
        </HelpText>
        <HelpText>In the future, we may add help content to this page, so keep an eye out.</HelpText>
      </Contents>
    </>
  );
};
HelpApp.displayName = 'Help';

export default HelpApp;
