import React from 'react';
import PropTypes from 'prop-types';
import {
  ChubTableBodyRow,
  ChubTableBodyCell,
  ChubTableBodyRowError,
  ChubTableBodyCellError,
} from '../../../styles/table';
import glamorous, { GlamorousComponent } from 'glamorous';
import { AttentionIcon } from '../../../styles/icons';
import { TableType } from '../PartnerSFTPTransfers.model';
import { ChubA } from '../../../styles/typography';

const BreakWordP: GlamorousComponent<any, any> = glamorous('p', {
  displayName: 'BreakWordP',
})({
  overflowWrap: 'break-word',
});

const PartnerSFTPPastTransfersTableRow = ({
  externalId,
  messageId,
  startDate,
  endDate,
  resultText,
  fileName,
  status,
  vlSerial,
  tableType,
}: {
  externalId: string;
  messageId: string;
  startDate: string;
  endDate: string;
  resultText: string;
  fileName: string;
  status: string;
  vlSerial: string;
  tableType: TableType;
}) => {
  const isErrorOrException = status === 'Error' || status === 'Exception';
  const RowType = isErrorOrException ? ChubTableBodyRowError : ChubTableBodyRow;
  const CellType = isErrorOrException ? ChubTableBodyCellError : ChubTableBodyCell;

  return (
    <RowType>
      {tableType === TableType.OUTGOING && <CellType colSpan='2'>{externalId}</CellType>}
      <CellType colSpan='5'>
        <BreakWordP>
          <ChubA to={`/lookup/lookupPartnerSFTPMessageId/${messageId}`}>{messageId}</ChubA>
        </BreakWordP>
      </CellType>
      <CellType colSpan='3' align='center'>
        {startDate}
      </CellType>
      <CellType colSpan='3' align='center'>
        {endDate}
      </CellType>
      <CellType colSpan='4'>
        <BreakWordP>
          {isErrorOrException && <AttentionIcon error='true' />}
          {resultText}
        </BreakWordP>
      </CellType>
      <CellType colSpan='4'>
        <BreakWordP>{fileName}</BreakWordP>
      </CellType>
      <CellType align='center' colSpan='2'>
        {status}
      </CellType>
      <CellType colSpan='2'>{vlSerial}</CellType>
    </RowType>
  );
};

PartnerSFTPPastTransfersTableRow.displayName = 'PartnerSFTPPastTransfersTableRow';
PartnerSFTPPastTransfersTableRow.propTypes = {
  externalId: PropTypes.string,
  messageId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  resultText: PropTypes.string,
  fileName: PropTypes.string,
  status: PropTypes.string,
  vlSerial: PropTypes.string,
  tableType: PropTypes.string,
};

export default PartnerSFTPPastTransfersTableRow;
