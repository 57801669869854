import { FileRouteTypeViewModel } from '../../../../../viewmodels/fileRouteType/FileRouteTypeViewModel';
import { uuidv4 } from '../../../../../mockable/uuidv4-wrapper';
import cloneDeep from 'lodash.clonedeep';

export function addFileRouteType(state) {
  const newFileRouteType = new FileRouteTypeViewModel({
    fileRouteTypeGUID: uuidv4(),
    fileTypeId: { origValue: 0 },
    description: { origValue: '' },
    direction: { origValue: '' },
    application: { origValue: '' },
    applicationCode: { origValue: '' },
    validForAS2: { origValue: false },
    validForSFTP: { origValue: false },
    validForPartnerSFTP: { origValue: false },
    directoryName: { origValue: '' },
    defaultRegex: { origValue: '' },
    defaultExtension: { origValue: '' },
    defaultMd5: { origValue: false },
    author: { origValue: 'user-unknown' },
  });
  newFileRouteType.added = true;

  return {
    ...state,
    fileRouteTypes: {
      [newFileRouteType.fileRouteTypeGUID]: newFileRouteType,
      ...state.fileRouteTypes,
    },
  };
}

export function deleteFileRouteType(state, { fileRouteTypeGUIDToDelete }) {
  const stateCopy = cloneDeep(state);
  const fileRouteTypeToDelete = stateCopy.fileRouteTypes[fileRouteTypeGUIDToDelete];
  if (fileRouteTypeToDelete) {
    fileRouteTypeToDelete.deleted = true;
  }
  return stateCopy;
}

export function updateFileRouteType(state, { fileRouteTypeGUID, key, value, serverMessages }) {
  const stateCopy = cloneDeep(state);
  const fileRouteType = stateCopy.fileRouteTypes[fileRouteTypeGUID];
  fileRouteType[key].value = value;
  fileRouteType[key].serverMessages = serverMessages;
  if (key === 'directoryName' || key === 'validForSFTP') {
    if (fileRouteType.validForSFTP.value && fileRouteType.directoryName && !fileRouteType.directoryName.value) {
      fileRouteType.directoryName.serverMessages = [
        { messageType: 'VALIDATION_ERROR', message: 'Directory is required if SFTP is checked.' },
      ];
    } else {
      fileRouteType.directoryName.serverMessages = [];
    }
  }
  return stateCopy;
}
