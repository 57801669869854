import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import ConfirmBubble from './ConfirmBubble';
import { ChubButton, ChubP, chubStandards } from '../styles/typography';
import { colors } from 'react-chui';

const SaveCancelBar = glamorous('div', { displayName: 'SaveCancelBar' })(chubStandards, {
  width: '100%',
  height: '50px',
  backgroundColor: colors.coolgraylight2,
});

const ButtonCollection = glamorous('div', { displayName: 'ButtonCollection' })({
  float: 'right',
});

const SaveButton = glamorous(ChubButton, { displayName: 'SaveButton' })({
  float: 'right',
  margin: '7px 25px 0 0',
  width: '86px',
});

const CancelButton = glamorous(ChubButton, { displayName: 'CancelButton' })({
  float: 'right',
  margin: '7px 25px 0 0',
  color: colors.brightblue,
  background: 'rgba(0,0,0,0)',
  boxShadow: 'none',
  ':hover': {
    background: 'transparent',
    color: colors.brightblue,
    textDecoration: 'underline',
  },
});

const Message = glamorous(ChubP, { displayName: 'Message' })({
  fontSize: '14px',
  marginRight: '15px',
  float: 'right',
  color: '#535454',
});

export default class SaveCancelFooter extends Component {
  static displayName = 'SaveCancelFooter';

  static noChangesMessages = [
    'There are no changes to save',
    'There are no changes to save',
    'There are no changes to save',
    "You haven't made any changes",
    'Still no changes',
    "There still aren't changes - clicking will not change that",
    'What are you hoping to have happen?',
    'While I admire your dedication, there are no changes to save',
    'Why are you doing this to me?',
    "Does your supervisor know you're spending time on this?",
    "If you keep doing this, I'm going to send them an email",
    "I'm typing it now",
    'I am serious',
    'I am filling in their address...',
    "I'm hitting send",
    "OK - it's sent. Now will you stop clicking?",
    "I'm just going to give them a minute to read it",
    'Talk amongst yourselves for a bit',
    '(sonicwaiting)',
    "You called my bluff - I can't send emails",
    'Please stop',
    "I can't save *nothing*",
    'Every time you click that button, a puppy cries',
    "You're a monster, you know that?",
    "You know what? I'm just not going to show you any more messages",
  ];

  static propTypes = {
    canCancel: PropTypes.bool,
    canSave: PropTypes.bool,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    saveSuccessful: PropTypes.bool,
    requireConfirm: PropTypes.bool,
    confirmMessage: PropTypes.string,
  };

  static defaultProps = {
    saveSuccessful: false,
    requireConfirm: false,
    confirmMessage: 'Are you sure?',
  };

  componentWillMount() {
    this.setState({
      showNoChangesMessage: -1,
      confirmedForSave: false,
      showConfirm: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { canSave } = this.props;
    if (canSave !== nextProps.canSave) {
      this.setState({
        showNoChangesMessage: -1,
      });
    }
  }

  hideConfirmBubble = () => {
    this.setState({
      showConfirm: false,
    });
  };

  _onNoConfirm = () => {
    this.hideConfirmBubble();
  };

  _onConfirm = () => {
    const { onSave } = this.props;
    this.hideConfirmBubble();
    onSave();
  };

  _onSave = () => {
    const { canSave, onSave, requireConfirm } = this.props;
    const { showNoChangesMessage } = this.state;

    if (!canSave) {
      this.setState({
        showNoChangesMessage: showNoChangesMessage + 1,
      });
      return;
    }

    this.setState({
      showNoChangesMessage: -1,
    });

    if (requireConfirm) {
      this.setState({
        showConfirm: true,
      });
      return;
    }

    onSave();
  };

  render() {
    const { canSave, onCancel, saveSuccessful, confirmMessage } = this.props;
    const { showNoChangesMessage, showConfirm } = this.state;
    const canShowSuccessfulSave = !canSave && saveSuccessful;

    return (
      <SaveCancelBar>
        <ButtonCollection>
          <CancelButton title='Cancel Changes' id='cancel-button' onClick={onCancel}>
            Cancel
          </CancelButton>
          <SaveButton title='Save Changes' id='save-button' onClick={this._onSave}>
            {showConfirm && (
              <ConfirmBubble
                id='confirm-bubble'
                onConfirm={this._onConfirm}
                onNoConfirm={this._onNoConfirm}
                confirmMessage={confirmMessage}
              />
            )}
            Save
          </SaveButton>
        </ButtonCollection>
        {canSave && <Message id='unsaved-changes-message'>You have unsaved changes.</Message>}
        {canShowSuccessfulSave && <Message id='save-successful-message'>Save successful.</Message>}
        {showNoChangesMessage >= 0 && (
          <Message id='no-changes-message'>{SaveCancelFooter.noChangesMessages[showNoChangesMessage]}</Message>
        )}
      </SaveCancelBar>
    );
  }
}
