export function toggleShowRelationships({ showRelationships, ...state }) {
  return {
    ...state,
    showRelationships: !showRelationships,
  };
}

export function fetchRelationshipsLoading(state) {
  return {
    ...state,
    isLoading: true,
    error: '',
  };
}

export function fetchRelationshipsCompleted(state) {
  return {
    ...state,
    isLoading: false,
    error: '',
  };
}

export function fetchRelationshipsFailed(state, { error }) {
  return {
    ...state,
    isLoading: false,
    error: error.message,
  };
}

export function receiveRelationships(state, { relationships }) {
  return {
    ...state,
    relationships,
  };
}
