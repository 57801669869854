import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DefaultHeaderContainer from './containers/DefaultHeaderContainer';
import FileRouteTypeListEditorContainer from './containers/FileRouteTypeListEditorContainer';
import LoadingStateProviderContainer from './containers/LoadingStateProviderContainer';
import ClientSummary from '../../../components/ClientSummary';

const FileRouteTypes = ({ readOnly }) => (
  <Fragment>
    <DefaultHeaderContainer readOnly={readOnly} />
    <ClientSummary clientName='CommerceHub' />
    <LoadingStateProviderContainer>{() => <FileRouteTypeListEditorContainer />}</LoadingStateProviderContainer>
  </Fragment>
);

FileRouteTypes.displayName = 'FileRouteTypes';
FileRouteTypes.propTypes = {
  readOnly: PropTypes.bool,
};

export default FileRouteTypes;
