import React from 'react';
import PropTypes from 'prop-types';
import { ChubTableBodyCell, ChubTableBodyRow } from '../../../styles/table';
import { ChubA } from '../../../styles/typography';

export const SftpConnectionsTableRow = ({ client_name, client_guid }: { client_name: string; client_guid: string }) => {
  return (
    <ChubTableBodyRow>
      <ChubTableBodyCell>
        <ChubA to={`/client/clientOverview/${client_guid}`}>{client_name}</ChubA>
      </ChubTableBodyCell>
    </ChubTableBodyRow>
  );
};

SftpConnectionsTableRow.displayName = 'SftpConnectionsTableRow';
SftpConnectionsTableRow.propTypes = {
  client_name: PropTypes.string,
  client_guid: PropTypes.string,
};
