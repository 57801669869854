import { combineReducers } from 'redux';
import undoableFileRouteType from './undoables/undoableFileRouteType';
import loadingReducer from './loading/fileRouteTypesLoadingReducer';

const reducer = combineReducers({
  loading: loadingReducer,
  undoableFileRouteTypes: undoableFileRouteType,
});

export default reducer;
