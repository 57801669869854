import configurationCanSave from './configurationCanSave';

export function receiveConfigurationViewModel(state, action) {
  const { currentSection } = state;
  const { configurationViewModel, showVersionName } = action;
  try {
    const currentSectionExists =
      configurationViewModel.sections &&
      Object.keys(configurationViewModel.sections).length > 0 &&
      configurationViewModel.sections[currentSection];
    return {
      ...state,
      ...configurationViewModel,
      showVersionName,
      currentSection: currentSectionExists ? currentSection : Object.keys(configurationViewModel.sections)[0],
    };
  } catch (ignore) {
    // This should be a ServerMessageViewModel, so we'll return it as such
    return {
      ...state,
      serverMessages: [configurationViewModel],
    };
  }
}

export function resetSectionCanSave(state, action) {
  const { sections } = state;
  const { calledAfterSave } = action;
  const sectionsCopy = { ...sections };
  Object.keys(sectionsCopy).forEach((sectionKey) => {
    const { as2Connections, sftpConnection } = sectionsCopy[sectionKey];
    // eslint-disable-next-line
    as2Connections &&
      as2Connections.forEach((as2Connection) => {
        Object.keys(as2Connection).forEach((configuration) => {
          if (as2Connection[configuration].origValue) {
            as2Connection[configuration].changed =
              typeof as2Connection[configuration].newValue !== 'undefined' &&
              as2Connection[configuration].origValue !== as2Connection[configuration].newValue;
          }
        });
        // eslint-disable-next-line
        as2Connection.fileRoutes &&
          Object.keys(as2Connection.fileRoutes).forEach((fileRouteGUID) => {
            const fileRoute = as2Connection.fileRoutes[fileRouteGUID];
            Object.keys(fileRoute).forEach((currentKey) => {
              const { origValue, newValue } = fileRoute[currentKey];
              if (origValue) {
                fileRoute[currentKey].changed = typeof newValue !== 'undefined' && origValue !== newValue;
              }
            });
          });
      });
    if (sftpConnection) {
      Object.keys(sftpConnection).forEach((configuration) => {
        if (sftpConnection[configuration].origValue) {
          sftpConnection[configuration].changed =
            typeof sftpConnection[configuration].newValue !== 'undefined' &&
            sftpConnection[configuration].origValue !== sftpConnection[configuration].newValue;
        }
      });
      // eslint-disable-next-line
      sftpConnection.fileRoutes &&
        Object.keys(sftpConnection.fileRoutes).forEach((fileRouteGUID) => {
          const fileRoute = sftpConnection.fileRoutes[fileRouteGUID];
          Object.keys(fileRoute).forEach((currentKey) => {
            const { origValue, newValue } = fileRoute[currentKey];
            if (origValue) {
              fileRoute[currentKey].changed = typeof newValue !== 'undefined' && origValue !== newValue;
            }
          });
          // eslint-disable-next-line
          fileRoute.subPaths &&
            Object.keys(fileRoute.subPaths).forEach((subPathGUID) => {
              const subPath = fileRoute.subPaths[subPathGUID];
              if (subPath.origValue) {
                subPath.changed = typeof subPath.newValue !== 'undefined' && subPath.origValue !== subPath.newValue;
              }
            });
        });
    }
    const canConfigurationSave = configurationCanSave(sectionsCopy[sectionKey]);
    const { serverMessages } = state;
    const hasError = Boolean(serverMessages) && serverMessages.length;
    sectionsCopy[sectionKey].canSave = canConfigurationSave;
    sectionsCopy[sectionKey].saveSuccessful = !hasError && !canConfigurationSave && calledAfterSave;
  });

  return {
    ...state,
    sections: {
      ...sectionsCopy,
    },
  };
}
