import { fetchClient } from '../../../util/fetchClient';
import {
  FETCH_CERTIFICATES_LOADING,
  FETCH_CONNECTIONS_COMPLETED,
  FETCH_CONNECTIONS_FAILED,
  FETCH_CONNECTIONS_LOADING,
  FILTER_CONNECTIONS,
  RECEIVE_CERTIFICATE_NAMES,
  RECEIVE_CONNECTIONS,
  RESET_STATE,
} from '../ConnectionsActionTypes';

export function fetchConnections(environmentName, protocol, filters) {
  return (dispatch) => {
    dispatch(fetchConnectionsLoading(environmentName, protocol));
    return fetchClient
      .get(`/connections/overview/protocol/${protocol}/environment/${environmentName}`)
      .then((response) => response.data)
      .then((messageDetails) => {
        dispatch(receiveConnections(messageDetails));
        dispatch(filterConnections(filters, protocol));
        dispatch(fetchConnectionsComplete());
      })
      .catch((error) => dispatch(fetchConnectionsFailed(error)));
  };
}

export function fetchCertificatesAndConnections(environmentName, protocol, filters) {
  return (dispatch) => {
    dispatch(fetchCertificatesLoading());
    fetchClient
      .get('/config/certificates')
      .then((response) => response.data)
      .then((data) => data.chubSystemCertificates.map((certificate) => certificate.certificateName))
      .then((certificateNames) => {
        dispatch(receiveCertificateNames(certificateNames));
        dispatch(fetchConnections(environmentName, protocol, filters));
      })
      .catch((error) => dispatch(fetchCertificatesFailed(error)));
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function filterConnections(filters, protocol) {
  return {
    type: FILTER_CONNECTIONS,
    usesNewIps: filters.usesNewIps,
    isEnabled: filters.isEnabled,
    certificate: filters.certificate,
    protocol,
  };
}

function fetchConnectionsLoading(selectedEnvironment, selectedProtocol) {
  return {
    type: FETCH_CONNECTIONS_LOADING,
    selectedEnvironment,
    selectedProtocol,
  };
}

function receiveConnections(messageDetails = {}) {
  return {
    type: RECEIVE_CONNECTIONS,
    messageDetails,
  };
}

function receiveCertificateNames(certificateNames) {
  return {
    type: RECEIVE_CERTIFICATE_NAMES,
    certificateNames,
  };
}

function fetchConnectionsComplete() {
  return {
    type: FETCH_CONNECTIONS_COMPLETED,
  };
}

function fetchConnectionsFailed(error) {
  return {
    type: FETCH_CONNECTIONS_FAILED,
    error,
  };
}

function fetchCertificatesLoading() {
  return {
    type: FETCH_CERTIFICATES_LOADING,
  };
}

function fetchCertificatesFailed(error) {
  return {
    type: FETCH_CONNECTIONS_FAILED,
    error,
  };
}
