import React, { useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { fetchFileRouteTypes } from './actions';

import FileRouteTypes from './FileRouteTypesApp';
import undoableFileRouteType from './reducers';

const store = createStore(undoableFileRouteType, applyMiddleware(thunkMiddleware));

const FileRouteTypesEntry = () => {
  useEffect(() => {
    store.dispatch(fetchFileRouteTypes());
  }, []);

  return (
    <Provider store={store}>
      <FileRouteTypes readOnly={false} />
    </Provider>
  );
};

export default FileRouteTypesEntry;
