import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';

const UndoRedoCollection = glamorous('p', { displayName: 'UndoRedoCollection' })({
  display: 'inline-block',
});

const CircularButton = glamorous('button', { displayName: 'CircularButton' })({
  backgroundColor: colors.medgray,
  border: 'none',
  borderRadius: '50%',
  height: '30px',
  margin: '0 4px',
  width: '30px',
  ':enabled': {
    ':hover': {
      background: '#888888',
      color: '#FFF',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
});

export default class UndoRedo extends Component {
  static displayName = 'UndoRedo';

  static propTypes = {
    canRedo: PropTypes.bool.isRequired,
    onRedo: PropTypes.func.isRequired,
    canUndo: PropTypes.bool.isRequired,
    onUndo: PropTypes.func.isRequired,
  };

  render() {
    const { canRedo, onRedo, canUndo, onUndo } = this.props;
    return (
      <UndoRedoCollection>
        <CircularButton title='Undo' id='undo-button' onClick={onUndo} disabled={!canUndo}>
          <i className='fa fa-undo' aria-hidden='true' />
        </CircularButton>
        <CircularButton title='Redo' id='redo-button' onClick={onRedo} disabled={!canRedo}>
          <i className='fa fa-repeat' aria-hidden='true' />
        </CircularButton>
      </UndoRedoCollection>
    );
  }
}
