import { RECEIVE_ENVIRONMENTS, RECEIVE_SAVE_RESULT, SET_READ_ONLY } from '../EnvironmentActionTypes';
import * as UndoableActions from '../EnvironmentsUndoableActionTypes';

import cloneDeep from 'lodash.clonedeep';

const initialState = {
  isLoading: false,
  isSaving: false,
  readOnly: true,
  error: '',
  environments: [],
};

function setReadOnly(state, { readOnly }) {
  return {
    ...state,
    readOnly,
  };
}

function receiveEnvironments(state, actionArgs) {
  const { environments } = actionArgs;
  return {
    ...state,
    environments,
  };
}

function updateEnvironment(state, { payload: { environmentGUID, newEnvironmentName, newAS2Gateways } }) {
  const stateCopy = cloneDeep(state);
  const environmentToUpdate = stateCopy.environments.find((environment) => {
    return environment.environmentGUID.origValue === environmentGUID;
  });
  if (environmentToUpdate) {
    if (newEnvironmentName) {
      environmentToUpdate.environmentName.value = newEnvironmentName;
    }
    if (newAS2Gateways) {
      environmentToUpdate.as2Gateways.value = newAS2Gateways;
    }
  }
  return stateCopy;
}

function addEnvironment(state, { newEnvironment }) {
  return {
    ...state,
    environments: [newEnvironment, ...state.environments],
  };
}

function receiveSaveResult(state, { errors, editedEnvironments = [] }) {
  const saveCompleteEnvironments = state.environments;
  const erroredEnvironmentGuids = errors.map(({ environmentGUID, errorMessage }) => {
    const errorEnv = saveCompleteEnvironments.find(
      (environment) => environment.environmentGUID.origValue === environmentGUID
    );
    errorEnv.environmentName.serverMessages = [errorMessage];
    return environmentGUID;
  });
  const successfullyEditedEnvironments = editedEnvironments.filter(
    ({ environmentGUID }) => !erroredEnvironmentGuids.includes(environmentGUID.value)
  );
  successfullyEditedEnvironments.forEach(({ environmentGUID, name, as2Gateways, createDate, updateDate }) => {
    const changedEnvironment = saveCompleteEnvironments.find(
      (environment) => environment.environmentGUID.origValue === environmentGUID
    );
    changedEnvironment.added = false;
    changedEnvironment.environmentName.reset(name);
    changedEnvironment.environmentGUID.reset(environmentGUID);
    changedEnvironment.as2Gateways.reset(JSON.stringify(as2Gateways));
    changedEnvironment.createDate = createDate;
    changedEnvironment.updateDate = updateDate;
  });

  return {
    ...state,
  };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_READ_ONLY:
      return setReadOnly(state, action);
    case RECEIVE_ENVIRONMENTS:
      return receiveEnvironments(state, action);
    case RECEIVE_SAVE_RESULT:
      return receiveSaveResult(state, action);
    case UndoableActions.UPDATE_ENVIRONMENT:
      return updateEnvironment(state, action);
    case UndoableActions.ADD_ENVIRONMENT:
      return addEnvironment(state, action);
    default:
      break;
  }
  return state;
};

export default reducer;
