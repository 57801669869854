import { connect } from 'react-redux';
import { updateEnvironment } from '../actions';
import EnvironmentRow from '../components/EnvironmentRow';

const mapStateToProps = ({
  undoableEnvironments: {
    present: { readOnly },
  },
}) => {
  return {
    readOnly,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (environment) => {
      dispatch(updateEnvironment(environment));
    },
  };
};

const EnvironmentRowContainer = connect(mapStateToProps, mapDispatchToProps)(EnvironmentRow);

export default EnvironmentRowContainer;
