import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SaveCancelFooter from '../../../components/SaveCancelFooter';
import { ChubButton, ChubInput, chubStandards } from '../../../styles/typography';
import glamorous from 'glamorous';
import { colors } from 'react-chui';
import { fetchClient } from '../../../util/fetchClient';
import { useNavigate } from 'react-router-dom';

const TextWrapper = glamorous('label', { displayName: 'TextWrapper' })(chubStandards, {
  height: '30px',
  marginLeft: '20px',
});

const SuccessTextWrapper = glamorous(TextWrapper, { displayName: 'GreenTextWrapper' })({
  color: colors.chgreen,
  margin: '0 50px',
});

const ErrorTextWrapper = glamorous(TextWrapper, { displayName: 'ErrorTextWrapper' })({
  color: colors.chred,
  margin: '0 50px',
});

const SaveCancelFooterWrapper = glamorous('div', { displayName: 'SaveCancelFooterWrapper' })({
  marginTop: '10px',
});

const CreateClientButton = glamorous(ChubButton, { displayName: 'CreateClientButton' })({
  width: 'auto',
  marginLeft: '5px',
  height: '28px',
  lineHeight: '28px',
  fontSize: '16px',
});

const ClientInput = glamorous(ChubInput, { displayName: 'ClientInput' })(({ visibility }) => ({
  height: '33px',
  width: '200px',
  maxWidth: '300px',
  marginLeft: '10px',
  visibility,
}));

const CLIENT_VERSIONS_PAGE = '/client/clientOverview/';
const BLANK_CLIENT_NAME_ERROR = 'Please enter a name for the new client.';

const ClientCreator = ({ searchQuery }) => {
  const [clientCreationData, setClientCreationData] = useState({
    showClientCreationTools: false,
    newClientName: searchQuery,
  });

  useEffect(() => {
    setClientCreationData({ showClientCreationTools: false, newClientName: searchQuery });
  }, [searchQuery]);

  const { showClientCreationTools, newClientName } = clientCreationData;

  const [saveResults, setSaveResults] = useState({ feedback: '', saveSuccess: false });
  const { feedback, saveSuccess } = saveResults;

  const navigate = useNavigate();

  const handleCreateClicked = () => {
    setClientCreationData({ showClientCreationTools: true, newClientName });
  };

  const handleCancelClicked = () => {
    setClientCreationData({ showClientCreationTools: false, newClientName: searchQuery });
  };

  const requestClientSave = (newClientName) => {
    return fetchClient.post(`/client/create?clientName=${newClientName}`).then((response) => response.data);
  };

  const handleSaveClicked = () => {
    if (newClientName) {
      requestClientSave(newClientName).then(({ feedback, saveSuccess, clientGUID }) => {
        setSaveResults({ feedback, saveSuccess });
        if (saveSuccess) {
          redirectOnClientCreation(clientGUID);
        }
      });
    } else {
      setSaveResults({ feedback: BLANK_CLIENT_NAME_ERROR, saveSuccess: false });
    }
  };

  const redirectOnClientCreation = (clientGUID) => {
    navigate(`${CLIENT_VERSIONS_PAGE}${clientGUID}`);
  };

  const onClientNameChange = (event) => {
    setClientCreationData({ showClientCreationTools, newClientName: event.target.value });
  };

  const renderFeedbackText = (feedback, saveSuccess) => {
    return saveSuccess ? (
      <SuccessTextWrapper id='success-text'>{feedback}</SuccessTextWrapper>
    ) : (
      <ErrorTextWrapper id='error-text'>{feedback}</ErrorTextWrapper>
    );
  };

  return (
    <Fragment>
      <TextWrapper id='client-not-found-text'>No exact name match found.</TextWrapper>
      <CreateClientButton id='create-client-button' onClick={() => handleCreateClicked()}>
        Create New Client
      </CreateClientButton>
      {showClientCreationTools && (
        <Fragment>
          <ClientInput id='client-name-textbox' value={newClientName} onChange={(event) => onClientNameChange(event)} />
          {feedback && renderFeedbackText(feedback, saveSuccess)}
          <SaveCancelFooterWrapper>
            <SaveCancelFooter
              canCancel={true}
              canSave={true}
              onCancel={() => handleCancelClicked()}
              onSave={() => handleSaveClicked()}
            />
          </SaveCancelFooterWrapper>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ClientCreator;
ClientCreator.displayName = 'ClientCreator';

ClientCreator.propTypes = {
  searchQuery: PropTypes.string,
};
