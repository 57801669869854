export const UPDATE_AS2ID = 'UPDATE_AS2ID';
export const ADD_AS2ID = 'ADD_AS2ID';
export const DELETE_AS2ID = 'DELETE_AS2ID';
export const SAVE_AS2IDS = 'SAVE_AS2IDS';
export const CANCEL_CHANGES = 'CANCEL_CHANGES';
export const REQUEST_AS2IDENTIFIERS = 'REQUEST_AS2IDENTIFIERS';
export const REQUEST_AS2IDENTIFIERS_FAILED = 'REQUEST_AS2IDENTIFIERS_FAILED';
export const RECEIVE_AS2IDENTIFIERS = 'RECEIVE_AS2IDENTIFIERS';
export const REQUEST_SAVE_AS2IDENTIFIERS = 'REQUEST_SAVE_AS2IDENTIFIERS';
export const SAVE_COMPLETE = 'SAVE_COMPLETE';
export const SAVE_FAILED = 'SAVE_FAILED';
