import configurationCanSave from './configurationCanSave';

export function constructNewStateWithFileRoutes(state, connectionTypeKey, fileRoutes) {
  const { currentSection, sections } = state;
  const editedCurrentSection = buildNewSection(sections[currentSection], connectionTypeKey, fileRoutes);

  return {
    ...state,
    sections: {
      ...sections,
      [currentSection]: {
        ...editedCurrentSection,
        canSave: configurationCanSave(editedCurrentSection),
      },
    },
  };
}

function buildNewSection(section, connectionTypeKey, fileRoutes) {
  let newSection;

  if (connectionTypeKey === 'as2Connections') {
    const as2ConnectionList = section[connectionTypeKey];
    const as2Connection = as2ConnectionList[0];
    const newAs2Connection = {
      ...as2Connection,
      fileRoutes,
    };

    as2ConnectionList.splice(0, 1, newAs2Connection);

    newSection = {
      ...section,
      as2Connections: as2ConnectionList,
    };
  } else if (connectionTypeKey === 'sftpConnection') {
    const sftpConnection = section[connectionTypeKey];
    const newSftpConnection = {
      ...sftpConnection,
      fileRoutes,
    };
    newSection = {
      ...section,
      sftpConnection: newSftpConnection,
    };
  } else {
    const partnerSftpConnectionList = section[connectionTypeKey];
    const partnerSftpConnection = partnerSftpConnectionList[0];
    const newPartnerSftpConnection = {
      ...partnerSftpConnection,
      fileRoutes,
    };

    partnerSftpConnectionList.splice(0, 1, newPartnerSftpConnection);

    newSection = {
      ...section,
      partnerSftpConnections: partnerSftpConnectionList,
    };
  }

  return newSection;
}
