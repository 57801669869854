import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { ChubButton, ChubP, selectableElement } from '../../../styles/typography';

const PasswordContainer = glamorous('div', { displayName: 'PasswordContainer' })({
  marginLeft: '12px',
});

const PasswordGroup = glamorous(ChubP, { displayName: 'PasswordGroup' })({
  fontSize: '18px',
  fontWeight: 'bold',
});

const Password = glamorous('span', { displayName: 'Password' })(selectableElement, {
  padding: '0 10px',
});

export default class PasswordGenerator extends Component {
  static displayName = 'PasswordGenerator';

  static propTypes = {
    sectionName: PropTypes.string,
    generatedPassword: PropTypes.string,
    requestGeneratedSftpPassword: PropTypes.func,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: true,
  };

  _renderPasswordText = () => {
    const { generatedPassword, readOnly } = this.props;
    const showPassword = typeof generatedPassword !== 'undefined';
    return showPassword && !readOnly ? generatedPassword : '********************';
  };

  _onClickGeneratePassword = () => {
    const { requestGeneratedSftpPassword, sectionName } = this.props;
    requestGeneratedSftpPassword(sectionName);
  };

  render() {
    const { readOnly } = this.props;
    return (
      <PasswordContainer>
        <PasswordGroup>
          <span>Password:</span>
          <Password id='generated-password'>{this._renderPasswordText()}</Password>
        </PasswordGroup>
        {!readOnly && (
          <ChubButton id='generate-password-button' onClick={this._onClickGeneratePassword}>
            Generate
          </ChubButton>
        )}
      </PasswordContainer>
    );
  }
}
