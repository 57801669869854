import React, { Component } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { ChubLinkButton } from '../../../styles/typography';

import { ChubTableBodyRow, ChubTableBodyCell } from '../../../styles/table';

const ViewVersionButton = glamorous(ChubLinkButton, { displayName: 'ViewVersionButton' })({
  textDecoration: 'none',
});

export default class VersionRow extends Component {
  static displayName = 'VersionRow';

  static propTypes = {
    clientGUID: PropTypes.string,
    name: PropTypes.string,
    environment: PropTypes.string,
    status: PropTypes.string,
    author: PropTypes.string,
    versionGUID: PropTypes.string,
    displayNonDeployedVersions: PropTypes.bool,
  };

  render() {
    const { name, environment, status, author, displayNonDeployedVersions, clientGUID, versionGUID, ...otherProps } =
      this.props;
    return (
      <ChubTableBodyRow {...otherProps}>
        <ChubTableBodyCell noWrap>{name}</ChubTableBodyCell>
        <ChubTableBodyCell noWrap>{environment}</ChubTableBodyCell>
        <ChubTableBodyCell noWrap>{status}</ChubTableBodyCell>
        <ChubTableBodyCell noWrap>{author}</ChubTableBodyCell>
        {displayNonDeployedVersions && (
          <ChubTableBodyCell>
            <ViewVersionButton to={`/commercehub/configuration/${clientGUID}/${versionGUID}`} id='view-link'>
              View
            </ViewVersionButton>
          </ChubTableBodyCell>
        )}
      </ChubTableBodyRow>
    );
  }
}
