import {
  RESET_STATE,
  SEND_TEST_FILE_COMPLETED,
  SEND_TEST_FILE_FAILED,
  SEND_TEST_FILE_PROCESSING,
} from '../ClientConfigurationActionTypes';

const initialState = {
  sections: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_TEST_FILE_PROCESSING:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.currentSection]: {
            testFileSendRequestComplete: false,
            error: '',
          },
        },
      };
    case SEND_TEST_FILE_COMPLETED:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.currentSection]: {
            testFileSendRequestComplete: true,
            error: '',
          },
        },
      };
    case SEND_TEST_FILE_FAILED:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.currentSection]: {
            testFileSendRequestComplete: true,
            error: action.error,
          },
        },
      };
    case RESET_STATE:
      return initialState;
  }
  return state;
};

export default reducer;
