import {
  FETCH_STOPPABLE_FILES_COMPLETED,
  FETCH_STOPPABLE_FILES_FAILED,
  FETCH_STOPPABLE_FILES_LOADING,
} from '../../StoppableFilesActionTypes';
import {
  fetchStoppableFilesCompleted,
  fetchStoppableFilesFailed,
  stoppableFilesLoading,
} from './stoppableFilesLoadingReducerMethods';

import { defaultEnvironments } from './../../defaultEnvironments';

const initialState = {
  isLoading: false,
  error: '',
  selectedEnvironment: defaultEnvironments[0],
  environmentList: defaultEnvironments,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STOPPABLE_FILES_LOADING:
      return stoppableFilesLoading(state, action);
    case FETCH_STOPPABLE_FILES_COMPLETED:
      return fetchStoppableFilesCompleted(state);
    case FETCH_STOPPABLE_FILES_FAILED:
      return fetchStoppableFilesFailed(state, action);
  }
  return state;
};

export default loadingReducer;
