import React from 'react';
import PropTypes from 'prop-types';

import DefaultHeader from '../../../components/DefaultHeader';
import As2LookupSearch from './components/As2LookupSearch';

const As2LookupApp = ({ messageId }: { messageId?: string }) => (
  <>
    <DefaultHeader showSearch={false} />
    <As2LookupSearch messageId={messageId} />
  </>
);

As2LookupApp.displayName = 'As2LookupApp';
As2LookupApp.propTypes = {
  messageId: PropTypes.string,
};

export default As2LookupApp;
