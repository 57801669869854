import axios, { AxiosRequestConfig } from 'axios';

let getAccessToken = () => Promise.resolve('');

const backendUrl = process.env.REACT_APP_BACKEND_URL || '';

export const fetchClient = axios.create({
  baseURL: backendUrl + process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

fetchClient.interceptors.request.use(async (config) => {
  const token = await getAccessToken();
  if (token && config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

fetchClient.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const errorMessage = error?.response?.data?.message;
    if (errorMessage && error) {
      error.message = errorMessage;
    }
    return Promise.reject(error);
  }
);

export const setGetAccessTokenFunc = (getAccessTokenFunc: () => Promise<string>) => {
  getAccessToken = getAccessTokenFunc;
};

export const fetcher = async (url: string, config?: AxiosRequestConfig) => {
  return (await fetchClient.get(url, config)).data;
};
