import {
  REQUEST_FILEROUTETYPES,
  REQUEST_FILEROUTETYPES_COMPLETE,
  REQUEST_FILEROUTETYPES_FAILED,
  SAVE_FILEROUTETYPES_COMPLETED,
  SAVE_FILEROUTETYPES_LOADING,
  LOADING_ERROR,
} from '../../FileRouteTypesActionTypes';

import {
  requestFileRouteTypes,
  requestFileRouteTypesFailed,
  requestFileRouteTypesComplete,
  saveFileRouteTypesLoading,
  saveFileRouteTypesCompleted,
  loadingError,
} from './fileRouteTypesLoadingReducerMethods';

const initialState = {
  isLoading: false,
  isSaving: false,
  error: '',
};

const fileRouteTypesLoadingReducer = (state = initialState, { type, ...actionArgs }) => {
  switch (type) {
    case REQUEST_FILEROUTETYPES:
      return requestFileRouteTypes(state);
    case REQUEST_FILEROUTETYPES_FAILED:
      return requestFileRouteTypesFailed(state, actionArgs);
    case REQUEST_FILEROUTETYPES_COMPLETE:
      return requestFileRouteTypesComplete(state);
    case SAVE_FILEROUTETYPES_LOADING:
      return saveFileRouteTypesLoading(state);
    case SAVE_FILEROUTETYPES_COMPLETED:
      return saveFileRouteTypesCompleted(state);
    case LOADING_ERROR:
      return loadingError(state, actionArgs);
    default:
      break;
  }
  return state;
};

export default fileRouteTypesLoadingReducer;
