import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import ExportAs2DataItem from '../ExportAs2DataItem';

const ChubExportInfoBlock = glamorous('dl', { displayName: 'ChubExportInfoBlock' })({
  margin: '50px',
});

export default class ChubExportInfo extends Component {
  static displayName = 'ExportInfo';

  static propTypes = {
    as2ExportProps: PropTypes.object,
  };

  render() {
    const {
      as2ExportProps: {
        as2Id,
        url,
        connectionEnabled,
        micAlgorithm,
        chubAs2Id,
        displaySigningCertificateOverride,
        partnerCertificate: { alias, subject, notBefore, notAfter, signatureAlgorithm, status, clientSerialNumber },
        signingCertificateOverride: { alias: signingCertificateAlias, subject: signingCertificateSubject,
            notBefore: signingCertificateNotBefore, notAfter: signingCertificateNotAfter,
            signatureAlgorithm: signingCertificateSignatureAlgorithm, status: signingCertificateStatus,
            serialNumber: signingCertificateSerialNumber },
        chubCertificate: { author, name, authority, createDate, updateDate, chubSerialNumber },
      },
    } = this.props;

    return (
      <ChubExportInfoBlock>
        <ExportAs2DataItem itemLabel='AS2 ID' itemData={as2Id} id='export-as2-id' />
        <ExportAs2DataItem itemLabel='Enabled' itemData={connectionEnabled.toString()} id='export-enabled' />
        <ExportAs2DataItem itemLabel='Mic Algorithm' itemData={micAlgorithm} id='export-mic-algorithm' />
        <ExportAs2DataItem itemLabel='URL' itemData={url} id='export-url' />
        <br />
        <ExportAs2DataItem itemLabel='Partner Certificate Info' itemData={''} id='export-partner-cert-info' />
        <ExportAs2DataItem itemLabel='Alias' itemData={alias} id='export-alias' />
        <ExportAs2DataItem itemLabel='Subject' itemData={subject} id='export-subject' />
        <ExportAs2DataItem itemLabel='Serial Number' itemData={clientSerialNumber} id='export-partner-serial-number' />
        <ExportAs2DataItem itemLabel='Not Before' itemData={notBefore} id='export-not-before' />
        <ExportAs2DataItem itemLabel='Not After' itemData={notAfter} id='export-not-after' />
        <ExportAs2DataItem
          itemLabel='Signature Algorithm'
          itemData={signatureAlgorithm}
          id='export-signature-algorithm'
        />
        <ExportAs2DataItem itemLabel='Status' itemData={status} id='export-status' />
        <br />
        <ExportAs2DataItem itemLabel='CommerceHub Certificate Info' itemData={''} id='export-chub-cert-info' />
        <ExportAs2DataItem itemLabel='Name' itemData={name} id='export-name' />
        <ExportAs2DataItem itemLabel='Author' itemData={author} id='export-author' />
        <ExportAs2DataItem itemLabel='Authority' itemData={authority} id='export-authority' />
        <ExportAs2DataItem itemLabel='CommerceHub AS2 ID' itemData={chubAs2Id} id='export-chub-as2-id' />
        <ExportAs2DataItem itemLabel='Serial Number' itemData={chubSerialNumber} id='export-chub-serial-number' />
        <ExportAs2DataItem itemLabel='Date Created' itemData={createDate} id='export-date-created' />
        <ExportAs2DataItem itemLabel='Date Updated' itemData={updateDate} id='export-date-updated' />
      {displaySigningCertificateOverride &&
      <>
          <br />
          <ExportAs2DataItem itemLabel='Partner Signing Certificate Info' itemData={''} id='export-partner-signing-cert-info' />
          <ExportAs2DataItem itemLabel='Alias' itemData={signingCertificateAlias} id='export-signing-cert-alias' />
          <ExportAs2DataItem itemLabel='Subject' itemData={signingCertificateSubject} id='export-signing-cert-subject' />
          <ExportAs2DataItem itemLabel='Serial Number' itemData={signingCertificateSerialNumber} id='export-partner-signing-cert-serial-number' />
          <ExportAs2DataItem itemLabel='Not Before' itemData={signingCertificateNotBefore} id='export-signing-cert-not-before' />
          <ExportAs2DataItem itemLabel='Not After' itemData={signingCertificateNotAfter} id='export-signing-cert-not-after' />
          <ExportAs2DataItem
              itemLabel='Signature Algorithm'
              itemData={signingCertificateSignatureAlgorithm}
              id='export-signing-cert-signature-algorithm'
          />
          <ExportAs2DataItem itemLabel='Status' itemData={signingCertificateStatus} id='export-signing-cert-status' />
      </>
      }
      </ChubExportInfoBlock>
    );
  }
}
