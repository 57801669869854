import { connect } from 'react-redux';
import PageMessages from '../components/PageMessages';

const mapStateToProps = ({ loading }) => {
  const { error } = loading;

  let serverMessages = [];
  if (loading.messageDetails && loading.messageDetails.serverMessages) {
    serverMessages = loading.messageDetails.serverMessages;
  }

  return {
    error,
    serverMessages,
  };
};

export default connect(mapStateToProps)(PageMessages);
