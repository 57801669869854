import {parse} from 'url';

export const DEFAULT_PORT = 80;
export const DEFAULT_HTTPS_PORT = 443;
export const DEFAULT_HOST = 'localhost';
export const defaultPort = (isHttps) => {
  return isHttps ? DEFAULT_HTTPS_PORT : DEFAULT_PORT;
};
export const protocolIsHttps = (protocol) => protocol === 'https:';

export function enforceUrlStructure(url) {
  const urlWithProtocol = url.toLowerCase().startsWith('http')
      ? url
      : `http://${url}`;
  const { protocol, host, path, port: parsedPort } = parse(urlWithProtocol);
  const isHttps = protocolIsHttps(protocol);
  let formattedUrl = isHttps ? 'https://' : 'http://';
  if (host) {
    formattedUrl += host;
  }
  else {
    formattedUrl += DEFAULT_HOST;
  }
  if (!parsedPort) {
    formattedUrl += `:${defaultPort(isHttps)}`;
  }
  if (path && host) {
    formattedUrl += path;
  }
  else {
    formattedUrl += '/';
  }
  return formattedUrl;
}
