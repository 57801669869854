import React from 'react';
import PropTypes from 'prop-types';
import glamorous, { GlamorousComponent } from 'glamorous';
import {
  ChubTableBodyRow,
  ChubTableBodyCell,
  ChubTableBodyRowError,
  ChubTableBodyCellError,
} from '../../../styles/table';
import { AttentionIcon } from '../../../styles/icons';

const OverflowWrapCell: GlamorousComponent<any, any> = glamorous(ChubTableBodyCell, {
  displayName: 'OverflowWrapCell',
})({
  overflowWrap: 'break-word',
});

const OverflowWrapCellError: GlamorousComponent<any, any> = glamorous(ChubTableBodyCellError, {
  displayName: 'OverflowWrapCellError',
})({
  overflowWrap: 'break-word',
});

const SFTPTransfersTableRow = ({
  partnerId,
  username,
  ipAddress,
  eventDesc,
  newFilePath,
  oldFilePath,
  bytesTransferred,
  eventTimestamp,
  statusDescription,
  success,
}: {
  partnerId: string;
  username: string;
  ipAddress: string;
  eventDesc: string;
  newFilePath: string;
  oldFilePath: string;
  bytesTransferred: number;
  eventTimestamp: string;
  statusDescription: string;
  success: boolean;
}) => {
  if (success) {
    return (
      <ChubTableBodyRow>
        <ChubTableBodyCell>{partnerId}</ChubTableBodyCell>
        <ChubTableBodyCell>{username}</ChubTableBodyCell>
        <ChubTableBodyCell>{ipAddress}</ChubTableBodyCell>
        <ChubTableBodyCell>{eventDesc}</ChubTableBodyCell>
        <OverflowWrapCell>{newFilePath}</OverflowWrapCell>
        <OverflowWrapCell>{oldFilePath}</OverflowWrapCell>
        <ChubTableBodyCell>{bytesTransferred}</ChubTableBodyCell>
        <ChubTableBodyCell>{eventTimestamp}</ChubTableBodyCell>
        <ChubTableBodyCell>{statusDescription}</ChubTableBodyCell>
        <ChubTableBodyCell>{success}</ChubTableBodyCell>
      </ChubTableBodyRow>
    );
  }

  return (
    <ChubTableBodyRowError>
      <ChubTableBodyCellError>{partnerId}</ChubTableBodyCellError>
      <ChubTableBodyCellError>{username}</ChubTableBodyCellError>
      <ChubTableBodyCellError>{ipAddress}</ChubTableBodyCellError>
      <ChubTableBodyCellError>{eventDesc}</ChubTableBodyCellError>
      <OverflowWrapCellError>{newFilePath}</OverflowWrapCellError>
      <OverflowWrapCellError>{oldFilePath}</OverflowWrapCellError>
      <ChubTableBodyCellError>{bytesTransferred}</ChubTableBodyCellError>
      <ChubTableBodyCellError>{eventTimestamp}</ChubTableBodyCellError>
      <ChubTableBodyCellError>
        <AttentionIcon error='true' />
        {statusDescription}
      </ChubTableBodyCellError>
      <ChubTableBodyCellError>{success}</ChubTableBodyCellError>
    </ChubTableBodyRowError>
  );
};

SFTPTransfersTableRow.displayName = 'SFTPTransfersTableRow';
SFTPTransfersTableRow.propTypes = {
  partnerId: PropTypes.string,
  username: PropTypes.string,
  ipAddress: PropTypes.string,
  eventDesc: PropTypes.string,
  newFilePath: PropTypes.string,
  oldFilePath: PropTypes.string,
  bytesTransferred: PropTypes.number,
  eventTimestamp: PropTypes.string,
  statusDescription: PropTypes.string,
  success: PropTypes.bool,
};
export default SFTPTransfersTableRow;
