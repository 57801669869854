import React from 'react';
import glamorous from 'glamorous';

import { ChubH2 } from '../../styles/typography';
import CertificateInfoBlock from '../../components/certificateInfo/CertificateInfoBlock';

import DefaultHeader from '../../components/DefaultHeader';
import ClientSummary from '../../components/ClientSummary';

import { useParams } from 'react-router-dom';

import useCertificate from './certificate.hook';
import LoadingStateProvider from '../../components/LoadingStateProvider';

const DetailsHeader = glamorous(ChubH2, { displayName: 'DetailsHeader' })({
  marginLeft: '50px',
});

const CertificateInfoApp = () => {
  const { certificateGuid } = useParams();
  const { certificate, isLoading, error } = useCertificate(certificateGuid);

  return (
    <>
      <DefaultHeader showUndoRedo={false} showSearch={true} />
      <ClientSummary clientName='CommerceHub' />
      <DetailsHeader>Certificate Details</DetailsHeader>
      <LoadingStateProvider error={error} isLoading={isLoading}>
        {() => <CertificateInfoBlock {...certificate} />}
      </LoadingStateProvider>
    </>
  );
};
CertificateInfoApp.displayName = 'CertificateInfo';

export default CertificateInfoApp;
