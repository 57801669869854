import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

const HiddenWarningElement = glamorous('div', { displayName: 'HiddenWarningElement' })({
  display: 'hidden',
});

export default class NavigationWarning extends Component {
  static displayName = 'NavigationWarning';

  static propTypes = {
    isEnabled: PropTypes.bool,
  };

  static defaultValues = {
    isEnabled: false,
  };

  componentDidMount = () => {
    const { isEnabled } = this.props;
    this.setOnNavigateWarning(isEnabled);
  };

  componentWillReceiveProps({ isEnabled: nextIsEnabled }) {
    const { isEnabled: currentIsEnabled } = this.props;
    if (nextIsEnabled !== currentIsEnabled) {
      this.setOnNavigateWarning(nextIsEnabled);
    }
  }

  setOnNavigateWarning(isEnabled) {
    if (isEnabled) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  render() {
    return <HiddenWarningElement />;
  }
}
