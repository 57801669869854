import { combineReducers } from 'redux';
import loadingReducer from './loading/stoppableFilesLoadingReducer';
import stopFileReducer from './stopfile';

const reducer = combineReducers({
  loading: loadingReducer,
  stopFile: stopFileReducer,
});

export default reducer;
