import { colors } from 'react-chui';
import glamorous from 'glamorous';

import { Link } from 'react-router-dom';

export const selectableElement = {
  WebkitUserSelect: 'all',
  MozUserSelect: 'all',
  MsUserSelect: 'all',
  userSelect: 'all',
};
export const chubStandards = {
  fontFamily: "'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  color: colors.darkgray,
};

export const ChubH1 = glamorous('h1', { displayName: 'ChubH1' })(chubStandards);
export const ChubH2 = glamorous('h2', { displayName: 'ChubH2' })(chubStandards);
export const ChubH3 = glamorous('h3', { displayName: 'ChubH3' })(chubStandards);
export const ChubH4 = glamorous('h4', { displayName: 'ChubH4' })(chubStandards);

export const ChubLinkButton = glamorous(Link, { displayName: 'ChubLinkButton' })(chubStandards, {
  background: colors.brightblue,
  border: 'none',
  borderRadius: '3px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  color: '#FFF',
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '18px',
  height: '33px',
  lineHeight: '33px',
  padding: '0 15px',
  position: 'relative',
  textAlign: 'center',
  textDecoration: 'none',
  verticalAlign: 'middle',
  ':hover': {
    background: '#1d82ff',
    color: '#FFF',
    textDecoration: 'none',
  },
});

export const ChubA = glamorous(Link, { displayName: 'ChubA' })(chubStandards, {
  color: colors.brightblue,
  textDecoration: 'none',
  cursor: 'pointer',
  fontWeight: 'normal',
  ':hover': {
    color: colors.brightblue,
    textDecoration: 'underline',
  },
});

export const ChubInput = glamorous('input', { displayName: 'ChubInput' })(chubStandards, (props) => ({
  borderRadius: '3px',
  MozBoxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  WebkitBoxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  boxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  MozBoxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
  boxSizing: 'border-box',
  display: 'inline-block',
  fontSize: '18px',
  padding: '0 10px 0 10px',
  resize: 'none',
  outline: 'none',
  verticalAlign: 'middle',
  border: props.invalid ? `1px solid ${colors.chred}` : '0 none',
  ':focus': {
    border: `1px solid ${props.invalid ? colors.chred : colors.brightblue}`,
  },
  ':read-only': {
    backgroundColor: colors.coolgraylight2,
    ':focus': {
      border: 'none',
    },
  },
}));

export const ChubTextarea = glamorous('textarea', { displayName: 'ChubTextarea' })(chubStandards, (props) => ({
  borderRadius: '3px',
  MozBoxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  WebkitBoxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  boxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  MozBoxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
  boxSizing: 'border-box',
  display: 'inline-block',
  fontSize: '18px',
  padding: '0 10px 0 10px',
  spellcheck: 'false',
  outline: 'none',
  verticalAlign: 'middle',
  border: props.invalid ? `1px solid ${colors.chred}` : '0 none',
  ':focus': {
    border: `1px solid ${props.invalid ? colors.chred : colors.brightblue}`,
  },
  ':read-only': {
    backgroundColor: colors.coolgraylight2,
    ':focus': {
      border: 'none',
    },
  },
}));

export const ChubP = glamorous('p', { displayName: 'ChubP' })(chubStandards, {
  color: colors.darkgray,
  fontSize: '15px',
});

export const ChubButton = glamorous('button', { displayName: 'ChubButton' })(chubStandards, {
  background: colors.brightblue,
  border: 'none',
  borderRadius: '3px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  color: '#FFF',
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '18px',
  height: '33px',
  lineHeight: '33px',
  padding: '0 15px',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',
  ':hover': {
    background: '#1d82ff',
    color: '#FFF',
    textDecoration: 'none',
  },
});

export const ChubSecondaryButton = glamorous(ChubButton, { displayName: 'ChubSecondaryButton' })(chubStandards, {
  color: colors.darkgray,
  background: 'white',
});

export const BoldLabel = glamorous(ChubP, { displayName: 'BoldLabel' })({
  fontSize: '20px',
  fontWeight: 'bold',
  marginLeft: '15px',
});

export const OptionalLabel = glamorous(ChubP, { displayName: 'OptionalLabel' })({
  fontSize: '12px',
  marginLeft: '15px',
});