import React from 'react';
import PropTypes from 'prop-types';
import { ChubSecondaryButton, ChubP } from '../../../../styles/typography';
import { CollapseIcon } from '../../../../styles/icons';
import glamorous from 'glamorous';

const TableFlex = glamorous('div', { displayName: 'TableFlex' })({
  margin: '0px 5px 20px 15px',
  display: 'flex',
  justifyContent: 'space-between',
});

const LeftColumnFlex = glamorous('div', { displayName: 'LeftColumnFlex' })({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'left',
  width: '200px',
});

const RightColumnFlex = glamorous('div', { displayName: 'RightColumnFlex' })({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
});

const BoldLabel = glamorous(ChubP, { displayName: 'BoldLabel' })({
  fontSize: '20px',
  fontWeight: 'bold',
  width: '150px',
});

const DesignNewFileRouteButton = glamorous(ChubSecondaryButton, { displayName: 'DesignNewFileRouteButton' })({
  marginTop: '15px',
  width: '150px',
});

const CollapseChiconButton = glamorous('button', { displayName: 'CollapseChiconButton' })({
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  boxShadow: 'none',
  marginTop: '15px',
  marginLeft: '10px'
});

const ExpandedFileRoutes = ({ connectionType, readOnly, parentId, children, onAddFileRoute, onToggleExpanded }) => (
  <TableFlex>
    <LeftColumnFlex>
      <BoldLabel>File Routes</BoldLabel>
      {!readOnly && (
        <div>
          <DesignNewFileRouteButton
            id={`${connectionType}-design-new-file-route-button-${parentId}`}
            parentId={parentId}
            onClick={() => onAddFileRoute(parentId)}
          >
            Design New
          </DesignNewFileRouteButton>
        </div>
      )}
    </LeftColumnFlex>
    {children()}
    <RightColumnFlex>
      <CollapseChiconButton id={`${connectionType}-collapse-file-route-button-${parentId}`} onClick={onToggleExpanded}>
        <CollapseIcon />
      </CollapseChiconButton>
    </RightColumnFlex>
  </TableFlex>
);
ExpandedFileRoutes.displayName = 'ExpandedFileRoutes';
ExpandedFileRoutes.propTypes = {
  connectionType: PropTypes.string,
  readOnly: PropTypes.bool,
  children: PropTypes.func,
  parentId: PropTypes.string,
  onToggleExpanded: PropTypes.func,
  onAddFileRoute: PropTypes.func,
  onDeleteFileRoute: PropTypes.func,
};
ExpandedFileRoutes.defaultProps = {
  connectionType: 'as2Connection',
  readOnly: true,
  // eslint-disable-next-line
  children: () => {},
};

export default ExpandedFileRoutes;
