import React from 'react';
import { useParams } from 'react-router-dom';

import PartnerSFTPTransfersResults from './PartnerSFTPTransfersResultsApp';

const PartnerSFTPTransfersResultsEntry = () => {
  const { partnerId, partnerGUID, numberOfTransfers, environmentName } = useParams();

  return (
    <PartnerSFTPTransfersResults
      partnerId={partnerId!}
      partnerGUID={partnerGUID!}
      numberOfTransfers={numberOfTransfers!}
      environmentName={environmentName!}
    />
  );
};

export default PartnerSFTPTransfersResultsEntry;
