import React from 'react';
import PropTypes from 'prop-types';
import { ChubTableBodyRow, ChubTableBodyCell } from '../../../styles/table';
import glamorous, { GlamorousComponent } from 'glamorous';

const LastFailedAttemptResult: GlamorousComponent<any, any> = glamorous('p', {
  displayName: 'LastFailedAttemptResult',
})({
  overflowWrap: 'break-word',
});

const AS2PendingTransfersTableRow = ({
  externalID,
  fileName,
  createDate,
  lastAttemptDate,
  lastFailedAttemptResult,
  totalAttempts,
  vlSerial,
}: {
  externalID: string;
  fileName: string;
  createDate: string;
  lastAttemptDate?: string;
  lastFailedAttemptResult: string;
  totalAttempts: number;
  vlSerial: string;
}) => (
  <ChubTableBodyRow>
    <ChubTableBodyCell>{externalID}</ChubTableBodyCell>
    <ChubTableBodyCell>{fileName}</ChubTableBodyCell>
    <ChubTableBodyCell>{createDate}</ChubTableBodyCell>
    <ChubTableBodyCell>{lastAttemptDate}</ChubTableBodyCell>
    <ChubTableBodyCell>
      <LastFailedAttemptResult>{lastFailedAttemptResult}</LastFailedAttemptResult>
    </ChubTableBodyCell>
    <ChubTableBodyCell>{totalAttempts}</ChubTableBodyCell>
    <ChubTableBodyCell>{vlSerial}</ChubTableBodyCell>
  </ChubTableBodyRow>
);

AS2PendingTransfersTableRow.displayName = 'AS2PendingTransfersTableRow';
AS2PendingTransfersTableRow.propTypes = {
  externalID: PropTypes.string,
  fileName: PropTypes.string,
  createDate: PropTypes.string,
  lastAttemptDate: PropTypes.string,
  lastFailedAttemptResult: PropTypes.string,
  totalAttempts: PropTypes.number,
  vlSerial: PropTypes.string,
};

export default AS2PendingTransfersTableRow;
