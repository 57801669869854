import { connect } from 'react-redux';

import VersionTable from '../components/VersionTable';

const mapStateToProps = (state) => {
  const {
    versionData: { client, deployedVersions, nonDeployedVersions },
    versionDisplay: { displayNonDeployedVersions },
  } = state;
  return {
    client,
    deployedVersions,
    nonDeployedVersions,
    displayNonDeployedVersions,
  };
};

export default connect(mapStateToProps)(VersionTable);
