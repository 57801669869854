import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PartnerSFTPTransfersResponse } from '../PartnerSFTPTransfers.model';
import { useFetch } from '../../../hooks/fetch-hook';
import PartnerSFTPTransfersResultsTables from './PartnerSFTPTransfersResultsTables';
import PartnerSFTPPageMessages from './PartnerSFTPPageMessages';
import LoadingStateProvider from '../../../components/LoadingStateProvider';
import glamorous, { GlamorousComponent } from 'glamorous';
// @ts-ignore
import { Select } from 'react-chui';
import { ChubH4, ChubInput, chubStandards } from '../../../styles/typography';
import { environments } from '../dropdownOptions';

const Controls: GlamorousComponent<any, any> = glamorous('div', { displayName: 'Controls' })(chubStandards, {
  margin: '20px auto 0 auto',
  maxWidth: '1525px',
});

const Control: GlamorousComponent<any, any> = glamorous('span', { displayName: 'Control' })(chubStandards, {
  marginLeft: '20px',
});

const Label: GlamorousComponent<any, any> = glamorous(ChubH4, { displayName: 'Label' })({
  margin: '0',
  display: 'inline-block',
  paddingRight: '10px',
});

const EnvironmentDropdown: GlamorousComponent<any, any> = glamorous(Select, { displayName: 'EnvironmentDropdown' })({
  maxWidth: '150px',
});

const NumberOfTransfersInput: GlamorousComponent<any, any> = glamorous(ChubInput, {
  displayName: 'NumberOfTransfersInput',
})({
  width: '60px',
});

const PartnerSFTPTransfers = ({
  partnerId,
  defaultNumberOfTransfers,
  defaultEnvironmentName,
}: {
  partnerId: string;
  defaultNumberOfTransfers: string;
  defaultEnvironmentName: string;
}) => {
  const [environmentName, setEnvironmentName] = useState(defaultEnvironmentName);
  const [numberOfTransfersInput, setNumberOfTransfersInput] = useState(defaultNumberOfTransfers);
  const [numberOfTransfers, setNumberOfTransfers] = useState(defaultNumberOfTransfers);

  const onSelectEnvironment = ({ target: { value } }: { target: { value: string } }) => {
    setEnvironmentName(value);
  };

  const onChangeNumberOfTransfersInput = ({ target: { value } }: { target: { value: string } }) => {
    setNumberOfTransfersInput(value);
  };

  const onBlurNumberOfTransfers = ({ target: { value } }: { target: { value: string } }) => {
    setNumberOfTransfers(value);
  };

  const renderEnvironmentDropdown = () => {
    const allOptions = environments.map((env) => (
      <option value={env} key={env}>
        {env}
      </option>
    ));
    return (
      <EnvironmentDropdown id='environment-dropdown' value={environmentName} onChange={onSelectEnvironment}>
        {allOptions}
      </EnvironmentDropdown>
    );
  };

  const { data, error, isLoading } = useFetch<PartnerSFTPTransfersResponse>(
    `/transfers/partnerSftp/${partnerId}/${numberOfTransfers}/${environmentName}`
  );

  const serverMessages = data?.serverMessages ? data.serverMessages : [];
  return (
    <>
      <PartnerSFTPPageMessages error={error} serverMessages={serverMessages} numberOfTransfers={numberOfTransfers} />
      {!isLoading && (
        <Controls>
          <Control>
            <Label>Environment:</Label>
            {renderEnvironmentDropdown()}
          </Control>
          <Control>
            <Label>Number of Transfers:</Label>
            <NumberOfTransfersInput
              type={'text'}
              value={numberOfTransfersInput}
              onChange={onChangeNumberOfTransfersInput}
              onBlur={onBlurNumberOfTransfers}
            />
          </Control>
        </Controls>
      )}
      <LoadingStateProvider error={error} isLoading={isLoading}>
        {() => (
          <PartnerSFTPTransfersResultsTables
            incomingFileTransfers={data!.incomingFileTransfers}
            outgoingFileTransfers={data!.outgoingFileTransfers}
            pendingFileTransfers={data!.pendingFileTransfers}
          />
        )}
      </LoadingStateProvider>
    </>
  );
};

PartnerSFTPTransfers.displayName = 'PartnerSFTPTransfers';
PartnerSFTPTransfers.propTypes = {
  partnerId: PropTypes.string,
  defaultNumberOfTransfers: PropTypes.string.isRequired,
  defaultEnvironmentName: PropTypes.string.isRequired,
};

export default PartnerSFTPTransfers;
