import React, { useEffect, useState } from 'react';
import DefaultHeader from '../DefaultHeader';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
interface CodeSnippetProps {
  code: string;
  title?: string;
}

const CodeSnippet: React.FC<CodeSnippetProps> = ({ title, code }) => (
  <div className='code-snippet'>
    <span className='code-snippet__title'>{title}</span>
    <div className='code-snippet__container'>
      <div className='code-snippet__wrapper'>
        <pre className='code-snippet__body'>{code}</pre>
      </div>
    </div>
  </div>
);

const Profile = () => {
  const { instance, accounts } = useMsal();
  const [token, setToken] = useState('');

  const account = accounts?.[0];

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account,
        })
        .then((result) => setToken(result.accessToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='content-layout'>
      <DefaultHeader showUndoRedo={false} />
      <div style={{ margin: '0 auto', maxWidth: '1200px' }}>
        <h1 className='content__title'>Profile</h1>
        <div className='content__body'>
          <p>
            You can use the ID Token to get the profile information of an authenticated user.
            <br />
            <strong>Only authenticated users can access this page.</strong>
          </p>
          <div className='profile-grid'>
            <div className='profile__header'>
              {/* <img src={user.picture} alt='Profile' className='profile__avatar' /> */}
              <div className='profile__headline'>
                <h2 className='profile__title'>{account?.username}</h2>
                <span className='profile__description'>{account?.name}</span>
              </div>
            </div>
            <div className='profile__details'>
              <CodeSnippet title='User Account Info' code={JSON.stringify(account, null, 2)} />
              <h2>Access Token:</h2>
              <p>{token}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
