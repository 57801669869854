import React, { PropsWithChildren } from 'react';
import glamorous from 'glamorous';

import { ChubH2 } from '../../styles/typography';
import OutgoingBacklogList from './OutgoingBacklogList';

import DefaultHeader from '../../components/DefaultHeader';
import ClientSummary from '../../components/ClientSummary';

import useOutgoingBacklog from './outgoing-backlog.hook';
import LoadingStateProvider from '../../components/LoadingStateProvider';

const DetailsHeader = glamorous<PropsWithChildren>(ChubH2, { displayName: 'DetailsHeader' })({
  marginLeft: '50px',
});

const OutgoingBacklogEntry = () => {
  const { backlog, isLoading, error } = useOutgoingBacklog();

  return (
    <>
      <DefaultHeader showUndoRedo={false} showSearch={true} />
      <ClientSummary clientName='CommerceHub' />
      <DetailsHeader>Harmony Outgoing Backlog Data</DetailsHeader>
      <LoadingStateProvider error={error} isLoading={isLoading}>
        {() => <OutgoingBacklogList backlog={backlog} />}
      </LoadingStateProvider>
    </>
  );
};
OutgoingBacklogEntry.displayName = 'OutgoingBacklog';

export default OutgoingBacklogEntry;
