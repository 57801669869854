import React, { Fragment } from 'react';
import glamorous from 'glamorous';
import { connect } from 'react-redux';
import SectionViewer from '../components/SectionViewer';
import CopySection from '../components/CopySection';
import As2ConfigurationBoxContainer from './As2ConfigurationBoxContainer';
import SftpConfigurationBoxContainer from './SftpConfigurationBoxContainer';
import PartnerSftpConfigurationBoxContainer from './PartnerSftpConfigurationBoxContainer';
import {
  setCurrentSection,
  addAs2Connection,
  addSftpConnection,
  addPartnerSftpConnection,
  fetchGeneratedSftpPassword,
  copySection,
  setCurrentSectionUndoable,
  showRelationships,
  deleteAs2Connection,
  deletePartnerSftpConnection,
} from '../actions';
import ServerMessages from '../../../components/ServerMessages';
import SaveCancelFooterContainer from './SaveCancelFooterContainer';
import { ChubH2, ChubButton, ChubLinkButton } from '../../../styles/typography';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const TabContent = glamorous('div', { displayName: 'TabContent' })({
  backgroundColor: 'white',
  padding: '20px',
});

const CopyToDiv = glamorous('div', { displayName: 'CopyToDiv' })({
  paddingBottom: '20px',
  maxWidth: '1500px',
  minHeight: '49px',
});

const AddConfigurationButton = glamorous(ChubButton, { displayName: 'AddConfigurationButton' })({
  display: 'block',
  margin: '10px 0',
});

const ViewParentButton = glamorous(ChubLinkButton, { displayName: 'ViewParentButton' })({
  display: 'block',
  margin: '10px 20px',
  float: 'right',
  textDecoration: 'none',
});

const AddRelationshipButton = glamorous(ChubButton, { displayName: 'AddRelationshipButton' })({
  display: 'block',
  margin: '10px 0',
  float: 'right',
});

const mapStateToProps = (state, ownProps) => {
  const {
    undoableConfigurationViewModel: {
      present: {
        clientName,
        clientGUID,
        currentSection,
        sections,
        chubSystemIdentifiers,
        as2FileRouteTypes,
        sftpFileRouteTypes,
        partnerSftpFileRouteTypes,
        chubSystemCertificates,
        serverMessages,
        copyableEnvironments,
        micAlgorithms,
        showVersionName,
      },
    },
  } = state;
  const {
    [currentSection]: {
      sftpConnection = null,
      partnerSftpConnections = [],
      readOnly = true,
      parentVersionGUID,
      environmentGUID,
      canAddRelationship = false,
      as2Connections = [],
      canSave = false,
      saveSuccessful = false,
    } = {},
  } = sections;

  const hasAS2Connection = as2Connections && as2Connections.length > 0;
  const hasPartnerSftpConnection = partnerSftpConnections && partnerSftpConnections.length > 0;
  const noConfiguration = !hasAS2Connection && !sftpConnection && !hasPartnerSftpConnection;
  const showAddRelationship = canAddRelationship;
  const showCopySection = !noConfiguration && Boolean(copyableEnvironments.length);
  const showSaveCancelFooter = canSave || !readOnly || saveSuccessful;
  const useAs2ForwarderAsDefault = ownProps?.flags?.useAs2ForwarderAsDefault;

  const enablePartnerSftp = ownProps?.flags.enablePartnerSftp;

  return {
    clientName,
    sections,
    currentSection,
    showVersionName,
    chubSystemIdentifiers,
    as2FileRouteTypes,
    sftpFileRouteTypes,
    partnerSftpFileRouteTypes,
    chubSystemCertificates,
    micAlgorithms,
    children: ({
      onClickAddAs2Connection,
      onClickDeleteAs2Connection,
      onClickAddSftpConnection,
      onClickAddPartnerSftpConnection,
      onClickDeletePartnerSftpConnection,
      onCopySection,
      onClickShowRelationships,
    }) => {
      return (
        <Fragment>
          <TabContent>
            <CopyToDiv>
              {showCopySection && (
                <CopySection {...{ onCopySection, sections, currentSection, copyableEnvironments }} />
              )}
              {showAddRelationship && (
                <AddRelationshipButton id='add-relationship-button' onClick={onClickShowRelationships}>
                  Add Relationship
                </AddRelationshipButton>
              )}
              <ViewParentButton
                id='view-parent-button'
                to={`/commercehub/configuration/${clientGUID}/${parentVersionGUID}/${environmentGUID}`}
              >
                View Parent Version
              </ViewParentButton>
            </CopyToDiv>
            {hasAS2Connection &&
              as2Connections.map((connection) => (
                <As2ConfigurationBoxContainer
                  onClickDeleteAs2Connection={onClickDeleteAs2Connection}
                  connection={connection}
                  key={connection.mailboxGUID}
                />
              ))}
            {sftpConnection && <SftpConfigurationBoxContainer />}
            {hasPartnerSftpConnection &&
              partnerSftpConnections.map((connection) => (
                <PartnerSftpConfigurationBoxContainer
                  onClickDeletePartnerSftpConnection={onClickDeletePartnerSftpConnection}
                  connection={connection}
                  key={connection.accountGUID}
                />
              ))}
            {noConfiguration && <ChubH2>No Configuration Found</ChubH2>}
            {!readOnly && (
              <AddConfigurationButton
                id='add-as2-connection'
                onClick={() => onClickAddAs2Connection(useAs2ForwarderAsDefault)}
              >
                Add AS2 Connection
              </AddConfigurationButton>
            )}
            {!readOnly && !sftpConnection && (
              <AddConfigurationButton id='add-sftp-connection' onClick={onClickAddSftpConnection}>
                Add SFTP Connection
              </AddConfigurationButton>
            )}
            {!readOnly && enablePartnerSftp && (
              <AddConfigurationButton id='add-partner-sftp-connection' onClick={onClickAddPartnerSftpConnection}>
                Add Partner SFTP Connection
              </AddConfigurationButton>
            )}
            <ServerMessages {...{ serverMessages }} />
          </TabContent>
          {showSaveCancelFooter && <SaveCancelFooterContainer />}
        </Fragment>
      );
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentSection: (newCurrentSection) => dispatch(setCurrentSection(newCurrentSection)),
    onClickShowRelationships: () => dispatch(showRelationships()),
    onClickAddAs2Connection: (useAs2ForwarderAsDefault) => dispatch(addAs2Connection(useAs2ForwarderAsDefault)),
    onClickDeleteAs2Connection: (mailboxGUID) => dispatch(deleteAs2Connection(mailboxGUID)),
    onCopySection: (environmentGUID, sectionName, copyingToNewSftpConnection) => {
      dispatch(copySection(environmentGUID, sectionName));
      dispatch(setCurrentSectionUndoable(sectionName));
      if (copyingToNewSftpConnection) {
        dispatch(fetchGeneratedSftpPassword());
      }
    },
    onClickAddSftpConnection: () => {
      dispatch(addSftpConnection());
      dispatch(fetchGeneratedSftpPassword());
    },
    onClickAddPartnerSftpConnection: () => {
      dispatch(addPartnerSftpConnection());
    },
    onClickDeletePartnerSftpConnection: (accountGUID) => dispatch(deletePartnerSftpConnection(accountGUID)),
  };
};

const SectionViewContainer = withLDConsumer()(connect(mapStateToProps, mapDispatchToProps)(SectionViewer));

export default SectionViewContainer;
