import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { ChubA } from '../../../styles/typography';
import {
  ChubTable,
  ChubTableBody,
  ChubTableHeadCell,
  ChubTableBodyRow,
  ChubTableBodyCell,
  ChubTableWrapper,
} from '../../../styles/table';

const ChubSystemCertificateWrapper = glamorous(ChubTableWrapper, { displayName: 'ChubSystemCertificateWrapper' })({
  maxWidth: '1300px',
  maxHeight: '600px',
  margin: '20px auto',
  overflowX: 'hidden',
});

const ChubSystemCertificateNameCell = glamorous(ChubTableBodyCell, { displayName: 'ChubSystemCertificateNameCell' })({
  textAlign: 'left',
});

const ChubSystemCertificateLinkCell = glamorous(ChubTableBodyCell, { displayName: 'ChubSystemCertificateLinkCell' })({
  textAlign: 'center',
});

const CertificatesDisplayTable = ({ chubSystemCertificates }) => (
  <Fragment>
    <ChubSystemCertificateWrapper id='chub-system-certificates'>
      <ChubTable>
        <thead>
          <tr>
            <ChubTableHeadCell>Certificate</ChubTableHeadCell>
            <ChubTableHeadCell>View Details</ChubTableHeadCell>
          </tr>
        </thead>
        <ChubTableBody>
          {chubSystemCertificates.map((listValue) => (
            <ChubTableBodyRow key={listValue.certificateName}>
              <ChubSystemCertificateNameCell id={`chub-system-certificate-name-${listValue.certificateGUID}`}>
                {listValue.certificateName}
              </ChubSystemCertificateNameCell>
              <ChubSystemCertificateLinkCell>
                <ChubA
                  to={`/config/certificate/${listValue.certificateGUID}`}
                  id={`chub-system-certificate-guid-${listValue.certificateGUID}`}
                >
                  View
                </ChubA>
              </ChubSystemCertificateLinkCell>
            </ChubTableBodyRow>
          ))}
        </ChubTableBody>
      </ChubTable>
    </ChubSystemCertificateWrapper>
  </Fragment>
);

CertificatesDisplayTable.displayName = 'DisplayCertificates';

CertificatesDisplayTable.propTypes = {
  chubSystemCertificates: PropTypes.array,
};

export default CertificatesDisplayTable;
