import { FILTER_CONNECTIONS, RECEIVE_CONNECTIONS, RESET_STATE } from '../ConnectionsActionTypes';
import { AS2ConnectionViewModel, ConnectionViewModel } from '../types/ConnectionViewModel';
import { isEnabledOptions, usesNewIpsOptions } from '../dropdownOptions';

const initialState = {
  connectionViewModels: [],
  displayableConnectionViewModels: [],
  selectedUsesNewIps: usesNewIpsOptions[0],
  selectedIsEnabled: isEnabledOptions[1], // default to true (also in ConnectionsOverviewEntry)
  selectedCertificate: '',
};

interface State {
  connectionViewModels: ConnectionViewModel[];
  displayableConnectionViewModels: ConnectionViewModel[];
  selectedUsesNewIps: string;
  selectedIsEnabled: string;
  selectedCertificate: string;
}

interface Action {
  type: string;
  messageDetails?: ConnectionViewModel[];
  usesNewIps?: string;
  isEnabled?: string;
  protocol?: string;
  certificate?: string;
}

const booleanToString = (value: boolean) => {
  if (value) {
    return 'true';
  }
  return 'false';
};

const filterForAS2 = (connectionViewModels: AS2ConnectionViewModel[], action: Action) => {
  const filteredByUsesNewIps =
    action.usesNewIps === ''
      ? connectionViewModels
      : connectionViewModels.filter(
          (connection) => booleanToString(connection.send_outbound_through_as2forwarder) === action.usesNewIps
        );
  const filteredByEnabled =
    action.isEnabled === ''
      ? filteredByUsesNewIps
      : filteredByUsesNewIps.filter((connection) => booleanToString(connection.is_enabled) === action.isEnabled);
  return action.certificate === ''
    ? filteredByEnabled
    : filteredByEnabled.filter((connection) => connection.certificate_name === action.certificate);
};

const filterForSftp = (connectionViewModels: ConnectionViewModel[], action: Action) => {
  return action.isEnabled === ''
    ? connectionViewModels
    : connectionViewModels.filter((connection) => booleanToString(connection.is_enabled) === action.isEnabled);
};

const connectionsReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case RECEIVE_CONNECTIONS: {
      const connectionViewModels = action.messageDetails;
      return {
        ...state,
        connectionViewModels,
      };
    }
    case FILTER_CONNECTIONS: {
      const { connectionViewModels } = state;
      const displayableConnectionViewModels =
        action.protocol === 'AS2'
          ? filterForAS2(connectionViewModels as AS2ConnectionViewModel[], action)
          : filterForSftp(connectionViewModels, action);

      return {
        ...state,
        selectedUsesNewIps: action.usesNewIps,
        selectedIsEnabled: action.isEnabled,
        selectedCertificate: action.certificate,
        displayableConnectionViewModels,
      };
    }
    case RESET_STATE:
      return initialState;
  }
  return state;
};

export default connectionsReducer;
