import { RECEIVE_FILEROUTETYPES, RECEIVE_SAVE_RESPONSE } from '../FileRouteTypesActionTypes';
import { ADD_FILEROUTETYPE, DELETE_FILEROUTETYPE, UPDATE_FILEROUTETYPE } from '../UndoableFileRouteTypeActionTypes';
import { receiveFileRouteTypes, receiveSaveResponse } from './fileRouteTypesReducers/afterFetchReducers';
import { addFileRouteType, deleteFileRouteType, updateFileRouteType } from './fileRouteTypesReducers/viewModelReducers';

const initialState = {
  isLoading: false,
  isSaving: false,
  saveSuccessful: false,
  serverMessage: '',
  fileRouteTypes: {},
};

const reducer = (state = initialState, { type, ...actionArgs }) => {
  switch (type) {
    case RECEIVE_FILEROUTETYPES:
      return receiveFileRouteTypes(state, actionArgs);
    case ADD_FILEROUTETYPE:
      return addFileRouteType(state);
    case DELETE_FILEROUTETYPE:
      return deleteFileRouteType(state, actionArgs);
    case UPDATE_FILEROUTETYPE:
      return updateFileRouteType(state, actionArgs);
    case RECEIVE_SAVE_RESPONSE:
      return receiveSaveResponse(state, actionArgs);
    default:
      break;
  }
  return state;
};

export default reducer;
