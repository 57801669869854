import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { chubStandards } from '../../styles/typography';

const ItemLabel = glamorous('dt', { displayName: 'ItemLabel' })(chubStandards, {
  fontWeight: 'bold',
});

const ItemData = glamorous('dd', { displayName: 'ItemData' })(chubStandards, {
  marginBottom: '15px',
});

export default class CertificateDataItem extends Component {
  static displayName = 'CertificateDataItem';

  static propTypes = {
    itemLabel: PropTypes.string,
    itemData: PropTypes.string,
    id: PropTypes.string.isRequired,
  };

  render() {
    const { itemLabel, itemData, id } = this.props;
    return [
      <ItemLabel key={`${id}-label`}>{itemLabel}:</ItemLabel>,
      <ItemData key={`${id}-description`} id={id}>
        {itemData}
      </ItemData>,
    ];
  }
}
