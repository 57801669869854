import { fetchClient } from '../util/fetchClient';
import { useEffect, useState } from 'react';

const initialFetchState = { data: undefined, isLoading: true, error: undefined };

export function useFetch<T>(url: string) {
  const [fetchState, setFetchState] = useState(initialFetchState);

  useEffect(() => {
    setFetchState(initialFetchState);
    const fetch = async () =>
      fetchClient
        .get(url)
        .then((response) => {
          setFetchState({ data: response.data, isLoading: false, error: undefined });
        })
        .catch((err) => {
          setFetchState({ data: undefined, isLoading: false, error: err });
        });
    fetch();
  }, [url]);
  return {
    data: fetchState.data,
    isLoading: fetchState.isLoading,
    error: fetchState.error,
  } as {
    data?: T;
    isLoading: boolean;
    error?: any;
  };
}
