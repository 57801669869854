import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import EditableField from '../../../components/EditableField';
import { SftpFileRoute } from './fileRoutes/FileRoutes';
import EditableTextInput from '../../../components/EditableTextInput';
import ConnectionToggle from './ConnectionToggle';
import ConfigurationLabel from './ConfigurationLabel';
import FileRouteTable from './fileRoutes/FileRouteTable';
import { ChubTable, ChubTableHeadCell, ChubTableBody } from '../../../styles/table';
import { SFTP_CONNECTION_TYPE } from '../../../util/commerceHubInfo';
import IpInfoBubble from '../../../components/IpInfoBubble';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const EditableFieldRow = glamorous('div', { displayName: 'EditableFieldRow' })({
  padding: '20px 0',
});
const Configuration = glamorous('div', { displayName: 'Configuration' })({
  margin: '20px',
});
const RouteTable = glamorous(ChubTable, { displayName: 'RouteTable' })({
  width: '100%',
});
const TableSection = glamorous('div', { displayName: 'TableSection' })({
  marginTop: '20px',
  maxWidth: '1200px',
  overflowX: 'scroll',
  flexGrow: 1,
});

const FlexContainer = glamorous('div', { displayName: 'FlexContainer' })({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  gap: '16px',
  margin: '8px 0',
});

const SftpConfiguration = ({
  environmentGUID,
  currentSection,
  username,
  connectionEnabled,
  fileRoutes,
  fileRoutesVisible,
  sftpFileRouteTypes,
  onChangeUsername,
  toggleFileRouteVisibility,
  onClickConnectionToggle,
  onChangeFileRouteKey,
  onChangeFileRouteMd5Verified,
  onChangeFileRouteAdditionalRoutingData,
  onChangeSubPath,
  onClickAddSubPath,
  onClickDeleteSubPath,
  onAddSFTPFileRoute,
  onDeleteSFTPFileRoute,
  readOnly,
  children,
  flags,
}) => (
  <Configuration>
    <EditableField {...{ ...connectionEnabled, readOnly }}>
      {({ ...editableInputProps }) => (
        <ConnectionToggle
          connectionType={SFTP_CONNECTION_TYPE}
          id='sftp-connection-toggle'
          onClick={onClickConnectionToggle}
          {...editableInputProps}
        />
      )}
    </EditableField>
    <EditableFieldRow>
      <EditableField {...{ ...username, readOnly }} id='sftp-username-field'>
        {({ ...editableInputProps }) => (
          <Fragment>
            <ConfigurationLabel for='sftp-username'>Username:</ConfigurationLabel>
            <EditableTextInput
              id='sftp-username'
              {...editableInputProps}
              onChange={onChangeUsername}
              usage={'sftpUsername'}
              medium
            />
          </Fragment>
        )}
      </EditableField>
    </EditableFieldRow>

    {flags?.useNewSftpUrls && (
      <EditableFieldRow css={{ marginTop: '24px' }}>
        <EditableField {...{ changed: false, origValue: false, readOnly }}>
          {({ ...editableInputProps }) => (
            <FlexContainer css={{ justifyContent: 'flex-start' }}>
              <ConfigurationLabel for={'send-outbound-through-as2forwarder-toggle-sftp'}>
                Use New IPs (via AWS)
                <IpInfoBubble environmentGUID={environmentGUID} environmentName={currentSection} protocol='sftp' />
              </ConfigurationLabel>
              <ConnectionToggle
                id={'send-outbound-through-as2forwarder-toggle-sftp'}
                connectionType={SFTP_CONNECTION_TYPE}
                {...editableInputProps}
              />
            </FlexContainer>
          )}
        </EditableField>
      </EditableFieldRow>
    )}

    <EditableFieldRow>{children()}</EditableFieldRow>
    <FileRouteTable
      connectionType={SFTP_CONNECTION_TYPE}
      onAddFileRoute={onAddSFTPFileRoute}
      {...{ fileRoutes, fileRoutesVisible, toggleFileRouteVisibility, sftpFileRouteTypes, readOnly }}
    >
      {() => (
        <TableSection>
          <RouteTable>
            <thead>
              <tr>
                <ChubTableHeadCell width='90px'>Application</ChubTableHeadCell>
                <ChubTableHeadCell width='90px'>Application Code</ChubTableHeadCell>
                <ChubTableHeadCell>Name</ChubTableHeadCell>
                <ChubTableHeadCell width='15%'>Key</ChubTableHeadCell>
                <ChubTableHeadCell width='100px'>Direction</ChubTableHeadCell>
                <ChubTableHeadCell width='100px'>Additional Routing Data</ChubTableHeadCell>
                <ChubTableHeadCell width='25px'>Md5</ChubTableHeadCell>
                <ChubTableHeadCell width='100px'>SubPaths</ChubTableHeadCell>
                <ChubTableHeadCell width='60px'>FilePath</ChubTableHeadCell>
                <ChubTableHeadCell width='20px' />
              </tr>
            </thead>
            <ChubTableBody>
              {Object.keys(fileRoutes).map(
                (fileRouteGUID) =>
                  !fileRoutes[fileRouteGUID].deleted && (
                    <SftpFileRoute
                      key={fileRouteGUID}
                      onDeleteFileRoute={onDeleteSFTPFileRoute}
                      {...{
                        ...fileRoutes[fileRouteGUID],
                        fileRouteGUID,
                        readOnly,
                        sftpFileRouteTypes,
                        onChangeFileRouteKey,
                        onChangeFileRouteMd5Verified,
                        onChangeFileRouteAdditionalRoutingData,
                        onChangeSubPath,
                        onClickAddSubPath,
                        onClickDeleteSubPath,
                      }}
                    />
                  )
              )}
            </ChubTableBody>
          </RouteTable>
        </TableSection>
      )}
    </FileRouteTable>
  </Configuration>
);
SftpConfiguration.displayName = 'SftpConfiguration';
SftpConfiguration.propTypes = {
  username: PropTypes.object,
  environmentGUID: PropTypes.string,
  currentSection: PropTypes.string,
  connectionEnabled: PropTypes.object,
  fileRoutes: PropTypes.object,
  fileRoutesVisible: PropTypes.bool,
  toggleFileRouteVisibility: PropTypes.func,
  sftpFileRouteTypes: PropTypes.object,
  onChangeUsername: PropTypes.func,
  readOnly: PropTypes.bool,
  children: PropTypes.func,
  onClickConnectionToggle: PropTypes.func,
  onChangeFileRouteKey: PropTypes.func,
  onChangeFileRouteMd5Verified: PropTypes.func,
  onChangeFileRouteAdditionalRoutingData: PropTypes.func,
  onChangeSubPath: PropTypes.func,
  onClickAddSubPath: PropTypes.func,
  onClickDeleteSubPath: PropTypes.func,
  onAddSFTPFileRoute: PropTypes.func,
  onDeleteSFTPFileRoute: PropTypes.func,
};
SftpConfiguration.defaultProps = {
  readOnly: true,
  fileRoutesVisible: false,
  sftpFileRouteTypes: [],
};

export default withLDConsumer()(SftpConfiguration);
