import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
// @ts-ignore
import { colors } from 'react-chui';
import { DateTime } from 'luxon';
import { FileDeliveryServerData } from '../CommsTransferData.model';

const Data = glamorous.div({
  marginBottom: '15px',
});

const Text = glamorous.div({
  fontSize: '15px',
  color: colors.darkgray,
});

function formatDate(date: Date) {
  const dateTime = DateTime.fromISO(date.toISOString(), { zone: 'America/New_York' });
  return dateTime.toFormat('EEE MMM dd HH:mm:ss ZZZZ yyyy');
}

const FileDeliveryData = ({ data }: { data: FileDeliveryServerData }) => {
  const endpointCode = data.destinationEndpoint ? data.destinationEndpoint.endpointCode : 'unroutable';
  const createDate = new Date(data.createDate);
  const formattedCreateDate = formatDate(createDate);
  return (
    <Data>
      <Text>
        Handoff date: <b>{formattedCreateDate}</b>
      </Text>
      <Text className='pathfinder-info'>
        Destination: <b>{endpointCode}</b>
      </Text>
    </Data>
  );
};

FileDeliveryData.displayName = 'FileDeliveryData';
FileDeliveryData.propTypes = {
  data: PropTypes.object,
};

export default FileDeliveryData;
