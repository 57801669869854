import React, { useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import StoppableFiles from './StoppableFilesApp';
import reducer from './reducers';
import { defaultEnvironments } from './defaultEnvironments';
import { fetchStoppableFiles } from './actions';

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

const StoppableFilesEntry = () => {
  useEffect(() => {
    store.dispatch(fetchStoppableFiles(defaultEnvironments[0], defaultEnvironments));
  }, []);

  return (
    <Provider store={store}>
      <StoppableFiles />
    </Provider>
  );
};

export default StoppableFilesEntry;
