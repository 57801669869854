import React, { Fragment, Component } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Select, Chicon } from 'react-chui';
import { ChubButton, ChubH2 } from '../../../styles/typography';

const CopyLabel = glamorous(ChubH2, { displayName: 'CopyLabel' })({
  margin: '0',
  display: 'inline-block',
  paddingBottom: '20px',
});

const CopyButton = glamorous(ChubButton, { displayName: 'CopyButton' })({
  margin: '0 10px',
});

export default class CopySection extends Component {
  static displayName = 'CopySection';

  static propTypes = {
    sections: PropTypes.object,
    copyableEnvironments: PropTypes.array,
    currentSection: PropTypes.string,
    onCopySection: PropTypes.func,
  };

  static defaultProps = {
    copyableEnvironments: [],
  };

  constructor(props) {
    super(props);
    const { copyableEnvironments, currentSection } = props;
    const { environmentGUID: firstCopyableEnvironmentGUID, sectionName: firstCopyableEnvironmentName } =
      this.findFirstCopyableEnvironment(copyableEnvironments, currentSection);
    this.state = {
      currentCopyToEnvironmentGUID: firstCopyableEnvironmentGUID,
      currentCopyToSectionName: firstCopyableEnvironmentName,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { currentSection } = this.props;
    if (currentSection !== nextProps.currentSection) {
      const { environmentGUID: firstCopyableEnvironmentGUID, sectionName: firstCopyableEnvironmentName } =
        this.findFirstCopyableEnvironment(nextProps.copyableEnvironments, nextProps.currentSection);
      this.setState({
        currentCopyToEnvironmentGUID: firstCopyableEnvironmentGUID,
        currentCopyToSectionName: firstCopyableEnvironmentName,
      });
    }
  }

  findFirstCopyableEnvironment(copyableEnvironments, currentSection) {
    return copyableEnvironments.find(({ sectionName }) => sectionName !== currentSection) || {};
  }

  onSectionSelect = ({ target: { value: currentCopyToEnvironmentGUID } }) => {
    const { copyableEnvironments } = this.props;
    const { sectionName: currentCopyToSectionName } = copyableEnvironments.find(
      ({ environmentGUID }) => environmentGUID === currentCopyToEnvironmentGUID
    );
    this.setState({
      currentCopyToEnvironmentGUID,
      currentCopyToSectionName,
    });
  };

  submitCopySection = () => {
    const { sections, onCopySection, currentSection, copyableEnvironments } = this.props;
    if (sections[currentSection].canSave) {
      window.alert('You have unsaved changes. Please save or cancel them before copying sections.'); // eslint-disable-line
    } else {
      const { currentCopyToEnvironmentGUID, currentCopyToSectionName } = this.state;
      const copyingToNewSftpConnection =
        sections[currentSection].sftpConnection &&
        !(sections[currentCopyToSectionName] && sections[currentCopyToSectionName].sftpConnection);

      const { environmentGUID: firstCopyableEnvironmentGUID, sectionName: firstCopyableEnvironmentName } =
        this.findFirstCopyableEnvironment(copyableEnvironments, currentCopyToSectionName);
      this.setState(
        {
          currentCopyToEnvironmentGUID: firstCopyableEnvironmentGUID,
          currentCopyToSectionName: firstCopyableEnvironmentName,
        },
        () => {
          onCopySection(currentCopyToEnvironmentGUID, currentCopyToSectionName, copyingToNewSftpConnection);
        }
      );
    }
  };

  render() {
    const { copyableEnvironments, currentSection } = this.props;
    const { currentCopyToEnvironmentGUID } = this.state;
    return (
      <Fragment>
        <CopyLabel>Copy To: </CopyLabel>
        <Select id='copy-section-dropdown' value={currentCopyToEnvironmentGUID} onChange={this.onSectionSelect}>
          {copyableEnvironments.reduce((allOptions, { environmentGUID, sectionName }) => {
            if (sectionName !== currentSection) {
              allOptions.push(
                <option value={environmentGUID} key={environmentGUID}>
                  {sectionName}
                </option>
              );
            }
            return allOptions;
          }, [])}
        </Select>
        <CopyButton title='Copy' id='copy-section-button' onClick={this.submitCopySection}>
          <Chicon name='rightcaret' />
        </CopyButton>
      </Fragment>
    );
  }
}
