import React from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import AS2Transfers from './components/AS2Transfers';
import { ChubA } from '../../styles/typography';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import ClientSummary from '../../components/ClientSummary';

const Documentation = glamorous.div({ margin: '0 20px' });

const AS2TransfersResultsApp = ({
  partnerId,
  partnerGUID,
  numberOfTransfers,
  environmentName,
}: {
  partnerId: string;
  partnerGUID: string;
  numberOfTransfers: string;
  environmentName: string;
}) => {
  return (
    <>
      <DefaultHeader showSearch={true} />
      <ClientSummary clientName={partnerId} clientGUID={partnerGUID} />
      <Documentation>
        <ChubA to={'/documentation/commonAS2Errors'}>Click here for help with common AS2 errors.</ChubA>
      </Documentation>
      <AS2Transfers
        partnerId={partnerId}
        defaultNumberOfTransfers={numberOfTransfers}
        defaultEnvironmentName={environmentName}
      />
    </>
  );
};

AS2TransfersResultsApp.displayName = 'AS2TransfersResultsApp';

AS2TransfersResultsApp.propTypes = {
  partnerId: PropTypes.string,
  partnerGUID: PropTypes.string,
  numberOfTransfers: PropTypes.string.isRequired,
  environmentName: PropTypes.string.isRequired,
};

export default AS2TransfersResultsApp;
