interface LoadingReducerState {
  isLoading: boolean;
  error: any;
  selectedEnvironment: string;
  selectedProtocol: string;
  environmentList: string[];
}

export interface LoadingReducerAction {
  type?: string;
  selectedEnvironment?: string;
  selectedProtocol?: string;
  environmentList?: string[];
}

export function connectionsLoading(state: LoadingReducerState, action: LoadingReducerAction) {
  const { selectedEnvironment, selectedProtocol } = action;
  return {
    ...state,
    isLoading: true,
    error: '',
    selectedEnvironment,
    selectedProtocol,
  };
}

export function fetchConnectionsCompleted(state: LoadingReducerState) {
  return {
    ...state,
    isLoading: false,
  };
}

export function fetchConnectionsFailed(state: LoadingReducerState, action: { type?: string; error?: any }) {
  const { error } = action;
  return {
    isLoading: false,
    error,
  };
}

export function certificatesLoading(state: LoadingReducerState) {
  return {
    ...state,
    isLoading: true,
    error: '',
  };
}

export function fetchCertificatesFailed(state: LoadingReducerState, action: { type?: string; error?: any }) {
  const { error } = action;
  return {
    isLoading: false,
    error,
  };
}
