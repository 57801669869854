import { connect } from 'react-redux';

import { fetchNonDeployedVersions, toggleNonDeployed } from '../actions';
import VersionSummary from '../components/VersionSummary';

const CLIENT_CONFIGURATION_BASE_URL = '/commercehub/configuration';

const mapStateToProps = (state) => {
  const {
    versionData: { deployedVersions, nonDeployedVersions, client },
    versionDisplay: { displayNonDeployedVersions },
  } = state;
  const { clientGUID } = client;
  return {
    deployedVersions,
    nonDeployedVersions,
    client,
    displayNonDeployedVersions,
    onVersionButtonClick: () => {
      window.location.href = `${CLIENT_CONFIGURATION_BASE_URL}/${clientGUID}`;
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNonDeployedClick: (clientGUID) => {
      dispatch(fetchNonDeployedVersions(clientGUID));
      dispatch(toggleNonDeployed());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionSummary);
