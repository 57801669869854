import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
// @ts-ignore
import { colors } from 'react-chui';
import { DateTime } from 'luxon';
import { As2ForwarderRequestSummary } from '../CommsTransferData.model';
import { ChubA } from '../../../../styles/typography';

const Data = glamorous.div({
  marginBottom: '32px',
  display: 'flex',
  flexDirection: 'column',
});

const Row = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '15px',
  color: colors.darkgray,
  padding: '1px 0',
});

const LeftColumn = glamorous.div({
  marginRight: '16px',
  textAlign: 'right',
  minWidth: '160px',
});

const RightColumn = glamorous.div({
  fontWeight: 'bold',
  textAlign: 'left',
});

function formatDate(date: Date) {
  const dateTime = DateTime.fromISO(date.toISOString(), { zone: 'America/New_York' });
  return dateTime.toFormat('EEE MMM dd HH:mm:ss.SSS ZZZZ yyyy');
}

const As2ForwarderData = ({ data }: { data: As2ForwarderRequestSummary }) => {
  const startTimeMillis = data.startTime;
  const formattedStartTime = startTimeMillis ? formatDate(new Date(startTimeMillis)) : undefined;

  const forwardTimeMillis = data.forwardTime;
  const formattedForwardTime = forwardTimeMillis ? formatDate(new Date(forwardTimeMillis)) : undefined;

  const endTimeMillis = data.endTime;
  const formattedEndTime = endTimeMillis ? formatDate(new Date(endTimeMillis)) : undefined;

  const storageTimeMillis = data.storageTime;
  const formattedStorageTime = storageTimeMillis ? formatDate(new Date(storageTimeMillis)) : undefined;

  const sendCompleteTimeMillis = data.sendCompleteTime;
  const formattedSendCompleteTime = sendCompleteTimeMillis ? formatDate(new Date(sendCompleteTimeMillis)) : undefined;

  return (
    <Data>
      <Row>
        <LeftColumn>Request Id</LeftColumn>
        <RightColumn>
          <ChubA to={`/as2Forwarder/request/${data.requestId}`}>
            <b>{data.requestId}</b>
          </ChubA>
        </RightColumn>
      </Row>
      <Row>
        <LeftColumn>Status</LeftColumn>
        <RightColumn>{data.status}</RightColumn>
      </Row>
      <Row>
        <LeftColumn>Forward URL</LeftColumn>
        <RightColumn>{data.forwardURL}</RightColumn>
      </Row>
      <Row>
        <LeftColumn>Request Type</LeftColumn>
        <RightColumn>{data.requestType}</RightColumn>
      </Row>
      <Row>
        <LeftColumn>Start Time</LeftColumn>
        <RightColumn>{formattedStartTime}</RightColumn>
      </Row>
      <Row>
        <LeftColumn>Storage Time</LeftColumn>
        <RightColumn>{formattedStorageTime}</RightColumn>
      </Row>
      <Row>
        <LeftColumn>Send Complete Time</LeftColumn>
        <RightColumn>{formattedSendCompleteTime}</RightColumn>
      </Row>
      <Row>
        <LeftColumn>Forward Time</LeftColumn>
        <RightColumn>{formattedForwardTime}</RightColumn>
      </Row>
      <Row>
        <LeftColumn>End Time</LeftColumn>
        <RightColumn>{formattedEndTime}</RightColumn>
      </Row>
    </Data>
  );
};

As2ForwarderData.displayName = 'As2ForwarderData';
As2ForwarderData.propTypes = {
  data: PropTypes.object,
};

export default As2ForwarderData;
