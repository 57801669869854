import { fetchClient } from '../../../util/fetchClient';

export default function runSearch(searchValue) {
  return searchForResults(searchValue).then((results) => {
    if (results.searchByName?.exactMatch) {
      const records = getRecords(results);
      if (records.length === 1 && !records[0].isAdminRecord) {
        return { linkToNavigateTo: `/client/clientOverview/${records[0].clientGUID}` };
      }
    }
    return { searchResults: results };
  });
}

const getRecords = (searchResults) => {
  return Object.values(searchResults).flatMap((result) => result.records);
};

const searchForResults = (searchQuery) => {
  return fetchClient
    .get(`/client/search?searchQuery=${encodeURIComponent(searchQuery)}`)
    .then((response) => response.data);
};
