import React, { Component } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { chubStandards } from '../styles/typography';
import UndoRedo from './UndoRedo';
import HeaderSearch from './HeaderSearch';
import { Link } from 'react-router-dom';
import LogoutLink from './auth/LogoutLink';
import HelpMessageLink from './HelpMessageLink';

const HeaderBar = glamorous('div', { displayName: 'HeaderBar' })({
  margin: '0 auto',
  height: '80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#061629',
  alignItems: 'center',
});

const ChubLogo = glamorous('img', { displayName: 'ChubLogo' })({
  width: 'auto',
  height: 'auto',
  marginLeft: '20px',
  marginRight: '40px',
  marginBottom: '15px',
});

const ReturnHomeLink = glamorous(Link, { displayName: 'ReturnHomeLink' })(chubStandards, {
  fontSize: '24px',
  textAlign: 'center',
  color: '#FFFFFF',
  marginRight: '20px',
  textDecorationLine: 'none',
  textDecoration: 'none',
  fontWeight: 'bold',
});

const Spacer = glamorous('div', { displayName: 'Spacer' })({
  flexGrow: '1',
});

class DefaultHeader extends Component {
  static displayName = 'DefaultHeader';

  /* eslint-disable */
  static defaultProps = {
    showSearch: true,
    showUndoRedo: false,
    canRedo: false,
    onRedo: () => {},
    canUndo: false,
    onUndo: () => {},
  };
  /* eslint-enable */

  static propTypes = {
    canRedo: PropTypes.bool,
    onRedo: PropTypes.func,
    canUndo: PropTypes.bool,
    onUndo: PropTypes.func,
    readOnly: PropTypes.bool,
    showSearch: PropTypes.bool,
    showUndoRedo: PropTypes.bool,
  };

  render() {
    const { canRedo, onRedo, canUndo, onUndo, showSearch, showUndoRedo } = this.props;

    return (
      <HeaderBar>
        <ChubLogo src='/images/logos/commercehub_logo.png' alt='CommerceHub Logo' />
        <ReturnHomeLink to='/' id='home-link'>
          Smooth Operator
        </ReturnHomeLink>

        {showUndoRedo && <UndoRedo canRedo={canRedo} onRedo={onRedo} canUndo={canUndo} onUndo={onUndo} />}

        {showSearch && <HeaderSearch />}
        <Spacer />
        <HelpMessageLink />
        <LogoutLink id='signout-link'>Logout</LogoutLink>
      </HeaderBar>
    );
  }
}

export default DefaultHeader;
