import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { chubStandards } from '../../../styles/typography';
import { ChubTableWrapper } from '../../../styles/table';
import ServerMessages from '../../../components/ServerMessages';

const Title = glamorous('h1', { displayName: 'Title' })(chubStandards, {
  size: '24px',
  textAlign: 'left',
  margin: '0 20px',
});

const PageMessagesWrapper = glamorous(ChubTableWrapper, { displayName: 'PageMessagesWrapper' })({
  maxWidth: '1300px',
  maxHeight: '600px',
  margin: '20px auto 0 auto',
  overflowX: 'hidden',
});

const PageMessages = ({ error, serverMessages }) => (
  <PageMessagesWrapper>
    {error && (
      <Fragment>
        <Title id='connections'>Error Fetching Connections</Title>
      </Fragment>
    )}
    {!error && (
      <Fragment>
        <Title id='connections'>Connections Overview</Title>
        <ServerMessages {...{ serverMessages }} />
      </Fragment>
    )}
  </PageMessagesWrapper>
);

PageMessages.displayName = 'PageMessages';
PageMessages.propTypes = {
  error: PropTypes.any,
  serverMessages: PropTypes.array,
};

export default PageMessages;
