import React from 'react';
import PropTypes from 'prop-types';
import glamorous, { GlamorousComponent } from 'glamorous';
import { chubStandards, ChubP } from '../../../styles/typography';
import { ChubTableWrapper } from '../../../styles/table';
import ServerMessages from '../../../components/ServerMessages';
import { ServerMessage } from '../../../models/server-message.model';

const Title: GlamorousComponent<any, any> = glamorous('h1', { displayName: 'Title' })(chubStandards, {
  size: '24px',
  textAlign: 'left',
  margin: '0 20px',
});

const CountMessage: GlamorousComponent<any, any> = glamorous(ChubP, { displayName: 'CountMessage' })({
  margin: '0 20px',
});

const PartnerSFTPPageMessagesWrapper: GlamorousComponent<any, any> = glamorous(ChubTableWrapper, {
  displayName: 'PartnerSFTPPageMessagesWrapper',
})({
  maxWidth: '1525px',
  maxHeight: '600px',
  margin: '20px auto 0 auto',
  overflowX: 'hidden',
});

const PartnerSFTPPageMessages = ({
  error,
  serverMessages,
  numberOfTransfers,
}: {
  error: any;
  serverMessages: ServerMessage[];
  numberOfTransfers: string;
}) => (
  <PartnerSFTPPageMessagesWrapper>
    {error && (
      <>
        <Title>Error Fetching Partner SFTP Transfer Results</Title>
      </>
    )}
    {!error && (
      <>
        <Title>Partner SFTP Results</Title>
        <CountMessage> The last {numberOfTransfers} recorded transfers</CountMessage>
        <ServerMessages {...{ serverMessages }} />
      </>
    )}
  </PartnerSFTPPageMessagesWrapper>
);

PartnerSFTPPageMessages.displayName = 'PartnerSFTPPageMessages';
PartnerSFTPPageMessages.propTypes = {
  error: PropTypes.string,
  serverMessages: PropTypes.array,
  numberOfTransfers: PropTypes.string,
};

export default PartnerSFTPPageMessages;
