import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import FileRouteTypeRow from '../containers/FileRouteTypeRowContainer';
import { colors } from 'react-chui';
import { ChubH2, ChubButton, ChubP } from '../../../../styles/typography';
import { ChubTableWrapper, ChubTable, ChubTableBody, ChubTableHeadCell } from '../../../../styles/table';
import NavigationWarning from '../../../../components/NavigationWarning';
import SaveCancelFooterContainer from '../containers/SaveCancelFooterContainer';

const FileRouteTypeTableWrapper = glamorous(ChubTableWrapper, { displayName: 'FileRouteTypeTableWrapper' })({
  maxHeight: '585px',
  margin: '20px',
  overflowX: 'hidden',
});

const TableTitle = glamorous(ChubH2, { displayName: 'TableTitle' })({
  marginLeft: '25px',
  display: 'inline-block',
});

const AddButton = glamorous(ChubButton, { displayName: 'AddButton' })({
  margin: '25px',
  float: 'right',
  display: 'inline-block',
});

const ServerMessage = glamorous(ChubP, { displayName: 'ServerMessage' })({
  fontSize: '18px',
  fontWeight: 'bolder',
  marginRight: '85px',
  textAlign: 'right',
  color: colors.chred,
});

const FileRouteTable = glamorous(ChubTable, { displayName: 'FileRouteTypeTable' })({
  display: 'block',
  overflowX: 'auto',
});

const FileRouteTypeHeadCell = glamorous(ChubTableHeadCell, { displayName: 'FileRouteTypeHeadCell' })(
  ({ columnType = 'auto' }) => {
    const styles = {
      width: 'auto',
    };
    switch (columnType) {
      case 'largeInput':
        styles.width = '420px';
        break;
      case 'mediumInput':
        styles.width = '150px';
        break;
      case 'smallInput':
        styles.width = '60px';
        break;
      case 'checkbox':
        styles.width = '40px';
        break;
      case 'guid':
        styles.width = '250px';
        break;
      case 'chicon':
        styles.width = '25px';
        break;
      default:
        break;
    }
    return styles;
  }
);

const FileRouteTypeTable = ({ children }) => {
  return (
    <FileRouteTable>
      <thead>
        <tr>
          <FileRouteTypeHeadCell align='center' columnType='smallInput'>
            Id
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='largeInput'>
            Description
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='smallInput'>
            Direction
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='smallInput'>
            Application
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='smallInput'>
            Application Code
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='smallInput'>
            SFTP Directory
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='checkbox'>
            AS2
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='checkbox'>
            SFTP
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='checkbox'>
            Partner SFTP
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='mediumInput'>
            Regex
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='mediumInput'>
            Extension
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='checkbox'>
            MD5
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell align='center' columnType='guid'>
            GUID
          </FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell>Updated</FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell>By</FileRouteTypeHeadCell>
          <FileRouteTypeHeadCell columnType='chicon' />
        </tr>
      </thead>
      <ChubTableBody>{children()}</ChubTableBody>
    </FileRouteTable>
  );
};

FileRouteTypeTable.propTypes = {
  children: PropTypes.func,
};

FileRouteTypeTable.displayName = 'FileRouteTypeTable';

export default class FileRouteTypeListEditor extends Component {
  static displayName = 'FileRouteTypeListEditor';
  static propTypes = {
    fileRouteTypes: PropTypes.object,
    changesMade: PropTypes.bool,
    serverMessage: PropTypes.string,
    onChangeEditableField: PropTypes.func,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
  };
  static defaultProps = {
    fileRouteTypes: {},
    serverMessage: '',
  };

  render() {
    const { fileRouteTypes, changesMade, serverMessage, onChangeEditableField, onDelete, onAdd } = this.props;

    return (
      <Fragment>
        <div>
          <TableTitle>Administer File Route Types</TableTitle>
          <AddButton id='file-route-type-adder' onClick={onAdd}>
            Add
          </AddButton>
        </div>
        <FileRouteTypeTableWrapper>
          <FileRouteTypeTable>
            {() => {
              return Object.values(fileRouteTypes)
                .filter(
                  (fileRouteType) =>
                    !fileRouteType.deleted || (fileRouteType.serverMessages && fileRouteType.serverMessages.length > 0)
                )
                .map((fileRouteTypeViewModel) => (
                  <FileRouteTypeRow
                    key={fileRouteTypeViewModel.fileRouteTypeGUID}
                    id={fileRouteTypeViewModel.fileRouteTypeGUID}
                    {...{ fileRouteTypeViewModel, onChangeEditableField, onDelete }}
                  />
                ));
            }}
          </FileRouteTypeTable>
          <NavigationWarning isEnabled={changesMade} />
        </FileRouteTypeTableWrapper>
        {serverMessage && <ServerMessage>{serverMessage}</ServerMessage>}
        <SaveCancelFooterContainer />
      </Fragment>
    );
  }
}
