import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import CertificateDataItem from './CertificateDataItem';

const CertificateInfoBlock = glamorous('dl', { displayName: 'CertificateInfoBlock' })({
  margin: '50px',
});

export default class CertificateInfo extends Component {
  static displayName = 'CertificateInfo';

  static propTypes = {
    alias: PropTypes.string,
    subject: PropTypes.string,
    notBefore: PropTypes.string,
    notAfter: PropTypes.string,
    serialNumber: PropTypes.string,
    signatureAlgorithm: PropTypes.string,
    status: PropTypes.string,
  };

  render() {
    const { alias, subject, notBefore, notAfter, serialNumber, signatureAlgorithm, status } = this.props;
    return (
      <CertificateInfoBlock>
        <CertificateDataItem itemLabel='Alias' itemData={alias} id='alias' />
        <CertificateDataItem itemLabel='Subject' itemData={subject} id='subject' />
        <CertificateDataItem itemLabel='Not Before' itemData={notBefore} id='not-before' />
        <CertificateDataItem itemLabel='Not After' itemData={notAfter} id='not-after' />
        <CertificateDataItem itemLabel='Serial Number' itemData={serialNumber} id='serial-number' />
        <CertificateDataItem itemLabel='Signature Algorithm' itemData={signatureAlgorithm} id='signature-algorithm' />
        <CertificateDataItem itemLabel='Status' itemData={status} id='status' />
      </CertificateInfoBlock>
    );
  }
}
