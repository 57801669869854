import { CHANGE_NUMBER_OF_SFTP_REQUESTS, RESET_STATE } from '../ClientOverviewActionTypes';

// Copied max from the EARL service (VLTraderResource.groovy)
const MAX_NUMBER_OF_TRANSFERS = 500;

const initialState = {
  numberOfTransfers: 30,
};

const sftpTransfersBrickReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_NUMBER_OF_SFTP_REQUESTS: {
      const { newValueRaw } = action;
      try {
        // Only allow positive integers.
        const newValue = Number(newValueRaw);
        if (newValue > 0 && newValue <= MAX_NUMBER_OF_TRANSFERS && newValueRaw.match(/^[0-9]+$/)) {
          return {
            ...state,
            numberOfTransfers: newValue,
          };
        }
      } catch (ignored) {
        return state;
      }
      break;
    }
    case RESET_STATE: {
      return initialState;
    }
  }
  return state;
};

export default sftpTransfersBrickReducer;
