import { parse } from 'url';
import { newState, updateCurrentSectionPartnerSftp } from '../../actions';
import { DEFAULT_PORT, defaultPort, enforceUrlStructure, protocolIsHttps } from "../../util/url-structure";

function urlHasChanged(origValue, newValue) {
    return newValue && origValue.toLowerCase() !== newValue.toLowerCase();
}

function findConnectionByGuid(connectionList, guid) {
    return connectionList.find((connection) => connection.accountGUID === guid);
}

export function setPartnerSftpConfigurationUrl(state, action) {
    const { url: newUrl, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { host: url, port } = partnerSftpConnection;
    const newEnforcedUrl = enforceUrlStructure(newUrl);
    const { port: urlPort } = parse(newEnforcedUrl);
    const newPort = parseInt(urlPort || DEFAULT_PORT, 10);

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        host: {
            ...url,
            newValue: newEnforcedUrl,
            changed: urlHasChanged(url.origValue, newEnforcedUrl),
        },
        port: {
            ...port,
            newValue: newPort,
            changed: port.origValue !== newPort,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}

export function setPartnerSftpConfigurationPort(state, action) {
    const { port: updatedPortValue, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { port, host: url } = partnerSftpConnection;
    const { newValue: newUrlValue, origValue: origUrlValue } = url;
    const { protocol, hostname, path } = parse(newUrlValue || origUrlValue);

    const newPort = parseInt(updatedPortValue || defaultPort(protocolIsHttps(protocol)), 10);
    const newUrl = `${protocol}//${hostname}:${newPort}${path}`;

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        host: {
            ...url,
            newValue: newUrl,
            changed: urlHasChanged(url.origValue, newUrl),
        },
        port: {
            ...port,
            newValue: newPort,
            changed: port.origValue !== newPort,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}

export function setPartnerSftpUsername(state, action) {
    const { username: newUsername, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { username } = partnerSftpConnection;

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        username: {
            ...username,
            newValue: newUsername,
            changed: username.origValue !== newUsername,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}

export function setPartnerSftpPassword(state, action) {
    const { password: newPassword, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { password } = partnerSftpConnection;

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        password: {
            ...password,
            newValue: newPassword,
            changed: password.origValue !== newPassword,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}

export function setClientAuthCertPassword(state, action) {
    const { clientAuthenticationCertPassword: newPassword, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { clientAuthenticationCertPassword } = partnerSftpConnection;

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        clientAuthenticationCertPassword: {
            ...clientAuthenticationCertPassword,
            newValue: newPassword,
            changed: clientAuthenticationCertPassword.origValue !== newPassword,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}

export function setClientAuthPrivateKeyFilePassword(state, action) {
    const { clientAuthenticationPrivateKeyFilePassword: newPassword, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { clientAuthenticationPrivateKeyFilePassword } = partnerSftpConnection;

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        clientAuthenticationPrivateKeyFilePassword: {
            ...clientAuthenticationPrivateKeyFilePassword,
            newValue: newPassword,
            changed: clientAuthenticationPrivateKeyFilePassword.origValue !== newPassword,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}

export function setClientAuthCert(state, action) {
    const { clientAuthenticationCert: newCert, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { clientAuthenticationCert } = partnerSftpConnection;

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        clientAuthenticationCert: {
            ...clientAuthenticationCert,
            newValue: newCert,
            changed: clientAuthenticationCert.origValue !== newCert,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}

export function setClientAuthPrivateKeyFile(state, action) {
    const { clientAuthenticationPrivateKeyFile: newFile, accountGUID } = action;
    const { sections, currentSection } = state;
    const partnerSftpConnectionList = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = findConnectionByGuid(partnerSftpConnectionList, accountGUID);
    const { clientAuthenticationPrivateKeyFile } = partnerSftpConnection;

    const newPartnerSftpConnection = {
        ...partnerSftpConnection,
        clientAuthenticationPrivateKeyFile: {
            ...clientAuthenticationPrivateKeyFile,
            newValue: newFile,
            changed: clientAuthenticationPrivateKeyFile.origValue !== newFile,
        },
    };

    const editedCurrentSection = updateCurrentSectionPartnerSftp(
        sections[currentSection],
        partnerSftpConnectionList,
        newPartnerSftpConnection,
        accountGUID
    );

    return newState(state, sections, currentSection, editedCurrentSection, accountGUID);
}
