import React, { useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { ConnectionsOverview } from './ConnectionsOverviewApp';
import reducer from './reducers';
import { defaultEnvironments, isEnabledOptions, protocols, usesNewIpsOptions } from './dropdownOptions';
import { fetchCertificatesAndConnections, resetState } from './actions';

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

const ConnectionsOverviewEntry = () => {
  useEffect(() => {
    store.dispatch(resetState());
    store.dispatch(
      fetchCertificatesAndConnections(defaultEnvironments[0], protocols[0], {
        usesNewIps: usesNewIpsOptions[0],
        isEnabled: isEnabledOptions[1], // default to true
        certificate: '',
      })
    );
  }, []);

  return (
    <Provider store={store}>
      <ConnectionsOverview />
    </Provider>
  );
};

export default ConnectionsOverviewEntry;
