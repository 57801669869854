import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import DefaultHeaderContainer from './containers/DefaultHeaderContainer';
import EnvironmentListEditorContainer from './containers/EnvironmentListEditorContainer';
import LoadingStateProviderContainer from './containers/LoadingStateProviderContainer';
import { ChubH2 } from '../../../styles/typography';

/* eslint-disable */
const TableTitle = glamorous(ChubH2, { displayName: 'TableTitle' })({
  marginLeft: '25px',
}) as any;
/* eslint-enable */

export interface EnvironmentsProp {
  readOnly: boolean;
}

const Environments = (props: EnvironmentsProp) => (
  <Fragment>
    <DefaultHeaderContainer {...props} />
    <div>
      <TableTitle id='administer-environments'>Administer Environments</TableTitle>
      <LoadingStateProviderContainer>{() => <EnvironmentListEditorContainer />}</LoadingStateProviderContainer>
    </div>
  </Fragment>
);

Environments.displayName = 'Environments';
Environments.propTypes = {
  readOnly: PropTypes.bool,
};

export default Environments;
