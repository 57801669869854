import { connect } from 'react-redux';
import StoppableFileTable from '../components/StoppableFileTable';
import { fetchStoppableFiles, stopFile } from '../actions';

const mapDispatchToProps = (dispatch) => {
  return {
    requestStoppableFiles: (environmentName, environmentList) => {
      dispatch(fetchStoppableFiles(environmentName, environmentList));
    },
    stopFileAction: (environmentName, sendID) => {
      dispatch(stopFile(environmentName, sendID));
    },
  };
};

const mapStateToProps = (state) => {
  const {
    loading: { selectedEnvironment, environmentList },
    stopFile: { stoppableFileViewModels },
  } = state;
  return { stoppableFileViewModels, selectedEnvironment, environmentList };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoppableFileTable);
