import cloneDeep from 'lodash.clonedeep';
import {
  RECEIVE_STOPPABLE_FILES,
  STOP_FILE_REQUESTED,
  STOP_FILE_COMPLETED,
  STOP_FILE_FAILED,
} from '../StoppableFilesActionTypes';

const initialState = {};

const stopFileReducer = (state = initialState, action) => {
  function updateStoppableViewModel(sendID, stoppedStatus, message) {
    const stateCopy = cloneDeep(state);
    const stoppableFileToUpdate = stateCopy.stoppableFileViewModels.find((stoppableFileViewModel) => {
      return stoppableFileViewModel.sendID === sendID;
    });
    if (stoppableFileToUpdate) {
      stoppableFileToUpdate.stoppedStatus = stoppedStatus;
      stoppableFileToUpdate.message = message;
    }
    return stateCopy;
  }

  switch (action.type) {
    case RECEIVE_STOPPABLE_FILES: {
      const { stoppableFileViewModels } = action.messageDetails;
      const { environmentName } = action;
      return {
        ...state,
        environmentName,
        stoppableFileViewModels,
      };
    }
    case STOP_FILE_REQUESTED: {
      const { sendID } = action;
      return updateStoppableViewModel(sendID, 'Stop Requested', '');
    }
    case STOP_FILE_COMPLETED: {
      const { sendID } = action;
      return updateStoppableViewModel(sendID, 'Stopped', '');
    }
    case STOP_FILE_FAILED: {
      const { sendID, error } = action;
      let { message } = action;
      if (error) {
        message = error.message;
        // This isn't ideal, but I don't want to overcomplicate the fetch call :(
        if (message.startsWith('Unexpected token <')) {
          message = 'Call to server failed!';
        }
      }
      return updateStoppableViewModel(sendID, '', message);
    }
  }
  return state;
};

export default stopFileReducer;
