import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { chubStandards, ChubInput } from '../../../styles/typography';
import { ChubTable, ChubTableBody, ChubTableHeadCell, ChubTableWrapper } from '../../../styles/table';
import StoppableFileTableRow from './StoppableFileTableRow';

const NoFilesMessage = glamorous('h3', { displayName: 'NoFilesMessage' })(chubStandards, {
  size: '18px',
  textAlign: 'left',
  margin: '0 0 20px 20px',
});

const StoppableFilesTableWrapper = glamorous(ChubTableWrapper, {
  displayName: 'StoppableFilesTableWrapper',
})({
  maxWidth: '1300px',
  maxHeight: '600px',
  margin: '20px auto 0 auto',
  overflowX: 'hidden',
});

const InputLabel = glamorous('label', { displayName: 'InputLabel' })(chubStandards, ({ fontSize = '20px' }) => ({
  padding: '0 10px',
  fontSize,
}));

const EnvironmentButtons = glamorous('div', { displayName: 'EnvironmentButtons' })(chubStandards, {
  margin: '20px',
});

export default class StoppableFileTable extends Component {
  static displayName = 'StoppableFileTable';
  static propTypes = {
    environmentList: PropTypes.array,
    stoppableFileViewModels: PropTypes.array,
    selectedEnvironment: PropTypes.string,
    requestStoppableFiles: PropTypes.func,
    stopFileAction: PropTypes.func,
    stopFile: PropTypes.object,
  };

  _onSelectEnvironment = ({ target: { value } }) => {
    const { environmentList, requestStoppableFiles } = this.props;
    requestStoppableFiles(value, environmentList);
  };

  _renderTypeRadioButtons = () => {
    const { selectedEnvironment, environmentList } = this.props;
    return Object.values(environmentList).map((environment) => (
      <InputLabel fontSize='16px' key={environment}>
        <ChubInput
          data-input-type='selected-environment'
          onChange={this._onSelectEnvironment}
          type='radio'
          name='environment'
          value={environment}
          checked={selectedEnvironment === environment}
        />
        {environment}
      </InputLabel>
    ));
  };

  render() {
    const { selectedEnvironment, stoppableFileViewModels, stopFileAction } = this.props;
    const isEmpty = !stoppableFileViewModels || stoppableFileViewModels.length === 0;
    return (
      <StoppableFilesTableWrapper>
        {<EnvironmentButtons>{this._renderTypeRadioButtons()}</EnvironmentButtons>}
        {isEmpty && <NoFilesMessage>No Stoppable Files Found</NoFilesMessage>}
        {!isEmpty && (
          <ChubTable>
            <thead>
              <tr>
                <ChubTableHeadCell>External ID</ChubTableHeadCell>
                <ChubTableHeadCell>Host</ChubTableHeadCell>
                <ChubTableHeadCell>Send ID</ChubTableHeadCell>
                <ChubTableHeadCell>Inserted Date</ChubTableHeadCell>
                <ChubTableHeadCell />
              </tr>
            </thead>
            <ChubTableBody>
              {stoppableFileViewModels.map((record, index) => (
                <StoppableFileTableRow key={index} {...{ selectedEnvironment, stopFileAction, ...record }} />
              ))}
            </ChubTableBody>
          </ChubTable>
        )}
      </StoppableFilesTableWrapper>
    );
  }
}
