import { combineReducers } from 'redux';
import as2TransfersBrickReducer from './as2TransfersBrickReducer';
import sftpTransfersBrickReducer from './sftpTransfersBrickReducer';
import partnerSftpTransfersBrickReducer from "./partnerSftpTransfersBrickReducer";
import versionDisplayReducer from './versionDisplayReducer';
import versionDataReducer from './versionDataReducer';

const reducer = combineReducers({
  as2TransfersBrick: as2TransfersBrickReducer,
  sftpTransfersBrick: sftpTransfersBrickReducer,
  partnerSftpTransfersBrick: partnerSftpTransfersBrickReducer,
  versionData: versionDataReducer,
  versionDisplay: versionDisplayReducer,
});

export default reducer;
