import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';
import NavigationWarning from '../../../../components/NavigationWarning';
import EnvironmentRow from '../containers/EnvironmentRowContainer';
import SaveCancelFooterContainer from '../containers/SaveCancelFooterContainer';
import { ChubTable, ChubTableBody, ChubTableHeadCell, ChubTableWrapper } from '../../../../styles/table';

import AdderBar from '../../../../components/AdderBar';
import { EnvironmentViewModel } from '../../../../viewmodels/EnvironmentViewModel';
import { EditableFieldViewModel } from '../../../../viewmodels/base/EditableFieldViewModel';
import { uuidv4 } from '../../../../mockable/uuidv4-wrapper';
import { ChubP, chubStandards } from '../../../../styles/typography';

const EnvironmentTableWrapper = glamorous(ChubTableWrapper, { displayName: 'EnvironmentTableWrapper' })({
  maxWidth: '1800px',
  maxHeight: '600px',
  margin: '0 auto',
  overflowX: 'hidden',
});

const EnvironmentHeadCell = glamorous(ChubTableHeadCell, { displayName: 'EnvironmentTableHeadCell' })(
  ({ columnType = 'auto' }) => {
    const styles = {
      width: 'auto',
    };
    switch (columnType) {
      case 'name':
        styles.width = '350px';
        break;
      case 'guid':
        styles.width = '260px';
        break;
      case 'button':
        styles.width = '25px';
        break;
      case 'date':
        styles.width = '200px';
        break;
      default:
        break;
    }
    return styles;
  }
);

const ErrorText = glamorous(ChubP, { displayName: 'ErrorText' })(chubStandards, {
  color: colors.chred,
  marginRight: '40px',
  textAlign: 'right',
});

const EnvironmentTable = ({ children }) => {
  return (
    <ChubTable>
      <thead>
        <tr>
          <EnvironmentHeadCell columnType='name'>Name</EnvironmentHeadCell>
          <EnvironmentHeadCell columnType='guid'>GUID</EnvironmentHeadCell>
          <EnvironmentHeadCell>AS2Gateways</EnvironmentHeadCell>
          <EnvironmentHeadCell columnType='date'>Create Date</EnvironmentHeadCell>
          <EnvironmentHeadCell columnType='date'>Update Date</EnvironmentHeadCell>
        </tr>
      </thead>
      <ChubTableBody>{children()}</ChubTableBody>
    </ChubTable>
  );
};

EnvironmentTable.propTypes = {
  children: PropTypes.func,
};

EnvironmentTable.displayName = 'EnvironmentTable';

export default class EnvironmentListEditor extends Component {
  static displayName = 'EnvironmentListEditor';
  static propTypes = {
    environmentList: PropTypes.array,
    readOnly: PropTypes.bool,
    changesMade: PropTypes.bool,
    updateEnvironment: PropTypes.func,
    addEnvironment: PropTypes.func,
    newEnvironment: PropTypes.string,
  };
  static defaultProps = {
    environmentList: [],
  };

  state = {
    errorText: '',
  };

  _renderWriteAssets = () => {
    const { errorText } = this.state;

    return (
      <Fragment>
        <AdderBar id='environment-adder' itemType='Environment' addItem={this._addEnvironment} showGUID={true} />
        {errorText && <ErrorText id='error-text'>{errorText}</ErrorText>}
        <SaveCancelFooterContainer />
      </Fragment>
    );
  };

  _addEnvironment = (environmentName, environmentGUID) => {
    const { environmentList, addEnvironment } = this.props;
    const newEnvironmentName = environmentName.trim();
    const newEnvironmentGUID = environmentGUID ? environmentGUID.trim() : uuidv4();

    const existingEnv = environmentList.find((env) => env.environmentGUID.origValue === newEnvironmentGUID);
    if (existingEnv) {
      this.setState({
        ...this.state,
        errorText: `Cannot add ${environmentName}, GUID ${newEnvironmentGUID} already exists`,
      });
      return;
    }

    const newEnvironment = new EnvironmentViewModel({
      environmentGUID: new EditableFieldViewModel({ origValue: newEnvironmentGUID }),
      environmentName: new EditableFieldViewModel({ origValue: newEnvironmentName }),
      as2Gateways: new EditableFieldViewModel({ origValue: [] }),
    });
    addEnvironment(newEnvironment);
    this.setState({
      ...this.state,
      errorText: '',
    });
  };

  render() {
    const { environmentList, readOnly, updateEnvironment, changesMade } = this.props;

    return (
      <Fragment>
        <EnvironmentTableWrapper>
          <EnvironmentTable>
            {() => {
              return environmentList
                .filter(
                  (environment) =>
                    !environment.deleted ||
                    !environment.environmentName.serverMessages ||
                    environment.environmentName.serverMessages.length > 0
                )
                .map((environment) => (
                  <EnvironmentRow
                    key={environment.environmentGUID.value}
                    id={environment.environmentGUID.value}
                    readOnly={readOnly}
                    onUpdate={updateEnvironment}
                    hasChanges={environment.hasChanges}
                    serverMessages={environment.serverMessages}
                    {...environment}
                  />
                ));
            }}
          </EnvironmentTable>
        </EnvironmentTableWrapper>
        {!readOnly && (
          <Fragment>
            {this._renderWriteAssets()}
            <NavigationWarning isEnabled={changesMade} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}
