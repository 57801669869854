import { connect } from 'react-redux';
import FileRouteTypeRow from '../components/FileRouteTypeRow';

const mapStateToProps = ({
  undoableFileRouteTypes: {
    present: { readOnly },
  },
}) => {
  return {
    readOnly,
  };
};

const mapDispatchToProps = () => {
  return {};
};

const FileRouteTypeRowContainer = connect(mapStateToProps, mapDispatchToProps)(FileRouteTypeRow);

export default FileRouteTypeRowContainer;
