import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import ExportAs2Bubble from './ExportAS2Bubble';
import { ChubButton } from '../../../styles/typography';

const ExportButton = glamorous(ChubButton, { displayName: 'ViewButton' })(() => ({
  margin: '0 10px',
  ':hover': {
    background: '#1d82ff',
  },
  display: 'inline-block',
  float: 'right',
}));

export default class ExportDetails extends Component {
  static displayName = 'ExportDetails';
  static propTypes = {
    parentId: PropTypes.string,
    as2ExportProps: PropTypes.object,
  };

  componentWillMount() {
    this.setState({
      viewChubCertificate: false,
      copiedToClipboard: false,
    });
  }

  _toggleBubble = () => {
    const { viewChubCertificate } = this.state;

    this.setState({
      viewChubCertificate: !viewChubCertificate,
    });
  };

  _showCopiedMessage = (newState) => {
    const { copiedToClipboard } = this.state;

    if (newState !== copiedToClipboard) {
      this.setState({
        copiedToClipboard: newState,
      });
    }
  };

  render() {
    const { as2ExportProps, parentId } = this.props;
    const { viewChubCertificate, copiedToClipboard } = this.state;

    const onClickViewExportBubble = this._toggleBubble;
    const onCopiedClick = this._showCopiedMessage;

    return (
      <ExportButton id={`export-view-${parentId}`} onClick={onClickViewExportBubble} value={as2ExportProps}>
        Export
        {viewChubCertificate && (
          <ExportAs2Bubble
            {...{ as2ExportProps, parentId, onClickViewExportBubble, copiedToClipboard, onCopiedClick }}
          />
        )}
      </ExportButton>
    );
  }
}
