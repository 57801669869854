import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import As2IdRow from '../containers/As2IdRowContainer';

import AdderBar from '../../../../components/AdderBar';
import NavigationWarning from '../../../../components/NavigationWarning';
import SaveCancelFooterContainer from '../containers/SaveCancelFooterContainer';
import { ChubTableWrapper, ChubTable, ChubTableBody, ChubTableHeadCell } from '../../../../styles/table';

const As2TableWrapper = glamorous(ChubTableWrapper, { displayName: 'As2TableWrapper' })({
  maxWidth: '1300px',
  maxHeight: '600px',
  margin: '0 auto',
  overflowX: 'hidden',
});

const WriteAssets = ({ addAs2Id }) => (
  <Fragment>
    <AdderBar id='as2id-adder' itemType='AS2ID' addItem={addAs2Id} />
    <SaveCancelFooterContainer />
  </Fragment>
);

WriteAssets.displayName = 'WriteAssets';
WriteAssets.propTypes = {
  addAs2Id: PropTypes.func,
};

const As2Table = ({ children: tableRows }) => (
  <ChubTable>
    <thead>
      <tr>
        <ChubTableHeadCell>AS2 ID</ChubTableHeadCell>
        <ChubTableHeadCell>GUID</ChubTableHeadCell>
        <ChubTableHeadCell>Create Date</ChubTableHeadCell>
        <ChubTableHeadCell>Update Date</ChubTableHeadCell>
        <ChubTableHeadCell>Author</ChubTableHeadCell>
      </tr>
    </thead>

    <ChubTableBody>{tableRows}</ChubTableBody>
  </ChubTable>
);

As2Table.propTypes = {
  children: PropTypes.any,
};

As2Table.displayName = 'As2Table';

const As2IdListEditor = ({ as2IdList, addAs2Id, changesMade }) => {
  const tableRows = as2IdList.map((as2Identifier) => (
    <As2IdRow key={as2Identifier.systemIdentifierGUID} id={as2Identifier.systemIdentifierGUID} {...as2Identifier} />
  ));
  return (
    <Fragment>
      <As2TableWrapper>
        <As2Table>{tableRows}</As2Table>
      </As2TableWrapper>
      <Fragment>
        <WriteAssets addAs2Id={addAs2Id} />
        <NavigationWarning isEnabled={changesMade} />
      </Fragment>
    </Fragment>
  );
};

As2IdListEditor.displayName = 'As2IdListEditor';
As2IdListEditor.propTypes = {
  as2IdList: PropTypes.array,
  changesMade: PropTypes.bool,
  updateAs2Id: PropTypes.func,
  addAs2Id: PropTypes.func,
  newAs2Id: PropTypes.string,
};

As2IdListEditor.defaultProps = {
  as2IdList: [],
};

export default As2IdListEditor;
