import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setGetAccessTokenFunc } from './util/fetchClient';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from './authConfig';

import { MsalProvider } from '@azure/msal-react';
import { AuthNavigationClient } from './AuthNavigationClient';

const msalInstance = new PublicClientApplication(msalConfig);

const AuthProviderWithNavigation = ({ children }: PropsWithChildren<unknown>): JSX.Element | null => {
  const navigate = useNavigate();
  const navigationClient = new AuthNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

setGetAccessTokenFunc(async () => {
  const account = msalInstance.getAllAccounts()?.[0];
  if (!account) {
    throw Error('User is not signed in');
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });

  return response.accessToken;
});

export default AuthProviderWithNavigation;
