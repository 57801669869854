import {
  RECEIVE_VERSION_DATA,
  RECEIVE_NONDEPLOYED_VERSIONS,
  REQUEST_VERSION_DATA_FAILED,
  RESET_STATE,
} from '../ClientOverviewActionTypes';

const initialState = {
  isLoading: true,
  deployedVersions: [],
  nonDeployedVersions: [],
  client: {},
  includesNonDeployedVersions: false,
  error: '',
};

const versionDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NONDEPLOYED_VERSIONS: {
      const { nonDeployedVersionsResponse } = action;
      return {
        ...state,
        nonDeployedVersions: nonDeployedVersionsResponse,
        includesNonDeployedVersions: true,
      };
    }
    case RECEIVE_VERSION_DATA: {
      const {
        versionDataResponse: { versionData },
      } = action;
      return {
        ...state,
        ...versionData,
        isLoading: false,
      };
    }
    case REQUEST_VERSION_DATA_FAILED: {
      const {
        error: { message: fetchError },
      } = action;
      return {
        ...state,
        error: fetchError,
      };
    }
    case RESET_STATE: {
      return initialState;
    }
  }
  return state;
};

export default versionDataReducer;
