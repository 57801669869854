import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';

import { ChubP } from '../styles/typography';

const ServerMessage = glamorous(ChubP, { displayName: 'ServerMessage' })(({ isError }) => {
  const styles = {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
  };
  if (isError) {
    styles.color = colors.chred;
  }
  return styles;
});

export default class EditableField extends Component {
  static displayName = 'EditableField';

  static propTypes = {
    children: PropTypes.func,
    serverMessages: PropTypes.array,
    onBlur: PropTypes.func,
    id: PropTypes.string,
    origValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  };

  static defaultProps = {
    serverMessages: [],
  };

  _renderServerMessages = () => {
    const { serverMessages } = this.props;
    return serverMessages.map(({ messageType, message }, index) => (
      <ServerMessage
        key={index}
        data-message-type='serverMessage'
        isError={messageType.toLowerCase().includes('error')}
      >
        {message}
      </ServerMessage>
    ));
  };

  _isModified = () => {
    const { origValue, newValue } = this.props;
    return origValue !== newValue;
  };

  render() {
    const { id, children, ...otherProps } = this.props;
    return (
      <Fragment>
        {children({
          ...otherProps,
          isModified: this._isModified(),
        })}
        <span id={`${id}-errors`}>{this._renderServerMessages()}</span>
      </Fragment>
    );
  }
}
