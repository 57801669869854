import React from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import { ChubA } from '../../styles/typography';
import glamorous from 'glamorous';
import ClientSummary from '../../components/ClientSummary';
import PropTypes from 'prop-types';
import { useFetch } from '../../hooks/fetch-hook';
import LoadingStateProvider from '../../components/LoadingStateProvider';
import { SFTPTransfersResponse } from './SFTPTransfers.model';
import SFTPPageMessages from './components/SFTPPageMessages';
import SFTPTransfersTable from './components/SFTPTransfersTable';

const Documentation = glamorous.div({ margin: '0 20px' });

const SFTPTransfersResults = ({
  partnerId,
  partnerGUID,
  numberOfTransfers,
  environmentName,
}: {
  partnerId: string;
  partnerGUID: string;
  numberOfTransfers: string;
  environmentName: string;
}) => {
  const { data, error, isLoading } = useFetch<SFTPTransfersResponse>(
    `/transfers/sftp/${partnerId}/${numberOfTransfers}/${environmentName}`
  );

  const serverMessages = data?.serverMessages ? data.serverMessages : [];

  return (
    <>
      <DefaultHeader showSearch={true} />
      <ClientSummary clientName={partnerId} clientGUID={partnerGUID} />
      <Documentation>
        <ChubA to={'/documentation/commonSFTPErrors'}>Click here for help with common SFTP errors.</ChubA>
      </Documentation>
      <SFTPPageMessages error={error} serverMessages={serverMessages} numberOfTransfers={numberOfTransfers} />
      <LoadingStateProvider error={error} isLoading={isLoading}>
        {() => (
          <SFTPTransfersTable
            isEmpty={!data!.sftpFileTransfers || data!.sftpFileTransfers.length === 0}
            fileTransfers={data!.sftpFileTransfers}
          />
        )}
      </LoadingStateProvider>
    </>
  );
};

SFTPTransfersResults.displayName = 'SFTPTransfersResults';
SFTPTransfersResults.propTypes = {
  partnerId: PropTypes.string,
  partnerGUID: PropTypes.string,
  numberOfTransfers: PropTypes.string,
  environmentName: PropTypes.string,
};

export default SFTPTransfersResults;
