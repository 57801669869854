import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SectionViewContainer from './SectionViewContainer';
import RelationshipsView from '../components/relationships/RelationshipsView';
import { toggleShowRelationships, addRelationship, fetchGeneratedSftpPassword } from '../actions';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const ViewController = ({
  relationships: { showRelationships, ...otherRelationshipProps },
  onCloseRelationships,
  onAddRelationship,
  hasSftpConnection,
  ...otherProps
}) => {
  if (showRelationships) {
    return (
      <RelationshipsView
        {...{ onCloseRelationships, onAddRelationship, hasSftpConnection, ...otherRelationshipProps, ...otherProps }}
      />
    );
  }
  return <SectionViewContainer />;
};
ViewController.displayName = 'ViewController';
ViewController.propTypes = {
  relationships: PropTypes.object,
  hasSftpConnection: PropTypes.bool,
  showRelationships: PropTypes.bool,
  onCloseRelationships: PropTypes.func,
  onAddRelationship: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseRelationships: () => dispatch(toggleShowRelationships()),
    onAddRelationship: (relationshipFileRoutes, generatePassword, useAs2ForwarderAsDefault) => {
      dispatch(addRelationship(relationshipFileRoutes, useAs2ForwarderAsDefault));
      dispatch(toggleShowRelationships());
      if (generatePassword) {
        dispatch(fetchGeneratedSftpPassword());
      }
    },
  };
};

const mapStateToProps = (state) => {
  const {
    relationships,
    undoableConfigurationViewModel: {
      present: { sections, currentSection },
    },
  } = state;
  const hasSftpConnection = sections[currentSection] ? Boolean(sections[currentSection].sftpConnection) : false;
  return { relationships, hasSftpConnection };
};

export default withLDConsumer()(connect(mapStateToProps, mapDispatchToProps)(ViewController));
