import { connect } from 'react-redux';
import { ConnectionsTable } from '../components/ConnectionsTable';
import { fetchConnections, filterConnections } from '../actions';

const mapDispatchToProps = (dispatch) => {
  return {
    requestConnections: (environmentName, protocol, filters) => {
      dispatch(fetchConnections(environmentName, protocol, filters));
    },
    filterConnections: (filters, protocol) => {
      dispatch(filterConnections(filters, protocol));
    },
  };
};

const enabledOptionStringToBoolean = (enabledOption) => {
  if (enabledOption === 'true') {
    return true;
  } else if (enabledOption === 'false') {
    return false;
  }
};

const getNumConnectionsThatMatchIsEnabledFilter = (enabledFilterSelected, selectedIsEnabled, connectionViewModels) => {
  if (!enabledFilterSelected) {
    return connectionViewModels.length;
  }
  const enabledFilter = enabledOptionStringToBoolean(selectedIsEnabled);
  return connectionViewModels.filter((viewModel) => viewModel.is_enabled === enabledFilter).length;
};

const getEnabledDescription = (enabledFilterSelected, selectedIsEnabled) => {
  if (!enabledFilterSelected) {
    return '';
  }
  const enabledFilter = enabledOptionStringToBoolean(selectedIsEnabled);
  if (enabledFilter) {
    return 'enabled';
  } else {
    return 'disabled';
  }
};

const percentageAsString = (numerator, denominator) => {
  if (denominator === 0) {
    return '';
  }
  const percentage = (numerator / denominator) * 100;
  if (percentage % 1 === 0) {
    return percentage.toString();
  }
  return percentage.toFixed(2).toString();
};

const getConnectionCountMessage = (
  selectedIsEnabled,
  selectedUsesNewIps,
  selectedCertificate,
  selectedProtocol,
  selectedEnvironment,
  connectionViewModels,
  displayableConnectionViewModels
) => {
  const enabledFilterSelected = selectedIsEnabled !== '';
  const usesNewIpsFilterSelected = selectedProtocol === 'AS2' && selectedUsesNewIps !== '';
  const selectedCertificateFilterSelected = selectedProtocol === 'AS2' && selectedCertificate !== '';
  /* Normal Filters: usesNewIps (for AS2 only), selectedCertificate (for AS2 only)
     Special Filters: enabled
     Not Filters (cause new retrieval): protocol, environment

    When only normal filters, or only "special" filters, are selected we want to show the count and percentage that
    match that filter out of the total number of retrieved (by protocol and environment)
    connections. However, if a special filter is selected along with one or more other filters, we want to show the count
    and percentage out of the number of retrieved connections that match the special filters.
    So countMatchingAllFilters out of countRetrievedMatchingSpecial instead of countMatchingAllFilters out of countRetrieved.
    Currently, the only special
    filter is the enabled filter, and it was chosen because the useNewIps percentage is most useful out of enabled
    connections rather than total connections retrieved.
   */
  const bothSpecialAndNonSpecialFiltersSelected =
    enabledFilterSelected && (usesNewIpsFilterSelected || selectedCertificateFilterSelected);

  if (bothSpecialAndNonSpecialFiltersSelected) {
    const numConnectionsRetrievedThatMatchIsEnabledFilter = getNumConnectionsThatMatchIsEnabledFilter(
      enabledFilterSelected,
      selectedIsEnabled,
      connectionViewModels
    );
    const enabledDescription = getEnabledDescription(enabledFilterSelected, selectedIsEnabled);
    const percentage = percentageAsString(
      displayableConnectionViewModels.length,
      numConnectionsRetrievedThatMatchIsEnabledFilter
    );
    const percentageText = percentage ? `(${percentage}%)` : '';
    return `Displaying ${displayableConnectionViewModels.length} out of ${numConnectionsRetrievedThatMatchIsEnabledFilter} ${enabledDescription} ${selectedProtocol} connections in ${selectedEnvironment} ${percentageText}.`;
  } else {
    const percentage = percentageAsString(displayableConnectionViewModels.length, connectionViewModels.length);
    const percentageText = percentage ? `(${percentage}%)` : '';
    return `Displaying ${displayableConnectionViewModels.length} out of ${connectionViewModels.length} ${selectedProtocol} connections in ${selectedEnvironment} ${percentageText}`;
  }
};

const mapStateToProps = (state) => {
  const {
    loading: { selectedEnvironment, selectedProtocol, environmentList },
    connections: {
      displayableConnectionViewModels,
      selectedUsesNewIps,
      selectedIsEnabled,
      selectedCertificate,
      connectionViewModels,
    },
    certificates: { certificateNames },
  } = state;

  const connectionCountMessage = getConnectionCountMessage(
    selectedIsEnabled,
    selectedUsesNewIps,
    selectedCertificate,
    selectedProtocol,
    selectedEnvironment,
    connectionViewModels,
    displayableConnectionViewModels
  );

  return {
    connectionViewModels: displayableConnectionViewModels,
    connectionCountMessage,
    selectedEnvironment,
    selectedProtocol,
    selectedUsesNewIps,
    selectedIsEnabled,
    environmentList,
    certificateNames,
    selectedCertificate,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsTable);
