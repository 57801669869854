import React from 'react';
import CertificateDisplayTable from './components/CertificateDisplayTable';
import DefaultHeader from '../../components/DefaultHeader';
import ClientSummary from '../../components/ClientSummary';

import useCertificates from './certificates.hook';
import LoadingStateProvider from '../../components/LoadingStateProvider';

const CertificatesApp = () => {
  const { certificates, isLoading, error } = useCertificates();

  return (
    <>
      <DefaultHeader showUndoRedo={false} showSearch={true} />
      <ClientSummary clientName='CommerceHub' />
      <LoadingStateProvider error={error} isLoading={isLoading}>
        {() => <CertificateDisplayTable chubSystemCertificates={certificates} />}
      </LoadingStateProvider>
    </>
  );
};
CertificatesApp.displayName = 'Certificates';

export default CertificatesApp;
