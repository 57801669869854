import React from 'react';
import glamorous from 'glamorous';
import { Spinner } from 'react-chui';
import useExternalLinkRedirect from '../../hooks/ExternalLinkRedirect';

const LoadingSpinner = glamorous(Spinner, {
  displayName: 'LoadingSpinner',
  withProps: {
    size: 'large',
  },
})({
  display: 'block',
  margin: '0 auto',
});

const CommonAS2ErrorsApp = () => {
  useExternalLinkRedirect('https://www.notion.so/commercehub/Common-AS2-Errors-6f559e301f0b42f9b1d5b1d4e9aa8aeb');

  return <LoadingSpinner />;
};
CommonAS2ErrorsApp.displayName = 'Common AS2 Errors';

export default CommonAS2ErrorsApp;
