import React, { useState } from 'react';
import glamorous, { H2, H3, Div } from 'glamorous';

import { colors, Bubble } from 'react-chui';
import { chubStandards } from '../styles/typography';
import { ipInfoData } from './ip-info-data';

const BUBBLE_OPTIONS = {
  attachment: 'middle left',
  targetOffset: '0 10px',
};

const InfoBubbleContent = glamorous(Bubble, { displayName: 'InfoBubbleContent' })({
  width: '920px',
});

const InfoBubbleHeader = glamorous(Bubble.Header, { displayName: 'InfoBubbleHeader' })(chubStandards, {
  maxHeight: '48px',
});

const InfoBubbleEnvironmentName = glamorous(H2, { displayName: 'InfoBubbleEnvironmentName' })(chubStandards, {
  paddingLeft: '16px',
  color: colors.darkblue,
  margin: '16px 0 8px 0',
});

const InfoBubbleFlexBox = glamorous('div', { displayName: 'InfoBubbleFlexBox' })({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'left',
});

const InfoBubbleFlexColumn = glamorous('div', { displayName: 'InfoBubbleFlexColumn' })({
  flex: '50%',
});

const InfoBubbleIpContainer = glamorous('div', { displayName: 'InfoBubbleIpContainer' })(chubStandards, {
  maxHeight: '480px',
  overflowY: 'auto',
  overflowX: 'hidden',
});

const InfoBubbleIcon = glamorous('i', { displayName: 'InfoBubbleIcon' })({
  cursor: 'pointer',
  margin: '4px 8px',
  fontSize: '28px',
  verticalAlign: 'sub',
  color: colors.brightblue,
});

const InfoBubbleDataContainer = glamorous(Div, { displayName: 'InfoBubbleDataContainer' })(chubStandards, {
  textAlign: 'left',
  whiteSpace: 'pre-line',
  padding: '0 8px',
  margin: '4px',
});

const InfoBubbleDataSection = ({ caption, data }) => {
  return (
    <InfoBubbleDataContainer style={{ margin: '4px' }}>
      <H3 style={{ margin: '8px 4px 0 4px' }}>{caption}</H3>
      <InfoBubbleIpContainer>
        {data.map((x) => (
          <Div key={x} style={{ display: 'flex', margin: '8px 4px' }}>
            <Div
              style={{
                minWidth: '400px',
                backgroundColor: 'lightgray',
                cursor: 'text',
                padding: '2px 4px',
                borderRadius: '2px',
              }}
            >
              {x}
            </Div>
          </Div>
        ))}
      </InfoBubbleIpContainer>
    </InfoBubbleDataContainer>
  );
};

const IpInfoBubble = ({ environmentGUID, environmentName, protocol }) => {
  const [showBubble, setShowBubble] = useState(false);
  const toggleBubble = () => {
    setShowBubble(!showBubble);
  };

  const environmentInfo = ipInfoData.find((x) => x.environmentGUID.toLowerCase() === environmentGUID.toLowerCase());
  const protocolInfo = environmentInfo?.protocols.find((x) => x.name.toLowerCase() === protocol);

  return (
    <InfoBubbleIcon className='ch-icon-circlequestion' onClick={() => toggleBubble()}>
      {showBubble && (
        <InfoBubbleContent tetherOptions={BUBBLE_OPTIONS} onRequestClose={() => toggleBubble()}>
          <InfoBubbleHeader>
            <snap>Server IPs Info</snap>
            <i
              style={{ float: 'right', cursor: 'pointer', padding: '4px' }}
              className='ch-icon-circlex'
              onClick={() => toggleBubble()}
            ></i>
          </InfoBubbleHeader>
          <InfoBubbleEnvironmentName>{environmentName}</InfoBubbleEnvironmentName>
          {protocolInfo && (
            <InfoBubbleFlexBox>
              <InfoBubbleFlexColumn>
                {protocolInfo.oldChubURLs && (
                  <InfoBubbleDataSection caption='Old URLs' data={protocolInfo.oldChubURLs} />
                )}
                {protocolInfo.oldSourceIPs && (
                  <InfoBubbleDataSection caption='Old IPs' data={protocolInfo.oldSourceIPs} />
                )}
              </InfoBubbleFlexColumn>
              <InfoBubbleFlexColumn>
                {protocolInfo.newChubURLs && (
                  <InfoBubbleDataSection caption='New URLs' data={protocolInfo.newChubURLs} />
                )}
                {protocolInfo.newSourceIPs && (
                  <InfoBubbleDataSection caption='New IPs' data={protocolInfo.newSourceIPs} />
                )}
              </InfoBubbleFlexColumn>
            </InfoBubbleFlexBox>
          )}
          {!protocolInfo && <Div style={{ margin: '16px' }}>Unable to find IP information for this environment</Div>}
        </InfoBubbleContent>
      )}
    </InfoBubbleIcon>
  );
};
IpInfoBubble.displayName = 'InfoBubble';

export default IpInfoBubble;
