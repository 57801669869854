import { uuidv4 } from '../../../../mockable/uuidv4-wrapper';

const setAs2ConnectionHasChanged = (as2ConnectionToCopy, mailboxGUID) => ({
  ...as2ConnectionToCopy,
  mailboxGUID,
  as2Id: {
    origValue: '',
    newValue: as2ConnectionToCopy.as2Id.origValue,
    changed: true,
  },
});

const setSftpConnectionHasChanged = (sftpConnectionToCopy) => ({
  ...sftpConnectionToCopy,
  username: {
    origValue: '',
    newValue: sftpConnectionToCopy.username.origValue,
    changed: true,
  },
});

const setPartnerSftpConnectionHasChanged = (partnerSftpConnectionToCopy, accountGUID) => ({
  ...partnerSftpConnectionToCopy,
  accountGUID,
  username: {
    origValue: '',
    newValue: partnerSftpConnectionToCopy.username.origValue,
    changed: true,
  },
});

export const copyAs2Connections = ({ as2Connections: as2ConnectionsListToCopy }, destinationSection, as2Gateway) => {
  const as2ConnectionsListResult = [];
  if (as2ConnectionsListToCopy && as2ConnectionsListToCopy.length > 0) {
    as2ConnectionsListToCopy.forEach((connectionToCopy) => {
      const destinationConnection =
        destinationSection.as2Connections &&
        destinationSection.as2Connections.find((conn) => connectionToCopy.as2Id.origValue === conn.as2Id.origValue);
      if (destinationConnection) {
        as2ConnectionsListResult.push(copyAs2FileRoutes(connectionToCopy, destinationConnection));
      } else {
        as2ConnectionsListResult.push(copyAs2Connection(connectionToCopy, uuidv4(), as2Gateway));
      }
    });
  }
  return {
    ...destinationSection,
    as2Connections: as2ConnectionsListResult,
  };
};

export const copyAs2Connection = (as2ConnectionToCopy, mailboxGUID, as2Gateway) => {
  return setAs2ConnectionHasChanged(
    {
      ...as2ConnectionToCopy,
      as2Gateway: {
        newValue: { ...as2Gateway },
        changed: true,
      },
    },
    mailboxGUID
  );
};

export const copyPartnerSftpConnection = (partnerSftpConnectionToCopy, accountGUID) => {
  return setPartnerSftpConnectionHasChanged(
      {
        ...partnerSftpConnectionToCopy
      },
      accountGUID
  );
};

export const copySftpConnection = ({ sftpConnection: sftpConnectionToCopy }, destinationSection) => ({
  ...destinationSection,
  sftpConnection: setSftpConnectionHasChanged(sftpConnectionToCopy),
});

export const copyAllConnections = (currentSection, destinationSection, environmentGUID, sectionName, as2Gateway) => {
  const destinationSectionAfterCopy = {
    ...currentSection,
    versionGUID: destinationSection ? destinationSection.versionGUID : currentSection.versionGUID,
    parentVersionGUID: destinationSection ? destinationSection.parentVersionGUID : currentSection.parentVersionGUID,
    environmentGUID,
    sectionName,
  };

  if (currentSection.sftpConnection) {
    destinationSectionAfterCopy.sftpConnection = setSftpConnectionHasChanged(currentSection.sftpConnection);
  }

  const as2ConnectionsDestination = [];
  if (currentSection.as2Connections && currentSection.as2Connections.length > 0) {
    currentSection.as2Connections.forEach((connectionToCopy) => {
      as2ConnectionsDestination.push(copyAs2Connection(connectionToCopy, uuidv4(), as2Gateway));
    });
  }
  destinationSectionAfterCopy.as2Connections = as2ConnectionsDestination;

  const partnerSftpConnectionsDestination = [];
  if (currentSection.partnerSftpConnections && currentSection.partnerSftpConnections.length > 0) {
    currentSection.partnerSftpConnections.forEach((connectionToCopy) => {
      partnerSftpConnectionsDestination.push(copyPartnerSftpConnection(connectionToCopy, uuidv4()));
    });
  }
  destinationSectionAfterCopy.partnerSftpConnections = partnerSftpConnectionsDestination;

  return destinationSectionAfterCopy;
};

export const copyAs2FileRoutes = ({ connectionEnabled, fileRoutes: fileRoutesToCopy }, destinationConnection) => {
  return setAs2ConnectionHasChanged(
    {
      ...destinationConnection,
      fileRoutes: fileRoutesToCopy,
      connectionEnabled,
    },
    destinationConnection.mailboxGUID
  );
};

export const copySftpFileRoutes = (
  { sftpConnection: { connectionEnabled, fileRoutes: fileRoutesToCopy } },
  destinationSection
) => ({
  ...destinationSection,
  sftpConnection: setSftpConnectionHasChanged({
    ...destinationSection.sftpConnection,
    fileRoutes: fileRoutesToCopy,
    connectionEnabled,
  }),
});

export const copyPartnerSftpFileRoutes = (
    { partnerSftpConnection: { connectionEnabled, fileRoutes: fileRoutesToCopy } },
    destinationSection
) => ({
  ...destinationSection,
  partnerSftpConnection: setPartnerSftpConnectionHasChanged({
    ...destinationSection.partnerSftpConnection,
    fileRoutes: fileRoutesToCopy,
    connectionEnabled,
  }),
});