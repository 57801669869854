import React, { useState } from 'react';
import glamorous from 'glamorous';
import { chubStandards } from '../styles/typography';
import { Chicon } from 'react-chui';
import { useNavigate } from 'react-router-dom';
import runSearch from '../pages/search/clientSearch/runSearch';

const SearchBar = glamorous('div', { displayName: 'SearchBar' })({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  borderRadius: '3px',
  MozBoxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  WebkitBoxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  boxShadow: '1px 1px 2px rgba(0,0,0,0.25) inset',
  MozBoxSizing: 'border-box',
  WebkitBoxSizing: 'border-box',
  boxSizing: 'border-box',
  marginLeft: '20px',
});

const SearchChiconButton = glamorous('button', { displayName: 'SearchChiconButton' })({
  border: 'none',
  background: 'transparent',
  boxShadow: 'none',
});

const SearchIcon = glamorous(Chicon, {
  withProps: { name: 'magglass' },
  displayName: 'SearchIcon',
})(chubStandards, {
  color: 'black',
  fontSize: '28px',
  fontStyle: 'normal',
  margin: '0 5px',
});

const SearchInput = glamorous('input', { displayName: 'SearchInput' })({
  display: 'inline-block',
  fontSize: '15px',
  resize: 'none',
  outline: 'none',
  WebkitFontSmoothing: 'antialiased',
  verticalAlign: 'middle',
  boxShadow: 'none',
  border: 'none',
  margin: '2px',
});

const RightIcon = glamorous('div', { displayName: 'RightIcon' })({
  width: '20px',
  border: 'none',
});

const HeaderSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();

  const redirectToSearchPage = (state) => {
    const searchUrl = `/search?searchString=${searchValue}`;
    navigate(searchUrl, state);
  };

  const conditionallyRedirect = (response) => {
    const { searchResults, linkToNavigateTo } = response;
    if (linkToNavigateTo) {
      navigate(linkToNavigateTo);
    } else {
      redirectToSearchPage({ state: { searchResults } });
    }
  };

  const performSearch = () => {
    runSearch(searchValue)
      .then((results) => conditionallyRedirect(results))
      .catch(() => redirectToSearchPage({ state: { error: true } }));
  };

  const handleKeyPress = (key) => {
    if (key === 'Enter' && searchValue) {
      performSearch();
    }
  };

  return (
    <SearchBar id='client-search'>
      <SearchChiconButton onClick={() => performSearch()}>
        <SearchIcon id='search-button' />
      </SearchChiconButton>
      <SearchInput
        type='text'
        onChange={(event) => setSearchValue(event.target.value)}
        onKeyPress={(event) => handleKeyPress(event.key)}
      />
      <RightIcon />
    </SearchBar>
  );
};

HeaderSearch.displayName = 'HeaderSearch';

export default HeaderSearch;
