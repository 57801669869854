export class EditableFieldViewModel {
  constructor(jsonModel = {}) {
    this.origValue = jsonModel.origValue;
    this.newValue = jsonModel.newValue;
    this.serverMessages = jsonModel.serverMessages ? jsonModel.serverMessages : [];
    this.changed = Boolean(jsonModel.hasChanges || false);
  }

  reset(arg) {
    this.origValue = arg;
    this.newValue = null;
    this.changed = false;
    this.serverMessages = [];
  }

  get hasChanges() {
    return this.changed || false;
  }

  get value() {
    return this.hasChanges ? this.newValue : this.origValue;
  }

  set value(arg) {
    if (arg === this.origValue) {
      this.newValue = null;
      this.changed = false;
    } else {
      this.newValue = arg;
      this.changed = true;
    }
  }
}
