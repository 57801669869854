import { connect } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import DefaultHeader from '../../../components/DefaultHeader';

const mapStateToProps = (state) => {
  const {
    undoableConfigurationViewModel: { past, future },
  } = state;
  return {
    canRedo: future.length > 0,
    canUndo: past.length > 0,
    showSearch: true,
    showUndoRedo: true,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRedo: () => dispatch(UndoActionCreators.redo()),
    onUndo: () => dispatch(UndoActionCreators.undo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
