import { connect } from 'react-redux';
import { changeNumberOfAS2Requests } from '../actions';

import AS2TransfersBrick from '../components/AS2TransfersBrick';

const TRANSFERS_RESULTS_BASE_URL = '/transfers/as2/page';

const mapStateToProps = (state) => {
  const {
    versionData: {
      client: { clientName, clientGUID },
    },
    as2TransfersBrick: { numberOfTransfers },
  } = state;
  return {
    numberOfTransfers,
    createLookupTransfersButtonUrl: (environmentName) =>
      `${TRANSFERS_RESULTS_BASE_URL}/${clientName}/${clientGUID}/${numberOfTransfers}/${environmentName}`,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeNumberOfRequests: (event) => {
      if (event && event.target) {
        dispatch(changeNumberOfAS2Requests(event.target.value));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AS2TransfersBrick);
