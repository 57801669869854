import { uuidv4 } from '../../../../mockable/uuidv4-wrapper';
import {
  AS2_CONNECTION_TYPE,
  SFTP_CONNECTION_TYPE,
  PARTNER_SFTP_CONNECTION_TYPE,
} from '../../../../util/commerceHubInfo';
import { newState } from '../../actions';

export function setFileRouteMd5Verified(state, action) {
  const { fileRouteGUID, md5Verified, connectionType } = action;
  const { sections, currentSection } = state;
  const currentFileRoute = sections[currentSection][connectionType].fileRoutes[fileRouteGUID];
  const changed = currentFileRoute.md5Verified.origValue !== md5Verified;
  const editedCurrentSection = {
    ...sections[currentSection],
    [connectionType]: {
      ...sections[currentSection][connectionType],
      fileRoutes: {
        ...sections[currentSection][connectionType].fileRoutes,
        [fileRouteGUID]: {
          ...currentFileRoute,
          md5Verified: {
            ...currentFileRoute.md5Verified,
            newValue: md5Verified,
            changed,
          },
        },
      },
    },
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function setFileRouteKey(state, action) {
  const { fileRouteGUID, fileTypeId, connectionType, mailboxGUID, accountGUID } = action;
  const { sections, currentSection, as2FileRouteTypes, sftpFileRouteTypes, partnerSftpFileRouteTypes } = state;

  const { name, applicationCode, endpointName, direction } =
    connectionType === AS2_CONNECTION_TYPE
      ? as2FileRouteTypes[fileTypeId]
      : connectionType === SFTP_CONNECTION_TYPE
      ? sftpFileRouteTypes[fileTypeId]
      : partnerSftpFileRouteTypes[fileTypeId];

  let editedCurrentSection;
  if (connectionType === SFTP_CONNECTION_TYPE) {
    const currentFileRoute = sections[currentSection][connectionType].fileRoutes[fileRouteGUID];
    const currentDirection = currentFileRoute.direction.newValue || currentFileRoute.direction.origValue;
    const changed = currentFileRoute.applicationKey.origValue !== fileTypeId;
    editedCurrentSection = {
      ...sections[currentSection],
      [connectionType]: {
        ...sections[currentSection][connectionType],
        fileRoutes: {
          ...sections[currentSection][connectionType].fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            applicationKey: {
              ...currentFileRoute.applicationKey,
              newValue: fileTypeId,
              changed,
            },
            applicationCode: {
              ...currentFileRoute.applicationCode,
              newValue: applicationCode,
              changed,
            },
            endpointName: {
              ...currentFileRoute.endpointName,
              newValue: endpointName,
              changed,
            },
            direction: {
              ...currentFileRoute.direction,
              newValue: direction,
              changed,
            },
            name: {
              ...currentFileRoute.name,
              newValue: name,
              changed,
            },
          },
        },
      },
    };
    if (currentDirection.toLowerCase() === 'incoming' && direction.toLowerCase() === 'outgoing') {
      editedCurrentSection[connectionType].fileRoutes[fileRouteGUID].additionalRoutingData = {
        ...currentFileRoute.additionalRoutingData,
        newValue: null,
        changed: true,
      };
    }
  } else if (connectionType === PARTNER_SFTP_CONNECTION_TYPE) {
    const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = partnerSftpConnections.find((connection) => connection.accountGUID === accountGUID);
    const currentFileRoute = partnerSftpConnection.fileRoutes[fileRouteGUID];
    const currentDirection = currentFileRoute.direction.newValue || currentFileRoute.direction.origValue;
    const changed = currentFileRoute.applicationKey.origValue !== fileTypeId;

    const newPartnerSftpConnectionList = [];
    partnerSftpConnections.forEach((connection) => {
      if (connection.accountGUID === accountGUID) {
        newPartnerSftpConnectionList.push({
          ...connection,
          fileRoutes: {
            ...connection.fileRoutes,
            [fileRouteGUID]: {
              ...currentFileRoute,
              applicationKey: {
                ...currentFileRoute.applicationKey,
                newValue: fileTypeId,
                changed,
              },
              applicationCode: {
                ...currentFileRoute.applicationCode,
                newValue: applicationCode,
                changed,
              },
              endpointName: {
                ...currentFileRoute.endpointName,
                newValue: endpointName,
                changed,
              },
              direction: {
                ...currentFileRoute.direction,
                newValue: direction,
                changed,
              },
              name: {
                ...currentFileRoute.name,
                newValue: name,
                changed,
              },
            },
          },
        });
      } else {
        newPartnerSftpConnectionList.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      partnerSftpConnections: [...newPartnerSftpConnectionList],
    };
    if (currentDirection.toLowerCase() === 'incoming' && direction.toLowerCase() === 'outgoing') {
      const currentConnection = editedCurrentSection.partnerSftpConnections.find(
          (connection) => connection.accountGUID === accountGUID
      );
      currentConnection.fileRoutes[fileRouteGUID].additionalRoutingData = {
        ...currentFileRoute.additionalRoutingData,
        newValue: null,
        changed: true,
      };
      currentConnection.fileRoutes[fileRouteGUID].contentType = {
        ...currentFileRoute.contentType,
        newValue: 'application/EDI-X12',
        changed: true,
      };
    }
    if (currentDirection.toLowerCase() === 'outgoing' && direction.toLowerCase() === 'incoming') {
      const currentConnection = editedCurrentSection.partnerSftpConnections.find(
          (connection) => connection.accountGUID === accountGUID
      );
      currentConnection.fileRoutes[fileRouteGUID].contentType = {
        ...currentFileRoute.contentType,
        newValue: null,
        changed: true,
      };
    }
  } else {
    const as2Connections = sections[currentSection].as2Connections;
    const as2Connection = as2Connections.find((connection) => connection.mailboxGUID === mailboxGUID);
    const currentFileRoute = as2Connection.fileRoutes[fileRouteGUID];
    const currentDirection = currentFileRoute.direction.newValue || currentFileRoute.direction.origValue;
    const changed = currentFileRoute.applicationKey.origValue !== fileTypeId;

    const newAs2ConnectionList = [];
    as2Connections.forEach((connection) => {
      if (connection.mailboxGUID === mailboxGUID) {
        newAs2ConnectionList.push({
          ...connection,
          fileRoutes: {
            ...connection.fileRoutes,
            [fileRouteGUID]: {
              ...currentFileRoute,
              applicationKey: {
                ...currentFileRoute.applicationKey,
                newValue: fileTypeId,
                changed,
              },
              applicationCode: {
                ...currentFileRoute.applicationCode,
                newValue: applicationCode,
                changed,
              },
              endpointName: {
                ...currentFileRoute.endpointName,
                newValue: endpointName,
                changed,
              },
              direction: {
                ...currentFileRoute.direction,
                newValue: direction,
                changed,
              },
              name: {
                ...currentFileRoute.name,
                newValue: name,
                changed,
              },
            },
          },
        });
      } else {
        newAs2ConnectionList.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      as2Connections: [...newAs2ConnectionList],
    };
    if (currentDirection.toLowerCase() === 'incoming' && direction.toLowerCase() === 'outgoing') {
      const currentConnection = editedCurrentSection.as2Connections.find(
        (connection) => connection.mailboxGUID === mailboxGUID
      );
      currentConnection.fileRoutes[fileRouteGUID].additionalRoutingData = {
        ...currentFileRoute.additionalRoutingData,
        newValue: null,
        changed: true,
      };
      currentConnection.fileRoutes[fileRouteGUID].contentType = {
        ...currentFileRoute.contentType,
        newValue: 'application/EDI-X12',
        changed: true,
      };
    }
    if (currentDirection.toLowerCase() === 'outgoing' && direction.toLowerCase() === 'incoming') {
      const currentConnection = editedCurrentSection.as2Connections.find(
        (connection) => connection.mailboxGUID === mailboxGUID
      );
      currentConnection.fileRoutes[fileRouteGUID].contentType = {
        ...currentFileRoute.contentType,
        newValue: null,
        changed: true,
      };
    }
  }
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function setFileRouteRegex(state, action) {
  const { fileRouteGUID, regex, connectionType, mailboxGUID } = action;
  const { sections, currentSection } = state;
  let editedCurrentSection;

  if (connectionType === SFTP_CONNECTION_TYPE) {
    const currentFileRoute = sections[currentSection][connectionType].fileRoutes[fileRouteGUID];
    const changed = currentFileRoute.regexPattern.origValue !== regex;
    editedCurrentSection = {
      ...sections[currentSection],
      [connectionType]: {
        ...sections[currentSection][connectionType],
        fileRoutes: {
          ...sections[currentSection][connectionType].fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            regexPattern: {
              ...currentFileRoute.regexPattern,
              newValue: regex,
              changed,
            },
          },
        },
      },
    };
  } else { // N/A for partnerSFTP
    const as2Connections = sections[currentSection].as2Connections;
    const as2Connection = as2Connections.find((connection) => connection.mailboxGUID === mailboxGUID);
    const currentFileRoute = as2Connection.fileRoutes[fileRouteGUID];
    const changed = currentFileRoute.regexPattern.origValue !== regex;
    const newAs2ConnectionList = [];
    as2Connections.forEach((connection) => {
      if (connection.mailboxGUID === mailboxGUID) {
        newAs2ConnectionList.push({
          ...connection,
          fileRoutes: {
            ...connection.fileRoutes,
            [fileRouteGUID]: {
              ...currentFileRoute,
              regexPattern: {
                ...currentFileRoute.regexPattern,
                newValue: regex,
                changed,
              },
            },
          },
        });
      } else {
        newAs2ConnectionList.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      as2Connections: [...newAs2ConnectionList],
    };
  }
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function setFileRouteExtension(state, action) {
  const { fileRouteGUID, extension, connectionType, mailboxGUID } = action;
  const { sections, currentSection } = state;
  let editedCurrentSection;
  if (connectionType === SFTP_CONNECTION_TYPE) {
    const currentFileRoute = sections[currentSection][connectionType].fileRoutes[fileRouteGUID];
    const changed = currentFileRoute.extension.origValue !== extension;
    editedCurrentSection = {
      ...sections[currentSection],
      [connectionType]: {
        ...sections[currentSection][connectionType],
        fileRoutes: {
          ...sections[currentSection][connectionType].fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            extension: {
              ...currentFileRoute.extension,
              newValue: extension,
              changed,
            },
          },
        },
      },
    };
  } else { // N/A for PartnerSFTP
    const as2Connections = sections[currentSection].as2Connections;
    const as2Connection = as2Connections.find((connection) => connection.mailboxGUID === mailboxGUID);
    const currentFileRoute = as2Connection.fileRoutes[fileRouteGUID];
    const changed = currentFileRoute.extension.origValue !== extension;

    const newAs2ConnectionList = [];
    as2Connections.forEach((connection) => {
      if (connection.mailboxGUID === mailboxGUID) {
        newAs2ConnectionList.push({
          ...connection,
          fileRoutes: {
            ...connection.fileRoutes,
            [fileRouteGUID]: {
              ...currentFileRoute,
              extension: {
                ...currentFileRoute.extension,
                newValue: extension,
                changed,
              },
            },
          },
        });
      } else {
        newAs2ConnectionList.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      as2Connections: [...newAs2ConnectionList],
    };
  }
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function setFileRouteContentType(state, action) {
  const { fileRouteGUID, contentType, mailboxGUID } = action;
  const { sections, currentSection } = state;
  let editedCurrentSection;
  const as2Connections = sections[currentSection].as2Connections;
  const as2Connection = as2Connections.find((connection) => connection.mailboxGUID === mailboxGUID);
  const currentFileRoute = as2Connection.fileRoutes[fileRouteGUID];
  const changed = currentFileRoute.contentType.origValue !== contentType;

  const newAs2ConnectionList = [];
  as2Connections.forEach((connection) => {
    if (connection.mailboxGUID === mailboxGUID) {
      newAs2ConnectionList.push({
        ...connection,
        fileRoutes: {
          ...connection.fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            contentType: {
              ...currentFileRoute.contentType,
              newValue: contentType,
              changed,
            },
          },
        },
      });
    } else {
      newAs2ConnectionList.push(connection);
    }
  });
  editedCurrentSection = {
    ...sections[currentSection],
    as2Connections: [...newAs2ConnectionList],
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function setFileRouteAdditionalRoutingData(state, action) {
  const { fileRouteGUID, additionalRoutingData, connectionType, mailboxGUID, accountGUID } = action;
  const { sections, currentSection } = state;
  let editedCurrentSection;

  if (connectionType === SFTP_CONNECTION_TYPE) {
    const currentFileRoute = sections[currentSection][connectionType].fileRoutes[fileRouteGUID];
    const changed = currentFileRoute.additionalRoutingData.origValue !== additionalRoutingData;
    editedCurrentSection = {
      ...sections[currentSection],
      [connectionType]: {
        ...sections[currentSection][connectionType],
        fileRoutes: {
          ...sections[currentSection][connectionType].fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            additionalRoutingData: {
              ...currentFileRoute.additionalRoutingData,
              newValue: additionalRoutingData,
              changed,
            },
          },
        },
      },
    };
  } else if (connectionType === PARTNER_SFTP_CONNECTION_TYPE) {
    const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = partnerSftpConnections.find((connection) => connection.accountGUID === accountGUID);
    const currentFileRoute = partnerSftpConnection.fileRoutes[fileRouteGUID];
    const changed = currentFileRoute.additionalRoutingData.origValue !== additionalRoutingData;

    const newPartnerSftpConnectionList = [];
    partnerSftpConnections.forEach((connection) => {
      if (connection.accountGUID === accountGUID) {
        newPartnerSftpConnectionList.push({
          ...connection,
          fileRoutes: {
            ...connection.fileRoutes,
            [fileRouteGUID]: {
              ...currentFileRoute,
              additionalRoutingData: {
                ...currentFileRoute.additionalRoutingData,
                newValue: additionalRoutingData,
                changed,
              },
            },
          },
        });
      } else {
        newPartnerSftpConnectionList.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      partnerSftpConnections: [...newPartnerSftpConnectionList],
    };
  } else {
    const as2Connections = sections[currentSection].as2Connections;
    const as2Connection = as2Connections.find((connection) => connection.mailboxGUID === mailboxGUID);
    const currentFileRoute = as2Connection.fileRoutes[fileRouteGUID];
    const changed = currentFileRoute.additionalRoutingData.origValue !== additionalRoutingData;

    const newAs2ConnectionList = [];
    as2Connections.forEach((connection) => {
      if (connection.mailboxGUID === mailboxGUID) {
        newAs2ConnectionList.push({
          ...connection,
          fileRoutes: {
            ...connection.fileRoutes,
            [fileRouteGUID]: {
              ...currentFileRoute,
              additionalRoutingData: {
                ...currentFileRoute.additionalRoutingData,
                newValue: additionalRoutingData,
                changed,
              },
            },
          },
        });
      } else {
        newAs2ConnectionList.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      as2Connections: [...newAs2ConnectionList],
    };
  }
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function toggleFileRouteVisibility(state, action) {
  const { connectionType, guid } = action;
  const { currentSection, sections } = state;

  if (connectionType === SFTP_CONNECTION_TYPE) {
    // Modifying state directly because then when an action is put on the undo list, the state will reflect the current view
    sections[currentSection][connectionType].fileRoutesVisible =
      !sections[currentSection][connectionType].fileRoutesVisible;
  } else if (connectionType === PARTNER_SFTP_CONNECTION_TYPE) {
    const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
    const partnerSftpConnection = partnerSftpConnections.find((connect) => connect.accountGUID === guid);
    partnerSftpConnection.fileRoutesVisible = !partnerSftpConnection.fileRoutesVisible;
  } else {
    const as2Connections = sections[currentSection].as2Connections;
    const as2Connection = as2Connections.find((connect) => connect.mailboxGUID === guid);
    as2Connection.fileRoutesVisible = !as2Connection.fileRoutesVisible;
  }
  return {
    ...state,
  };
}

export function addNewSftpFileRoute(state) {
  const { currentSection, sections, sftpFileRouteTypes } = state;
  const defaultKey = Object.keys(sftpFileRouteTypes)[0];
  const defaultName = sftpFileRouteTypes[defaultKey].name;
  const defaultApplication = sftpFileRouteTypes[defaultKey].endpointName;
  const defaultApplicationCode = sftpFileRouteTypes[defaultKey].applicationCode;
  const defaultDirection = sftpFileRouteTypes[defaultKey].direction;
  const fileRouteGUID = uuidv4();
  const editedCurrentSection = {
    ...sections[currentSection],
    sftpConnection: {
      ...sections[currentSection].sftpConnection,
      fileRoutes: {
        ...sections[currentSection].sftpConnection.fileRoutes,
        [fileRouteGUID]: {
          md5Verified: {
            origValue: null,
            newValue: false,
            changed: true,
          },
          additionalRoutingData: {
            origValue: null,
            newValue: null,
            changed: false,
          },
          subPaths: [],
          name: {
            origValue: '',
            newValue: defaultName,
            changed: true,
          },
          fileRouteGUID,
          applicationKey: {
            origValue: '',
            newValue: defaultKey,
            changed: true,
          },
          endpointName: {
            origValue: '',
            newValue: defaultApplication,
            changed: true,
          },
          direction: {
            origValue: '',
            newValue: defaultDirection,
            changed: true,
          },
          applicationCode: {
            origValue: '',
            newValue: defaultApplicationCode,
            changed: true,
          },
          added: true,
          deleted: false,
        },
      },
    },
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function addNewAs2FileRoute(state, action) {
  const { currentSection, sections, as2FileRouteTypes } = state;
  const { mailboxGUID } = action;
  const defaultKey = Object.keys(as2FileRouteTypes)[0];
  const defaultName = as2FileRouteTypes[defaultKey].name;
  const defaultApplication = as2FileRouteTypes[defaultKey].endpointName;
  const defaultApplicationCode = as2FileRouteTypes[defaultKey].applicationCode;
  const defaultDirection = as2FileRouteTypes[defaultKey].direction;
  const defaultContentType = defaultDirection.toLowerCase() === 'outgoing' ? 'application/EDI-X12' : null;
  const fileRouteGUID = uuidv4();

  const as2Connections = sections[currentSection].as2Connections;
  const newAs2ConnectionList = [];
  as2Connections.forEach((connection) => {
    if (connection.mailboxGUID === mailboxGUID) {
      newAs2ConnectionList.push({
        ...connection,
        fileRoutes: {
          ...connection.fileRoutes,
          [fileRouteGUID]: {
            regexPattern: {
              origValue: null,
              newValue: null,
              changed: false,
            },
            extension: {
              origValue: null,
              newValue: null,
              changed: false,
            },
            contentType: {
              origValue: null,
              newValue: defaultContentType,
              changed: !!defaultContentType,
            },
            additionalRoutingData: {
              origValue: null,
              newValue: null,
              changed: false,
            },
            name: {
              origValue: '',
              newValue: defaultName,
              changed: true,
            },
            fileRouteGUID,
            applicationKey: {
              origValue: '',
              newValue: defaultKey,
              changed: true,
            },
            applicationCode: {
              origValue: '',
              newValue: defaultApplicationCode,
              changed: true,
            },
            endpointName: {
              origValue: '',
              newValue: defaultApplication,
              changed: true,
            },
            direction: {
              origValue: '',
              newValue: defaultDirection,
              changed: true,
            },
            added: true,
            deleted: false,
          },
        },
      });
    } else {
      newAs2ConnectionList.push(connection);
    }
  });
  const editedCurrentSection = {
    ...sections[currentSection],
    as2Connections: [...newAs2ConnectionList],
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function addNewPartnerSftpFileRoute(state, action) {
  const { currentSection, sections, partnerSftpFileRouteTypes } = state;
  const { accountGUID } = action;
  const defaultKey = Object.keys(partnerSftpFileRouteTypes)[0];
  const defaultName = partnerSftpFileRouteTypes[defaultKey].name;
  const defaultApplication = partnerSftpFileRouteTypes[defaultKey].endpointName;
  const defaultApplicationCode = partnerSftpFileRouteTypes[defaultKey].applicationCode;
  const defaultDirection = partnerSftpFileRouteTypes[defaultKey].direction;
  const fileRouteGUID = uuidv4();
  const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
  const newPartnerSftpConnectionList = [];
  partnerSftpConnections.forEach((connection) => {
    if (connection.accountGUID === accountGUID) {
      newPartnerSftpConnectionList.push({
        ...connection,
        fileRoutes: {
          ...connection.fileRoutes,
          [fileRouteGUID]: {
            fileRouteGUID,
            subPaths: [],
            name: {
              origValue: '',
              newValue: defaultName,
              changed: true,
            },
            applicationCode: {
              origValue: '',
              newValue: defaultApplicationCode,
              changed: true,
            },
            applicationKey: {
              origValue: '',
              newValue: defaultKey,
              changed: true,
            },
            endpointName: {
              origValue: '',
              newValue: defaultApplication,
              changed: true,
            },
            direction: {
              origValue: '',
              newValue: defaultDirection,
              changed: true,
            },
            added: true,
            deleted: false,
          },
        },
      });
    } else {
      newPartnerSftpConnectionList.push(connection);
    }
  });
  const editedCurrentSection = {
    ...sections[currentSection],
    partnerSftpConnections: [...newPartnerSftpConnectionList],
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function deleteFileRoute(state, action) {
  const { currentSection, sections } = state;
  const { fileRouteGUID, connectionType, mailboxGUID, accountGUID } = action;
  let editedCurrentSection;

  if (connectionType === SFTP_CONNECTION_TYPE) {
    const fileRoutes = sections[currentSection][connectionType].fileRoutes;
    const currentFileRoute = fileRoutes[fileRouteGUID];
    const fileRoutesAfterDelete = currentFileRoute.name.origValue
      ? {
          ...fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            deleted: true,
          },
        }
      : Object.keys(fileRoutes).reduce((accum, currentFileRouteGUID) => {
          if (currentFileRouteGUID !== fileRouteGUID) {
            accum[currentFileRouteGUID] = fileRoutes[currentFileRouteGUID];
          }
          return accum;
        }, {});
    editedCurrentSection = {
      ...sections[currentSection],
      [connectionType]: {
        ...sections[currentSection][connectionType],
        fileRoutes: fileRoutesAfterDelete,
      },
    };
  } else if (connectionType === PARTNER_SFTP_CONNECTION_TYPE) {
    const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
    const connectionArray = [];
    partnerSftpConnections.forEach((partnerSftpConnection) => {
      if (partnerSftpConnection.accountGUID === accountGUID) {
        const fileRoutes = partnerSftpConnection.fileRoutes;
        const currentFileRoute = fileRoutes[fileRouteGUID];
        const fileRoutesAfterDelete = currentFileRoute.name.origValue
            ? {
              ...fileRoutes,
              [fileRouteGUID]: {
                ...currentFileRoute,
                deleted: true,
              },
            }
            : Object.keys(fileRoutes).reduce((accum, currentFileRouteGUID) => {
              if (currentFileRouteGUID !== fileRouteGUID) {
                accum[currentFileRouteGUID] = fileRoutes[currentFileRouteGUID];
              }
              return accum;
            }, {});
        connectionArray.push({
          ...partnerSftpConnection,
          fileRoutes: fileRoutesAfterDelete,
        });
      } else {
        connectionArray.push(partnerSftpConnection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      partnerSftpConnections: connectionArray,
    };
  } else {
    const as2Connections = sections[currentSection].as2Connections;
    const connectionArray = [];
    as2Connections.forEach((as2Connection) => {
      if (as2Connection.mailboxGUID === mailboxGUID) {
        const fileRoutes = as2Connection.fileRoutes;
        const currentFileRoute = fileRoutes[fileRouteGUID];
        const fileRoutesAfterDelete = currentFileRoute.name.origValue
          ? {
              ...fileRoutes,
              [fileRouteGUID]: {
                ...currentFileRoute,
                deleted: true,
              },
            }
          : Object.keys(fileRoutes).reduce((accum, currentFileRouteGUID) => {
              if (currentFileRouteGUID !== fileRouteGUID) {
                accum[currentFileRouteGUID] = fileRoutes[currentFileRouteGUID];
              }
              return accum;
            }, {});
        connectionArray.push({
          ...as2Connection,
          fileRoutes: fileRoutesAfterDelete,
        });
      } else {
        connectionArray.push(as2Connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      as2Connections: connectionArray,
    };
  }
  return newState(state, sections, currentSection, editedCurrentSection, mailboxGUID);
}
