import { connect } from 'react-redux';
import SaveCancelFooter from '../../../../components/SaveCancelFooter';
import { saveFileRouteTypes } from '../actions/index';

const mapStateToProps = ({
  undoableFileRouteTypes: {
    present: { fileRouteTypes, saveSuccessful },
  },
}) => {
  const canSave =
    Boolean(Object.values(fileRouteTypes).find(({ hasChanges }) => hasChanges)) &&
    !Object.values(fileRouteTypes).find(({ hasError }) => hasError);
  return {
    canCancel: true,
    canSave,
    saveSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCancel: () => {
      window.location.reload();
    },
    onSave: () => {
      dispatch(saveFileRouteTypes());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveCancelFooter);
