import React from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import LoadingStateProvider from '../../components/LoadingStateProvider';
import PropTypes from 'prop-types';

import { useFetch } from '../../hooks/fetch-hook';
import { AS2ForwarderRequest } from './AS2ForwarderRequest.model';
import AS2ForwarderRequestDisplay from './components/AS2ForwarderRequestDisplay';
import glamorous, { GlamorousComponent } from 'glamorous';
import { ChubH2 } from '../../styles/typography';

const RequestHeader: GlamorousComponent<any, any> = glamorous(ChubH2, { displayName: 'RequestHeader' })({
  marginLeft: '8px',
});

const AS2ForwarderRequestApp = ({ requestId }: { requestId: string }) => {
  const { data, error, isLoading } = useFetch<AS2ForwarderRequest>(`/as2Forwarder/request/${requestId}`);

  return (
    <>
      <DefaultHeader showSearch={true} />
      <RequestHeader>AS2 Forwarder Request</RequestHeader>
      <LoadingStateProvider error={error} isLoading={isLoading}>
        {() => <AS2ForwarderRequestDisplay request={data!} />}
      </LoadingStateProvider>
    </>
  );
};

AS2ForwarderRequestApp.displayName = 'AS2ForwarderRequestApp';

AS2ForwarderRequestApp.propTypes = {
  requestId: PropTypes.string.isRequired,
};

export default AS2ForwarderRequestApp;
