import glamorous from 'glamorous';
import { chubStandards } from '../../../styles/typography';

export default glamorous('label', { displayName: 'ConfigurationLabel' })(chubStandards, ({ paddingLeft = 0 }) => ({
  fontSize: '18px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  marginLeft: '12px',
  marginRight: '12px',
  paddingLeft,
}));
