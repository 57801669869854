import { ServerMessage } from '../../models/server-message.model';

export enum TableType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}
export interface AS2TransfersResponse {
  partnerID: string;
  incomingFileTransfers: AS2TransferData[];
  outgoingFileTransfers: AS2TransferData[];
  pendingFileTransfers: PendingAS2TransferData[];
  serverMessages: ServerMessage[];
}

export interface AS2TransferData {
  transferId: string;
  externalId: string;
  messageId: string;
  host: string;
  startDate: string;
  endDate: string;
  direction: string;
  isReceipt: string;
  status: string;
  fileName: string;
  resultText: string;
  vlSerial: string;
}

export interface PendingAS2TransferData {
  externalID: string;
  fileName: string;
  lastFailedAttemptResult: string;
  totalAttempts: number;
  createDate: string;
  lastAttemptDate?: string;
  vlSerial: string;
}
