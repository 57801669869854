import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';

import CertificateInfoBubble from './CertificateInfoBubble';
import { ChubButton, chubStandards } from '../../../styles/typography';
import { DeleteIcon } from "../../../styles/icons";

const UploadCertificateControls = glamorous('span', { displayName: 'UploadCertificateControls' })(chubStandards, ({ padding = 0 }) => ({
  display: 'inline-flex',
  padding
}));

const ViewButton = glamorous(ChubButton, { displayName: 'ViewButton' })((props) => ({
  background: props.value ? 'white' : colors.chred,
  color: props.value ? colors.darkgray : 'white',
  margin: '0 10px',
  ':hover': {
    background: props.value ? colors.coolgraylight2 : '#c44f39',
  },
}));

const RemoveButton = glamorous('button', { displayName: 'DeleteButton' })({
  border: 'none',
  padding: '0',
  float: 'right',
  lineHeight: '33px',
  background: 'none',
  ':disabled': {
    display: 'none',
  },
});

const UploadLabel = glamorous('label', { displayName: 'UploadLabel' })(chubStandards, {
  background: colors.brightblue,
  border: 'none',
  borderRadius: '3px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  color: '#FFF',
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: '18px',
  height: '33px',
  lineHeight: '33px',
  padding: '0 15px',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',
  margin: '0 0 0 10px',
  ':hover': {
    background: '#1d82ff',
    color: '#FFF',
    textDecoration: 'none',
  },
});
const HiddenUploadInput = glamorous('input', { displayName: 'HiddenUploadInput' })({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  fontSize: 1,
  width: 0,
  height: '100%',
  opacity: 0,
});

const WarningMessage = glamorous.span({
  color: colors.chred,
  width: '400px',
  marginLeft: '10px',
  fontStyle: 'italic',
  fontSize: '12px',
  lineHeight: '16px',
  wordWrap: 'break-word'
})

export default class UploadCertificate extends Component {
  static displayName = 'UploadCertificate';
  static propTypes = {
    onClickViewPartnerCertificate: PropTypes.func,
    uploadCertificate: PropTypes.func,
    onClickRemoveCertificate: PropTypes.func,
    viewPartnerCertificate: PropTypes.bool,
    readOnly: PropTypes.bool,
    newValue: PropTypes.object,
    origValue: PropTypes.object,
    parentId: PropTypes.string,
    padding: PropTypes.string,
  };

  render() {
    const {
      onClickViewPartnerCertificate,
      viewPartnerCertificate,
      uploadCertificate,
      onClickRemoveCertificate,
      readOnly,
      newValue,
      origValue,
      parentId,
      warningMessage,
      paddingTop,
      ...otherProps
    } = this.props;
    const hasCertificate = (newValue && newValue.certificateBytes.length > 0) || (!newValue && origValue.certificateBytes.length > 0);
    return (
      <UploadCertificateControls padding>
        {!readOnly && (
          <UploadLabel id={`cert-upload-${parentId}`} htmlFor={`cert-input-${parentId}`}>
            Upload
          </UploadLabel>
        )}
        <HiddenUploadInput onChange={(event) => uploadCertificate(event)} id={`cert-input-${parentId}`} name='certificate' type='file' />
        <ViewButton
          id={`cert-view-${parentId}`}
          onClick={onClickViewPartnerCertificate}
          value={hasCertificate}
        >
          View
          {viewPartnerCertificate && <CertificateInfoBubble {...{ newValue, origValue, parentId, ...otherProps }} />}
        </ViewButton>
        {onClickRemoveCertificate && !readOnly && hasCertificate &&
        <RemoveButton
          id={`remove-cert-button-${parentId}`}
          onClick={onClickRemoveCertificate}
        >
          <DeleteIcon />
        </RemoveButton> }
        {warningMessage && <WarningMessage>{warningMessage}</WarningMessage>}
      </UploadCertificateControls>
    );
  }
}
