import React from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import LoadingStateProviderContainer from './containers/LoadingStateProviderContainer';
import PageMessagesContainer from './containers/PageMessagesContainer';
import ConnectionsTableContainer from './containers/ConnectionsTableContainer';

export const ConnectionsOverview = () => {
  return (
    <>
      <DefaultHeader showSearch={true} />
      <PageMessagesContainer />
      <LoadingStateProviderContainer>{() => <ConnectionsTableContainer />}</LoadingStateProviderContainer>
    </>
  );
};

ConnectionsOverview.displayName = 'ConnectionsOverview';
