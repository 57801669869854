import { ServerMessage } from '../../models/server-message.model';

export enum TableType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}
export interface PartnerSFTPTransfersResponse {
  partnerID: string;
  incomingFileTransfers: PartnerSFTPTransferData[];
  outgoingFileTransfers: PartnerSFTPTransferData[];
  pendingFileTransfers: PendingPartnerSFTPTransferData[];
  serverMessages: ServerMessage[];
}

export interface PartnerSFTPTransferData {
  transferId: string;
  externalId: string;
  messageId: string;
  host: string;
  startDate: string;
  endDate: string;
  direction: string;
  isReceipt: string;
  status: string;
  fileName: string;
  resultText: string;
  vlSerial: string;
}

export interface PendingPartnerSFTPTransferData {
  externalID: string;
  fileName: string;
  lastFailedAttemptResult: string;
  totalAttempts: number;
  createDate: string;
  lastAttemptDate?: string;
  vlSerial: string;
}
