import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
// @ts-ignore
import { colors } from 'react-chui';

import { VLTransferServerData } from '../CommsTransferData.model';

const Data = glamorous.div({
  marginBottom: '15px',
});

const Text = glamorous.div({
  fontSize: '15px',
  color: colors.darkgray,
});

const VLTransferData = ({ data }: { data: VLTransferServerData }) => {
  return (
    <Data>
      <Text className='transfer-info'>
        Host: <b>{data.host}</b>
      </Text>
      <Text>
        Start date: <b>{data.startDate}</b>
      </Text>
      <Text>
        End date: <b>{data.endDate}</b>
      </Text>
      <Text>
        Filename: <b>{data.fileName}</b>
      </Text>
      <Text>
        Direction: <b>{data.direction}</b>
      </Text>
      <Text>
        Status: <b>{data.status}</b>
      </Text>
    </Data>
  );
};

VLTransferData.displayName = 'VlTransferData';
VLTransferData.propTypes = {
  data: PropTypes.object,
};

export default VLTransferData;
