import React from 'react';
import { useParams } from 'react-router-dom';

import AS2TransfersResultsApp from './AS2TransfersResultsApp';

const AS2TransfersResultsEntry = () => {
  const { partnerId, partnerGUID, numberOfTransfers, environmentName } = useParams();

  return (
    <AS2TransfersResultsApp
      partnerId={partnerId!}
      partnerGUID={partnerGUID!}
      numberOfTransfers={numberOfTransfers!}
      environmentName={environmentName!}
    />
  );
};

export default AS2TransfersResultsEntry;
