import { connect } from 'react-redux';
import SaveCancelFooter from '../../../../components/SaveCancelFooter';
import { saveAs2Identifiers } from '../actions';

const mapStateToProps = (state) => {
  const { past } = state;
  return {
    canCancel: true,
    canSave: past.length > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCancel: () => {
      window.location.reload();
    },
    onSave: () => {
      dispatch(saveAs2Identifiers());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveCancelFooter);
