import glamorous from 'glamorous';
import { colors } from 'react-chui';

import { chubStandards } from './typography';

export const ChubTableWrapper = glamorous('div', { displayName: 'ChubTableWrapper' })(chubStandards, {
  overflow: 'scroll',
});

export const ChubTable = glamorous('table', { displayName: 'ChubTable' })(chubStandards, {
  backgroundColor: '#FFFFFF',
  MozMorderRadius: '3px 3px 0 0',
  WebkitBorderRadius: '3px 3px 0 0',
  borderRadius: '3px',
  borderSpacing: 'initial',
  width: '100%',
  tableLayout: 'fixed',
});

export const ChubTableHeadCell = glamorous('th', { displayName: 'ChubTableHeadCell' })(
  chubStandards,
  ({ fitToContent, active, align = 'left', width = 'auto' }) => ({
    position: 'sticky',
    top: '0',
    borderBottom: 'none',
    borderRight: `1px solid ${colors.darkblue}`,
    color: '#FFFFFF',
    fontSize: '11px',
    height: '30px',
    letterSpacing: '1px',
    padding: '0 15px',
    textAlign: align,
    textTransform: 'uppercase',
    ':last-child': {
      borderRight: '0',
    },
    backgroundColor: active ? colors.brightblue : colors.deepblue,
    whiteSpace: fitToContent ? 'nowrap' : '',
    width: fitToContent ? '1%' : width,
    zIndex: 9,
  })
);

export const ChubTableBody = glamorous('tbody', { displayName: 'ChubTableBody' })(chubStandards, {
  width: '100%',
  overflow: 'auto',
  fontSize: '13px',
});

export const ChubTableBodyRow = glamorous('tr', { displayName: 'ChubTableBodyRow' })(chubStandards, {
  height: '40px',
  backgroundColor: '#FFFFFF',
  ':nth-child(odd)': {
    backgroundColor: colors.faintblue,
  },
  zIndex: 1,
});

export const ChubTableBodyCell = glamorous('td', { displayName: 'ChubTableBodyCell' })(
  chubStandards,
  ({ noWrap, align = 'left' }) => ({
    borderRight: `1px solid ${colors.medgray}`,
    padding: '10px 15px',
    ':last-child': {
      borderRight: '0',
    },
    textAlign: align,
    overflow: noWrap ? 'hidden' : '',
    textOverflow: noWrap ? 'ellipsis' : '',
    whiteSpace: noWrap ? 'nowrap' : '',
  })
);

export const ChubTableBodyRowError = glamorous(ChubTableBodyRow, { displayName: 'ChubTableBodyRowError' })(
  chubStandards,
  {
    color: colors.chred,
    fontWeight: 'bold',
  }
);

export const ChubTableBodyCellError = glamorous(ChubTableBodyCell, { displayName: 'ChubTableBodyCellError' })(
  chubStandards,
  {
    color: 'inherit',
  }
);
