import {
  TOGGLE_SHOW_RELATIONSHIPS,
  FETCH_RELATIONSHIPS_LOADING,
  FETCH_RELATIONSHIPS_COMPLETED,
  FETCH_RELATIONSHIPS_FAILED,
  RECEIVE_RELATIONSHIPS,
  RESET_STATE,
} from '../ClientConfigurationActionTypes';
import {
  toggleShowRelationships,
  fetchRelationshipsLoading,
  fetchRelationshipsCompleted,
  fetchRelationshipsFailed,
  receiveRelationships,
} from './relationshipsReducers/loadingMethods';

const initialState = {
  showRelationships: false,
  isLoading: false,
  error: '',
};

const reducer = (state = initialState, { type, ...actionArgs }) => {
  switch (type) {
    case TOGGLE_SHOW_RELATIONSHIPS:
      return toggleShowRelationships(state);
    case FETCH_RELATIONSHIPS_LOADING:
      return fetchRelationshipsLoading(state);
    case FETCH_RELATIONSHIPS_COMPLETED:
      return fetchRelationshipsCompleted(state);
    case FETCH_RELATIONSHIPS_FAILED:
      return fetchRelationshipsFailed(state, actionArgs);
    case RECEIVE_RELATIONSHIPS:
      return receiveRelationships(state, actionArgs);
    case RESET_STATE:
      return initialState;
  }
  return state;
};

export default reducer;
