import React, { Component } from 'react';
import { ChubH1, ChubH4, ChubLinkButton, chubStandards } from '../../styles/typography';
import { colors } from 'react-chui';
import { writeCookie } from './CookieFunctions';
import { intersectionExists } from './CollectionComparison';
import PropTypes from 'prop-types';
import DefaultHeader from '../../components/DefaultHeader';
import glamorous from 'glamorous';

const ADMIN = 'ADMIN';
const CUSTOMER_SUPPORT_REP = 'CUSTOMER_SUPPORT_REP';
const ONBOARDING_SUPPORT_REP = 'ONBOARDING_SUPPORT_REP';
const VIEWER = 'VIEWER';

const buckets = [
  {
    title: 'Documentation',
    links: [
      {
        linkLabel: 'Common AS2 Errors',
        linkPath: '/documentation/commonAS2Errors',
        access: [CUSTOMER_SUPPORT_REP, ONBOARDING_SUPPORT_REP, VIEWER],
      },
      {
        linkLabel: 'Common SFTP Errors',
        linkPath: '/documentation/commonSFTPErrors',
        access: [CUSTOMER_SUPPORT_REP, ONBOARDING_SUPPORT_REP, VIEWER],
      },
    ],
  },
  {
    title: 'AS2 Files',
    links: [
      {
        linkLabel: 'File by AS2 Message ID',
        linkPath: '/lookup/lookupAS2MessageId',
        access: [CUSTOMER_SUPPORT_REP, ONBOARDING_SUPPORT_REP, VIEWER],
      },
      {
        linkLabel: 'Stoppable AS2 Files',
        linkPath: '/transfers/as2/stoppableFiles',
        access: [CUSTOMER_SUPPORT_REP, ONBOARDING_SUPPORT_REP, VIEWER],
      },
    ],
  },
  {
    title: 'Dashboards',
    links: [
      {
        linkLabel: 'Harmony Outgoing Backlog',
        linkPath: '/dashboard/outgoingBacklog',
        access: [CUSTOMER_SUPPORT_REP, ONBOARDING_SUPPORT_REP, VIEWER],
      },
      {
        linkLabel: 'Connections Overview',
        linkPath: '/dashboard/connectionsOverview',
        access: [CUSTOMER_SUPPORT_REP, ONBOARDING_SUPPORT_REP, VIEWER],
      },
    ],
  },
  {
    title: 'Config',
    links: [
      {
        linkLabel: 'Certificates',
        linkPath: '/config/certificates',
        access: [CUSTOMER_SUPPORT_REP, ADMIN],
      },
    ],
  },
  {
    title: 'Admin',
    links: [
      {
        linkLabel: 'AS2 IDs',
        linkPath: '/admin/as2ids',
        access: [ADMIN],
      },
      {
        linkLabel: 'Environments',
        linkPath: '/admin/environments',
        access: [ADMIN],
      },
      {
        linkLabel: 'File Route Types',
        linkPath: '/admin/fileRouteTypes',
        access: [ADMIN],
      },
    ],
  },
];

const MenuLinkButton = glamorous(ChubLinkButton, { displayName: 'MenuLinkButton' })({
  margin: '10px',
  display: 'block',
  textDecoration: 'none',
  maxWidth: '300px',
});

const Dashboard = glamorous('div', { displayName: 'LeftMargin' })(chubStandards, {
  margin: '0 auto',
  maxWidth: '1200px',
});

const BucketH4 = glamorous(ChubH4, { displayName: 'BucketH4' })(({ isSelected }) => ({
  display: 'inline-block',
  margin: '10px',
  border: isSelected ? '1px solid' : '0px',
  padding: '3px',
  borderRadius: '3px',
  cursor: 'pointer',
  backgroundColor: isSelected ? colors.medgray : '',
}));

const MENU_SELECTION_COOKIE_NAME = 'mainMenuSelection';

export default class IndexDashboard extends Component {
  static displayName = 'ClientNotFound';
  static defaultProps = {
    userRoles: ['INVALID_USER'],
  };
  static propTypes = {
    userRoles: PropTypes.array,
    readOnly: PropTypes.bool,
  };

  state = {
    selectedBucket: 'Documentation',
    errorTextVisible: false,
  };

  _selectBucket = (bucketTitle) => {
    return () => {
      writeCookie(MENU_SELECTION_COOKIE_NAME, bucketTitle);
      this.setState({
        selectedBucket: bucketTitle,
      });
    };
  };

  _renderBuckets = (selection) => {
    return buckets.map(({ title }) => (
      <BucketH4 isSelected={title === selection} onClick={this._selectBucket(title)} key={title}>
        {title}
      </BucketH4>
    ));
  };

  _renderLinks = (bucketName) => {
    const { userRoles } = this.props;
    let bucket = buckets.find(({ title }) => title === bucketName);
    if (!bucket) {
      bucket = buckets[0];
      this._selectBucket(bucket.title);
    }
    return bucket.links.map(({ linkLabel, linkPath, access }) => {
      if (userRoles.includes(ADMIN) || intersectionExists(userRoles, access)) {
        return (
          <MenuLinkButton to={linkPath} key={linkLabel}>
            {linkLabel}
          </MenuLinkButton>
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { selectedBucket } = this.state;

    const selectedInCookie = null; //readCookie(MENU_SELECTION_COOKIE_NAME);
    const selection = selectedInCookie ? selectedInCookie : selectedBucket;
    return (
      <div>
        <DefaultHeader showUndoRedo={false} />
        <Dashboard>
          <ChubH1>Main Menu</ChubH1>
          {this._renderBuckets(selection)}
          <hr />
          {this._renderLinks(selection)}
        </Dashboard>
      </div>
    );
  }
}
