import { newState } from '../../actions';
import { uuidv4 } from '../../../../mockable/uuidv4-wrapper';

export function setSubPath(state, action) {
  const { connectionType } = action;
  if (connectionType === 'partnerSFTP') {
    return setPartnerSftpSubpath(state, action);
  } else {
    return setSftpSubpath(state, action);
  }
}

export function setSftpSubpath(state, action) {
  const { fileRouteGUID, subPathGUID, subPath } = action;
  const { sections, currentSection } = state;
  const currentFileRoute = sections[currentSection].sftpConnection.fileRoutes[fileRouteGUID]
  const editedCurrentSection = {
    ...sections[currentSection],
    sftpConnection: {
      ...sections[currentSection].sftpConnection,
      fileRoutes: {
        ...sections[currentSection].sftpConnection.fileRoutes,
        [fileRouteGUID]: {
          ...currentFileRoute,
          subPaths: {
            ...currentFileRoute.subPaths,
            [subPathGUID]: {
              ...currentFileRoute.subPaths[subPathGUID],
              newValue: subPath,
              changed: currentFileRoute.subPaths[subPathGUID].origValue !== subPath,
            },
          },
        },
      },
    },
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function setPartnerSftpSubpath(state, action) {
  const { fileRouteGUID, accountGUID,subPathGUID, subPath } = action;
  const { sections, currentSection } = state;
  const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
  const partnerSftpConnection = sections[currentSection].partnerSftpConnections.find((connection) => connection.accountGUID === accountGUID);
  const currentFileRoute = partnerSftpConnection.fileRoutes[fileRouteGUID];
  const newPartnerSftpConnectionList = [];

  partnerSftpConnections.forEach((connection) => {
    if (connection.accountGUID === accountGUID) {
      newPartnerSftpConnectionList.push({
        ...partnerSftpConnection,
        fileRoutes: {
          ...partnerSftpConnection.fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            subPaths: {
              ...currentFileRoute.subPaths,
              [subPathGUID]: {
                ...currentFileRoute.subPaths[subPathGUID],
                newValue: subPath,
                changed: currentFileRoute.subPaths[subPathGUID].origValue !== subPath,
              },
            },
          },
        },
      });
    } else {
      newPartnerSftpConnectionList.push((connection));
    }
  })

  const editedCurrentSection = {
    ...sections[currentSection],
    partnerSftpConnections: [...newPartnerSftpConnectionList],
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function deleteSubPath(state, action) {
  const { connectionType } = action;
  if (connectionType === 'partnerSFTP') {
    return deletePartnerSftpSubpath(state, action);
  } else {
    return deleteSftpSubpath(state, action);
  }
}

export function deleteSftpSubpath(state, action) {
  const { fileRouteGUID, subPathGUID } = action;
  const { sections, currentSection } = state;
  const currentFileRoute = sections[currentSection].sftpConnection.fileRoutes[fileRouteGUID];
  const subPathsAfterDelete = isSubPathFromServer(currentFileRoute, subPathGUID);

  const editedCurrentSection = {
    ...sections[currentSection],
    sftpConnection: {
      ...sections[currentSection].sftpConnection,
      fileRoutes: {
        ...sections[currentSection].sftpConnection.fileRoutes,
        [fileRouteGUID]: {
          ...currentFileRoute,
          subPaths: subPathsAfterDelete,
        },
      },
    },
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function deletePartnerSftpSubpath(state, action) {
  const { fileRouteGUID, subPathGUID, accountGUID } = action;
  const { sections, currentSection } = state;
  const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
  const partnerSftpConnection = sections[currentSection].partnerSftpConnections.find((connection) => connection.accountGUID === accountGUID);
  const currentFileRoute = partnerSftpConnection.fileRoutes[fileRouteGUID];
  const newPartnerSftpConnectionList = [];

  const subPathsAfterDelete = isSubPathFromServer(currentFileRoute, subPathGUID);

  partnerSftpConnections.forEach((connection) => {
    if (connection.accountGUID === accountGUID) {
      newPartnerSftpConnectionList.push({
        ...partnerSftpConnection,
        fileRoutes: {
          ...partnerSftpConnection.fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            subPaths: subPathsAfterDelete,
          },
        },
      });
    } else {
      newPartnerSftpConnectionList.push((connection));
    }
  });

  const editedCurrentSection = {
    ...sections[currentSection],
    partnerSftpConnections: [...newPartnerSftpConnectionList],
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function isSubPathFromServer(currentFileRoute, subPathGUID) {
  const isFromServer = ({ origValue }) => origValue !== null;
  return isFromServer(currentFileRoute.subPaths[subPathGUID]) ? {
      ...currentFileRoute.subPaths,
      [subPathGUID]: {
        ...currentFileRoute.subPaths[subPathGUID],
        deleted: true,
        changed: true,
      },
    }
    : Object.keys(currentFileRoute.subPaths).reduce((accum, currentSubPathGUID) => {
      if (currentSubPathGUID !== subPathGUID) {
        accum[currentSubPathGUID] = currentFileRoute.subPaths[currentSubPathGUID];
      }
      return accum;
    }, {});
}

export function addSubPath(state, action) {
  const { connectionType } = action;
  if (connectionType === 'partnerSFTP') {
    return addPartnerSftpSubpath(state, action);
  } else {
    return addSftpSubpath(state, action);
  }
}

export function addSftpSubpath(state, action) {
  const { fileRouteGUID } = action;
  const { sections, currentSection } = state;
  const currentFileRoute = sections[currentSection].sftpConnection.fileRoutes[fileRouteGUID];
  const editedCurrentSection = {
    ...sections[currentSection],
    sftpConnection: {
      ...sections[currentSection].sftpConnection,
      fileRoutes: {
        ...sections[currentSection].sftpConnection.fileRoutes,
        [fileRouteGUID]: {
          ...currentFileRoute,
          subPaths: {
            ...currentFileRoute.subPaths,
            [uuidv4()]: {
              origValue: null,
              newValue: '',
              changed: false,
            },
          },
        },
      },
    },
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function addPartnerSftpSubpath(state, action) {
  const { fileRouteGUID, accountGUID } = action;
  const { sections, currentSection } = state;
  const partnerSftpConnections = sections[currentSection].partnerSftpConnections;
  const partnerSftpConnection = sections[currentSection].partnerSftpConnections.find((connection) => connection.accountGUID === accountGUID);
  const currentFileRoute = partnerSftpConnection.fileRoutes[fileRouteGUID];
  const newPartnerSftpConnectionList = [];

  partnerSftpConnections.forEach((connection) => {
    if (connection.accountGUID === accountGUID) {
      newPartnerSftpConnectionList.push({
        ...partnerSftpConnection,
        fileRoutes: {
          ...partnerSftpConnection.fileRoutes,
          [fileRouteGUID]: {
            ...currentFileRoute,
            subPaths: {
              ...currentFileRoute.subPaths,
              [uuidv4()]: {
                origValue: null,
                newValue: '',
                changed: false,
              },
            },
          },
        },
      });
    } else {
      newPartnerSftpConnectionList.push((connection));
    }
  })

  const editedCurrentSection = {
    ...sections[currentSection],
    partnerSftpConnections: [...newPartnerSftpConnectionList],
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function getCurrentFileRoute(sections, currentSection, fileRouteGUID, accountGUID, connectionType) {
  return connectionType === 'partnerSFTP' ?
      sections[currentSection].partnerSftpConnections.find((connection) => connection.accountGUID === accountGUID) :
      sections[currentSection].sftpConnection.fileRoutes[fileRouteGUID]
}