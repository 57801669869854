import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { Select } from 'react-chui';

import EditableField from '../../../../components/EditableField';
import EditableTextInput from '../../../../components/EditableTextInput';
import SubPaths from './SubPaths';
import FilePaths from './FilePaths';
import { ChubTableBodyRow, ChubTableBodyCell } from '../../../../styles/table';
import { DeleteIcon, CollapseIcon, ExpandIcon } from '../../../../styles/icons';
import { ChubP, selectableElement, ChubInput, chubStandards } from '../../../../styles/typography';

import {
  AS2_CONNECTION_TYPE,
  SFTP_CONNECTION_TYPE,
  PARTNER_SFTP_CONNECTION_TYPE,
} from '../../../../util/commerceHubInfo';

const FileRouteInputProps = {
  fontSize: '14px',
};

const SubPathCount = glamorous('span', { displayName: 'SubPathCount' })(chubStandards, {
  fontSize: '15px',
  padding: '15px 0',
  display: 'inline-block',
});

const SubPathButton = glamorous('button', { displayName: 'SubPathButton' })({
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  boxShadow: 'none',
  marginTop: '8px',
  display: 'inline-block',
  float: 'right',
});

const FilePathButton = glamorous('button', { displayName: 'FilePathButton' })({
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  boxShadow: 'none',
  marginTop: '3px',
  marginRight: '7px',
  display: 'inline-block',
  float: 'right',
});

const ReadOnlyText = glamorous(ChubP, { displayName: 'ReadOnlyText' })(selectableElement, {
  display: 'inline-block',
  padding: '0 10px',
  fontSize: '14px',
});

const DeleteButton = glamorous('button', { displayName: 'DeleteButton' })({
  border: 'none',
  padding: '0',
  float: 'right',
  background: 'none',
  ':disabled': {
    display: 'none',
  },
});

const as2FileRouteInputStyle = {
  marginLeft: '2px',
};

const ReadWriteSelect = ({ readOnly, newValue, origValue, children, ...otherProps }) => {
  return readOnly ? (
    <ReadOnlyText {...otherProps}>{newValue || origValue}</ReadOnlyText>
  ) : (
    <Select {...otherProps} value={newValue || origValue}>
      {children()}
    </Select>
  );
};

ReadWriteSelect.displayName = 'ReadWriteSelect';
ReadWriteSelect.propTypes = {
  readOnly: PropTypes.bool,
  origValue: PropTypes.string,
  newValue: PropTypes.string,
  children: PropTypes.func,
};

export class As2FileRoute extends Component {
  static displayName = 'As2FileRoute';

  static propTypes = {
    applicationKey: PropTypes.object,
    applicationCode: PropTypes.object,
    direction: PropTypes.object,
    endpointName: PropTypes.object,
    extension: PropTypes.object,
    contentType: PropTypes.object,
    additionalRoutingData: PropTypes.object,
    name: PropTypes.object,
    regexPattern: PropTypes.object,
    readOnly: PropTypes.bool,
    fileRouteGUID: PropTypes.string,
    parentId: PropTypes.string,
    as2FileRouteTypes: PropTypes.object,
    onChangeFileRouteKey: PropTypes.func,
    onChangeFileRouteDirection: PropTypes.func,
    onChangeFileRouteRegex: PropTypes.func,
    onChangeFileRouteExtension: PropTypes.func,
    onChangeFileRouteContentType: PropTypes.func,
    onChangeFileRouteAdditionalRoutingData: PropTypes.func,
    onDeleteFileRoute: PropTypes.func,
  };

  static defaultProps = {
    applicationKey: {},
    applicationCode: {},
    direction: {},
    endpointName: {},
    extension: {},
    contentType: {},
    additionalRoutingData: {},
    name: {},
    regexPattern: {},
    readOnly: true,
  };

  _onChangeKey = ({ target: { value: messageTypeId } }) => {
    const { onChangeFileRouteKey, fileRouteGUID, parentId } = this.props;
    onChangeFileRouteKey(AS2_CONNECTION_TYPE, fileRouteGUID, messageTypeId, parentId);
  };

  _onChangeRegex = (regex) => {
    const { onChangeFileRouteRegex, fileRouteGUID, parentId } = this.props;
    onChangeFileRouteRegex(AS2_CONNECTION_TYPE, fileRouteGUID, regex, parentId);
  };

  _onChangeExtension = (extension) => {
    const { onChangeFileRouteExtension, fileRouteGUID, parentId } = this.props;
    onChangeFileRouteExtension(AS2_CONNECTION_TYPE, fileRouteGUID, extension, parentId);
  };

  _onChangeContentType = (contentType) => {
    const { onChangeFileRouteContentType, fileRouteGUID, parentId } = this.props;
    onChangeFileRouteContentType(AS2_CONNECTION_TYPE, fileRouteGUID, contentType, parentId);
  };

  _onChangeAdditionalRoutingData = (additionalRoutingData) => {
    const { onChangeFileRouteAdditionalRoutingData, fileRouteGUID, parentId } = this.props;
    onChangeFileRouteAdditionalRoutingData(AS2_CONNECTION_TYPE, fileRouteGUID, additionalRoutingData, parentId);
  };

  _handleDeleteIconClick = (fileRouteGUID, parentID) => () => {
    const { onDeleteFileRoute } = this.props;
    onDeleteFileRoute(fileRouteGUID, AS2_CONNECTION_TYPE, parentID);
  };

  render() {
    const {
      applicationKey,
      applicationCode,
      direction,
      extension,
      contentType,
      additionalRoutingData,
      name,
      regexPattern,
      readOnly,
      fileRouteGUID,
      endpointName,
      as2FileRouteTypes,
      parentId,
    } = this.props;
    const currentDirection = direction.newValue || direction.origValue;
    const disableAdditionalRoutingData = currentDirection.toLowerCase() !== 'incoming';
    const disableContentType = currentDirection.toLowerCase() !== 'outgoing';

    return (
      <ChubTableBodyRow>
        <ChubTableBodyCell>
          <EditableField {...endpointName}>
            {({ origValue, newValue }) => (
              <ReadOnlyText data-name='as2-fileroute-application' id={`as2-fileroute-application-${fileRouteGUID}`}>
                {newValue || origValue}
              </ReadOnlyText>
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...applicationCode}>
            {({ origValue, newValue, changed }) => (
              <ReadOnlyText
                data-name='as2-fileroute-applicationCode'
                id={`as2-fileroute-applicationCode-${fileRouteGUID}`}
              >
                {changed ? newValue : origValue}
              </ReadOnlyText>
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...name}>
            {({ origValue, newValue }) => (
              <ReadOnlyText data-name='as2-fileroute-name' id={`as2-fileroute-name-${fileRouteGUID}-${parentId}`}>
                {newValue || origValue}
              </ReadOnlyText>
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...applicationKey, readOnly }}>
            {({ origValue, newValue }) => (
              <ReadWriteSelect
                data-name='as2-fileroute-key'
                id={`as2-fileroute-key-${fileRouteGUID}-${parentId}`}
                onChange={this._onChangeKey}
                {...{ origValue, newValue, readOnly }}
              >
                {() =>
                  Object.keys(as2FileRouteTypes).map((fileTypeId) => (
                    <option key={fileTypeId} value={fileTypeId}>
                      {as2FileRouteTypes[fileTypeId].text}
                    </option>
                  ))
                }
              </ReadWriteSelect>
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...direction}>
            {({ origValue, newValue }) => (
              <ReadOnlyText data-name='as2-fileroute-direction' id={`as2-fileroute-direction-${fileRouteGUID}`}>
                {newValue || origValue}
              </ReadOnlyText>
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...additionalRoutingData, readOnly }}>
            {({ ...editableInputProps }) => (
              <EditableTextInput
                style={as2FileRouteInputStyle}
                disabled={disableAdditionalRoutingData ? 'disabled' : ''}
                maxLength={1000}
                data-name='as2-fileroute-additional-routing-data'
                onChange={this._onChangeAdditionalRoutingData}
                id={`as2-fileroute-additional-routing-data-${fileRouteGUID}-${parentId}`}
                {...{ ...editableInputProps, ...FileRouteInputProps }}
                small
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...regexPattern, readOnly }}>
            {({ ...editableInputProps }) => (
              <EditableTextInput
                style={as2FileRouteInputStyle}
                data-name='as2-fileroute-regex'
                onChange={this._onChangeRegex}
                id={`as2-fileroute-regex-${fileRouteGUID}-${parentId}`}
                {...{ ...editableInputProps, ...FileRouteInputProps }}
                small
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...extension, readOnly }}>
            {({ ...editableInputProps }) => (
              <EditableTextInput
                style={as2FileRouteInputStyle}
                data-name='as2-fileroute-extension'
                onChange={this._onChangeExtension}
                id={`as2-fileroute-extension-${fileRouteGUID}-${parentId}`}
                {...{ ...editableInputProps, ...FileRouteInputProps }}
                small
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...contentType, readOnly }}>
            {({ ...editableInputProps }) => (
              <EditableTextInput
                style={as2FileRouteInputStyle}
                disabled={disableContentType ? 'disabled' : ''}
                data-name='as2-fileroute-content-type'
                onChange={this._onChangeContentType}
                id={`as2-fileroute-content-type-${fileRouteGUID}-${parentId}`}
                {...{ ...editableInputProps, ...FileRouteInputProps }}
                small
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <DeleteButton
            disabled={readOnly ? 'disabled' : ''}
            id={`as2-file-route-delete-${fileRouteGUID}-${parentId}`}
            data-name='file-route-delete-button'
            onClick={this._handleDeleteIconClick(fileRouteGUID, parentId)}
          >
            <DeleteIcon />
          </DeleteButton>
        </ChubTableBodyCell>
      </ChubTableBodyRow>
    );
  }
}

export class SftpFileRoute extends Component {
  static displayName = 'SftpFileRoute';

  static propTypes = {
    applicationKey: PropTypes.object,
    applicationCode: PropTypes.object,
    direction: PropTypes.object,
    directoryName: PropTypes.object,
    endpointName: PropTypes.object,
    subPaths: PropTypes.object,
    name: PropTypes.object,
    md5Verified: PropTypes.object,
    additionalRoutingData: PropTypes.object,
    readOnly: PropTypes.bool,
    fileRouteGUID: PropTypes.string,
    sftpFileRouteTypes: PropTypes.object,
    onChangeFileRouteKey: PropTypes.func,
    onChangeFileRouteDirection: PropTypes.func,
    onChangeFileRouteAdditionalRoutingData: PropTypes.func,
    onChangeFileRouteMd5Verified: PropTypes.func,
    onClickAddSubPath: PropTypes.func,
    onChangeSubPath: PropTypes.func,
    onClickDeleteSubPath: PropTypes.func,
    onDeleteFileRoute: PropTypes.func,
  };

  static defaultProps = {
    applicationKey: {},
    applicationCode: {},
    direction: {},
    directoryName: {},
    endpointName: {},
    subPaths: {},
    name: {},
    additionalRoutingData: {},
    md5Verified: {},
    readOnly: true,
  };

  state = {
    showSubpaths: false,
    showFilepaths: false,
  };

  _onChangeKey = ({ target: { value: messageTypeId } }) => {
    const { onChangeFileRouteKey, fileRouteGUID } = this.props;
    onChangeFileRouteKey(SFTP_CONNECTION_TYPE, fileRouteGUID, messageTypeId);
  };

  _onChangeAdditionalRoutingData = (additionalRoutingData) => {
    const { onChangeFileRouteAdditionalRoutingData, fileRouteGUID } = this.props;
    onChangeFileRouteAdditionalRoutingData(SFTP_CONNECTION_TYPE, fileRouteGUID, additionalRoutingData);
  };

  _onChangeMd5 = ({ target: { checked: md5Verified } }) => {
    const { onChangeFileRouteMd5Verified, fileRouteGUID } = this.props;
    onChangeFileRouteMd5Verified(SFTP_CONNECTION_TYPE, fileRouteGUID, md5Verified);
  };

  _md5Verified = () => {
    const {
      md5Verified: { newValue, origValue },
    } = this.props;
    return typeof newValue === 'undefined' ? origValue : newValue;
  };

  _getVisibleSubPaths = () => {
    const { subPaths } = this.props;
    return Object.keys(subPaths).reduce((accum, currentSubPathGUID) => {
      if (!subPaths[currentSubPathGUID].deleted) {
        accum[currentSubPathGUID] = subPaths[currentSubPathGUID];
      }
      return accum;
    }, {});
  };

  _toggleShowSubpaths = () => {
    const { showSubpaths } = this.state;
    this.setState({
      showSubpaths: !showSubpaths,
    });
  };

  _toggleShowFilepaths = () => {
    const { showFilepaths } = this.state;
    this.setState({
      showFilepaths: !showFilepaths,
    });
  };

  _handleDeleteIconClick = (fileRouteGUID) => () => {
    const { onDeleteFileRoute } = this.props;
    onDeleteFileRoute(fileRouteGUID, SFTP_CONNECTION_TYPE);
  };

  render() {
    const {
      applicationKey,
      applicationCode,
      direction,
      name,
      md5Verified,
      readOnly,
      fileRouteGUID,
      endpointName,
      onChangeSubPath,
      onClickAddSubPath,
      onClickDeleteSubPath,
      sftpFileRouteTypes,
      additionalRoutingData,
      connectionType,
    } = this.props;

    const currentDirection = direction.newValue || direction.origValue;
    const disableAdditionalRoutingData = currentDirection.toLowerCase() !== 'incoming';

    const { showSubpaths, showFilepaths } = this.state;
    const subPaths = this._getVisibleSubPaths();
    const sftpFileRouteType = sftpFileRouteTypes[applicationKey.origValue || applicationKey.newValue];
    const directoryName = sftpFileRouteType ? sftpFileRouteType.directoryName : '';
    return (
      <Fragment>
        <ChubTableBodyRow>
          <ChubTableBodyCell>
            <EditableField {...endpointName}>
              {({ origValue, newValue }) => (
                <ReadOnlyText data-name='sftp-fileroute-application' id={`sftp-fileroute-application-${fileRouteGUID}`}>
                  {newValue || origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...applicationCode}>
              {({ origValue, newValue, changed }) => (
                <ReadOnlyText
                  data-name='sftp-fileroute-applicationCode'
                  id={`sftp-fileroute-applicationCode-${fileRouteGUID}`}
                >
                  {changed ? newValue : origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...name}>
              {({ origValue, newValue }) => (
                <ReadOnlyText data-name='sftp-fileroute-name' id={`sftp-fileroute-name-${fileRouteGUID}`}>
                  {newValue || origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...{ ...applicationKey, readOnly }}>
              {({ origValue, newValue }) => (
                <ReadWriteSelect
                  data-name='sftp-fileroute-key'
                  id={`sftp-fileroute-key-${fileRouteGUID}`}
                  onChange={this._onChangeKey}
                  {...{ origValue, newValue, readOnly }}
                >
                  {() =>
                    Object.keys(sftpFileRouteTypes).map((fileTypeId) => (
                      <option key={fileTypeId} value={fileTypeId}>
                        {sftpFileRouteTypes[fileTypeId].text}
                      </option>
                    ))
                  }
                </ReadWriteSelect>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...direction}>
              {({ origValue, newValue }) => (
                <ReadOnlyText data-name='sftp-fileroute-direction' id={`sftp-fileroute-direction-${fileRouteGUID}`}>
                  {newValue || origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...{ ...additionalRoutingData, readOnly }}>
              {({ ...editableInputProps }) => (
                <EditableTextInput
                  disabled={disableAdditionalRoutingData ? 'disabled' : ''}
                  maxLength={1000}
                  data-name='sftp-fileroute-additional-routing-data'
                  onChange={this._onChangeAdditionalRoutingData}
                  id={`sftp-fileroute-additional-routing-data-${fileRouteGUID}`}
                  {...{ ...editableInputProps, ...FileRouteInputProps }}
                  small
                />
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...{ ...md5Verified, readOnly }}>
              {() => (
                <ChubInput
                  type='checkbox'
                  data-name='sftp-fileroute-md5'
                  onChange={this._onChangeMd5}
                  id={`sftp-fileroute-md5-${fileRouteGUID}`}
                  checked={this._md5Verified()}
                  disabled={readOnly ? 'disabled' : ''}
                />
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <SubPathCount id={`sftp-fileroute-subPath-count-${fileRouteGUID}`}>
              {Object.keys(subPaths).length}
            </SubPathCount>
            <SubPathButton
              data-name='subPath-view-toggle'
              id={`subPath-view-toggle-${fileRouteGUID}`}
              onClick={this._toggleShowSubpaths}
            >
              {showSubpaths ? <CollapseIcon /> : <ExpandIcon />}
            </SubPathButton>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <FilePathButton
              data-name='filePath-view-toggle'
              id={`filePath-view-toggle-${fileRouteGUID}`}
              onClick={this._toggleShowFilepaths}
            >
              {showFilepaths ? <CollapseIcon /> : <ExpandIcon />}
            </FilePathButton>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <DeleteButton
              disabled={readOnly ? 'disabled' : ''}
              id={`subPath-delete-button-${fileRouteGUID}`}
              data-name='file-route-delete-button'
              onClick={this._handleDeleteIconClick(fileRouteGUID)}
            >
              <DeleteIcon />
            </DeleteButton>
          </ChubTableBodyCell>
        </ChubTableBodyRow>
        {showSubpaths && (
          <SubPaths
            {...{
              readOnly,
              subPaths,
              fileRouteGUID,
              onChangeSubPath,
              onClickAddSubPath,
              onClickDeleteSubPath,
              connectionType,
            }}
          />
        )}
        {showFilepaths && <FilePaths {...{ directoryName, direction, subPaths }} />}
      </Fragment>
    );
  }
}

export class PartnerSftpFileRoute extends Component {
  static displayName = 'PartnerSftpFileRoute';

  static propTypes = {
    applicationKey: PropTypes.object,
    applicationCode: PropTypes.object,
    direction: PropTypes.object,
    endpointName: PropTypes.object,
    additionalRoutingData: PropTypes.object,
    subPaths: PropTypes.object,
    name: PropTypes.object,
    readOnly: PropTypes.bool,
    fileRouteGUID: PropTypes.string,
    parentId: PropTypes.string,
    partnerSftpFileRouteTypes: PropTypes.object,
    onChangeFileRouteKey: PropTypes.func,
    onChangeFileRouteDirection: PropTypes.func,
    onChangeFileRouteAdditionalRoutingData: PropTypes.func,
    onDeleteFileRoute: PropTypes.func,
    onClickAddSubPath: PropTypes.func,
    onChangeSubPath: PropTypes.func,
    onClickDeleteSubPath: PropTypes.func,
    connectionType: PropTypes.string,
  };

  static defaultProps = {
    applicationKey: {},
    applicationCode: {},
    direction: {},
    endpointName: {},
    additionalRoutingData: {},
    name: {},
    readOnly: true,
    subPaths: {},
  };

  state = {
    showSubpaths: false,
  };

  _onChangeKey = ({ target: { value: messageTypeId } }) => {
    const { onChangeFileRouteKey, fileRouteGUID, parentId } = this.props;
    onChangeFileRouteKey(PARTNER_SFTP_CONNECTION_TYPE, fileRouteGUID, messageTypeId, parentId);
  };

  _onChangeAdditionalRoutingData = (additionalRoutingData) => {
    const { onChangeFileRouteAdditionalRoutingData, fileRouteGUID, parentId } = this.props;
    onChangeFileRouteAdditionalRoutingData(
      PARTNER_SFTP_CONNECTION_TYPE,
      fileRouteGUID,
      additionalRoutingData,
      parentId
    );
  };

  _handleDeleteIconClick = (fileRouteGUID, parentID) => () => {
    const { onDeleteFileRoute } = this.props;
    onDeleteFileRoute(fileRouteGUID, PARTNER_SFTP_CONNECTION_TYPE, parentID);
  };

  _getVisibleSubPaths = () => {
    const { subPaths } = this.props;
    return Object.keys(subPaths).reduce((accum, currentSubPathGUID) => {
      if (!subPaths[currentSubPathGUID].deleted) {
        accum[currentSubPathGUID] = subPaths[currentSubPathGUID];
      }
      return accum;
    }, {});
  };

  _toggleShowSubpaths = () => {
    const { showSubpaths } = this.state;
    this.setState({
      showSubpaths: !showSubpaths,
    });
  };

  render() {
    const {
      applicationKey,
      applicationCode,
      direction,
      additionalRoutingData,
      name,
      readOnly,
      fileRouteGUID,
      endpointName,
      partnerSftpFileRouteTypes,
      parentId,
      onChangeSubPath,
      onClickAddSubPath,
      onClickDeleteSubPath,
      connectionType,
    } = this.props;
    const { showSubpaths } = this.state;
    const subPaths = this._getVisibleSubPaths();

    return (
      <Fragment>
        <ChubTableBodyRow>
          <ChubTableBodyCell>
            <EditableField {...endpointName}>
              {({ origValue, newValue }) => (
                <ReadOnlyText
                  data-name='partner-sftp-fileroute-application'
                  id={`partner-sftp-fileroute-application-${fileRouteGUID}`}
                >
                  {newValue || origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...applicationCode}>
              {({ origValue, newValue, changed }) => (
                <ReadOnlyText
                  data-name='partner-sftp-fileroute-applicationCode'
                  id={`partner-sftp-fileroute-applicationCode-${fileRouteGUID}`}
                >
                  {changed ? newValue : origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...name}>
              {({ origValue, newValue }) => (
                <ReadOnlyText
                  data-name='partner-sftp-fileroute-name'
                  id={`partner-sftp-fileroute-name-${fileRouteGUID}-${parentId}`}
                >
                  {newValue || origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...{ ...applicationKey, readOnly }}>
              {({ origValue, newValue }) => (
                <ReadWriteSelect
                  data-name='partner-sftp-fileroute-key'
                  id={`partner-sftp-fileroute-key-${fileRouteGUID}-${parentId}`}
                  onChange={this._onChangeKey}
                  {...{ origValue, newValue, readOnly }}
                >
                  {() =>
                    Object.keys(partnerSftpFileRouteTypes).map((fileTypeId) => (
                      <option key={fileTypeId} value={fileTypeId}>
                        {partnerSftpFileRouteTypes[fileTypeId].text}
                      </option>
                    ))
                  }
                </ReadWriteSelect>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...direction}>
              {({ origValue, newValue }) => (
                <ReadOnlyText
                  data-name='partner-sftp-fileroute-direction'
                  id={`partner-sftp-fileroute-direction-${fileRouteGUID}`}
                >
                  {newValue || origValue}
                </ReadOnlyText>
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <EditableField {...{ ...additionalRoutingData, readOnly }}>
              {({ ...editableInputProps }) => (
                <EditableTextInput
                  maxLength={1000}
                  data-name='partner-sftp-fileroute-additional-routing-data'
                  onChange={this._onChangeAdditionalRoutingData}
                  id={`partner-sftp-fileroute-additional-routing-data-${fileRouteGUID}-${parentId}`}
                  {...{ ...editableInputProps, ...FileRouteInputProps }}
                  small
                />
              )}
            </EditableField>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <SubPathCount id={`sftp-fileroute-subPath-count-${fileRouteGUID}`}>
              {Object.keys(subPaths).length}
            </SubPathCount>
            <SubPathButton
              data-name='subPath-view-toggle'
              id={`subPath-view-toggle-${fileRouteGUID}`}
              onClick={this._toggleShowSubpaths}
            >
              {showSubpaths ? <CollapseIcon /> : <ExpandIcon />}
            </SubPathButton>
          </ChubTableBodyCell>
          <ChubTableBodyCell>
            <DeleteButton
              disabled={readOnly ? 'disabled' : ''}
              id={`partner-sftp-file-route-delete-${fileRouteGUID}-${parentId}`}
              data-name='file-route-delete-button'
              onClick={this._handleDeleteIconClick(fileRouteGUID, parentId)}
            >
              <DeleteIcon />
            </DeleteButton>
          </ChubTableBodyCell>
        </ChubTableBodyRow>
        {showSubpaths && (
          <SubPaths
            {...{
              readOnly,
              subPaths,
              fileRouteGUID,
              onChangeSubPath,
              onClickAddSubPath,
              onClickDeleteSubPath,
              parentId,
              connectionType,
            }}
          />
        )}
      </Fragment>
    );
  }
}
