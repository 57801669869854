import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { colors } from 'react-chui';
import { chubStandards } from '../../../styles/typography';
import { DeleteIcon } from '../../../styles/icons';

const ConfigurationBoxArea = glamorous('div', { displayName: 'ConfigurationBoxArea' })({
  maxWidth: '1500px',
  minWidth: '1000px',
  display: 'flex',
  paddingBottom: '20px',
});

const SectionNameArea = glamorous('div', { displayName: 'SectionNameArea' })(({ backgroundColor }) => ({
  width: '50px',
  display: 'inline-block',
  backgroundColor,
  borderRadius: '3px 0 0 3px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
}));

const SectionName = glamorous('p', { displayName: 'SectionName' })(chubStandards, ({ textColor }) => ({
  width: '100px',
  textAlign: 'center',
  fontSize: '25px',
  color: textColor,
  WebkitTransform: 'rotate(-90deg) translate(-25px, -25px)',
  display: 'inline-block',
}));

const RenderedConfiguration = glamorous('div', { displayName: 'RenderedConfiguration' })({
  width: '100%',
  display: 'inline-block',
  borderColor: colors.darkgray,
  backgroundColor: colors.coolgraylight1,
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
});

const DeleteButton = glamorous('button', { displayName: 'DeleteButton' })({
  border: 'none',
  paddingTop: '5px',
  float: 'right',
  background: 'none',
  ':disabled': {
    display: 'none',
  },
});

const _partnerSftpStyle = (sectionName) => {
  return sectionName === 'PartnerSFTP'
    ? {
        width: '180px',
        WebkitTransform: 'rotate(-90deg) translate(-60px, -65px)',
      }
    : {};
};

const _handleDeleteIconClick = (otherProps) => {
  const {
    connection: { mailboxGUID, accountGUID },
    onClickDeleteAs2Connection,
    onClickDeletePartnerSftpConnection,
  } = otherProps;
  if (mailboxGUID) {
    onClickDeleteAs2Connection(mailboxGUID);
  } else if (accountGUID) {
    onClickDeletePartnerSftpConnection(accountGUID);
  }
};

const ConfigurationBox = ({ children, sectionName, textColor, backgroundColor, readOnly, ...otherProps }) => (
  <ConfigurationBoxArea>
    <SectionNameArea {...{ backgroundColor }}>
      <SectionName {...{ textColor }} style={{ ..._partnerSftpStyle(sectionName) }}>
        {sectionName}
        {(sectionName === 'AS2' || sectionName === 'PartnerSFTP') && (
          <DeleteButton
            disabled={readOnly ? 'disabled' : ''}
            data-name='section-delete-button'
            onClick={() => {
              _handleDeleteIconClick(otherProps);
            }}
          >
            <DeleteIcon />
          </DeleteButton>
        )}
      </SectionName>
    </SectionNameArea>
    <RenderedConfiguration>{children(otherProps)}</RenderedConfiguration>
  </ConfigurationBoxArea>
);

ConfigurationBox.displayName = 'ConfigurationBox';
ConfigurationBox.propTypes = {
  sectionName: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  readOnly: PropTypes.bool,
  children: PropTypes.func,
};
ConfigurationBox.defaultProps = {
  backgroundColor: 'white',
  textColor: colors.darkgray,
};

export default ConfigurationBox;
