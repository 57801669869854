import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { Select } from 'react-chui';
import { ChubP, selectableElement } from '../../../styles/typography';

const ReadOnlySelect = glamorous(ChubP, { displayName: 'ReadOnlySelect' })(selectableElement, {
  display: 'inline-block',
  padding: '0 10px',
  fontSize: '18px',
});

export default class EditableSelect extends Component {
  static displayName = 'EditableSelect';

  static propTypes = {
    id: PropTypes.string,
    values: PropTypes.array,
    origValue: PropTypes.string,
    newValue: PropTypes.string,
    isModified: PropTypes.bool,
    onChange: PropTypes.func,
    changed: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    values: [],
    // eslint-disable-next-line
    onChange: () => {},
  };

  _findIdByGuid = (searchableGuid) => {
    const { values } = this.props;
    const { value } = values.find(({ guid }) => guid === searchableGuid) || { value: 'Unknown value' };
    return value;
  };

  _onChange = ({ target: { children, value: guid } }) => {
    const { onChange, origValue, id } = this.props;
    let value = '';
    Array.from(children).forEach((node) => {
      if (node.value === guid) {
        value = node.textContent;
      }
    });
    const isModified = guid !== origValue;
    onChange(value, guid, isModified, id);
  };

  render() {
    const { readOnly, origValue, values, newValue, ...otherProps } = this.props;
    return readOnly ? (
      <ReadOnlySelect {...otherProps}>{this._findIdByGuid(origValue)}</ReadOnlySelect>
    ) : (
      <Select {...otherProps} onChange={this._onChange} value={newValue || origValue}>
        {values.map((object) => (
          <option key={object.guid} value={object.guid}>
            {object.value}
          </option>
        ))}
      </Select>
    );
  }
}
