function configurationCanSave(
  {
    as2Connections = [{ fileRoutes: {} }],
    sftpConnection = { fileRoutes: {} },
    partnerSftpConnections = [{ fileRoutes: {} }],
  },
  as2ConnectionsListChanged,
  partnerSftpConnectionsListChanged
) {
  const as2ConnectionsCanSave =
    as2Connections &&
    as2Connections.reduce((accum, connection) => {
      return Object.keys(connection).reduce((inner, key) => {
        if (!key || !connection[key] || key === 'mailboxGUID') {
          return inner;
        }
        return connection[key].changed || inner;
      }, accum);
    }, false);
  const sftpConnectionCanSave = Object.keys(sftpConnection).reduce(
    (accum, currentKey) => sftpConnection[currentKey].changed || accum,
    false
  );
  const partnerSftpConnectionsCanSave =
    partnerSftpConnections &&
    partnerSftpConnections.reduce((accum, connection) => {
      return Object.keys(connection).reduce((inner, key) => {
        if (!key || !connection[key] || key === 'accountGUID') {
          return inner;
        }
        return connection[key].changed || inner;
      }, accum);
    }, false);
  const as2ConnectionsListFileRoutesCanSave =
    as2Connections &&
    as2Connections.reduce((outerAccum, connection) => {
      return Object.keys(connection.fileRoutes || {}).reduce((accum, currentFileRouteGUID) => {
        return (
          Object.keys(connection.fileRoutes[currentFileRouteGUID]).reduce((accum2, currentKey) => {
            return (
              accum2 ||
              connection.fileRoutes[currentFileRouteGUID][currentKey].changed ||
              connection.fileRoutes[currentFileRouteGUID].deleted ||
              connection.fileRoutes[currentFileRouteGUID].added
            );
          }, false) || accum
        );
      }, outerAccum);
    }, false);
  const sftpConnectionFileRoutesCanSave = Object.keys(sftpConnection.fileRoutes || {}).reduce(
    (accum, currentFileRouteGUID) => {
      const fileRouteChanged = Object.keys(sftpConnection.fileRoutes[currentFileRouteGUID]).reduce(
        (accum2, currentKey) => {
          return (
            accum2 ||
            sftpConnection.fileRoutes[currentFileRouteGUID][currentKey].changed ||
            sftpConnection.fileRoutes[currentFileRouteGUID].deleted ||
            sftpConnection.fileRoutes[currentFileRouteGUID].added
          );
        },
        false
      );
      const subPathsChanged = Object.keys(sftpConnection.fileRoutes[currentFileRouteGUID].subPaths).reduce(
        (accum2, currentSubPathGUID) => {
          const curSubPath = sftpConnection.fileRoutes[currentFileRouteGUID].subPaths[currentSubPathGUID];
          return accum2 || curSubPath.changed || curSubPath.added || curSubPath.deleted;
        },
        false
      );
      return fileRouteChanged || subPathsChanged || accum;
    },
    false
  );
  const partnerSftpConnectionsListFileRoutesCanSave =
    partnerSftpConnections &&
    partnerSftpConnections.reduce((outerAccum, connection) => {
      return Object.keys(connection.fileRoutes || {}).reduce((accum, currentFileRouteGUID) => {
        const fileRouteChanged = Object.keys(connection.fileRoutes[currentFileRouteGUID]).reduce(
          (accum2, currentKey) => {
            return (
              accum2 ||
              connection.fileRoutes[currentFileRouteGUID][currentKey].changed ||
              connection.fileRoutes[currentFileRouteGUID].deleted ||
              connection.fileRoutes[currentFileRouteGUID].added
            );
          },
          false
        );

        const subPathsChanged = Object.keys(connection.fileRoutes[currentFileRouteGUID].subPaths).reduce(
          (accum2, currentSubPathGUID) => {
            const curSubPath = connection.fileRoutes[currentFileRouteGUID].subPaths[currentSubPathGUID];
            return accum2 || curSubPath.changed || curSubPath.added || curSubPath.deleted;
          },
          false
        );
        return fileRouteChanged || subPathsChanged || accum;
      }, outerAccum);
    }, false);

  // Check to see if we have generated a password
  const haveGeneratedSftpPassword = typeof sftpConnection.generatedPassword !== 'undefined';

  return (
    // as2ConnectionCanSave ||
    as2ConnectionsCanSave ||
    sftpConnectionCanSave ||
    partnerSftpConnectionsCanSave ||
    as2ConnectionsListFileRoutesCanSave ||
    as2ConnectionsListChanged ||
    partnerSftpConnectionsListFileRoutesCanSave ||
    partnerSftpConnectionsListChanged ||
    // as2ConnectionFileRoutesCanSave ||
    sftpConnectionFileRoutesCanSave ||
    haveGeneratedSftpPassword
  );
}

// eslint-disable-next-line no-unused-vars
function findAs2ConnectionByGuid(as2ConnectionList, guid) {
  return guid ? as2ConnectionList.find((connection) => connection.mailboxGUID === guid) : { fileRoutes: {} };
}

export default configurationCanSave;
