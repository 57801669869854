import React from 'react';
import { Tooltip, TooltipTrigger } from 'react-chui';
import glamorous from 'glamorous';
import { Link } from 'react-router-dom';
import { chubStandards } from '../styles/typography';

const HelpMessage = glamorous(Link, { displayName: 'HelpMessage' })(chubStandards, {
  color: '#FFFFFF',
  fontSize: '20px',
  marginRight: '20px',
  textDecorationLine: 'none',
});

const HelpMessageLink = () => {
  const tooltip = (
    <Tooltip>
      <div>Please leave a message in #h_os-cyto-platypus</div>
    </Tooltip>
  );
  return (
    <TooltipTrigger {...{ tooltip }}>
      <HelpMessage to={'/help'}>Need help?</HelpMessage>
    </TooltipTrigger>
  );
};

HelpMessageLink.displayName = 'ErrorTooltip';

export default HelpMessageLink;
