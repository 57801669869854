import React from 'react';
import PropTypes from 'prop-types';
import glamorous, { GlamorousComponent } from 'glamorous';
import { chubStandards } from '../../../styles/typography';
import { ChubTableBody, ChubTableHeadCell, ChubTable, ChubTableWrapper } from '../../../styles/table';
import SFTPTransfersTableRow from './SFTPTransfersTableRow';
import { SFTPTransferData } from '../SFTPTransfers.model';

const NoTransfersMessage: GlamorousComponent<any, any> = glamorous('h3', { displayName: 'NoTransfersMessage' })(
  chubStandards,
  {
    size: '18px',
    textAlign: 'left',
    margin: '0 20px',
  }
);

const TransfersTableWrapper: GlamorousComponent<any, any> = glamorous(ChubTableWrapper, {
  displayName: 'TransfersTableWrapper',
})({
  maxWidth: '1700px',
  maxHeight: '600px',
  margin: '20px auto 0 auto',
  overflowX: 'hidden',
});

const SFTPTransfersTable = ({ isEmpty, fileTransfers }: { isEmpty: boolean; fileTransfers: SFTPTransferData[] }) => {
  return (
    <TransfersTableWrapper>
      {isEmpty && <NoTransfersMessage>No transfers found</NoTransfersMessage>}
      {!isEmpty && (
        <ChubTable>
          <thead>
            <tr>
              <ChubTableHeadCell>Partner ID</ChubTableHeadCell>
              <ChubTableHeadCell>User Name</ChubTableHeadCell>
              <ChubTableHeadCell>IP Address</ChubTableHeadCell>
              <ChubTableHeadCell>Event Description</ChubTableHeadCell>
              <ChubTableHeadCell overflow-wrap='break-word' width='250px'>
                New File Path
              </ChubTableHeadCell>
              <ChubTableHeadCell overflow-wrap='break-word' width='250px'>
                Old File Path
              </ChubTableHeadCell>
              <ChubTableHeadCell>Bytes Transferred</ChubTableHeadCell>
              <ChubTableHeadCell>Event Timestamp</ChubTableHeadCell>
              <ChubTableHeadCell>Status Description</ChubTableHeadCell>
              <ChubTableHeadCell>Success</ChubTableHeadCell>
            </tr>
          </thead>
          <ChubTableBody>
            {fileTransfers.map((record, index) => (
              <SFTPTransfersTableRow key={index} {...record} />
            ))}
          </ChubTableBody>
        </ChubTable>
      )}
    </TransfersTableWrapper>
  );
};

SFTPTransfersTable.displayName = 'SFTPTransfersTable';
SFTPTransfersTable.propTypes = {
  title: PropTypes.string,
  isEmpty: PropTypes.bool,
  fileTransfers: PropTypes.array,
};

export default SFTPTransfersTable;
