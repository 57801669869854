import { connect } from 'react-redux';
import { addAs2Id, updateAs2Id } from '../actions';
import As2IdListEditor from '../components/As2IdListEditor';

const mapStateToProps = (state) => {
  const {
    past,
    present: { as2Identifiers },
  } = state;
  return {
    as2IdList: as2Identifiers,
    changesMade: past.length > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAs2Id: (as2Identifier) => {
      dispatch(updateAs2Id(as2Identifier));
    },
    addAs2Id: (as2Id) => {
      dispatch(addAs2Id(as2Id));
    },
  };
};

const As2IdListEditorContainer = connect(mapStateToProps, mapDispatchToProps)(As2IdListEditor);

export default As2IdListEditorContainer;
