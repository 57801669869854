import React from 'react';
import PropTypes from 'prop-types';
import glamorous, { GlamorousComponent } from 'glamorous';
import { chubStandards } from '../../../styles/typography';
import { ChubTableBody, ChubTableHeadCell, ChubTable, ChubTableWrapper } from '../../../styles/table';
import PartnerSFTPPendingTransfersTableRow from './PartnerSFTPPendingTransfersTableRow';
import { PendingPartnerSFTPTransferData } from '../PartnerSFTPTransfers.model';

const Title: GlamorousComponent<any, any> = glamorous('h2', { displayName: 'Title' })(chubStandards, {
  size: '24px',
  textAlign: 'left',
  margin: '0 20px',
});

const NoTransfersMessage: GlamorousComponent<any, any> = glamorous('h3', { displayName: 'NoTransfersMessage' })(
  chubStandards,
  {
    size: '18px',
    textAlign: 'left',
    margin: '0 20px',
  }
);

const PendingTransfersTableWrapper: GlamorousComponent<any, any> = glamorous(ChubTableWrapper, {
  displayName: 'PendingTransfersTableWrapper',
})({
  maxWidth: '1525px',
  maxHeight: '600px',
  margin: '20px auto 0 auto',
  overflowX: 'hidden',
});

const PartnerSFTPPendingTransfersTable = ({
  title,
  isEmpty,
  fileTransfers,
}: {
  title: string;
  isEmpty: boolean;
  fileTransfers: PendingPartnerSFTPTransferData[];
}) => {
  return (
    <PendingTransfersTableWrapper>
      <Title>{title}</Title>
      {isEmpty && <NoTransfersMessage>No pending transfers exist</NoTransfersMessage>}
      {!isEmpty && (
        <ChubTable>
          <thead>
          <tr>
            <ChubTableHeadCell>File ID</ChubTableHeadCell>
            <ChubTableHeadCell>File Name</ChubTableHeadCell>
            <ChubTableHeadCell>Create Date</ChubTableHeadCell>
            <ChubTableHeadCell>Most Recent Send Attempt</ChubTableHeadCell>
            <ChubTableHeadCell>Most Recent Send Result</ChubTableHeadCell>
            <ChubTableHeadCell>Total Attempts</ChubTableHeadCell>
            <ChubTableHeadCell>VL Serial</ChubTableHeadCell>
          </tr>
          </thead>
          <ChubTableBody>
            {fileTransfers.map((record, index) => (
              <PartnerSFTPPendingTransfersTableRow key={index} {...record} />
            ))}
          </ChubTableBody>
        </ChubTable>
      )}
    </PendingTransfersTableWrapper>
  );
};

PartnerSFTPPendingTransfersTable.displayName = 'PartnerSFTPPendingTransfersTable';
PartnerSFTPPendingTransfersTable.propTypes = {
  title: PropTypes.string,
  isEmpty: PropTypes.bool,
  fileTransfers: PropTypes.array,
};

export default PartnerSFTPPendingTransfersTable;
