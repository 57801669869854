import { connect } from 'react-redux';
import SaveCancelFooter from '../../../../components/SaveCancelFooter';
import { saveEnvironments } from '../actions/index';

const mapStateToProps = ({
  undoableEnvironments: {
    present: { environments },
  },
}) => {
  const canSave = Boolean(environments.find(({ hasChanges }) => hasChanges));

  return {
    canCancel: true,
    canSave,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCancel: () => {
      window.location.reload();
    },
    onSave: () => {
      dispatch(saveEnvironments());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveCancelFooter);
