import { connect } from 'react-redux';

import LoadingStateProvider from '../../../../components/LoadingStateProvider';

const mapStateToProps = (state) => {
  const {
    present: { listError, isFetching, isSaving },
  } = state;
  return {
    error: listError,
    isLoading: isFetching,
    isSaving,
  };
};

export default connect(mapStateToProps)(LoadingStateProvider);
