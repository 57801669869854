import React from 'react';
import glamorous from 'glamorous';
import { Spinner } from 'react-chui';
import useExternalLinkRedirect from '../../hooks/ExternalLinkRedirect';

const LoadingSpinner = glamorous(Spinner, {
  displayName: 'LoadingSpinner',
  withProps: {
    size: 'large',
  },
})({
  display: 'block',
  margin: '0 auto',
});

const CommonSFTPErrorsApp = () => {
  useExternalLinkRedirect(
    'https://www.notion.so/commercehub/Common-sFTP-Errors-Troubleshooting-8d3d8e6732934a2ebbc3254f0421635e'
  );
  return <LoadingSpinner />;
};
CommonSFTPErrorsApp.displayName = 'Common SFTP Errors';

export default CommonSFTPErrorsApp;
