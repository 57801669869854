import React, { useEffect } from 'react';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { fetchInitialData } from './actions';
import ClientConfiguration from './ClientConfigurationApp';
import undoableConfiguration from './reducers';
import { useParams } from 'react-router-dom';

const store = createStore(undoableConfiguration, applyMiddleware(thunkMiddleware));

const ClientConfigurationEntry = () => {
  const { clientGUID, versionGUID, environmentGUID } = useParams();

  useEffect(() => {
    store.dispatch(fetchInitialData(clientGUID, versionGUID, environmentGUID));
  }, [clientGUID, versionGUID, environmentGUID]);

  return (
    <Provider store={store}>
      <ClientConfiguration />
    </Provider>
  );
};

export default ClientConfigurationEntry;
