import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { ChubInput, ChubP } from '../../../../styles/typography';
import { ChubTableBodyRow, ChubTableBodyCell } from '../../../../styles/table';
import { DeleteIcon } from '../../../../styles/icons';

import { colors } from 'react-chui';

const As2Input = glamorous(ChubInput, { displayName: 'Input' })(
  {
    width: '100%',
    lineHeight: 2,
    fontSize: '13px',
  },
  ({ editMade, error, hasSpaces }) => {
    const styles = {
      border: '0 none',
    };

    if (editMade) {
      styles.border = `1px solid ${colors.chgreen}`;
    } else if (error) {
      styles.border = `1px solid ${colors.chred}`;
    }

    if (hasSpaces) {
      styles.backgroundColor = colors.chyellow;
    }

    return styles;
  }
);

const DeleteButton = glamorous('button', { displayName: 'DeleteButton' })({
  border: 'none',
  padding: '0',
  float: 'right',
  background: 'none',
});

const Message = glamorous(ChubP, { displayName: 'Message' })(({ hasChanges, isError }) => {
  const styles = {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
  };
  if (hasChanges) {
    styles.color = colors.chgreen;
  } else if (isError) {
    styles.color = colors.chred;
  }
  return styles;
});

const formAs2Identifier = (as2ID, author, createDate, systemIdentifierGUID, origAs2ID, updateDate) => {
  const as2Identifier = {
    as2ID,
    author,
    createDate,
    systemIdentifierGUID,
    origAs2ID,
  };
  if (updateDate) {
    as2Identifier.updateDate = updateDate;
  }

  return as2Identifier;
};

export default class As2IdRow extends Component {
  static displayName = 'As2IdRow';

  static propTypes = {
    as2ID: PropTypes.string,
    systemIdentifierGUID: PropTypes.string,
    createDate: PropTypes.string,
    updateDate: PropTypes.string,
    author: PropTypes.string,
    origAs2ID: PropTypes.string,
    error: PropTypes.string,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    editMade: PropTypes.bool,
  };

  state = {
    localAs2ID: '',
    hasSpaces: false,
  };

  _updateAs2Id = () => {
    const { as2ID, author, createDate, systemIdentifierGUID, origAs2ID, onUpdate, updateDate } = this.props;
    const { localAs2ID } = this.state;

    if (localAs2ID !== as2ID) {
      onUpdate(formAs2Identifier(localAs2ID, author, createDate, systemIdentifierGUID, origAs2ID, updateDate));
    }
  };

  _deleteThisRow = () => {
    const { as2ID, author, createDate, systemIdentifierGUID, origAs2ID, onDelete, updateDate } = this.props;
    onDelete(formAs2Identifier(as2ID, author, createDate, systemIdentifierGUID, origAs2ID, updateDate));
  };

  _beginsOrEndsWithSpaces = (val) => {
    const beginsOrEndsWithSpaces = new RegExp('^\\s+.*$|^.*\\s+$');
    return beginsOrEndsWithSpaces.test(val);
  };

  componentWillMount() {
    const { as2ID } = this.props;

    this.setState({
      localAs2ID: as2ID,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { as2ID } = this.props;
    if (as2ID !== nextProps.as2ID) {
      this.setState({
        localAs2ID: nextProps.as2ID,
      });
    }
  }

  _wasEditMade = () => {
    const { origAs2ID } = this.props;
    const { localAs2ID } = this.state;
    return localAs2ID !== origAs2ID;
  };

  _onChange = (event) => {
    this.setState({
      localAs2ID: event.target.value,
      hasSpaces: this._beginsOrEndsWithSpaces(event.target.value),
    });
  };

  render() {
    const { systemIdentifierGUID, createDate, updateDate, author, editMade, error } = this.props;
    const { localAs2ID, hasSpaces } = this.state;

    return (
      <ChubTableBodyRow key={systemIdentifierGUID}>
        <ChubTableBodyCell>
          <As2Input
            editMade={editMade}
            error={error}
            value={localAs2ID}
            onChange={this._onChange}
            onBlur={this._updateAs2Id}
            id={`as2id-row-edit-input-${systemIdentifierGUID}`}
            hasSpaces={this._beginsOrEndsWithSpaces(localAs2ID)}
          />
          {error && !editMade && (
            <Message id={`as2id-row-error-${systemIdentifierGUID}`} isError>
              {error}
            </Message>
          )}
          {editMade && (
            <Message id={`as2id-row-unsaved-changes-${systemIdentifierGUID}`} hasChanges>
              Unsaved Changes
            </Message>
          )}
          {hasSpaces && (
            <Message id={`as2id-row-leading-or-trailing-whitespace-${systemIdentifierGUID}`} isError>
              AS2 ID begins or ends with spaces
            </Message>
          )}
        </ChubTableBodyCell>
        <ChubTableBodyCell data-local-as2id={localAs2ID}>{systemIdentifierGUID}</ChubTableBodyCell>
        <ChubTableBodyCell>{createDate}</ChubTableBodyCell>
        <ChubTableBodyCell>{updateDate}</ChubTableBodyCell>
        <ChubTableBodyCell>
          {author}
          <DeleteButton
            id={`as2id-row-delete-${systemIdentifierGUID}`}
            title='Delete AS2 ID'
            onClick={this._deleteThisRow}
          >
            <DeleteIcon />
          </DeleteButton>
        </ChubTableBodyCell>
      </ChubTableBodyRow>
    );
  }
}
