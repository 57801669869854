import React from 'react';
import PropTypes from 'prop-types';
import glamorous, { GlamorousComponent } from 'glamorous';
import { chubStandards } from '../../../styles/typography';
import { ChubTableBody, ChubTableHeadCell, ChubTable, ChubTableWrapper } from '../../../styles/table';
import PartnerSFTPPastTransfersTableRow from './PartnerSFTPPastTransfersTableRow';
import { PartnerSFTPTransferData, TableType } from '../PartnerSFTPTransfers.model';

const Title: GlamorousComponent<any, any> = glamorous('h2', { displayName: 'Title' })(chubStandards, {
  size: '24px',
  textAlign: 'left',
  margin: '0 20px',
});

const NoTransfersMessage: GlamorousComponent<any, any> = glamorous('h3', { displayName: 'NoTransfersMessage' })(
  chubStandards,
  {
    size: '18px',
    textAlign: 'left',
    margin: '0 20px',
  }
);

const PastTransfersTableWrapper: GlamorousComponent<any, any> = glamorous(ChubTableWrapper, {
  displayName: 'PastTransfersTableWrapper',
})({
  maxWidth: '1525px',
  maxHeight: '600px',
  margin: '20px auto 0 auto',
  overflowX: 'hidden',
});

const PartnerSFTPPastTransfersTable = ({
   title,
   isEmpty,
   fileTransfers,
   tableType,
 }: {
  title: string;
  isEmpty: boolean;
  fileTransfers: PartnerSFTPTransferData[];
  tableType: TableType;
}) => {
  return (
    <PastTransfersTableWrapper>
      <Title>{title}</Title>
      {isEmpty && <NoTransfersMessage>No transfers of this type exist</NoTransfersMessage>}
      {!isEmpty && (
        <ChubTable>
          <thead>
          <tr>
            {tableType === TableType.OUTGOING && <ChubTableHeadCell colSpan='2'>File ID</ChubTableHeadCell>}
            <ChubTableHeadCell colSpan='5'>Message ID</ChubTableHeadCell>
            <ChubTableHeadCell colSpan='3'>Start Date</ChubTableHeadCell>
            <ChubTableHeadCell colSpan='3'>End Date</ChubTableHeadCell>
            <ChubTableHeadCell colSpan='4'>Result</ChubTableHeadCell>
            <ChubTableHeadCell colSpan='4'>File Name</ChubTableHeadCell>
            <ChubTableHeadCell colSpan='2'>Status</ChubTableHeadCell>
            <ChubTableHeadCell colSpan='2'>VL Serial</ChubTableHeadCell>
          </tr>
          </thead>
          <ChubTableBody>
            {fileTransfers.map((record, index) => (
              <PartnerSFTPPastTransfersTableRow key={index} {...record} tableType={tableType} />
            ))}
          </ChubTableBody>
        </ChubTable>
      )}
    </PastTransfersTableWrapper>
  );
};

PartnerSFTPPastTransfersTable.displayName = 'PartnerSFTPPastTransfersTable';
PartnerSFTPPastTransfersTable.propTypes = {
  title: PropTypes.string,
  isEmpty: PropTypes.bool,
  fileTransfers: PropTypes.array,
  tableType: PropTypes.string,
};

export default PartnerSFTPPastTransfersTable;
