import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';

import { ChubTableWrapper, ChubTable, ChubTableHeadCell, ChubTableBody } from '../../../styles/table';
import VersionRow from './VersionRow';
import VersionDisplayLoadingStateProviderContainer from '../containers/VersionDisplayLoadingStateProviderContainer';

const TableWrapper = glamorous(ChubTableWrapper, { displayName: 'TableWrapper' })({
  display: 'inline-block',
  verticalAlign: 'middle',
  height: '240px',
  width: '75%',
  border: '2px solid #0E2546',
  borderRadius: '4px',
  backgroundColor: colors.coolgraylight2,
  overflowX: 'hidden',
  overflowY: 'scroll',
});

export default class VersionTable extends Component {
  static displayName = 'VersionTable';

  static propTypes = {
    deployedVersions: PropTypes.array,
    nonDeployedVersions: PropTypes.array,
    client: PropTypes.object,
    displayNonDeployedVersions: PropTypes.bool,
  };

  renderHeaderRow = () => {
    const { displayNonDeployedVersions } = this.props;
    return (
      <tr>
        <ChubTableHeadCell>Version</ChubTableHeadCell>
        <ChubTableHeadCell>Environment</ChubTableHeadCell>
        <ChubTableHeadCell>Status</ChubTableHeadCell>
        <ChubTableHeadCell>Author</ChubTableHeadCell>
        {displayNonDeployedVersions && <ChubTableHeadCell>View Version</ChubTableHeadCell>}
      </tr>
    );
  };

  renderBodyRows = () => {
    const {
      client: { clientGUID },
      deployedVersions,
      nonDeployedVersions,
      displayNonDeployedVersions,
    } = this.props;
    let toDisplay = deployedVersions;
    if (displayNonDeployedVersions && nonDeployedVersions) {
      toDisplay = deployedVersions.concat(nonDeployedVersions);
    }
    return toDisplay.map((version, index) => {
      return (
        <VersionRow
          key={index}
          clientGUID={clientGUID}
          versionGUID={version.versionGUID}
          name={version.name}
          environment={version.environment}
          status={version.status}
          author={version.author}
          displayNonDeployedVersions={displayNonDeployedVersions}
        />
      );
    });
  };

  render() {
    return (
      <TableWrapper>
        <ChubTable>
          <thead>{this.renderHeaderRow()}</thead>
          <VersionDisplayLoadingStateProviderContainer>
            {() => <ChubTableBody>{this.renderBodyRows()}</ChubTableBody>}
          </VersionDisplayLoadingStateProviderContainer>
        </ChubTable>
      </TableWrapper>
    );
  }
}
