import React, { Fragment, useEffect, useState } from 'react';
import { ChubH2 } from '../../../styles/typography';
import glamorous from 'glamorous';
import DefaultHeader from '../../../components/DefaultHeader';
import ClientSearchResultTable from '../components/ClientSearchResultTable';
import ClientCreator from '../components/ClientCreator';
import PropTypes from 'prop-types';
import { InternalServerErrorBrick } from 'react-chui';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import performSearch from './runSearch';

const SearchedForText = glamorous(ChubH2, { displayName: 'SearchedForText' })({
  marginLeft: '10px',
});

const ClientSearchResults = () => {
  const [searchResults, setSearchResults] = useState(null);
  const [catastrophicError, setCatastrophicError] = useState(false);
  const location = useLocation();
  const { state } = location;

  const [searchParams] = useSearchParams();

  const searchQuery = searchParams.get('searchString');

  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      const { searchResults, error } = state;
      if (error) {
        setCatastrophicError(true);
      } else {
        setSearchResults(searchResults);
      }
    } else {
      performSearch(searchQuery)
        .then((response) => {
          const { linkToNavigateTo, searchResults } = response;
          if (linkToNavigateTo) {
            navigate(linkToNavigateTo);
          } else {
            setSearchResults(searchResults);
          }
        })
        .catch(() => setCatastrophicError(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  if (searchResults) {
    const { searchByAS2ID, searchByAS2Url, searchByName } = searchResults;
    const exactNameMatch = searchByName && searchByName.exactMatch;
    return (
      <Fragment>
        <DefaultHeader showUndoRedo={false} />
        <SearchedForText>You searched for: {searchQuery}</SearchedForText>
        {!exactNameMatch && <ClientCreator {...{ searchQuery }} />}
        {searchByName && <ClientSearchResultTable resultType='Name' resultList={searchByName} />}
        {searchByAS2ID && <ClientSearchResultTable resultType='AS2ID' resultList={searchByAS2ID} />}
        {searchByAS2Url && <ClientSearchResultTable resultType='AS2Url' resultList={searchByAS2Url} />}
      </Fragment>
    );
  }

  if (catastrophicError) {
    return (
      <>
        <DefaultHeader showUndoRedo={false} />
        <InternalServerErrorBrick
          className={'smooth-operator-error'}
          emailSubject={'Support Request / Smooth Operator Server Error'}
          onTryAgain={() => navigate(`${location.pathname}?searchString=${searchQuery}`, { replace: true })}
          errorInfo={{ errorTimestamp: new Date().toISOString() }}
        />
      </>
    );
  }
  return <div>Loading...</div>;
};

ClientSearchResults.displayName = 'ClientSearchResults';
ClientSearchResults.propTypes = {
  searchQuery: PropTypes.string,
};

export default ClientSearchResults;
