import React, { Component, Fragment } from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import LoadingStateProviderContainer from './containers/LoadingStateProviderContainer';
import PageMessagesContainer from './containers/PageMessagesContainer';
import StoppableFilesTableContainer from './containers/StoppableFilesTableContainer';

export default class StoppableFiles extends Component {
  static displayName = 'StoppableFiles';

  render() {
    return (
      <Fragment>
        <DefaultHeader showSearch={true} />
        <PageMessagesContainer />
        <LoadingStateProviderContainer>{() => <StoppableFilesTableContainer />}</LoadingStateProviderContainer>
      </Fragment>
    );
  }
}
