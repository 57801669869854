import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { Bubble, colors } from 'react-chui';

import { ChubButton, chubStandards } from '../../../styles/typography';
import { CloseIcon } from '../../../styles/icons';
import CertificateInfoBlock from '../../../components/certificateInfo/CertificateInfoBlock';

const InfoBubble = glamorous(Bubble, { displayName: 'InfoBubble' })({
  width: '500px',
  maxHeight: '600px',
  overflow: 'scroll',
});

const BubbleHeader = glamorous(Bubble.Header, { displayName: 'BubbleHeader' })(chubStandards);

const CloseButton = glamorous(ChubButton, { displayName: 'CloseButton' })({
  background: 'none',
  padding: '0',
  color: colors.medblue,
  margin: '0 0 0 250px',
  ':hover': {
    background: 'none',
    color: colors.white,
  },
});

const BUBBLE_OPTIONS = {
  attachment: 'middle left',
  constraints: [],
};

const CertificateInfoBubble = ({ origValue, newValue, parentId, onRequestBubbleClose }) => {
  const { alias, subject, serialNumber, notBefore, notAfter, signatureAlgorithm, status } = newValue || origValue;
  return (
    <InfoBubble tetherOptions={BUBBLE_OPTIONS} onRequestClose={onRequestBubbleClose}>
      <BubbleHeader>
        Certificate Details
        <CloseButton id={`close-certificate-bubble-button-${parentId}`} onClick={onRequestBubbleClose}>
          <CloseIcon />
        </CloseButton>
      </BubbleHeader>
      <CertificateInfoBlock
        {...{ alias, subject, serialNumber, notBefore, notAfter, signatureAlgorithm, status, parentId }}
      />
    </InfoBubble>
  );
};

CertificateInfoBubble.displayName = 'CertificateInfoBubble';
CertificateInfoBubble.propTypes = {
  origValue: PropTypes.object,
  newValue: PropTypes.object,
  parentId: PropTypes.string,
  onRequestBubbleClose: PropTypes.func,
};

export default CertificateInfoBubble;
