import { newState } from '../../actions';

export function setSftpUsername(state, action) {
  const { username } = action;
  const { currentSection, sections } = state;
  const editedCurrentSection = {
    ...sections[currentSection],
    sftpConnection: {
      ...sections[currentSection].sftpConnection,
      username: {
        ...sections[currentSection].sftpConnection.username,
        newValue: username,
        changed: sections[currentSection].sftpConnection.username.origValue !== username,
      },
    },
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}
