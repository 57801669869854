import useSWR from 'swr';
import { fetcher } from '../../util/fetchClient';

const useEnvironments = () => {
  const { data, error } = useSWR(`/admin/environments`, fetcher);

  return {
    environments: data
      ? data.map((x: any) => ({
          name: x.environmentName.origValue,
          guid: x.environmentGUID.origValue,
          as2Gateways: x.as2Gateways.origValue,
        }))
      : [],
    isLoading: !error && !data,
    error,
  };
};

export default useEnvironments;
