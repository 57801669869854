import React, { useEffect } from 'react';

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { fetchAs2Identifiers } from './actions';

import As2Ids from './As2IdsApp';
import undoableAs2Id from './reducers';

const store = createStore(undoableAs2Id, applyMiddleware(thunkMiddleware));

const As2IdsEntry = () => {
  useEffect(() => {
    store.dispatch(fetchAs2Identifiers());
  }, []);

  return (
    <Provider store={store}>
      <As2Ids />
    </Provider>
  );
};

export default As2IdsEntry;
