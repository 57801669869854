export const requestFileRouteTypes = (state) => ({
  ...state,
  isLoading: true,
  fileRouteTypes: {},
});

export function requestFileRouteTypesFailed(state, { error }) {
  return {
    ...state,
    isLoading: false,
    error,
  };
}

export function requestFileRouteTypesComplete(state) {
  return {
    ...state,
    isLoading: false,
  };
}

export function saveFileRouteTypesLoading(state) {
  return {
    ...state,
    isSaving: true,
  };
}

export function saveFileRouteTypesCompleted(state) {
  return {
    ...state,
    isSaving: false,
  };
}

export function loadingError(state, { error }) {
  return {
    ...state,
    isSaving: false,
    isLoading: false,
    serverMessage: error,
  };
}
