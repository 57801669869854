import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { ChubInput, ChubP } from '../../../../styles/typography';
import { ChubTableBodyCell, ChubTableBodyRow } from '../../../../styles/table';
import { colors } from 'react-chui';

const EnvironmentInput = glamorous(ChubInput, { displayName: 'Input' })(
  {
    width: '100%',
    lineHeight: 2,
    fontSize: '13px',
  },
  ({ hasChanges, error }) => {
    const styles = {
      border: '0 none',
    };
    if (hasChanges) {
      styles.border = `1px solid ${colors.chgreen}`;
    } else if (error) {
      styles.border = `1px solid ${colors.chred}`;
    }
    return styles;
  }
);

const Message = glamorous(ChubP, { displayName: 'Message' })(({ hasChanges, isError }) => {
  const styles = {
    fontStyle: 'italic',
    fontSize: '12px',
    lineHeight: '18px',
    margin: 0,
  };
  if (hasChanges) {
    styles.color = colors.chgreen;
  } else if (isError) {
    styles.color = colors.chred;
  }
  return styles;
});

export default class EnvironmentRow extends Component {
  static displayName = 'EnvironmentRow';

  static propTypes = {
    readOnly: PropTypes.bool,
    environmentName: PropTypes.object,
    environmentGUID: PropTypes.object,
    as2Gateways: PropTypes.object,
    hasChanges: PropTypes.bool,
    serverMessages: PropTypes.string,
    createDate: PropTypes.string,
    updateDate: PropTypes.string,
    error: PropTypes.string,
    onUpdate: PropTypes.func,
  };

  state = {
    localEnvironmentName: '',
    localAS2Gateways: '',
  };

  componentWillMount() {
    const { environmentName, as2Gateways } = this.props;

    this.setState({
      localEnvironmentName: environmentName.value,
      localAS2Gateways: as2Gateways.value,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { environmentName, as2Gateways } = this.props;
    const payload = {};
    if (environmentName.value !== nextProps.environmentName.value) {
      payload.localEnvironmentName = nextProps.environmentName.value;
    }

    if (as2Gateways.value !== nextProps.as2Gateways.value) {
      payload.localAS2Gateways = nextProps.as2Gateways.value;
    }
    this.setState(payload);
  }

  _onChangeName = (event) => {
    this.setState({
      localEnvironmentName: event.target.value,
    });
  };

  _onChangeAS2Gateways = (event) => {
    this.setState({
      localAS2Gateways: event.target.value,
    });
  };

  _updateEnvironmentName = () => {
    const { onUpdate, environmentName, environmentGUID } = this.props;
    const { localEnvironmentName } = this.state;

    if (localEnvironmentName !== environmentName.value) {
      const payload = {
        environmentGUID: environmentGUID.origValue,
        newEnvironmentName: localEnvironmentName,
      };
      onUpdate(payload);
    }
  };

  _updateEnvironmentAS2Gateways = () => {
    const { onUpdate, environmentGUID, as2Gateways } = this.props;
    const { localAS2Gateways } = this.state;

    if (localAS2Gateways !== as2Gateways.value) {
      const payload = {
        environmentGUID: environmentGUID.origValue,
        newAS2Gateways: localAS2Gateways,
      };
      onUpdate(payload);
    }
  };

  _checkGatewaysDataFormat = () => {
    const { localAS2Gateways } = this.state;

    try {
      const gateways = JSON.parse(localAS2Gateways);
      if (!Array.isArray(gateways)) return false;

      return gateways.every((x) => x.name && x.url);
    } catch (ex) {
      return false;
    }
  };

  render() {
    const { readOnly, hasChanges, serverMessages, environmentGUID, createDate, updateDate, ...otherProps } = this.props;
    const { localEnvironmentName, localAS2Gateways } = this.state;
    const hasError = Boolean(serverMessages);

    const hasGatewayError = !this._checkGatewaysDataFormat();

    return (
      <ChubTableBodyRow {...otherProps}>
        <ChubTableBodyCell>
          <EnvironmentInput
            {...{ readOnly, hasChanges, hasError }}
            value={localEnvironmentName}
            onChange={this._onChangeName}
            onBlur={this._updateEnvironmentName}
            id={`environment-row-edit-input-${environmentGUID.value}`}
          />
          {hasError && (
            <Message id={`environment-row-error-${environmentGUID.value}`} isError>
              {serverMessages}
            </Message>
          )}
          {hasChanges && !hasError && (
            <Message id={`environment-row-unsaved-changes-${environmentGUID.value}`} hasChanges>
              Unsaved Changes
            </Message>
          )}
        </ChubTableBodyCell>
        <ChubTableBodyCell data-local-environment-name={localEnvironmentName}>
          {environmentGUID.value}
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EnvironmentInput
            {...{ readOnly, hasChanges }}
            value={localAS2Gateways}
            onChange={this._onChangeAS2Gateways}
            onBlur={this._updateEnvironmentAS2Gateways}
            id={`as2gateways-row-edit-input-${environmentGUID.value}`}
          />
          {hasGatewayError && (
            <Message id={`as2gateways-row-error-${environmentGUID.value}`} isError>
              {`AS2Gateways should have format: [{"name": string, "url": string, "preferred"?:boolean}]`}
            </Message>
          )}
          {hasChanges && !hasGatewayError && (
            <Message id={`as2gateways-row-unsaved-changes-${environmentGUID.value}`} hasChanges>
              Unsaved Changes
            </Message>
          )}
        </ChubTableBodyCell>
        <ChubTableBodyCell>{createDate}</ChubTableBodyCell>
        <ChubTableBodyCell>{updateDate}</ChubTableBodyCell>
      </ChubTableBodyRow>
    );
  }
}
