import {
  ADD_AS2ID,
  RECEIVE_AS2IDENTIFIERS,
  REQUEST_AS2IDENTIFIERS,
  REQUEST_SAVE_AS2IDENTIFIERS,
  SAVE_COMPLETE,
  SAVE_FAILED,
  UPDATE_AS2ID,
  REQUEST_AS2IDENTIFIERS_FAILED,
  DELETE_AS2ID,
} from '../As2IdActionTypes';
import cloneDeep from 'lodash.clonedeep';

const initialState = {
  isFetching: false,
  isSaving: false,
  listError: '',
  locallyDeletedAs2Ids: [],
  as2Identifiers: [],
};

function completeSave(state, { errors, editedAs2Ids = [], deletedAs2Ids = [] }) {
  const { as2Identifiers, locallyDeletedAs2Ids } = state;
  const saveCompleteAs2Identifiers = locallyDeletedAs2Ids.concat(as2Identifiers);

  const erroredAs2IdGuids = errors.map(({ systemIdentifierGUID, errorMessage }) => {
    saveCompleteAs2Identifiers.find(
      (as2Identifier) => as2Identifier.systemIdentifierGUID === systemIdentifierGUID
    ).error = errorMessage;
    return systemIdentifierGUID;
  });
  const successfulDeletedAs2IdGuids = deletedAs2Ids.reduce((accumulatedGuids, { systemIdentifierGUID }) => {
    if (!erroredAs2IdGuids.includes(systemIdentifierGUID)) {
      accumulatedGuids.push(systemIdentifierGUID);
    }
    return accumulatedGuids;
  }, []);
  const successfulEditedAs2Ids = editedAs2Ids.filter(
    ({ systemIdentifierGUID }) => !erroredAs2IdGuids.includes(systemIdentifierGUID)
  );
  successfulEditedAs2Ids.forEach(({ systemIdentifierGUID, as2ID, createDate, updateDate, author }) => {
    const changedAs2ID = saveCompleteAs2Identifiers.find(
      (as2Identifier) => as2Identifier.systemIdentifierGUID === systemIdentifierGUID
    );
    changedAs2ID.origAs2ID = as2ID;
    changedAs2ID.as2ID = as2ID;
    changedAs2ID.error = '';
    changedAs2ID.createDate = createDate;
    changedAs2ID.updateDate = updateDate;
    changedAs2ID.author = author;
    changedAs2ID.editMade = false;
  });

  const as2IdentifiersAfterSave = saveCompleteAs2Identifiers.filter(
    ({ systemIdentifierGUID }) => !successfulDeletedAs2IdGuids.includes(systemIdentifierGUID)
  );

  return {
    ...state,
    isSaving: false,
    as2Identifiers: as2IdentifiersAfterSave,
    locallyDeletedAs2Ids: [],
  };
}

function updateAs2Id(state, { payload: { as2ID, systemIdentifierGUID } }) {
  const stateCopy = cloneDeep(state);
  const as2IdentifierToUpdate = stateCopy.as2Identifiers.find((as2Identifier) => {
    return as2Identifier.systemIdentifierGUID === systemIdentifierGUID;
  });
  if (as2IdentifierToUpdate) {
    as2IdentifierToUpdate.as2ID = as2ID;
    as2IdentifierToUpdate.editMade = true;
  }
  return stateCopy;
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_AS2ID: {
      const { as2Identifiers } = state;
      const { deletedAs2Identifier } = action;
      const as2IdentifiersCopy = as2Identifiers.filter(
        ({ systemIdentifierGUID }) => systemIdentifierGUID !== deletedAs2Identifier.systemIdentifierGUID
      );
      return {
        ...state,
        as2Identifiers: as2IdentifiersCopy,
        locallyDeletedAs2Ids: [...state.locallyDeletedAs2Ids, deletedAs2Identifier],
      };
    }
    case REQUEST_AS2IDENTIFIERS: {
      return {
        ...state,
        isFetching: true,
        as2Identifiers: [],
      };
    }
    case RECEIVE_AS2IDENTIFIERS: {
      const { as2Identifiers } = action;
      return {
        ...state,
        isFetching: false,
        as2Identifiers,
      };
    }
    case UPDATE_AS2ID: {
      return updateAs2Id(state, action);
    }
    case ADD_AS2ID: {
      return {
        ...state,
        as2Identifiers: [action.payload, ...state.as2Identifiers],
      };
    }
    case REQUEST_SAVE_AS2IDENTIFIERS: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case SAVE_COMPLETE: {
      return completeSave(state, action);
    }
    case SAVE_FAILED: {
      const {
        error: { message: saveError },
      } = action;
      return {
        ...state,
        listError: saveError,
      };
    }
    case REQUEST_AS2IDENTIFIERS_FAILED: {
      const {
        error: { message: fetchError },
      } = action;
      return {
        ...state,
        listError: fetchError,
      };
    }
  }
  return state;
};

export default reducer;
