import React from 'react';
import glamorous, { GlamorousComponent } from 'glamorous';
import { ChubH3, ChubH4 } from '../../../../styles/typography';
import PropTypes from 'prop-types';
import FileDeliveryData from './FileDeliveryData';
import VLTransferData from './VLTransferData';
import { uuidv4 } from '../../../../mockable/uuidv4-wrapper';
// @ts-ignore
import { colors, InternalServerErrorBrick, Spinner } from 'react-chui';
import As2ForwarderData from './As2ForwarderData';
import {
  As2ForwarderRequestSummary,
  CommsTransferDataResponse,
  FileDeliveryServerData,
  VLTransferServerData,
} from '../CommsTransferData.model';
import { useFetch } from '../../../../hooks/fetch-hook';

const SearchResultsTitle: GlamorousComponent<any, any> = glamorous(ChubH3, { displayName: 'SearchResultsTitle' })({
  marginTop: '0px',
  marginBottom: '20px',
  fontSize: '24px',
  fontWeight: 600,
});

const Subtitle: GlamorousComponent<any, any> = glamorous(ChubH4, { displayName: 'SearchResultsTitle' })({
  marginTop: '30px',
  marginBottom: '20px',
  fontSize: '20px',
  fontWeight: 600,
});

const Text = glamorous.div({
  fontSize: '15px',
  color: colors.darkgray,
});

const NoRecords: GlamorousComponent<any, any> = glamorous(ChubH4, { displayName: 'NoRecords' })({
  marginTop: '30px',
  marginBottom: '20px',
  fontSize: '20px',
  fontWeight: 600,
});

const LoadingSpinner = glamorous(Spinner, {
  displayName: 'LoadingSpinner',
  withProps: {
    size: 'large',
  },
})({
  display: 'block',
  margin: '0 auto',
});

const containsData = <T,>(array?: Array<T>) => {
  return array && array.length;
};

const buildVlTransferDataComponents = (hasVlTransferErrors: boolean, vlTransferData?: VLTransferServerData[]) => {
  if (hasVlTransferErrors) {
    return <Text>Error retrieving AS2 transfer records</Text>;
  }
  return containsData(vlTransferData) ? (
    vlTransferData!.map((data) => <VLTransferData data={data} key={uuidv4()} />)
  ) : (
    <Text>No AS2 transfer records found</Text>
  );
};

const buildFileDeliveryDataComponents = (
  hasFileDeliveryDataErrors: boolean,
  fileDeliveryData?: FileDeliveryServerData[]
) => {
  if (hasFileDeliveryDataErrors) {
    return <Text>Error retrieving internal application handoffs</Text>;
  }
  return containsData(fileDeliveryData) ? (
    fileDeliveryData!.map((data) => <FileDeliveryData data={data} key={uuidv4()} />)
  ) : (
    <Text>No internal application handoffs found</Text>
  );
};

const buildAs2ForwarderDataComponents = (
  hasAs2ForwarderDataErrors: boolean,
  as2ForwarderData?: As2ForwarderRequestSummary[]
) => {
  if (hasAs2ForwarderDataErrors) {
    return <Text>Error retrieving AS2 Forwarder Data</Text>;
  }
  return containsData(as2ForwarderData) ? (
    as2ForwarderData!.map((data) => <As2ForwarderData data={data} key={uuidv4()} />)
  ) : (
    <Text>No AS2 Forwarder Data found</Text>
  );
};

const As2LookupSearchResults = ({ messageId }: { messageId: string }) => {
  const fetchUrl = `/files/${messageId}`;

  const { data, error, isLoading } = useFetch<CommsTransferDataResponse>(fetchUrl);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const hasCatastrophicError = error && error?.response?.status !== 404;

  if (hasCatastrophicError) {
    return (
      <InternalServerErrorBrick
        className={'smooth-operator-error'}
        emailSubject={'Support Request / Smooth Operator Server Error'}
        onTryAgain={() => window.location.reload()}
        errorInfo={{ errorTimestamp: new Date().toISOString() }}
      />
    );
  }

  const has404Error = error?.response?.status === 404;

  if (has404Error) {
    return <NoRecords id='no-results'>No transfer records found!</NoRecords>;
  }

  const commsTransferData = data?.commsTransferData;
  const commsTransferDataErrors = data?.errors;

  const vlTransferData = commsTransferData?.vlTransferData;
  const fileDeliveryData = commsTransferData?.fileDeliveryData;
  const as2ForwarderData = commsTransferData?.as2ForwarderData;

  const hasVlTransferErrors = commsTransferDataErrors?.vlTransferDataError;
  const hasFileDeliveryErrors = commsTransferDataErrors?.fileDeliveryDataError;
  const hasAs2ForwarderErrors = commsTransferDataErrors?.as2ForwarderDataError;

  const vlTransferDataComponents = buildVlTransferDataComponents(!!hasVlTransferErrors, vlTransferData);
  const fileDeliveryDataComponents = buildFileDeliveryDataComponents(!!hasFileDeliveryErrors, fileDeliveryData);
  const as2ForwarderDataComponents = buildAs2ForwarderDataComponents(!!hasAs2ForwarderErrors, as2ForwarderData);

  return (
    <>
      <SearchResultsTitle>Search Results</SearchResultsTitle>
      <Text>
        Transfer information for Message ID <b>{messageId}</b>
      </Text>
      <Subtitle>AS2 Transfers</Subtitle>
      {vlTransferDataComponents}
      <Subtitle>AS2 Forwarder Transfers</Subtitle>
      {as2ForwarderDataComponents}
      <Subtitle>Internal Handoffs</Subtitle>
      {fileDeliveryDataComponents}
    </>
  );
};

As2LookupSearchResults.displayName = 'As2LookupSearchResults';
As2LookupSearchResults.propTypes = {
  messageId: PropTypes.string.isRequired,
};

export default As2LookupSearchResults;
