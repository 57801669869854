export const SET_CLIENT_INFO = 'SET_CLIENT_INFO';
export const FETCH_CONFIGURATION_VIEW_MODEL_LOADING = 'FETCH_CONFIGURATION_VIEW_MODEL_LOADING';
export const FETCH_CONFIGURATION_VIEW_MODEL_COMPLETED = 'FETCH_CONFIGURATION_VIEW_MODEL_COMPLETED';
export const SAVE_CONFIGURATION_VIEW_MODEL_LOADING = 'SAVE_CONFIGURATION_VIEW_MODEL_LOADING';
export const SAVE_CONFIGURATION_VIEW_MODEL_COMPLETED = 'SAVE_CONFIGURATION_VIEW_MODEL_COMPLETED';
export const CONFIGURATION_DATA_REQUEST_FAILED = 'CONFIGURATION_DATA_REQUEST_FAILED';
export const RECEIVE_CONFIGURATION_VIEW_MODEL = 'RECEIVE_CONFIGURATION_SECTIONS';
export const RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE = 'RECEIVE_CONFIGURATION_VIEW_MODEL_UNDOABLE';
export const RESET_SECTION_CAN_SAVE = 'RESET_SECTION_CAN_SAVE';
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION';
export const SET_CURRENT_SECTION_UNDOABLE = 'SET_CURRENT_SECTION_UNDOABLE';
export const TOGGLE_VIEW_PARTNER_CERTIFICATE = 'TOGGLE_VIEW_PARTNER_CERTIFICATE';
export const TOGGLE_VIEW_PARTNER_SIGNING_CERTIFICATE_OVERRIDE = 'TOGGLE_VIEW_PARTNER_SIGNING_CERTIFICATE_OVERRIDE';
export const FETCH_GENERATED_SFTP_PASSWORD_FAILED = 'FETCH_GENERATED_PASSWORD_FAILED';
export const SET_CONFIGURATION_URL = 'SET_CONFIGURATION_URL';
export const SET_CONFIGURATION_PORT = 'SET_CONFIGURATION_PORT';
export const SET_CONFIGURATION_HTTPS = 'SET_CONFIGURATION_HTTPS';
export const SET_CONFIGURATION_MICALGORITHM = 'SET_CONFIGURATION_MICALGORITHM';
export const SET_MIC_ALGORITHM_LIST = 'SET_MIC_ALGORITHM_LIST';
export const SET_CHUB_SYSTEM_IDENTIFIER_LIST = 'SET_CHUB_SYSTEM_IDENTIFIER_LIST';
export const SET_CHUB_SYSTEM_CERTIFICATE_LIST = 'SET_CHUB_SYSTEM_CERTIFICATE_LIST';
export const SET_CHUB_SYSTEM_IDENTIFIER = 'SET_CHUB_SYSTEM_IDENTIFIER';
export const SET_AS2_FILE_ROUTE_LIST = 'SET_AS2_FILE_ROUTE_LIST';
export const SET_SFTP_FILE_ROUTE_LIST = 'SET_SFTP_FILE_ROUTE_LIST';
export const SET_PARTNER_SFTP_FILE_ROUTE_LIST = 'SET_PARTNER_SFTP_FILE_ROUTE_LIST';
export const SET_CHUB_SYSTEM_CERTIFICATE = 'SET_CHUB_SYSTEM_CERTIFICATE';
export const SET_AS2_ID = 'SET_AS2_ID';
export const SET_MAILBOX_NAME = 'SET_MAILBOX_NAME';
export const TOGGLE_FILE_ROUTE_VISIBILITY = 'TOGGLE_FILE_ROUTE_VISIBILITY';
export const SET_CONNECTION_ENABLED = 'SET_CONNECTION_ENABLED';
export const SET_FILE_ROUTE_KEY = 'SET_FILE_ROUTE_KEY';
export const SET_FILE_ROUTE_REGEX = 'SET_FILE_ROUTE_REGEX';
export const SET_FILE_ROUTE_EXTENSION = 'SET_FILE_ROUTE_EXTENSION';
export const SET_FILE_ROUTE_CONTENT_TYPE = 'SET_FILE_ROUTE_CONTENT_TYPE';
export const SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA = 'SET_FILE_ROUTE_ADDITIONAL_ROUTING_DATA';
export const SET_SFTP_USERNAME = 'SET_SFTP_USERNAME';
export const SET_FILE_ROUTE_MD5_VERIFIED = 'SET_FILE_ROUTE_MD5_VERIFIED';
export const SET_SUB_PATH = 'SET_SUB_PATH';
export const ADD_SUB_PATH = 'ADD_SUB_PATH';
export const DELETE_SUB_PATH = 'DELETE_SUB_PATH';
export const ADD_NEW_AS2_FILE_ROUTE = 'ADD_NEW_AS2_FILE_ROUTE';
export const ADD_NEW_SFTP_FILE_ROUTE = 'ADD_NEW_SFTP_FILE_ROUTE';
export const ADD_NEW_PARTNER_SFTP_FILE_ROUTE = 'ADD_NEW_PARTNER_SFTP_FILE_ROUTE';
export const DELETE_FILE_ROUTE = 'DELETE_FILE_ROUTE';
export const ADD_AS2_CONNECTION = 'ADD_AS2_CONNECTION';
export const ADD_SFTP_CONNECTION = 'ADD_SFTP_CONNECTION';
export const ADD_PARTNER_SFTP_CONNECTION = 'ADD_PARTNER_SFTP_CONNECTION';
export const COPY_SECTION = 'COPY_SECTION';
export const SET_COPYABLE_ENVIRONMENTS = 'SET_COPYABLE_ENVIRONMENTS';
export const SET_VERSION_NAME = 'SET_VERSION_NAME';
export const FETCH_RELATIONSHIPS_LOADING = 'FETCH_RELATIONSHIPS_LOADING';
export const FETCH_RELATIONSHIPS_COMPLETED = 'FETCH_RELATIONSHIPS_COMPLETED';
export const FETCH_RELATIONSHIPS_FAILED = 'FETCH_RELATIONSHIPS_FAILED';
export const TOGGLE_SHOW_RELATIONSHIPS = 'TOGGLE_SHOW_RELATIONSHIPS';
export const RECEIVE_RELATIONSHIPS = 'RECEIVE_RELATIONSHIPS';
export const ADD_RELATIONSHIP = 'ADD_RELATIONSHIP';
export const SEND_TEST_FILE_PROCESSING = 'SEND_TEST_FILE_PROCESSING';
export const SEND_TEST_FILE_COMPLETED = 'SEND_TEST_FILE_COMPLETED';
export const SEND_TEST_FILE_FAILED = 'SEND_TEST_FILE_FAILED';
export const SET_ALLOW_DUPLICATE_MESSAGE_IDS = 'SET_ALLOW_DUPLICATE_MESSAGE_IDS';
export const SET_AUTHENTICATION_CERT_HREF = 'SET_AUTHENTICATION_CERT_HREF';
export const SET_AUTHENTICATION_CERT_PASSWORD = 'SET_AUTHENTICATION_CERT_PASSWORD';
export const SET_SECURITY_AUTHENTICATION_PASSWORD = 'SET_SECURITY_AUTHENTICATION_PASSWORD';
export const SET_SECURITY_AUTHENTICATION_REALM = 'SET_SECURITY_AUTHENTICATION_REALM';
export const SET_SECURITY_AUTHENTICATION_TYPE = 'SET_SECURITY_AUTHENTICATION_TYPE';
export const SET_SECURITY_AUTHENTICATION_USERNAME = 'SET_SECURITY_AUTHENTICATION_USERNAME';
export const SET_CONNECTION_TIMEOUT = 'SET_CONNECTION_TIMEOUT';
export const SET_SSL_ALLOW_LEGACY_RENEGOTIATION = 'SET_SSL_ALLOW_LEGACY_RENEGOTIATION';
export const SET_SSL_CIPHER = 'SET_SSL_CIPHER';
export const SET_SSL_MINIMUM_PROTOCOL_VERSION = 'SET_SSL_MINIMUM_PROTOCOL_VERSION';
export const SET_SSL_MAXIMUM_PROTOCOL_VERSION = 'SET_SSL_MAXIMUM_PROTOCOL_VERSION';
export const SET_OUTGOING_RECEIPT_ASYNC_RESENDS = 'SET_OUTGOING_RECEIPT_ASYNC_RESENDS';
export const SET_OUTGOING_RECEIPT_ASYNC_RETRY_DELAY = 'SET_OUTGOING_RECEIPT_ASYNC_RETRY_DELAY';
export const SET_OUTGOING_RECEIPT_ASYNC_TIMEOUT = 'SET_OUTGOING_RECEIPT_ASYNC_TIMEOUT';
export const DELETE_AS2_CONNECTION = 'DELETE_AS2_CONNECTION';
export const DELETE_PARTNER_SFTP_CONNECTION = 'DELETE_PARTNER_SFTP_CONNECTION';
export const RESET_STATE = 'RESET_STATE';
export const SET_AS2GATEWAY_ENABLED = 'SET_AS2GATEWAY_ENABLED';
export const SET_AS2GATEWAY = 'SET_AS2GATEWAY';
export const SET_PARTNER_SFTP_CONFIGURATION_URL = 'SET_PARTNER_SFTP_CONFIGURATION_URL';
export const SET_PARTNER_SFTP_USERNAME = 'SET_PARTNER_SFTP_USERNAME';
export const SET_PARTNER_SFTP_CONFIGURATION_PORT = 'SET_PARTNER_SFTP_CONFIGURATION_PORT';
export const SET_PARTNER_SFTP_PASSWORD = 'SET_PARTNER_SFTP_PASSWORD';
export const SET_CLIENT_AUTH_CERT = 'SET_CLIENT_AUTH_CERT';
export const SET_CLIENT_AUTH_CERT_PASSWORD = 'SET_CLIENT_AUTH_CERT_PASSWORD';
export const SET_CLIENT_AUTH_PRIVATE_KEY_FILE = 'SET_CLIENT_AUTH_PRIVATE_KEY_FILE';
export const SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD = 'SET_CLIENT_AUTH_PRIVATE_KEY_FILE_PASSWORD';
