import useSWR from 'swr';
import { fetcher } from '../../util/fetchClient';

const useCertificates = () => {
  const { data, error } = useSWR('/config/certificates', fetcher);

  return {
    certificates: data?.chubSystemCertificates,
    isLoading: !error && !data,
    error,
  };
};

export default useCertificates;
