import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { Bubble, colors } from 'react-chui';

import { ChubButton, ChubP, chubStandards } from '../../../styles/typography';
import { CloseIcon } from '../../../styles/icons';
import ChubCertificateInfoBlock from '../../../components/certificateInfo/ChubExportInfoBlock';

const InfoBubble = glamorous(Bubble, { displayName: 'InfoBubble' })({
  width: '500px',
  maxHeight: '600px',
  overflowY: 'scroll',
  overflowX: 'hidden',
});

const CopyInfoButton = glamorous(ChubButton, { displayName: 'ViewButton' })(() => ({
  marginBottom: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  ':hover': {
    background: '#1d82ff',
  },
  display: 'block',
}));

const Message = glamorous(ChubP, { displayName: 'Message' })({
  fontSize: '14px',
  marginBottom: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
  textAlign: 'center',
  color: '#535454',
});

const BubbleHeader = glamorous(Bubble.Header, { displayName: 'BubbleHeader' })(chubStandards);

const CloseButton = glamorous(ChubButton, { displayName: 'CloseButton' })({
  background: 'none',
  padding: '0',
  color: colors.medblue,
  margin: '0 0 0 250px',
  ':hover': {
    background: 'none',
    color: colors.white,
  },
});

const BUBBLE_OPTIONS = {
  attachment: 'middle right',
  constraints: [],
};

const copyToClipboard = (as2ExportProps, onCopiedClick) => {
  const {
    as2Id,
    url,
    connectionEnabled,
    micAlgorithm,
    chubAs2Id,
    displaySigningCertificateOverride,
    partnerCertificate: { alias, subject, notBefore, notAfter, signatureAlgorithm, status, clientSerialNumber },
    signingCertificateOverride: { alias: signingCertificateAlias, subject: signingCertificateSubject,
      notBefore: signingCertificateNotBefore, notAfter: signingCertificateNotAfter,
      signatureAlgorithm: signingCertificateSignatureAlgorithm, status: signingCertificateStatus,
      serialNumber: signingCertificateSerialNumber },
    chubCertificate: { author, name, authority, createDate, updateDate, chubSerialNumber },
  } = as2ExportProps;

  const partnerSigningCertificateOverrideText = displaySigningCertificateOverride ?
      `
    Partner Signing Certificate Override:
        Alias: ${signingCertificateAlias}
        Subject: ${signingCertificateSubject}
        Serial Number: ${signingCertificateSerialNumber}
        Not Before: ${signingCertificateNotBefore}
        Not After: ${signingCertificateNotAfter}
        Signature Algorithm: ${signingCertificateSignatureAlgorithm}
        Status: ${signingCertificateStatus}`: ''

  const textField = document.createElement('textarea');

  textField.innerHTML = `AS2 Info:
    AS2 ID: ${as2Id}
    Enabled: ${connectionEnabled}
    Mic Algorithm: ${micAlgorithm}
    URL: ${url}
    Partner Certificate:
        Alias: ${alias}
        Subject: ${subject}
        Serial Number: ${clientSerialNumber}
        Not Before: ${notBefore}
        Not After: ${notAfter}
        Signature Algorithm: ${signatureAlgorithm}
        Status: ${status}
    CommerceHub Certificate:
        Name: ${name}
        Author: ${author}
        Authority: ${authority}
        CommerceHub AS2 ID: ${chubAs2Id}
        Serial Number: ${chubSerialNumber}
        Create Date: ${createDate}
        Update Date: ${updateDate}${partnerSigningCertificateOverrideText}
        `;

  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  onCopiedClick(true);
};

const ExportAS2Bubble = ({ as2ExportProps, parentId, onClickViewExportBubble, copiedToClipboard, onCopiedClick }) => {
  return (
    <InfoBubble
      id={`export-info-bubble-${parentId}`}
      tetherOptions={BUBBLE_OPTIONS}
      onRequestClose={onClickViewExportBubble}
    >
      <BubbleHeader>
        AS2 Configuration
        <CloseButton id={`close-export-bubble-button-${parentId}`} onClick={onClickViewExportBubble}>
          <CloseIcon />
        </CloseButton>
      </BubbleHeader>
      <CopyInfoButton id={`export-as2-${parentId}`} onClick={() => copyToClipboard(as2ExportProps, onCopiedClick)}>
        Copy Info
      </CopyInfoButton>
      {copiedToClipboard && <Message id={`copied-export-${parentId}`}>Saved to clipboard.</Message>}
      <ChubCertificateInfoBlock {...{ as2ExportProps, parentId }} />
    </InfoBubble>
  );
};

ExportAS2Bubble.displayName = 'ExportAS2Bubble';
ExportAS2Bubble.propTypes = {
  parentId: PropTypes.string,
  onClickViewExportBubble: PropTypes.func,
  onCopiedClick: PropTypes.func,
  as2ExportProps: PropTypes.object,
  copiedToClipboard: PropTypes.bool,
};

export default ExportAS2Bubble;
