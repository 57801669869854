import React from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { ChubP } from '../../../../styles/typography';
import { ExpandIcon } from '../../../../styles/icons';

const CollapsedFileRoutesDiv = glamorous('div', { displayName: 'CollapsedFileRoutesDiv' })({
  margin: '0px 0px 0px 0px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TotalLabelFlex = glamorous('div', { displayName: 'TotalLabelFlex' })({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const RightColumnFlex = glamorous('div', { displayName: 'RightColumnFlex' })({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
});

const TotalLabel = glamorous(ChubP, { displayName: 'TotalLabel' })({
  fontSize: '20px',
  marginLeft: '10px',
});

const BoldLabel = glamorous(ChubP, { displayName: 'BoldLabel' })({
  fontSize: '20px',
  fontWeight: 'bold',
  marginLeft: '15px',
});

const ExpandChiconButton = glamorous('button', { displayName: 'ExpandChiconButton' })({
  border: 'none',
  background: 'transparent',
  cursor: 'pointer',
  boxShadow: 'none',
  marginRight: '5px'
});

const CollapsedFileRoutes = ({ connectionType, fileRoutes, onToggleExpanded, parentId }) => (
  <CollapsedFileRoutesDiv>
    <BoldLabel>File Routes</BoldLabel>
    <TotalLabelFlex>
      <TotalLabel>Total:</TotalLabel>
      <TotalLabel id={`total${connectionType}s-${parentId}`}>
        {Object.keys(fileRoutes).filter((key) => !fileRoutes[key].deleted).length}
      </TotalLabel>
    </TotalLabelFlex>
    <RightColumnFlex>
      <ExpandChiconButton id={`${connectionType}-expand-file-route-button-${parentId}`} onClick={onToggleExpanded}>
        <ExpandIcon />
      </ExpandChiconButton>
    </RightColumnFlex>
  </CollapsedFileRoutesDiv>
);
CollapsedFileRoutes.displayName = 'CollapsedFileRoutes';
CollapsedFileRoutes.propTypes = {
  connectionType: PropTypes.string,
  fileRoutes: PropTypes.object,
  onToggleExpanded: PropTypes.func,
  parentId: PropTypes.string,
};
CollapsedFileRoutes.defaultProps = {
  fileRoutes: {},
};

export default CollapsedFileRoutes;
