import { connect } from 'react-redux';
import { changeNumberOfSFTPRequests } from '../actions';

import SFTPTransfersBrick from '../components/SFTPTransfersBrick';

const TRANSFERS_RESULTS_BASE_URL = '/transfers/sftp/page';

const mapStateToProps = (state) => {
  const {
    versionData: {
      client: { clientName, clientGUID },
    },
    sftpTransfersBrick: { numberOfTransfers },
  } = state;
  return {
    numberOfTransfers,
    createLookupTransfersButtonUrl: (environmentName) =>
      `${TRANSFERS_RESULTS_BASE_URL}/${clientName}/${clientGUID}/${numberOfTransfers}/${environmentName}`,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeNumberOfRequests: (event) => {
      if (event && event.target) {
        dispatch(changeNumberOfSFTPRequests(event.target.value));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SFTPTransfersBrick);
