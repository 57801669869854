import {
  CHANGE_NUMBER_OF_AS2_REQUESTS,
  CHANGE_NUMBER_OF_SFTP_REQUESTS,
  CHANGE_NUMBER_OF_PARTNER_SFTP_REQUESTS,
  TOGGLE_NONDEPLOYED_VERSIONS,
  RECEIVE_NONDEPLOYED_VERSIONS,
  REQUEST_NONDEPLOYED_VERSIONS_FAILED,
  RECEIVE_VERSION_DATA,
  REQUEST_VERSION_DATA_FAILED,
  RESET_STATE,
} from '../ClientOverviewActionTypes';
import { fetchClient } from '../../../util/fetchClient';

export function changeNumberOfAS2Requests(newValueRaw) {
  return {
    type: CHANGE_NUMBER_OF_AS2_REQUESTS,
    newValueRaw,
  };
}

export function changeNumberOfSFTPRequests(newValueRaw) {
  return {
    type: CHANGE_NUMBER_OF_SFTP_REQUESTS,
    newValueRaw,
  };
}

export function changeNumberOfPartnerSFTPRequests(newValueRaw) {
  return {
    type: CHANGE_NUMBER_OF_PARTNER_SFTP_REQUESTS,
    newValueRaw,
  };
}

export function toggleNonDeployed() {
  return {
    type: TOGGLE_NONDEPLOYED_VERSIONS,
  };
}

function receiveNonDeployedVersions(nonDeployedVersionsResponse = []) {
  return {
    type: RECEIVE_NONDEPLOYED_VERSIONS,
    nonDeployedVersionsResponse,
  };
}

function receiveClientVersionData(versionDataResponse = []) {
  return {
    type: RECEIVE_VERSION_DATA,
    versionDataResponse,
  };
}

function requestClientVersionDataFailed(error) {
  return {
    type: REQUEST_VERSION_DATA_FAILED,
    error,
  };
}

export function resetStateThenFetchClientVersionData(clientGUID) {
  return (dispatch) => {
    dispatch(resetState());
    return fetchClient
      .get(`/client/overview/${clientGUID}`)
      .then((response) => response.data)
      .then((clientVersionData) => {
        dispatch(receiveClientVersionData(clientVersionData));
      })
      .catch((error) => dispatch(requestClientVersionDataFailed(error)));
  };
}

function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function fetchNonDeployedVersions(clientGUID) {
  return (dispatch, getState) => {
    const {
      versionData: { includesNonDeployedVersions },
    } = getState();
    if (!includesNonDeployedVersions) {
      return fetchClient
        .get(`/client/nonDeployedVersions/${clientGUID}`, {})
        .then((response) => response.data)
        .then(({ nonDeployedVersions }) => {
          dispatch(receiveNonDeployedVersions(nonDeployedVersions));
        })
        .catch((error) => dispatch(fetchNonDeployedVersionsFailed(error)));
    }
    return null;
  };
}

function fetchNonDeployedVersionsFailed(error) {
  return {
    type: REQUEST_NONDEPLOYED_VERSIONS_FAILED,
    error,
  };
}
