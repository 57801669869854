import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { debounce } from '../mockable/debounce-wrapper';
import { ChubTextarea, selectableElement } from '../styles/typography';

const ConfigurationTextarea = glamorous(ChubTextarea, { displayName: 'ConfigurationTextarea' })(
    ({ small, medium, large, fontSize, width }) => {
        const baseStyles = {
            margin: '10px',
            ':disabled': {
                ...selectableElement,
                MozBoxShadow: 'none',
                WebkitBoxShadow: 'none',
                background: 'none',
                boxShadow: 'none',
                margin: '0 0 5px 0',
            },
        };

        if (small) {
            baseStyles.width = '75px';
        } else if (medium) {
            baseStyles.width = '300px';
        } else if (large) {
            baseStyles.width = '400px';
        } else if (width) {
            baseStyles.width = width;
        }

        if (fontSize) {
            baseStyles.fontSize = fontSize;
        }

        return baseStyles;
    }
);

const InputContainer = glamorous('div', { displayName: 'InputContainer' })(() => {
    return {
        fontStyle: 'italic',
        fontSize: '12px',
        lineHeight: '18px',
        margin: 0,
        display: 'inline-block',
    };
});

export default class EditableTextAreaInput extends Component {
    static displayName = 'EditableTextAreaInput';
    static propTypes = {
        origValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isModified: PropTypes.bool,
        onChange: PropTypes.func,
        changed: PropTypes.bool,
        readOnly: PropTypes.bool,
        debounceWaitMS: PropTypes.number,
        usage: PropTypes.string,
    };

    static defaultProps = {
        onChange: () => undefined,
        debounceWaitMS: 500,
    };

    constructor(props) {
        super(props);
        const { origValue, newValue, debounceWaitMS } = props;
        this.state = {
            inputValue: typeof newValue === 'undefined' ? origValue : newValue,
        };
        this._onChangeCommit = debounce((value, id) => {
            const { onChange } = this.props;
            onChange(value, id);
        }, debounceWaitMS);
    }

    componentWillReceiveProps(nextProps) {
        const { origValue, newValue } = nextProps;
        const { origValue: currentOrigValue, newValue: currentNewValue } = this.props;
        const { inputValue } = this.state;
        if (origValue !== currentOrigValue || newValue !== currentNewValue || newValue !== inputValue) {
            const adjustedNewValue = newValue === null ? '' : newValue;
            this.setState({
                inputValue: typeof newValue === 'undefined' ? origValue : adjustedNewValue,
            });
        }
    }

    _onChange = ({ target: { value, id } }) => {
        this.setState({
            inputValue: value,
        });
        this._onChangeCommit(value, id);
    };

    render() {
        const { readOnly, usage, ...otherProps } = this.props;
        const { inputValue } = this.state;
        return (
            <InputContainer>
                {readOnly ? (
                    <ConfigurationTextarea
                        title={inputValue}
                        {...otherProps}
                        value={inputValue}
                        onChange={this._onChange}
                        usage={usage}
                        disabled
                    />
                ) : (
                    <ConfigurationTextarea
                        title={inputValue}
                        {...otherProps}
                        value={inputValue}
                        onChange={this._onChange}
                        usage={usage}
                    />
                )}
            </InputContainer>
        );
    }
}
