import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { chubStandards, ChubButton } from '../../../styles/typography';
import ClientSummaryContainer from '../containers/ClientSummaryContainer';
import VersionTableContainer from '../containers/VersionTableContainer';
import AS2TransfersBrickContainer from '../containers/AS2TransfersBrickContainer';
import SFTPTransfersBrickContainer from '../containers/SFTPTransfersBrickContainer';
// eslint-disable-next-line no-unused-vars
import PartnerSFTPTransfersBrickContainer from '../containers/PartnerSFTPTransfersBrickContainer';

const VersionSummaryElement = glamorous.div(chubStandards, {
  backgroundColor: 'white',
  borderRadius: '3px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
  margin: '30px 3% 20px 3%',
  height: '350px',
  whiteSpace: 'nowrap',
});

const NonDeployedToggle = glamorous('input', { displayName: 'NonDeployedToggle' })({
  display: 'inline-block',
  margin: '1em',
  cursor: 'pointer',
  verticalAlign: 'middle',
});

const NonDeployedToggleLabel = glamorous('div', { displayName: 'NonDeployedToggleLabel' })({
  display: 'inline-block',
  lineHeight: '25px',
  width: '9%',
  marginRight: '1%',
  verticalAlign: 'middle',
  whiteSpace: 'normal',
});

const ViewButton = glamorous(ChubButton, { displayName: 'ViewButton' })({
  display: 'inline-block',
  height: '50px',
  width: '100px',
  marginRight: '20px',
});

const NoVersionsTextWrapper = glamorous('h3', { displayName: 'NoVersionsTextWrapper' })({
  textAlign: 'center',
  paddingTop: '40px',
});

const PageWidthWrapper = glamorous('div', { displayName: 'PageWidthWrapper' })({
  margin: '0px auto 20px auto',
  overflowX: 'hidden',
  paddingTop: '15px',
});

const SectionTitle = glamorous('h3', { displayName: 'SectionTitle' })(chubStandards, {
  size: '24px',
  textAlign: 'left',
  margin: '0 20px',
});

const SameLineWrapper = glamorous('div', { dispalyName: 'SameLineWrapper' })({
  display: 'inline-block',
});

const RightJustifyDiv = glamorous('div', { displayName: 'RightJustifyDiv' })({
  display: 'flex',
  flexGrow: '1',
  alignItems: 'baseline',
  justifyContent: 'space-between',
});

export default class VersionSummary extends Component {
  static displayName = 'VersionSummary';

  static propTypes = {
    deployedVersions: PropTypes.array,
    nonDeployedVersions: PropTypes.array,
    client: PropTypes.object,
    displayNonDeployedVersions: PropTypes.bool,
    onVersionButtonClick: PropTypes.func,
    toggleNonDeployedClick: PropTypes.func,
  };

  displayVersions = () => {
    const { deployedVersions, nonDeployedVersions, client, displayNonDeployedVersions, onVersionButtonClick } =
      this.props;
    const versionsExist = deployedVersions.length > 0 || nonDeployedVersions.length > 0;
    if (versionsExist) {
      return (
        <VersionSummaryElement>
          <PageWidthWrapper>
            <RightJustifyDiv>
              <SameLineWrapper>
                <SectionTitle>Configuration</SectionTitle>
              </SameLineWrapper>
              <SameLineWrapper>
                <ViewButton onClick={onVersionButtonClick}>View</ViewButton>
              </SameLineWrapper>
            </RightJustifyDiv>
          </PageWidthWrapper>
          <NonDeployedToggle
            id='non-deployed-toggle'
            type='checkbox'
            onChange={this._onNonDeployedToggleChange}
            checked={displayNonDeployedVersions}
          />
          <NonDeployedToggleLabel>Show non-deployed versions</NonDeployedToggleLabel>
          <VersionTableContainer />
        </VersionSummaryElement>
      );
    } else {
      return (
        <VersionSummaryElement>
          <NoVersionsTextWrapper id='no-versions-text'>
            No Versions found for client {client.clientName}!
          </NoVersionsTextWrapper>
        </VersionSummaryElement>
      );
    }
  };

  _onNonDeployedToggleChange = () => {
    const { client, toggleNonDeployedClick } = this.props;
    toggleNonDeployedClick(client.clientGUID);
  };

  componentWillMount() {
    const { toggleNonDeployedClick, client, deployedVersions } = this.props;
    // If there are no deployed versions to display, show non-deployed versions by default
    if (deployedVersions.length === 0) {
      toggleNonDeployedClick(client.clientGUID);
    }
  }

  render() {
    return (
      <Fragment>
        <ClientSummaryContainer />
        {this.displayVersions()}
        <AS2TransfersBrickContainer />
        <SFTPTransfersBrickContainer />
        {/* // TODO */}
        {/* // Commented out for now until we can filter the transfers */}
        {/* // to only show PartnerSFTP */}
        {/*<PartnerSFTPTransfersBrickContainer />*/}
      </Fragment>
    );
  }
}
