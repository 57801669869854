import React, { Component, Fragment } from 'react';
import { Select } from 'react-chui';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { ChubInput, BoldLabel, OptionalLabel } from '../../../styles/typography';
import EditableField from '../../../components/EditableField';
import EditableIdSelect from './EditableIdSelect';
import ConfigurationLabel from './ConfigurationLabel';
import EditableTextInput from '../../../components/EditableTextInput';
import UploadCertificate from "./UploadCertificate";

const AdvancedOptions = glamorous('div', { displayName: 'AdvancedOptions' })({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  verticalAlign: 'baseline',
});

const UnorderedList = glamorous('ul', { displayName: 'UnorderedList' })({
  listStyle: 'none',
});

const ListItem = glamorous('li', { displayName: 'ListItem' })({
  marginTop: '5px',
  marginBottom: '5px',
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'baseline',
  minHeight: '40px',
});

const Input = glamorous(ChubInput, { displayName: 'Input' })({
  marginLeft: '10px',
  backgroundColor: '#F0F3F7',
});

const TextInput = glamorous(EditableTextInput, { displayName: 'TextInput' })({
  marginLeft: '10px',
  backgroundColor: '#F0F3F7',
});

export default class ExpandedAdvancedOptions extends Component {
  static displayName = 'ExpandedAdvancedOptions';

  static propTypes = {
    parentId: PropTypes.string,
    allowDuplicateMessageIds: PropTypes.object,
    authenticationCertHref: PropTypes.object,
    authenticationCertPassword: PropTypes.object,
    chubSystemCertificates: PropTypes.array,
    securityAuthenticationPassword: PropTypes.object,
    securityAuthenticationRealm: PropTypes.object,
    securityAuthenticationType: PropTypes.object,
    securityAuthenticationUsername: PropTypes.object,
    connectionTimeout: PropTypes.object,
    sslAllowLegacyRenegotiation: PropTypes.bool,
    sslMinimumProtocolVersion: PropTypes.object,
    sslMaximumProtocolVersion: PropTypes.object,
    sslCipher: PropTypes.object,
    outgoingReceiptAsyncResends: PropTypes.object,
    outgoingReceiptAsyncRetryDelay: PropTypes.object,
    outgoingReceiptAsyncTimeout: PropTypes.object,
    onChangeAllowDuplicateMessageIds: PropTypes.func,
    onChangeAuthenticationCertHref: PropTypes.func,
    onChangeAuthenticationCertPassword: PropTypes.func,
    onChangeSecurityAuthenticationPassword: PropTypes.func,
    onChangeSecurityAuthenticationRealm: PropTypes.func,
    onChangeSecurityAuthenticationType: PropTypes.func,
    onChangeSecurityAuthenticationUsername: PropTypes.func,
    onChangeConnectionTimeout: PropTypes.func,
    onChangeSslAllowLegacyRenegotiation: PropTypes.func,
    onChangeSslCipher: PropTypes.func,
    onChangeSslMinimumProtocolVersion: PropTypes.func,
    onChangeSslMaximumProtocolVersion: PropTypes.func,
    onChangeOutgoingReceiptAsyncResends: PropTypes.func,
    onChangeOutgoingReceiptAsyncRetryDelay: PropTypes.func,
    onChangeOutgoingReceiptAsyncTimeout: PropTypes.func,
    onSigningCertificateOverrideRequestBubbleClose: PropTypes.func,
    onClickViewPartnerSigningCertificateOverride: PropTypes.func,
    onClickRemoveSigningCertificateOverride: PropTypes.func,
    uploadPartnerSigningCertificateOverride: PropTypes.func,
    viewPartnerSigningCertificateOverride: PropTypes.bool,
    advancedOptionsVisible: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { allowDuplicateMessageIds, sslAllowLegacyRenegotiation } = props;

    this.state = {
      allowDuplicateMessageIdsChecked: allowDuplicateMessageIds.origValue,
      sslAllowLegacyRenegotiationChecked: sslAllowLegacyRenegotiation.origValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { allowDuplicateMessageIds, sslAllowLegacyRenegotiation } = nextProps;
    const {
      allowDuplicateMessageIds: {
        origValue: allowDuplicateMessageIdsCurrentOrigValue,
        newValue: allowDuplicateMessageIdsCurrentNewValue,
      },
      sslAllowLegacyRenegotiation: {
        origValue: sslAllowLegacyRenegotiationCurrentOrigValue,
        newValue: sslAllowLegacyRenegotiationCurrentNewValue,
      },
    } = this.props;
    const { allowDuplicateMessageIdsChecked, sslAllowLegacyRenegotiationChecked } = this.state;
    if (
      allowDuplicateMessageIds.origValue !== allowDuplicateMessageIdsCurrentOrigValue ||
      allowDuplicateMessageIds.newValue !== allowDuplicateMessageIdsCurrentNewValue ||
      allowDuplicateMessageIds.newValue !== allowDuplicateMessageIdsChecked ||
      sslAllowLegacyRenegotiation.origValue !== sslAllowLegacyRenegotiationCurrentOrigValue ||
      sslAllowLegacyRenegotiation.newValue !== sslAllowLegacyRenegotiationCurrentNewValue ||
      sslAllowLegacyRenegotiation.newValue !== sslAllowLegacyRenegotiationChecked
    ) {
      this.setState({
        allowDuplicateMessageIdsChecked:
          typeof allowDuplicateMessageIds.newValue === 'undefined'
            ? allowDuplicateMessageIds.origValue
            : allowDuplicateMessageIds.newValue,
        sslAllowLegacyRenegotiationChecked:
          typeof sslAllowLegacyRenegotiation.newValue === 'undefined'
            ? sslAllowLegacyRenegotiation.origValue
            : sslAllowLegacyRenegotiation.newValue,
      });
    }
  }

  _allowDuplicateMessageIdsChecked = () => {
    const {
      allowDuplicateMessageIds: { origValue, newValue },
    } = this.props;
    return typeof newValue === 'undefined' ? origValue : newValue;
  };

  _onChangeAllowDuplicateMessageIds = ({ target: { checked }, currentTarget: { id } }) => {
    const { onChangeAllowDuplicateMessageIds } = this.props;
    onChangeAllowDuplicateMessageIds(checked, id);
  };

  _onChangeAuthenticationCertHref = (value, guid, isModified, id) => {
    const { onChangeAuthenticationCertHref } = this.props;
    onChangeAuthenticationCertHref(
      guid === 'NONE' ? undefined : value,
      guid === 'NONE' ? undefined : guid,
      isModified,
      id
    );
  };

  _onChangeAuthenticationCertPassword = (value, id) => {
    const { onChangeAuthenticationCertPassword } = this.props;
    onChangeAuthenticationCertPassword(value, id);
  };

  _onChangeSecurityAuthenticationPassword = (value, id) => {
    const { onChangeSecurityAuthenticationPassword } = this.props;
    onChangeSecurityAuthenticationPassword(value, id);
  };

  _onChangeSecurityAuthenticationRealm = (value, id) => {
    const { onChangeSecurityAuthenticationRealm } = this.props;
    onChangeSecurityAuthenticationRealm(value, id);
  };

  _onChangeSecurityAuthenticationType = (value, id) => {
    const { onChangeSecurityAuthenticationType } = this.props;
    onChangeSecurityAuthenticationType(value, id);
  };

  _onChangeSecurityAuthenticationUsername = (value, id) => {
    const { onChangeSecurityAuthenticationUsername } = this.props;
    onChangeSecurityAuthenticationUsername(value, id);
  };

  _onChangeConnectionTimeout = (value, id) => {
    const { onChangeConnectionTimeout } = this.props;
    onChangeConnectionTimeout(value, id);
  };

  _sslAllowLegacyRenegotiationChecked = () => {
    const {
      sslAllowLegacyRenegotiation: { origValue, newValue },
    } = this.props;
    return typeof newValue === 'undefined' ? origValue : newValue;
  };

  _onChangeSslAllowLegacyRenegotiation = ({ target: { checked }, currentTarget: { id } }) => {
    const { onChangeSslAllowLegacyRenegotiation } = this.props;
    onChangeSslAllowLegacyRenegotiation(checked, id);
  };

  _onChangeSslCipher = (value, id) => {
    const { onChangeSslCipher } = this.props;
    onChangeSslCipher(value, id);
  };

  _onChangeSslMinimumProtocolVersion = (value, id) => {
    const { onChangeSslMinimumProtocolVersion } = this.props;
    onChangeSslMinimumProtocolVersion(value, id);
  };

  _onChangeSslMaximumProtocolVersion = ({ target: { value }, currentTarget: { id } }) => {
    const { onChangeSslMaximumProtocolVersion } = this.props;
    onChangeSslMaximumProtocolVersion(value === '' ? null : value, id);
  };

  _onChangeOutgoingReceiptAsyncResends = (value, id) => {
    const { onChangeOutgoingReceiptAsyncResends } = this.props;
    onChangeOutgoingReceiptAsyncResends(value, id);
  };

  _onChangeOutgoingReceiptAsyncRetryDelay = (value, id) => {
    const { onChangeOutgoingReceiptAsyncRetryDelay } = this.props;
    onChangeOutgoingReceiptAsyncRetryDelay(value, id);
  };

  _onChangeOutgoingReceiptAsyncTimeout = (value, id) => {
    const { onChangeOutgoingReceiptAsyncTimeout } = this.props;
    onChangeOutgoingReceiptAsyncTimeout(value, id);
  };

  _falsyToEmptyString(value) {
    return value ? value : '';
  }

  _propertyValue = (obj) => {
    return typeof obj.newValue === 'undefined' ? obj.origValue : obj.newValue;
  };

  _fixNullIntFieldValue = (obj) => {
    if (typeof obj.origValue === 'undefined') {
      obj.origValue = null;
    }
  };

  render() {
    const {
      parentId,
      authenticationCertPassword,
      chubSystemCertificates,
      securityAuthenticationPassword,
      securityAuthenticationRealm,
      securityAuthenticationType,
      securityAuthenticationUsername,
      connectionTimeout,
      sslCipher,
      sslMinimumProtocolVersion,
      sslMaximumProtocolVersion,
      outgoingReceiptAsyncResends,
      outgoingReceiptAsyncRetryDelay,
      outgoingReceiptAsyncTimeout,
      onSigningCertificateOverrideRequestBubbleClose,
      viewPartnerSigningCertificateOverride,
      onClickViewPartnerSigningCertificateOverride,
      uploadPartnerSigningCertificateOverride,
      clientSigningCertificateOverride,
      onClickRemoveSigningCertificateOverride,
      readOnly,
    } = this.props;

    // PET-3678 : If we have "undefined" value, then any value displayed for these "int"
    // fields from another environment somehow "bleeds" into environments without a value.
    this._fixNullIntFieldValue(connectionTimeout);
    this._fixNullIntFieldValue(outgoingReceiptAsyncResends);
    this._fixNullIntFieldValue(outgoingReceiptAsyncRetryDelay);
    this._fixNullIntFieldValue(outgoingReceiptAsyncTimeout);

    const authCertOptions = [{ guid: 'NONE', value: ' - NONE - ' }, ...chubSystemCertificates];

    const authCertGuid = authenticationCertPassword.changed
      ? { ...authenticationCertPassword, newValue: authenticationCertPassword.newValue ?? 'NONE' }
      : { ...authenticationCertPassword, origValue: authenticationCertPassword.origValue ?? 'NONE' };

    return (
      <Fragment>
        <BoldLabel>Advanced Options</BoldLabel>
        <OptionalLabel>These fields are optional</OptionalLabel>
        <AdvancedOptions>
          <UnorderedList>
            <ListItem>
              <EditableField id={`allowDuplicateMessageIds-field-${parentId}`}>
                {() => (
                  <Fragment>
                    <ConfigurationLabel for={`allowDuplicateMessageIds-${parentId}`}>
                      Allow Duplicate Message IDs:
                    </ConfigurationLabel>
                    <Input
                      id={`allowDuplicateMessageIds-${parentId}`}
                      type='checkbox'
                      onChange={this._onChangeAllowDuplicateMessageIds}
                      checked={this._allowDuplicateMessageIdsChecked()}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>

            <ListItem>
              <EditableField {...{ ...authCertGuid }} id={`authenticationCert-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`authenticationCert-${parentId}`}>
                      Authentication Certificate:
                    </ConfigurationLabel>
                    <EditableIdSelect
                      id={`authenticationCert-${parentId}`}
                      {...editableInputProps}
                      onChange={this._onChangeAuthenticationCertHref}
                      values={authCertOptions}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>

            <ListItem>
              <EditableField {...{ ...clientSigningCertificateOverride, readOnly }} id={`signingCertificateOverride-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                    <Fragment>
                      <ConfigurationLabel for={`signingCertificateOverride-${parentId}`}>
                        Signing Certificate Override:
                      </ConfigurationLabel>
                      <UploadCertificate
                          {...{
                            ...editableInputProps,
                            onRequestBubbleClose: onSigningCertificateOverrideRequestBubbleClose,
                            uploadCertificate: uploadPartnerSigningCertificateOverride,
                            onClickViewPartnerCertificate: onClickViewPartnerSigningCertificateOverride,
                            viewPartnerCertificate: viewPartnerSigningCertificateOverride,
                            parentId: `signingCertificateOverride-${parentId}`,
                            onClickRemoveCertificate: onClickRemoveSigningCertificateOverride
                          }}
                      />
                    </Fragment>
                )}
              </EditableField>
            </ListItem>

            <ListItem>
              <EditableField id={`securityAuthenticationPassword-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`securityAuthenticationPassword-${parentId}`}>
                      Security Authentication Password:
                    </ConfigurationLabel>
                    <TextInput
                      id={`securityAuthenticationPassword-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeSecurityAuthenticationPassword}
                      newValue={this._propertyValue(securityAuthenticationPassword)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`securityAuthenticationRealm-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`securityAuthenticationRealm-${parentId}`}>
                      Security Authentication Realm:
                    </ConfigurationLabel>
                    <TextInput
                      id={`securityAuthenticationRealm-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeSecurityAuthenticationRealm}
                      newValue={this._propertyValue(securityAuthenticationRealm)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`securityAuthenticationType-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`securityAuthenticationType-${parentId}`}>
                      Security Authentication Type:
                    </ConfigurationLabel>
                    <TextInput
                      id={`securityAuthenticationType-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeSecurityAuthenticationType}
                      newValue={this._propertyValue(securityAuthenticationType)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`securityAuthenticationUsername-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`securityAuthenticationUsername-${parentId}`}>
                      Security Authentication Username:
                    </ConfigurationLabel>
                    <TextInput
                      id={`securityAuthenticationUsername-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeSecurityAuthenticationUsername}
                      newValue={this._propertyValue(securityAuthenticationUsername)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`connectionTimeout-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`connectionTimeout-${parentId}`}>
                      Connection Timeout (seconds):
                    </ConfigurationLabel>
                    <TextInput
                      id={`connectionTimeout-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeConnectionTimeout}
                      newValue={this._propertyValue(connectionTimeout)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`sslAllowLegacyRenegotiation-field-${parentId}`}>
                {() => (
                  <Fragment>
                    <ConfigurationLabel for={`sslAllowLegacyRenegotiation-${parentId}`}>
                      SSL Allow Legacy Renegotiation:
                    </ConfigurationLabel>
                    <Input
                      id={`sslAllowLegacyRenegotiation-${parentId}`}
                      type='checkbox'
                      onChange={this._onChangeSslAllowLegacyRenegotiation}
                      checked={this._sslAllowLegacyRenegotiationChecked()}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`sslCipher-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`sslCipher-${parentId}`}>SSL Cipher:</ConfigurationLabel>
                    <TextInput
                      id={`sslCipher-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeSslCipher}
                      newValue={this._propertyValue(sslCipher)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`sslMinimumProtocolVersion-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`sslMinimumProtocolVersion-${parentId}`}>
                      SSL Minimum Protocol Version:
                    </ConfigurationLabel>
                    <TextInput
                      id={`sslMinimumProtocolVersion-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeSslMinimumProtocolVersion}
                      newValue={this._propertyValue(sslMinimumProtocolVersion)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`sslMaximumProtocolVersion-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <>
                    <ConfigurationLabel for={`sslMaximumProtocolVersion-${parentId}`}>
                      SSL Maximum Protocol Version:
                    </ConfigurationLabel>
                    <Select
                      onChange={this._onChangeSslMaximumProtocolVersion}
                      value={this._falsyToEmptyString(this._propertyValue(sslMaximumProtocolVersion))}
                      {...editableInputProps}
                      id={`sslMaximumProtocolVersion-${parentId}`}
                    >
                      {['', 'ssl3.0', 'tls1.0', 'tls1.1', 'tls1.2', 'tls1.3'].map((version) => (
                        <option key={version} value={version}>
                          {version}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`outgoingReceiptAsyncResends-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`outgoingReceiptAsyncResends-${parentId}`}>
                      Outgoing Receipt Async Resends:
                    </ConfigurationLabel>
                    <TextInput
                      id={`outgoingReceiptAsyncResends-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeOutgoingReceiptAsyncResends}
                      newValue={this._propertyValue(outgoingReceiptAsyncResends)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`outgoingReceiptAsyncRetryDelay-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`outgoingReceiptAsyncRetryDelay-${parentId}`}>
                      Outgoing Receipt Async Retry Delay (seconds):
                    </ConfigurationLabel>
                    <TextInput
                      id={`outgoingReceiptAsyncRetryDelay-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeOutgoingReceiptAsyncRetryDelay}
                      newValue={this._propertyValue(outgoingReceiptAsyncRetryDelay)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
            <ListItem>
              <EditableField id={`outgoingReceiptAsyncTimeout-field-${parentId}`}>
                {({ ...editableInputProps }) => (
                  <Fragment>
                    <ConfigurationLabel for={`outgoingReceiptAsyncTimeout-${parentId}`}>
                      Outgoing Receipt Async Timeout (minutes):
                    </ConfigurationLabel>
                    <TextInput
                      id={`outgoingReceiptAsyncTimeout-${parentId}`}
                      debounceWaitMS={1500}
                      {...editableInputProps}
                      onChange={this._onChangeOutgoingReceiptAsyncTimeout}
                      newValue={this._propertyValue(outgoingReceiptAsyncTimeout)}
                    />
                  </Fragment>
                )}
              </EditableField>
            </ListItem>
          </UnorderedList>
        </AdvancedOptions>
      </Fragment>
    );
  }
}
