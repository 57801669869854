import useSWR from 'swr';
import { fetcher } from '../../util/fetchClient';

export interface OutgoingBacklogData {
  partnerId: string;
  backlog: number;
  avgMinutesWaiting: number;
  maxMinutesWaiting: number;
}

const useOutgoingBacklog = () => {
  const { data, error } = useSWR(`/dashboards/outgoingBacklog`, fetcher);

  return {
    backlog: data as OutgoingBacklogData[],
    isLoading: !error && !data,
    error,
  };
};

export default useOutgoingBacklog;
