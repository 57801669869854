export class DeletableEntity {
  constructor() {
    this.added = false;
    this.deleted = false;
  }

  get hasChanges() {
    return this.added | this.deleted;
  }
}
