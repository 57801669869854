import { connect } from 'react-redux';
import SaveCancelFooter from '../../../components/SaveCancelFooter';
import { saveConfigurationViewModel } from '../actions';

const PROD_ENVIRONMENT_GUID = 'fcbb15d8-b0cc-4baf-89e8-1889196c0278';

const determineConfirmMessage = (headVersionStatus, currentEnvironmentGUID) => {
  let confirmMessage = '';
  if (currentEnvironmentGUID?.toLowerCase() === PROD_ENVIRONMENT_GUID) {
    confirmMessage += 'This change will update production.\n\nAre you sure?'
  }
  if (headVersionStatus === 'MANUAL') {
    if (confirmMessage) {
      confirmMessage += '\n\n';
    }
    confirmMessage += 'This is a MANUAL configuration. You are modifying a configuration that is NOT being used yet! ' +
        'The new configuration will still have a status of MANUAL.' +
        '\n\nAre you abso-posi-ti-definitely sure you want to do this?';
  }
  return confirmMessage;
}

const mapStateToProps = (state) => {
  const {
    undoableConfigurationViewModel: {
      past,
      future,
      present: { sections, currentSection },
    },
  } = state;
  const { canSave, saveSuccessful, environmentGUID: currentEnvironmentGUID, headVersionStatus } = sections[currentSection];
  const confirmMessage = determineConfirmMessage(headVersionStatus, currentEnvironmentGUID);

  return {
    canCancel: canSave,
    canSave,
    saveSuccessful: saveSuccessful && !past.length && !future.length,
    requireConfirm: !!confirmMessage,
    confirmMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCancel: () => {
      window.location.reload();
    },
    onSave: () => {
      dispatch(saveConfigurationViewModel());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveCancelFooter);
