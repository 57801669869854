import React from 'react';
import PropTypes from 'prop-types';
import AS2PastTransfersTable from './AS2PastTransfersTable';
import AS2PendingTransfersTable from './AS2PendingTransfersTable';
import { AS2TransferData, PendingAS2TransferData, TableType } from '../AS2Transfers.model';

const AS2TransfersResultsTables = ({
  incomingFileTransfers,
  outgoingFileTransfers,
  pendingFileTransfers,
}: {
  incomingFileTransfers: AS2TransferData[];
  outgoingFileTransfers: AS2TransferData[];
  pendingFileTransfers: PendingAS2TransferData[];
}) => {
  const incomingIsEmpty = !incomingFileTransfers || incomingFileTransfers.length === 0;
  const outgoingIsEmpty = !outgoingFileTransfers || outgoingFileTransfers.length === 0;
  const pendingIsEmpty = !pendingFileTransfers || pendingFileTransfers.length === 0;

  return (
    <>
      <AS2PastTransfersTable
        title={'Incoming Transfers'}
        isEmpty={incomingIsEmpty}
        fileTransfers={incomingFileTransfers}
        tableType={TableType.INCOMING}
      />
      <AS2PastTransfersTable
        title={'Outgoing Transfers'}
        isEmpty={outgoingIsEmpty}
        fileTransfers={outgoingFileTransfers}
        tableType={TableType.OUTGOING}
      />
      <AS2PendingTransfersTable
        title={'Pending Outbound Transfers'}
        isEmpty={pendingIsEmpty}
        fileTransfers={pendingFileTransfers}
      />
    </>
  );
};

AS2TransfersResultsTables.displayName = 'AS2TransfersResultsTables';
AS2TransfersResultsTables.propTypes = {
  incomingFileTransfers: PropTypes.array,
  outgoingFileTransfers: PropTypes.array,
  pendingFileTransfers: PropTypes.array,
};

export default AS2TransfersResultsTables;
