import React from 'react';
import glamorous from 'glamorous';

import As2IdListEditorContainer from './containers/As2IdListEditorContainer';
import DefaultHeaderContainer from './containers/DefaultHeaderContainer';
import LoadingStateProviderContainer from './containers/LoadingStateProviderContainer';
import ClientSummary from '../../../components/ClientSummary';
import { ChubH2 } from '../../../styles/typography';

const TableTitle = glamorous(ChubH2, { displayName: 'TableTitle' })({
  marginLeft: '25px',
});

const As2Ids = () => (
  <div>
    <DefaultHeaderContainer />
    <ClientSummary clientName='CommerceHub' />
    <TableTitle>CommerceHub AS2 IDs</TableTitle>
    <LoadingStateProviderContainer>{() => <As2IdListEditorContainer />}</LoadingStateProviderContainer>
  </div>
);

As2Ids.displayName = 'As2Ids';

export default As2Ids;
