import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { Spinner } from 'react-chui';
import { chubStandards } from '../styles/typography';

const ViewBlock = glamorous('div', { displayName: 'ViewBlock' })(chubStandards, {
  fontSize: '20px',
  margin: '20px auto',
  maxWidth: '1000px',
  textAlign: 'center',
});

const LoadingSpinner = glamorous(Spinner, {
  displayName: 'LoadingSpinner',
  withProps: {
    size: 'large',
  },
})({
  display: 'block',
  margin: '0 auto',
});

const SavingSpinner = glamorous(Spinner, {
  displayName: 'SavingSpinner',
  withProps: {
    size: 'small',
  },
})({
  display: 'inline-block',
});

const ErrorDiv = glamorous('div', { displayName: 'ErrorDiv' })({
  display: 'inline-block',
  width: '500px',
  backgroundColor: '#d3d3d3',
  textAlign: 'center',
});

const Saving = ({ ...otherProps }) => (
  <span key='saving-indicator' {...otherProps}>
    Saving changes...
    <SavingSpinner key='saving-spinner' />
  </span>
);

Saving.displayName = 'Saving';

const Error = ({ errorMessage, ...otherProps }) => <ErrorDiv {...otherProps}>{errorMessage}</ErrorDiv>;

Error.displayName = 'Error';
Error.propTypes = {
  errorMessage: PropTypes.string,
};

const LoadingStateProvider = ({ children, error, isLoading, isSaving }) => {
  if (error) {
    return (
      <ViewBlock>
        <Error id='loadingstateprovider-error-view' errorMessage={error.message || error} />
      </ViewBlock>
    );
  }
  if (isLoading) {
    return (
      <ViewBlock>
        <LoadingSpinner id='loadingstateprovider-loading-view' />
      </ViewBlock>
    );
  }
  return (
    <Fragment>
      {isSaving && (
        <ViewBlock>
          <Saving id='loadingstateprovider-save-view' />
        </ViewBlock>
      )}
      {children()}
    </Fragment>
  );
};

LoadingStateProvider.displayName = 'LoadingStateProvider';
LoadingStateProvider.propTypes = {
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  children: PropTypes.func,
};
LoadingStateProvider.defaultProps = {
  error: '',
  isSaving: false,
  isLoading: false,
  children: () => undefined,
};

export default LoadingStateProvider;
