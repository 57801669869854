import { EditableFieldViewModel } from '../base/EditableFieldViewModel';
import { DeletableEntity } from '../base/DeletableEntity';

export class FileRouteTypeViewModel extends DeletableEntity {
  constructor(jsonModel) {
    super();
    this.fileRouteTypeGUID = jsonModel.fileRouteTypeGUID;
    this.createDate = jsonModel.createDate;
    this.updateDate = jsonModel.updateDate;
    this.fileTypeId = new EditableFieldViewModel(jsonModel.fileTypeId);
    this.description = new EditableFieldViewModel(jsonModel.description);
    this.author = new EditableFieldViewModel(jsonModel.author);
    this.directoryName = new EditableFieldViewModel(jsonModel.directoryName);
    this.validForAS2 = new EditableFieldViewModel(jsonModel.validForAS2);
    this.validForSFTP = new EditableFieldViewModel(jsonModel.validForSFTP);
    this.validForPartnerSFTP = new EditableFieldViewModel(jsonModel.validForPartnerSFTP);
    this.direction = new EditableFieldViewModel(jsonModel.direction);
    this.application = new EditableFieldViewModel(jsonModel.application);
    this.applicationCode = new EditableFieldViewModel(jsonModel.applicationCode);
    this.defaultMd5 = new EditableFieldViewModel(jsonModel.defaultMd5);
    this.defaultRegex = new EditableFieldViewModel(jsonModel.defaultRegex);
    this.defaultExtension = new EditableFieldViewModel(jsonModel.defaultExtension);
  }

  get hasChanges() {
    return (
      super.hasChanges ||
      this.fileTypeId.hasChanges ||
      this.description.hasChanges ||
      this.author.hasChanges ||
      this.directoryName.hasChanges ||
      this.validForAS2.hasChanges ||
      this.validForSFTP.hasChanges ||
      this.validForPartnerSFTP.hasChanges ||
      this.direction.hasChanges ||
      this.application.hasChanges ||
      this.applicationCode.hasChanges ||
      this.defaultMd5.hasChanges ||
      this.defaultRegex.hasChanges ||
      this.defaultExtension.hasChanges
    );
  }

  get serverMessages() {
    return [
      ...this.fileTypeId.serverMessages,
      ...this.description.serverMessages,
      ...this.author.serverMessages,
      ...this.directoryName.serverMessages,
      ...this.validForAS2.serverMessages,
      ...this.validForSFTP.serverMessages,
      ...this.validForPartnerSFTP.serverMessages,
    ];
  }

  get hasError() {
    return Boolean(this.serverMessages[0]);
  }
}
