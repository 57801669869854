import React, { PropsWithChildren } from 'react';
import glamorous from 'glamorous';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { OutgoingBacklogData } from './outgoing-backlog.hook';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const BacklogInfoBlock = glamorous<PropsWithChildren>('dl', { displayName: 'BacklogInfoBlock' })({
  margin: '32px',
});

const BacklogEmptyBlock = glamorous<PropsWithChildren>('h4', { displayName: 'BacklogEmptyBlock' })({
  margin: '50px',
});

const OutgoingBacklogList = ({ backlog }: { backlog: OutgoingBacklogData[] }) => {
  if (backlog.length < 1) {
    return <BacklogEmptyBlock>The Harmony Outgoing Backlog is empty!</BacklogEmptyBlock>;
  }

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'end',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 0,
      },
    },
    interaction: {
      intersect: true,
      mode: 'index',
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: 'bold',
            size: 16,
          },
          color: 'rgb(70, 70, 70)',
        },
      },
      'average-minutes': {
        position: 'bottom',
        display: 'auto',
        min: 0,
        ticks: {
          color: 'rgb(53, 162, 235)',
          stepSize: 500,
        },
        grid: {
          color: 'rgba(53, 162, 235, 0.5)',
        },
      },
      'backlog-number': {
        position: 'bottom',
        display: 'auto',
        min: 0,
        ticks: {
          color: 'rgb(255, 99, 132)',
          stepSize: 10,
        },
        grid: {
          color: 'rgba(255, 99, 132, 0.5)',
        },
      },
    },
  };

  const labels = backlog.map((x) => x.partnerId);
  const data = {
    labels,
    datasets: [
      {
        label: 'average minutes waiting',
        data: backlog.map((x) => x.avgMinutesWaiting),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        maxBarThickness: 20,
        barPercentage: 0.95,
        categoryPercentage: 0.8,
        xAxisID: 'average-minutes',
      },
      {
        label: '# of files in backlog',
        data: backlog.map((x) => x.backlog),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        maxBarThickness: 20,
        barPercentage: 0.95,
        categoryPercentage: 0.8,
        xAxisID: 'backlog-number',
      },
    ],
  };

  const height = Math.max(backlog.length * 48 + 92, 144);

  return (
    <BacklogInfoBlock>
      <div style={{ height }}>
        <Bar options={options} data={data} />
      </div>
    </BacklogInfoBlock>
  );
};

export default OutgoingBacklogList;
