import { connect } from 'react-redux';
import FileRouteTypeListEditor from '../components/FileRouteTypeListEditor';
import { addFileRouteType, updateFileRouteType, deleteFileRouteType } from '../actions';

const mapStateToProps = ({
  undoableFileRouteTypes: {
    past,
    present: { fileRouteTypes, serverMessage },
  },
}) => {
  return {
    fileRouteTypes,
    changesMade: past.length > 0,
    serverMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAdd: () => dispatch(addFileRouteType()),
    onChangeEditableField: (...args) => dispatch(updateFileRouteType(...args)),
    onDelete: (...args) => dispatch(deleteFileRouteType(...args)),
  };
};

const FileRouteTypeListEditorContainer = connect(mapStateToProps, mapDispatchToProps)(FileRouteTypeListEditor);

export default FileRouteTypeListEditorContainer;
