import React from 'react';
import { connect } from 'react-redux';
import { colors } from 'react-chui';

import ConfigurationBox from '../components/ConfigurationBox';
import PartnerSftpConfiguration from '../components/PartnerSftpConfiguration';
import {
  toggleFileRouteVisibility,
  setFileRouteKey,
  setConnectionEnabled,
  setFileRouteAdditionalRoutingData,
  addPartnerSFTPFileRoute,
  deleteFileRoute,
  setSubPath,
  addSubPath,
  deleteSubPath,
  setPartnerSFTPUrl,
  setPartnerSFTPUsername,
  setPartnerSFTPPort,
  setPartnerSFTPPassword,
  setClientAuthCert,
  setClientAuthCertPassword,
  setClientAuthPrivateKeyFile,
  setClientAuthenticationPrivateKeyFilePassword,
} from '../actions';

const mapStateToProps = (state) => {
  const {
    undoableConfigurationViewModel: {
      present: { sections, currentSection, partnerSftpFileRouteTypes, clientName, clientGUID },
    },
  } = state;
  const { readOnly, canSave, headVersionStatus, environmentGUID } = sections[currentSection];

  return {
    sectionName: 'PartnerSFTP',
    backgroundColor: colors.chorange,
    textColor: 'white',
    readOnly,
    children: (props) => {
      const {
        accountGUID,
        connectionEnabled,
        fileRoutesVisible,
        fileRoutes,
        host,
        port,
        username,
        password,
        clientAuthenticationCert,
        clientAuthenticationCertPassword,
        clientAuthenticationPrivateKeyFile,
        clientAuthenticationPrivateKeyFilePassword,
      } = props.connection;
      return (
        <PartnerSftpConfiguration
          {...{
            environmentGUID,
            connectionEnabled,
            readOnly,
            fileRoutesVisible,
            fileRoutes,
            partnerSftpFileRouteTypes,
            toggleFileRouteVisibility,
            currentSection,
            canSave,
            headVersionStatus,
            clientName,
            clientGUID,
            ...props,
            id: accountGUID,
            url: host,
            port,
            username,
            clientAuthenticationCert,
            clientAuthenticationCertPassword,
            clientAuthenticationPrivateKeyFile,
            clientAuthenticationPrivateKeyFilePassword,
            password,
          }}
        />
      );
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleFileRouteVisibility: (...args) => dispatch(toggleFileRouteVisibility(...args)),
    onChangeFileRouteKey: (...args) => dispatch(setFileRouteKey(...args)),
    onClickConnectionToggle: (...args) => dispatch(setConnectionEnabled(...args)),
    onAddPartnerSFTPFileRoute: (...args) => dispatch(addPartnerSFTPFileRoute(...args)),
    onDeletePartnerSFTPFileRoute: (...args) => dispatch(deleteFileRoute(...args)),
    onChangeFileRouteAdditionalRoutingData: (...args) => dispatch(setFileRouteAdditionalRoutingData(...args)),
    onChangeSubPath: (...args) => dispatch(setSubPath(...args)),
    onClickAddSubPath: (...args) => dispatch(addSubPath(...args)),
    onClickDeleteSubPath: (...args) => dispatch(deleteSubPath(...args)),
    onChangeUrl: (...args) => dispatch(setPartnerSFTPUrl(...args)),
    onChangePort: (...args) => dispatch(setPartnerSFTPPort(...args)),
    onChangeUsername: (...args) => dispatch(setPartnerSFTPUsername(...args)),
    onChangePassword: (...args) => dispatch(setPartnerSFTPPassword(...args)),
    onChangeClientAuthCertPassword: (...args) => dispatch(setClientAuthCertPassword(...args)),
    onChangeClientAuthPrivateKeyFilePassword: (...args) => dispatch(setClientAuthenticationPrivateKeyFilePassword(...args)),
    onChangeClientAuthCert: (...args) => dispatch(setClientAuthCert(...args)),
    onChangeClientAuthPrivateKeyFile: (...args) => dispatch(setClientAuthPrivateKeyFile(...args)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationBox);
