import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import glamorous, { GlamorousComponent } from 'glamorous';
import { ChubH4, chubStandards } from '../../../styles/typography';
import { ChubTable, ChubTableBody, ChubTableHeadCell, ChubTableWrapper } from '../../../styles/table';
import { AS2ConnectionViewModel, ConnectionViewModel } from '../types/ConnectionViewModel';
// @ts-ignore
import { Select } from 'react-chui';
import { isEnabledOptions, protocols, usesNewIpsOptions } from '../dropdownOptions';
import { As2ConnectionsTableRow } from './As2ConnectionsTableRow';
import { SftpConnectionsTableRow } from './SftpConnectionsTableRow';
import { PartnerSftpConnectionsTableRow } from './PartnerSftpConnectionsTableRow';

const NoConnectionsMessage: GlamorousComponent<any, any> = glamorous('h3', { displayName: 'NoConnectionsMessage' })(
  chubStandards,
  {
    size: '18px',
    textAlign: 'left',
    margin: '0 0 20px 20px',
  }
);

const ConnectionsTableWrapper: GlamorousComponent<any, any> = glamorous(ChubTableWrapper, {
  displayName: 'ConnectionsTableWrapper',
})({
  maxWidth: '1400px',
  maxHeight: '700px',
  margin: '0px auto 0 auto',
  overflowX: 'hidden',
});

const Controls: GlamorousComponent<any, any> = glamorous('div', { displayName: 'Controls' })(chubStandards, {
  maxWidth: '1400px',
  margin: '20px auto 20px auto',
});

const ConnectionCount: GlamorousComponent<any, any> = glamorous('div', { displayName: 'ConnectionCount' })(
  chubStandards,
  {
    maxWidth: '1400px',
    margin: '20px auto 20px auto',
    fontFamily: chubStandards.fontFamily,
    fontSize: '16px',
  }
);

const DropdownControls: GlamorousComponent<any, any> = glamorous('span', { displayName: 'DropdownControls' })(
  chubStandards,
  {
    marginRight: '10px',
  }
);

const Label: GlamorousComponent<any, any> = glamorous(ChubH4, { displayName: 'Label' })({
  margin: '0',
  display: 'inline-block',
  paddingRight: '10px',
});

const EnvironmentDropdown: GlamorousComponent<any, any> = glamorous(Select, { displayName: 'EnvironmentDropdown' })({
  maxWidth: '150px',
});

const Dropdown: GlamorousComponent<any, any> = glamorous(Select, { displayName: 'Dropdown' })({
  maxWidth: '330px',
});

export const ConnectionsTable = ({
  connectionViewModels,
  connectionCountMessage,
  environmentList,
  requestConnections,
  filterConnections,
  selectedEnvironment,
  selectedProtocol,
  selectedUsesNewIps,
  selectedIsEnabled,
  selectedCertificate,
  certificateNames,
}: {
  connectionViewModels: ConnectionViewModel[];
  connectionCountMessage: string;
  environmentList: string[];
  requestConnections: (
    environment: string,
    protocol: string,
    filters: { usesNewIps: string; isEnabled: string; certificate: string }
  ) => void;
  filterConnections: (
    filters: { usesNewIps: string; isEnabled: string; certificate: string },
    protocol: string
  ) => void;
  selectedEnvironment: string;
  selectedProtocol: string;
  selectedUsesNewIps: string;
  selectedIsEnabled: string;
  selectedCertificate: string;
  certificateNames: string[];
}) => {
  const onSelectEnvironment = ({ target: { value } }: { target: { value: string } }) => {
    requestConnections(value, selectedProtocol, {
      usesNewIps: selectedUsesNewIps,
      isEnabled: selectedIsEnabled,
      certificate: selectedCertificate,
    });
  };

  const onSelectProtocol = ({ target: { value } }: { target: { value: string } }) => {
    requestConnections(selectedEnvironment, value, {
      usesNewIps: selectedUsesNewIps,
      isEnabled: selectedIsEnabled,
      certificate: selectedCertificate,
    });
  };

  const onSelectUsesNewIps = ({ target: { value } }: { target: { value: string } }) => {
    filterConnections(
      { usesNewIps: value, isEnabled: selectedIsEnabled, certificate: selectedCertificate },
      selectedProtocol
    );
  };

  const onSelectIsEnabled = ({ target: { value } }: { target: { value: string } }) => {
    filterConnections(
      { usesNewIps: selectedUsesNewIps, isEnabled: value, certificate: selectedCertificate },
      selectedProtocol
    );
  };

  const onSelectCertificate = ({ target: { value } }: { target: { value: string } }) => {
    filterConnections(
      { usesNewIps: selectedUsesNewIps, isEnabled: selectedIsEnabled, certificate: value },
      selectedProtocol
    );
  };

  const renderEnvironmentDropdown = () => {
    return (
      <EnvironmentDropdown id='environment-dropdown' value={selectedEnvironment} onChange={onSelectEnvironment}>
        {environmentList.reduce((allOptions: ReactElement[], environment: string) => {
          allOptions.push(
            <option value={environment} key={environment}>
              {environment}
            </option>
          );
          return allOptions;
        }, [])}
      </EnvironmentDropdown>
    );
  };

  const renderProtocolDropdown = () => {
    return (
      <Dropdown id='protocol-dropdown' value={selectedProtocol} onChange={onSelectProtocol}>
        {protocols.reduce((allOptions: ReactElement[], protocol: string) => {
          allOptions.push(
            <option value={protocol} key={protocol}>
              {protocol}
            </option>
          );
          return allOptions;
        }, [])}
      </Dropdown>
    );
  };

  const renderUsesNewIpsDropdown = () => {
    return (
      <Dropdown id='uses-new-ips-dropdown' value={selectedUsesNewIps} onChange={onSelectUsesNewIps}>
        {usesNewIpsOptions.reduce((allOptions: ReactElement[], usesNewIps: string) => {
          allOptions.push(
            <option value={usesNewIps} key={usesNewIps}>
              {usesNewIps}
            </option>
          );
          return allOptions;
        }, [])}
      </Dropdown>
    );
  };

  const renderCertificateDropdown = () => {
    return (
      <Dropdown id='certificate-dropdown' value={selectedCertificate} onChange={onSelectCertificate}>
        {certificateNames.reduce((allOptions: ReactElement[], certificate: string) => {
          allOptions.push(
            <option value={certificate} key={certificate}>
              {certificate}
            </option>
          );
          return allOptions;
        }, [])}
      </Dropdown>
    );
  };

  const renderIsEnabledDropdown = () => {
    return (
      <Dropdown id='is-enabled-dropdown' value={selectedIsEnabled} onChange={onSelectIsEnabled}>
        {isEnabledOptions.reduce((allOptions: ReactElement[], isEnabled: string) => {
          allOptions.push(
            <option value={isEnabled} key={isEnabled}>
              {isEnabled}
            </option>
          );
          return allOptions;
        }, [])}
      </Dropdown>
    );
  };

  const renderUsesNewIpsControls = () => {
    if (selectedProtocol === 'AS2') {
      return (
        <DropdownControls>
          <Label>Uses New IPs:</Label>
          {renderUsesNewIpsDropdown()}
        </DropdownControls>
      );
    } else {
      return <></>;
    }
  };

  const renderCertificateControls = () => {
    if (selectedProtocol === 'AS2') {
      return (
        <DropdownControls>
          <Label>Chub Cert:</Label>
          {renderCertificateDropdown()}
        </DropdownControls>
      );
    } else {
      return <></>;
    }
  };

  const renderAs2Table = () => {
    return (
      <ChubTable>
        <thead>
          <tr>
            <ChubTableHeadCell>Client Name</ChubTableHeadCell>
            <ChubTableHeadCell>AS2 ID</ChubTableHeadCell>
            <ChubTableHeadCell>Chub AS2 ID</ChubTableHeadCell>
            <ChubTableHeadCell>Chub Certificate</ChubTableHeadCell>
          </tr>
        </thead>
        <ChubTableBody>
          {(connectionViewModels as AS2ConnectionViewModel[])
            .sort((a, b) => a.client_name.localeCompare(b.client_name))
            .map((record, index: number) => (
              <As2ConnectionsTableRow key={index} {...record} />
            ))}
        </ChubTableBody>
      </ChubTable>
    );
  };

  const renderSftpTable = () => {
    return (
      <ChubTable>
        <thead>
          <tr>
            <ChubTableHeadCell>Client Name</ChubTableHeadCell>
          </tr>
        </thead>
        <ChubTableBody>
          {connectionViewModels
            .sort((a, b) => a.client_name.localeCompare(b.client_name))
            .map((record: ConnectionViewModel, index: number) => (
              <SftpConnectionsTableRow key={index} {...record} />
            ))}
        </ChubTableBody>
      </ChubTable>
    );
  };

  const renderPartnerSftpTable = () => {
    return (
      <ChubTable>
        <thead>
          <tr>
            <ChubTableHeadCell>Client Name</ChubTableHeadCell>
          </tr>
        </thead>
        <ChubTableBody>
          {connectionViewModels
            .sort((a, b) => a.client_name.localeCompare(b.client_name))
            .map((record: ConnectionViewModel, index: number) => (
              <PartnerSftpConnectionsTableRow key={index} {...record} />
            ))}
        </ChubTableBody>
      </ChubTable>
    );
  };

  const isEmpty = !connectionViewModels || connectionViewModels.length === 0;
  return (
    <>
      <Controls>
        <DropdownControls>
          <Label>Environment:</Label>
          {renderEnvironmentDropdown()}
        </DropdownControls>
        <DropdownControls>
          <Label>Protocol:</Label>
          {renderProtocolDropdown()}
        </DropdownControls>
        <DropdownControls>
          <Label>Is Enabled:</Label>
          {renderIsEnabledDropdown()}
        </DropdownControls>
        {renderUsesNewIpsControls()}
        {renderCertificateControls()}
      </Controls>
      <ConnectionCount>{connectionCountMessage}</ConnectionCount>
      <ConnectionsTableWrapper>
        {isEmpty && <NoConnectionsMessage>No Connections Found</NoConnectionsMessage>}
        {!isEmpty && selectedProtocol === 'AS2' && renderAs2Table()}
        {!isEmpty && selectedProtocol === 'SFTP' && renderSftpTable()}
        {!isEmpty && selectedProtocol === 'partnerSFTP' && renderPartnerSftpTable()}
      </ConnectionsTableWrapper>
    </>
  );
};
ConnectionsTable.displayName = 'ConnectionsTable';
ConnectionsTable.propTypes = {
  connectionViewModels: PropTypes.array,
  connectionCountMessage: PropTypes.string,
  environmentList: PropTypes.array,
  selectedEnvironment: PropTypes.string,
  selectedProtocol: PropTypes.string,
  selectedNewUseIps: PropTypes.string,
  selectedIsEnabled: PropTypes.string,
  requestConnections: PropTypes.func,
  filterConnections: PropTypes.func,
  selectedCertificate: PropTypes.string,
  certificateNames: PropTypes.array,
};
