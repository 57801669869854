import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';

import { ChubTableBodyRow, ChubTableBodyCell } from '../../../../styles/table';
import { ChubButton } from '../../../../styles/typography';
import EditableField from '../../../../components/EditableField';
import EditableTextInput from '../../../../components/EditableTextInput';

import { DeleteIcon } from '../../../../styles/icons';
const DeleteButton = glamorous('button', { displayName: 'DeleteButton' })({
  border: 'none',
  padding: '0',
  float: 'right',
  cursor: 'pointer',
  background: 'none',
  ':disabled': {
    display: 'none',
  },
});
const AddSubPathButton = glamorous(ChubButton, { displayName: 'AddSubPathButton' })({
  float: 'right',
  ':disabled': {
    display: 'none',
  },
});
const SubPathDataCell = glamorous(ChubTableBodyCell, { displayName: 'SubPathDataCell' })({
  backgroundColor: colors.lightblue,
  border: 'none',
});

export default class SubPaths extends Component {
  static displayName = 'SubPaths';

  static propTypes = {
    subPaths: PropTypes.object,
    onChangeSubPath: PropTypes.func,
    onClickAddSubPath: PropTypes.func,
    onClickDeleteSubPath: PropTypes.func,
    readOnly: PropTypes.bool,
    fileRouteGUID: PropTypes.string,
    parentId: PropTypes.string,
    connectionType: PropTypes.string,
  };

  _onChangeSubPath = (subPathGUID) => (subPath) => {
    const { onChangeSubPath, fileRouteGUID, parentId, connectionType } = this.props;
    onChangeSubPath(fileRouteGUID, subPathGUID, subPath, parentId, connectionType);
  };

  _onClickAddSubPath = () => {
    const { fileRouteGUID, onClickAddSubPath, parentId, connectionType } = this.props;
    onClickAddSubPath(fileRouteGUID, parentId, connectionType);
  };
  _onClickDeleteSubPath = (subPathGUID) => () => {
    const { onClickDeleteSubPath, fileRouteGUID, parentId, connectionType } = this.props;
    onClickDeleteSubPath(fileRouteGUID, subPathGUID, parentId, connectionType);
  };

  render() {
    const { subPaths, fileRouteGUID, readOnly } = this.props;
    const [firstGUID, ...otherGUIDs] = Object.keys(subPaths);
    return (
      <Fragment>
        <ChubTableBodyRow>
          <ChubTableBodyCell colSpan='2' rowSpan={Object.keys(subPaths).length || 1}>
            <AddSubPathButton
              id={`subPath-add-button-${fileRouteGUID}`}
              onClick={this._onClickAddSubPath}
              data-name='subPath-add-button'
              disabled={readOnly ? 'disabled' : ''}
            >
              Add SubPath
            </AddSubPathButton>
          </ChubTableBodyCell>
          {firstGUID && (
            <Fragment>
              <SubPathDataCell colSpan='4'>
                <EditableField {...{ ...subPaths[firstGUID], readOnly }}>
                  {(editableInputProps) => (
                    <EditableTextInput
                      data-name='subPath'
                      onChange={this._onChangeSubPath(firstGUID)}
                      id={`subPath-${firstGUID}`}
                      {...editableInputProps}
                    />
                  )}
                </EditableField>
              </SubPathDataCell>
              <SubPathDataCell>
                <DeleteButton
                  id={`subPath-delete-button-${firstGUID}`}
                  onClick={this._onClickDeleteSubPath(firstGUID)}
                  data-name='subPath-delete-button'
                  disabled={readOnly ? 'disabled' : ''}
                >
                  <DeleteIcon />
                </DeleteButton>
              </SubPathDataCell>
            </Fragment>
          )}
        </ChubTableBodyRow>
        {otherGUIDs.map((currentGUID) => (
          <ChubTableBodyRow key={currentGUID}>
            <SubPathDataCell colSpan='4'>
              <EditableField {...{ ...subPaths[currentGUID], readOnly }}>
                {(editableInputProps) => (
                  <EditableTextInput
                    data-name='subPath'
                    onChange={this._onChangeSubPath(currentGUID)}
                    id={`subPath-${currentGUID}`}
                    {...editableInputProps}
                  />
                )}
              </EditableField>
            </SubPathDataCell>
            <SubPathDataCell>
              <DeleteButton
                id={`subPath-delete-button-${currentGUID}`}
                onClick={this._onClickDeleteSubPath(currentGUID)}
                data-name='subPath-delete-button'
                disabled={readOnly ? 'disabled' : ''}
              >
                <DeleteIcon />
              </DeleteButton>
            </SubPathDataCell>
          </ChubTableBodyRow>
        ))}
      </Fragment>
    );
  }
}
