import { connect } from 'react-redux';

import ClientSummary from '../../../components/ClientSummary';

const mapStateToProps = (state) => {
  const {
    versionData: {
      client: { clientName, clientGUID },
    },
  } = state;
  return {
    clientName,
    clientGUID,
  };
};

export default connect(mapStateToProps)(ClientSummary);
