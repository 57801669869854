import React, { Component, Fragment } from 'react';
import glamorous from 'glamorous';
import { Spinner, Select, colors } from 'react-chui';
import PropTypes from 'prop-types';
import { ChubButton, ChubH2, chubStandards } from '../../../../styles/typography';
import { DeleteIcon } from '../../../../styles/icons';
import {
  ChubTable,
  ChubTableBody,
  ChubTableBodyCell,
  ChubTableBodyRow,
  ChubTableHeadCell,
} from '../../../../styles/table';

const DEFAULT_GROUP_MESSAGE = 'Please Choose One';
const SELECTED_ALL = 'SELECTED_ALL';

const TableWrapper = glamorous('div', { displayName: 'TableWrapper' })({
  maxWidth: '1300px',
  margin: '10px auto',
  padding: '10px',
  borderRadius: '3px',
  backgroundColor: 'white',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
});

const LoadingSpinner = glamorous(Spinner, {
  displayName: 'LoadingSpinner',
  withProps: {
    size: 'large',
  },
})({
  display: 'block',
  margin: '0 auto',
});

const CloseButton = glamorous('button', { displayName: 'CloseButton' })({
  border: 'none',
  padding: '0',
  float: 'right',
  cursor: 'pointer',
  background: 'none',
  ':disabled': {
    display: 'none',
  },
});

const ErrorMessage = glamorous('div', { displayName: 'ErrorMessage' })(chubStandards, {
  backgroundColor: colors.chred,
  textAlign: 'center',
  fontWeight: 'bold',
  color: 'white',
  fontSize: '25px',
});

const InputLabel = glamorous('label', { displayName: 'InputLabel' })(chubStandards, ({ fontSize = '20px' }) => ({
  padding: '0 10px',
  fontSize,
}));

const AddButtonSection = glamorous('div', { displayName: 'AddButtonSection' })({
  textAlign: 'right',
  paddingTop: '25px',
});

const FileRoutePreview = (fileRoute) => {
  const {
    name: { origValue: name },
    applicationKey: { origValue: applicationKey },
    direction: { origValue: direction },
    connectionType,
  } = fileRoute;
  return (
    <ChubTableBodyRow>
      <ChubTableBodyCell>{applicationKey}</ChubTableBodyCell>
      <ChubTableBodyCell>{connectionType}</ChubTableBodyCell>
      <ChubTableBodyCell>{direction}</ChubTableBodyCell>
      <ChubTableBodyCell>{name}</ChubTableBodyCell>
    </ChubTableBodyRow>
  );
};
FileRoutePreview.propTypes = {
  name: PropTypes.object,
  applicationKey: PropTypes.object,
};
FileRoutePreview.displayName = 'FileRoutePreview';

export default class RelationshipsView extends Component {
  static displayName = 'RelationshipsView';
  static propTypes = {
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    hasSftpConnection: PropTypes.bool,
    onCloseRelationships: PropTypes.func,
    onAddRelationship: PropTypes.func,
    relationships: PropTypes.object,
  };

  state = {
    selectedSecondaryPartner: '',
  };

  _renderRelationshipOptions = () => {
    const {
      relationships: { relationshipFileRoutes },
    } = this.props;
    const { selectedSecondaryPartner } = this.state;

    const relationshipOptions = Object.keys(relationshipFileRoutes)
      .sort()
      .map((relationshipName) => (
        <option value={relationshipName} key={relationshipName}>
          {relationshipName}
        </option>
      ));
    if (!selectedSecondaryPartner || selectedSecondaryPartner === SELECTED_ALL) {
      return [
        <option value={DEFAULT_GROUP_MESSAGE} key={DEFAULT_GROUP_MESSAGE}>
          {DEFAULT_GROUP_MESSAGE}
        </option>,
      ].concat(relationshipOptions);
    }

    return relationshipOptions;
  };

  _onSelectSecondaryPartner = ({ target: { value } }) => {
    this.setState({
      selectedSecondaryPartner: value,
    });
  };

  _onSelectAll = () => {
    this.setState({
      selectedSecondaryPartner: SELECTED_ALL,
    });
  };

  _getSelectedFileRoutes = () => {
    const {
      relationships: { relationshipFileRoutes },
    } = this.props;
    const { selectedSecondaryPartner } = this.state;
    if (selectedSecondaryPartner === SELECTED_ALL) {
      let flattenedFileRoutes = [];
      Object.values(relationshipFileRoutes).forEach((fileRoute) => {
        flattenedFileRoutes = flattenedFileRoutes.concat(fileRoute);
      });
      const uniqueFileRoutesMap = [];
      flattenedFileRoutes.forEach((fileRoute) => {
        const uk = fileRoute.applicationKey.origValue + fileRoute.connectionType;
        if (!uniqueFileRoutesMap[uk]) {
          uniqueFileRoutesMap[uk] = fileRoute;
        } else if (uniqueFileRoutesMap[uk].subPaths) {
          uniqueFileRoutesMap[uk].subPaths = { ...uniqueFileRoutesMap[uk].subPaths, ...fileRoute.subPaths };
        }
      });
      return Object.values(uniqueFileRoutesMap);
    }
    return relationshipFileRoutes[selectedSecondaryPartner] ? relationshipFileRoutes[selectedSecondaryPartner] : [];
  };

  _renderFileRouteTable = () => {
    const fileRoutes = this._getSelectedFileRoutes();
    return (
      <ChubTable>
        <thead>
          <tr>
            <ChubTableHeadCell>Key</ChubTableHeadCell>
            <ChubTableHeadCell>Type</ChubTableHeadCell>
            <ChubTableHeadCell>Direction</ChubTableHeadCell>
            <ChubTableHeadCell>Name</ChubTableHeadCell>
          </tr>
        </thead>
        <ChubTableBody>
          {fileRoutes.map((fileRoute) => (
            <FileRoutePreview key={fileRoute.fileRouteGUID} {...fileRoute} />
          ))}
        </ChubTableBody>
      </ChubTable>
    );
  };

  _onClickAddRelationship = () => {
    const { onAddRelationship, hasSftpConnection, flags } = this.props;
    // We only want to generate a password if there is no existing SFTP connection
    onAddRelationship(this._getSelectedFileRoutes(), !hasSftpConnection, flags?.useAs2ForwarderAsDefault);
  };

  render() {
    const { isLoading, onCloseRelationships, error } = this.props;
    const { selectedSecondaryPartner } = this.state;

    if (isLoading) {
      return <LoadingSpinner data-name='relationships-loading' />;
    }

    // This assignment has to happen after Loading has completed. Prior to that relationships doesn't exist
    const {
      relationships: { relationshipFileRoutes },
    } = this.props;
    const hasRelationships = Object.keys(relationshipFileRoutes).length > 0;
    let errorMessage;
    if (error) {
      errorMessage = <ErrorMessage data-name='relationships-error'>{error}</ErrorMessage>;
    } else if (!hasRelationships) {
      errorMessage = <ErrorMessage data-name='no-relationships-message'>No relationships found</ErrorMessage>;
    }

    return (
      <TableWrapper>
        <CloseButton data-button-type='relationships-close' onClick={onCloseRelationships}>
          <DeleteIcon fontSize='35px' />
        </CloseButton>
        <ChubH2>Add Relationship</ChubH2>
        {errorMessage ? (
          errorMessage
        ) : (
          <Fragment>
            <InputLabel>
              Relationship:
              <Select
                data-input-type='secondary-partner'
                theme='dark'
                onChange={this._onSelectSecondaryPartner}
                value={selectedSecondaryPartner}
              >
                {this._renderRelationshipOptions()}
              </Select>
            </InputLabel>
            <ChubButton id='add-all-button' onClick={this._onSelectAll}>
              Choose ALL Relationships
            </ChubButton>
            <ChubH2>Selected</ChubH2>
            {this._renderFileRouteTable()}
            <AddButtonSection>
              <ChubButton onClick={this._onClickAddRelationship} data-button-type='relationship-add'>
                Add to Configuration
              </ChubButton>
            </AddButtonSection>
          </Fragment>
        )}
      </TableWrapper>
    );
  }
}
