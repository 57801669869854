import glamorous from 'glamorous';
import { Chicon, colors } from 'react-chui';

const Icon = glamorous(Chicon)({
  fontSize: '19px',
  fontStyle: 'normal',
  width: 'auto',
  margin: '0 5px',
});

export const DeleteIcon = glamorous(Icon, { displayName: 'DeleteIcon', withProps: { name: 'circlex' } })(
  ({ fontSize = '19px' }) => ({
    color: colors.chred,
    cursor: 'pointer',
    fontSize,
  })
);

export const AddIcon = glamorous(Icon, { displayName: 'AddIcon', withProps: { name: 'plus' } })({
  cursor: 'pointer',
});

export const UnavailableIcon = glamorous(Icon, { displayName: 'UnavailableIcon', withProps: { name: 'lock' } })({
  color: colors.chred,
});

export const ConfirmIcon = glamorous(Icon, { displayName: 'ConfirmIcon', withProps: { name: 'circlecheck' } })({
  color: colors.chgreen,
});

export const CollapseIcon = glamorous(Chicon, {
  withProps: { name: 'crosseye' },
  displayName: 'CollapseIcon',
})({
  color: 'black',
  fontSize: '28px',
  fontStyle: 'normal',
  margin: '0 5px',
});

export const ExpandIcon = glamorous(Chicon, {
  withProps: { name: 'eye' },
  displayName: 'ExpandIcon',
})({
  color: 'black',
  fontSize: '28px',
  fontStyle: 'normal',
  margin: '0 5px',
});

export const CloseIcon = glamorous(Icon, { displayName: 'CloseIcon', withProps: { name: 'circlex' } })();

export const AttentionIcon = glamorous(Icon, {
  displayName: 'ChangeIcon',
  filterProps: ['editMade'],
  withProps: {
    name: 'trianglealert',
  },
})(
  ({ error }) => ({ color: error ? colors.chred : null }),
  ({ editMade }) => ({ color: editMade ? colors.chgreen : null })
);
