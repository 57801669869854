import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';
import { chubStandards } from '../../../styles/typography';

const ToggleBackground = glamorous('div', { displayname: 'ToggleBackground' })(({ checked }) => {
  const baseStyles = {
    backgroundColor: colors.medgray,
    display: 'inline-block',
    borderRadius: '20px',
    padding: '0 20px 0 0',
    height: '20px',
  };
  if (checked) {
    baseStyles.backgroundColor = colors.brightblue;
  }
  return baseStyles;
});
const ToggleInput = glamorous('input', { displayname: 'ToggleInput' })(() => ({
  display: 'none',
}));
const ToggleStatus = glamorous('p', { displayName: 'ToggleStatus' })(chubStandards, {
  marginTop: '4px',
  marginBottom: '4px',
});
const ToggleLabel = glamorous('label', { displayname: 'ToggleLabel' })(({ checked, readOnly }) => {
  const baseStyles = {
    position: 'relative',
    transition: 'transform 0.1s',
    cursor: 'pointer',
    backgroundColor: 'white',
    height: '20px',
    lineHeight: '0px',
    margin: 0,
    display: 'inline-block',
    width: '20px',
    borderRadius: '20px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.25)',
  };
  if (checked) {
    baseStyles.transform = 'translateX(20px)';
  }
  if (readOnly) {
    baseStyles.cursor = 'default';
  }
  return baseStyles;
});

const Toggle = glamorous('div', { displayName: 'Toggle' })({
  textAlign: 'center',
  display: 'inline-block',
});

export default class ConnectionToggle extends Component {
  static displayName = 'ConnectionToggle';

  static propTypes = {
    connectionType: PropTypes.string,
    onClick: PropTypes.func,
    origValue: PropTypes.bool,
    newValue: PropTypes.bool,
    serverMessages: PropTypes.array,
    changed: PropTypes.bool,
    id: PropTypes.string,
    readOnly: PropTypes.bool,
    css: PropTypes.object,
  };

  static defaultProps = {
    // eslint-disable-next-line
    onClick: () => {},
    connectionType: '',
    origValue: false,
    changed: false,
    readOnly: true,
  };

  _isEnabled = () => {
    const { origValue, newValue, changed } = this.props;
    return changed ? newValue : origValue;
  };

  _onClick = () => {
    const { id, onClick, changed, origValue, newValue, connectionType, readOnly } = this.props;
    if (!readOnly) {
      const currValue = changed ? !newValue : !origValue;
      onClick(currValue, connectionType, id);
    }
  };

  render() {
    const { id, css, ...otherProps } = this.props;
    const checked = this._isEnabled();
    const connectionStatus = checked ? 'Enabled' : 'Disabled';
    return (
      <Toggle css={css}>
        <ToggleBackground {...{ checked }}>
          <ToggleInput {...{ checked }} readOnly />
          <ToggleLabel {...{ ...otherProps, checked, id }} onClick={this._onClick} />
        </ToggleBackground>
        <ToggleStatus id={`${id}-status`}>{connectionStatus}</ToggleStatus>
      </Toggle>
    );
  }
}
