import { connect } from 'react-redux';

import LoadingStateProvider from '../../../components/LoadingStateProvider';

const mapStateToProps = (state) => {
  const {
    versionDisplay: { listError },
  } = state;
  return {
    error: listError,
  };
};

export default connect(mapStateToProps)(LoadingStateProvider);
