import React from 'react';
import { useParams } from 'react-router-dom';

import AS2ForwarderRequestApp from './AS2ForwarderRequestApp';

const AS2ForwarderRequestEntry = () => {
  const { requestId } = useParams();

  return <AS2ForwarderRequestApp requestId={requestId!} />;
};

export default AS2ForwarderRequestEntry;
