import React from 'react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './../../authConfig';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import AuthLoading from './AuthLoading';
import AuthError from './AuthError';

interface ProtectedRouteProps {
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={AuthError}
      loadingComponent={AuthLoading}
    >
      {element}
    </MsalAuthenticationTemplate>
  );
};

export default ProtectedRoute;
