import {
  FETCH_STOPPABLE_FILES_LOADING,
  FETCH_STOPPABLE_FILES_COMPLETED,
  FETCH_STOPPABLE_FILES_FAILED,
  RECEIVE_STOPPABLE_FILES,
  STOP_FILE_REQUESTED,
  STOP_FILE_COMPLETED,
  STOP_FILE_FAILED,
} from '../StoppableFilesActionTypes';

import { fetchClient } from '../../../util/fetchClient';

export function fetchStoppableFiles(environmentName, environmentList) {
  return (dispatch) => {
    dispatch(fetchStoppableFilesLoading(environmentName, environmentList));
    return fetchClient
      .get(`/transfers/as2/stoppableFiles/${environmentName}`)
      .then((response) => response.data)
      .then((messageDetails) => {
        dispatch(receiveStoppableFilesResults(messageDetails));
        dispatch(fetchStoppableFilesComplete());
      })
      .catch((error) => dispatch(fetchStoppableFilesResultsFailed(error)));
  };
}

function fetchStoppableFilesLoading(selectedEnvironment, environmentList) {
  return {
    type: FETCH_STOPPABLE_FILES_LOADING,
    selectedEnvironment,
    environmentList,
  };
}

function receiveStoppableFilesResults(messageDetails = {}) {
  return {
    type: RECEIVE_STOPPABLE_FILES,
    messageDetails,
  };
}

function fetchStoppableFilesComplete() {
  return {
    type: FETCH_STOPPABLE_FILES_COMPLETED,
  };
}

function fetchStoppableFilesResultsFailed(error) {
  return {
    type: FETCH_STOPPABLE_FILES_FAILED,
    error,
  };
}

export function stopFile(environmentName, sendID) {
  return (dispatch) => {
    dispatch(stopFileRequested(environmentName, sendID));
    return fetchClient
      .post(`/transfers/as2/stoppableFiles/${environmentName}/stop/${sendID}`)
      .then((response) => response.data)
      .then((messageDetails) => {
        dispatch(receiveStopFileResult(messageDetails));
      })
      .catch((error) => dispatch(stopFileError(sendID, error)));
  };
}

function stopFileRequested(environmentName, sendID) {
  return {
    type: STOP_FILE_REQUESTED,
    environmentName,
    sendID,
  };
}

function receiveStopFileResult(messageDetails = {}) {
  const { environmentName, sendID, success, message } = messageDetails;
  if (success) {
    return {
      type: STOP_FILE_COMPLETED,
      environmentName,
      sendID,
      message,
    };
  }
  return (dispatch) => {
    dispatch(stopFileFailed(sendID, message));
  };
}

function stopFileError(sendID, error) {
  return {
    type: STOP_FILE_FAILED,
    sendID,
    error,
  };
}

function stopFileFailed(sendID, message) {
  return {
    type: STOP_FILE_FAILED,
    sendID,
    message,
  };
}
