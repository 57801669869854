import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { ChubInput, chubStandards } from '../../../../styles/typography';
import { ChubTableBodyCell, ChubTableBodyRow } from '../../../../styles/table';
import EditableField from '../../../../components/EditableField';
import EditableTextInput from '../../../../components/EditableTextInput';
import { DeleteIcon } from '../../../../styles/icons';

const TableToggle = glamorous('div', { displayName: 'TableToggle' })(chubStandards, {
  display: 'inline-block',
  borderRadius: '3px',
  padding: '3px',
  margin: '0 10px',
});

const DeleteButton = glamorous('button', { displayName: 'DeleteButton' })({
  border: 'none',
  padding: '0',
  background: 'none',
  ':disabled': {
    display: 'none',
  },
});

export default class FileRouteTypeRow extends Component {
  static displayName = 'FileRouteTypeRow';

  static propTypes = {
    readOnly: PropTypes.bool,
    error: PropTypes.string,
    fileRouteTypeViewModel: PropTypes.object,
    onChangeEditableField: PropTypes.func,
    onDelete: PropTypes.func,
  };

  _isANumber = (value) => {
    const regex = /^[0-9]+$/;
    return value.match(regex);
  };

  _onChangeFileTypeID = (value) => {
    const {
      onChangeEditableField,
      fileRouteTypeViewModel: { fileRouteTypeGUID },
    } = this.props;
    let serverMessages = [];
    if (!this._isANumber(value)) {
      serverMessages = [{ messageType: 'VALIDATION_ERROR', message: `${value} is not an integer` }];
    }
    onChangeEditableField('fileTypeId', value, fileRouteTypeGUID, serverMessages);
  };

  _onChangeValidForAS2 = () => {
    const {
      onChangeEditableField,
      fileRouteTypeViewModel: { validForAS2, fileRouteTypeGUID },
    } = this.props;
    onChangeEditableField('validForAS2', !validForAS2.value, fileRouteTypeGUID);
  };

  _onChangeValidForSFTP = () => {
    const {
      onChangeEditableField,
      fileRouteTypeViewModel: { validForSFTP, fileRouteTypeGUID },
    } = this.props;
    onChangeEditableField('validForSFTP', !validForSFTP.value, fileRouteTypeGUID);
  };

  _onChangeValidForPartnerSFTP = () => {
    const {
      onChangeEditableField,
      fileRouteTypeViewModel: { validForPartnerSFTP, fileRouteTypeGUID },
    } = this.props;
    onChangeEditableField('validForPartnerSFTP', !validForPartnerSFTP.value, fileRouteTypeGUID);
  };

  _onChangeDefaultMd5 = () => {
    const {
      onChangeEditableField,
      fileRouteTypeViewModel: { defaultMd5, fileRouteTypeGUID },
    } = this.props;
    onChangeEditableField('defaultMd5', !defaultMd5.value, fileRouteTypeGUID);
  };

  render() {
    const {
      readOnly,
      onChangeEditableField,
      onDelete,
      fileRouteTypeViewModel: {
        fileTypeId,
        description,
        directoryName,
        validForAS2,
        validForSFTP,
        validForPartnerSFTP,
        createDate,
        updateDate,
        author,
        fileRouteTypeGUID,
        direction,
        application,
        applicationCode,
        defaultMd5,
        defaultRegex,
        defaultExtension,
      },
      ...otherProps
    } = this.props;
    return (
      <ChubTableBodyRow {...otherProps}>
        <ChubTableBodyCell>
          <EditableField {...{ ...fileTypeId, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='fileTypeId'
                width='60px'
                fontSize='13px'
                onChange={(value) => this._onChangeFileTypeID(value)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...description, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='description'
                large
                fontSize='13px'
                onChange={(value) => onChangeEditableField('description', value, fileRouteTypeGUID)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...direction, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='direction'
                small
                fontSize='13px'
                onChange={(value) => onChangeEditableField('direction', value, fileRouteTypeGUID)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...application, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='application'
                width='100px'
                fontSize='13px'
                onChange={(value) => onChangeEditableField('application', value, fileRouteTypeGUID)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...applicationCode, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='applicationCode'
                width='100px'
                fontSize='13px'
                onChange={(value) => onChangeEditableField('applicationCode', value, fileRouteTypeGUID)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...directoryName, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='directoryName'
                width='100px'
                fontSize='13px'
                onChange={(value) => onChangeEditableField('directoryName', value, fileRouteTypeGUID)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell align='center'>
          <TableToggle>
            <ChubInput
              id='valid-for-as2-checkbox'
              data-guid={fileRouteTypeGUID}
              data-input-type='validForAS2'
              type='checkbox'
              checked={validForAS2.value}
              onChange={this._onChangeValidForAS2}
              readOnly={readOnly}
            />
          </TableToggle>
        </ChubTableBodyCell>
        <ChubTableBodyCell align='center'>
          <TableToggle>
            <ChubInput
              id='valid-for-sftp-checkbox'
              data-guid={fileRouteTypeGUID}
              data-input-type='validForSFTP'
              type='checkbox'
              checked={validForSFTP.value}
              onChange={this._onChangeValidForSFTP}
              readOnly={readOnly}
            />
          </TableToggle>
        </ChubTableBodyCell>
        <ChubTableBodyCell align='center'>
          <TableToggle>
            <ChubInput
              id='valid-for-partner-sftp-checkbox'
              data-guid={fileRouteTypeGUID}
              data-input-type='validForPartnerSFTP'
              type='checkbox'
              checked={validForPartnerSFTP.value}
              onChange={this._onChangeValidForPartnerSFTP}
              readOnly={readOnly}
            />
          </TableToggle>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...defaultRegex, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='defaultRegex'
                small
                fontSize='13px'
                onChange={(value) => onChangeEditableField('defaultRegex', value, fileRouteTypeGUID)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell>
          <EditableField {...{ ...defaultExtension, readOnly }}>
            {({ origValue, newValue }) => (
              <EditableTextInput
                data-guid={fileRouteTypeGUID}
                data-input-type='defaultExtension'
                small
                fontSize='13px'
                onChange={(value) => onChangeEditableField('defaultExtension', value, fileRouteTypeGUID)}
                {...{ origValue, newValue, readOnly }}
              />
            )}
          </EditableField>
        </ChubTableBodyCell>
        <ChubTableBodyCell align='center'>
          <TableToggle>
            <ChubInput
              id='default-md5-checkbox'
              data-guid={fileRouteTypeGUID}
              data-input-type='defaultMd5'
              type='checkbox'
              checked={defaultMd5.value}
              onChange={this._onChangeDefaultMd5}
              readOnly={readOnly}
            />
          </TableToggle>
        </ChubTableBodyCell>
        <ChubTableBodyCell id='fileRouteTypeGUID'>{fileRouteTypeGUID}</ChubTableBodyCell>
        <ChubTableBodyCell>{updateDate || createDate}</ChubTableBodyCell>
        <ChubTableBodyCell>{author.value}</ChubTableBodyCell>
        {!readOnly && (
          <ChubTableBodyCell align='center'>
            <DeleteButton
              data-guid={fileRouteTypeGUID}
              data-button-type='delete'
              onClick={() => onDelete(fileRouteTypeGUID)}
            >
              <DeleteIcon />
            </DeleteButton>
          </ChubTableBodyCell>
        )}
      </ChubTableBodyRow>
    );
  }
}
