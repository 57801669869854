import React from 'react';
import PropTypes from 'prop-types';
import PartnerSFTPPastTransfersTable from './PartnerSFTPPastTransfersTable';
import PartnerSFTPPendingTransfersTable from './PartnerSFTPPendingTransfersTable';
import { PartnerSFTPTransferData, PendingPartnerSFTPTransferData, TableType } from '../PartnerSFTPTransfers.model';

const PartnerSFTPTransfersResultsTables = ({
  incomingFileTransfers,
  outgoingFileTransfers,
  pendingFileTransfers,
}: {
  incomingFileTransfers: PartnerSFTPTransferData[];
  outgoingFileTransfers: PartnerSFTPTransferData[];
  pendingFileTransfers: PendingPartnerSFTPTransferData[];
}) => {
  const incomingIsEmpty = !incomingFileTransfers || incomingFileTransfers.length === 0;
  const outgoingIsEmpty = !outgoingFileTransfers || outgoingFileTransfers.length === 0;
  const pendingIsEmpty = !pendingFileTransfers || pendingFileTransfers.length === 0;

  return (
    <>
      <PartnerSFTPPastTransfersTable
        title={'Incoming Transfers'}
        isEmpty={incomingIsEmpty}
        fileTransfers={incomingFileTransfers}
        tableType={TableType.INCOMING}
      />
      <PartnerSFTPPastTransfersTable
        title={'Outgoing Transfers'}
        isEmpty={outgoingIsEmpty}
        fileTransfers={outgoingFileTransfers}
        tableType={TableType.OUTGOING}
      />
      <PartnerSFTPPendingTransfersTable
        title={'Pending Outbound Transfers'}
        isEmpty={pendingIsEmpty}
        fileTransfers={pendingFileTransfers}
      />
    </>
  );
};

PartnerSFTPTransfersResultsTables.displayName = 'PartnerSFTPTransfersResultsTables';
PartnerSFTPTransfersResultsTables.propTypes = {
  incomingFileTransfers: PropTypes.array,
  outgoingFileTransfers: PropTypes.array,
  pendingFileTransfers: PropTypes.array,
};

export default PartnerSFTPTransfersResultsTables;
