export function stoppableFilesLoading(state, action) {
  const { selectedEnvironment, environmentList } = action;
  return {
    ...state,
    isLoading: true,
    error: '',
    selectedEnvironment,
    environmentList,
  };
}

export function fetchStoppableFilesCompleted(state) {
  return {
    ...state,
    isLoading: false,
  };
}

export function fetchStoppableFilesFailed(state, action) {
  const { error } = action;
  return {
    isLoading: false,
    error,
  };
}
