import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { uuidv4 } from '../../../../mockable/uuidv4-wrapper';
import {
  REQUEST_AS2IDENTIFIERS,
  REQUEST_AS2IDENTIFIERS_FAILED,
  RECEIVE_AS2IDENTIFIERS,
  REQUEST_SAVE_AS2IDENTIFIERS,
  SAVE_COMPLETE,
  SAVE_FAILED,
  ADD_AS2ID,
  UPDATE_AS2ID,
  DELETE_AS2ID,
} from '../As2IdActionTypes';

import { fetchClient } from '../../../../util/fetchClient';

function requestAs2Identifiers() {
  return {
    type: REQUEST_AS2IDENTIFIERS,
  };
}

function receiveAs2Identifiers(as2IdResponse = {}) {
  const as2Identifiers = Object.keys(as2IdResponse).map((as2IdIndex) => {
    //eslint-disable-next-line
    const as2Id = Object.assign({}, as2IdResponse[as2IdIndex]);

    as2Id.origAs2ID = as2Id.as2ID;
    return as2Id;
  });

  return {
    type: RECEIVE_AS2IDENTIFIERS,
    as2Identifiers,
  };
}

export function fetchAs2Identifiers() {
  return (dispatch) => {
    dispatch(requestAs2Identifiers());

    return fetchClient
      .get('/admin/as2Ids')
      .then((response) => response.data)
      .then(({ as2Ids }) => {
        dispatch(receiveAs2Identifiers(as2Ids));
        dispatch(UndoActionCreators.clearHistory());
      })
      .catch((error) => dispatch(fetchAs2IdentifiersFailed(error)));
  };
}

function fetchAs2IdentifiersFailed(error) {
  return {
    type: REQUEST_AS2IDENTIFIERS_FAILED,
    error,
  };
}

function requestSaveAs2Identifiers() {
  return {
    type: REQUEST_SAVE_AS2IDENTIFIERS,
  };
}

function saveComplete(errors, editedAs2Ids, deletedAs2Ids) {
  return {
    type: SAVE_COMPLETE,
    errors,
    editedAs2Ids,
    deletedAs2Ids,
  };
}

function saveFailed(error) {
  return {
    type: SAVE_FAILED,
    error,
  };
}

export function deleteAs2Identifier(deletedAs2Identifier) {
  return {
    type: DELETE_AS2ID,
    deletedAs2Identifier,
  };
}

export function saveAs2Identifiers() {
  return (dispatch, getState) => {
    const {
      present: { as2Identifiers, locallyDeletedAs2Ids },
    } = getState();
    const editedAs2Ids = as2Identifiers.filter(({ editMade }) => editMade);
    const editedAs2IdsCopy = JSON.parse(JSON.stringify(editedAs2Ids));
    const deletedAs2IdsCopy = JSON.parse(JSON.stringify(locallyDeletedAs2Ids));
    editedAs2IdsCopy.forEach((as2Id) => {
      as2Id.createDate = null;
      as2Id.updateDate = null;
    });
    deletedAs2IdsCopy.forEach((as2Id) => {
      as2Id.createDate = null;
      as2Id.updateDate = null;
    });

    const requestBody = {
      deletedAs2Ids: deletedAs2IdsCopy,
      editedAs2Ids: editedAs2IdsCopy,
    };

    dispatch(requestSaveAs2Identifiers());

    return fetchClient
      .post('/admin/as2Ids', requestBody)
      .then((response) => response.data)
      .then(({ errors, editedAs2Ids: responseEditedAs2Ids, deletedAs2Ids: responseDeletedAs2Ids }) => {
        dispatch(saveComplete(errors, responseEditedAs2Ids, responseDeletedAs2Ids));
        dispatch(UndoActionCreators.clearHistory());
      })
      .catch((error) => {
        dispatch(saveFailed(error));
      });
  };
}

export function updateAs2Id(as2Identifier) {
  return {
    type: UPDATE_AS2ID,
    payload: as2Identifier,
  };
}

export function addAs2Id(as2Id) {
  return {
    type: ADD_AS2ID,
    payload: {
      as2ID: as2Id,
      editMade: true,
      origAs2ID: '',
      systemIdentifierGUID: uuidv4(),
    },
  };
}
