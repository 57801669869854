import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { colors } from 'react-chui';
import { ChubTableBodyRow, ChubTableBodyCell } from '../../../styles/table';
import { ChubButton, ChubP, chubStandards } from '../../../styles/typography';
import { DateTime } from 'luxon';

const StopButton = glamorous(ChubButton, { displayName: 'StopButton' })((props) => ({
  background: colors.chred,
  color: 'white',
  margin: '0 10px',
  ':hover': {
    background: props.value ? colors.coolgraylight2 : '#c44f39',
  },
}));

const StatusText = glamorous(ChubP, { displayName: 'StatusText' })(chubStandards, {
  fontWeight: 'bold',
  fontSize: '16px',
});

const ErrorText = glamorous(ChubP, { displayName: 'ErrorText' })(chubStandards, {
  color: colors.chred,
  textAlign: 'center',
});

function formatDate(date) {
  const dateTime = DateTime.fromISO(date.toISOString(), { zone: 'America/New_York' });
  return dateTime.toFormat('MMM d, yyyy h:mm:ss a ZZZZ');
}

export default class StoppableFileTableRow extends Component {
  static displayName = 'StoppableFileTableRow';
  static propTypes = {
    selectedEnvironment: PropTypes.string,
    externalId: PropTypes.string,
    host: PropTypes.string,
    sendID: PropTypes.number,
    insertedDT: PropTypes.string,
    stoppedStatus: PropTypes.string,
    message: PropTypes.string,
    stopFileAction: PropTypes.func,
  };

  _onStopButtonClick = () => {
    const { selectedEnvironment, sendID, stopFileAction } = this.props;
    stopFileAction(selectedEnvironment, sendID);
  };

  render() {
    const { externalId, host, sendID, insertedDT, stoppedStatus, message } = this.props;
    const insertedDate = new Date(insertedDT);
    return (
      <ChubTableBodyRow>
        <ChubTableBodyCell>{externalId}</ChubTableBodyCell>
        <ChubTableBodyCell>{host}</ChubTableBodyCell>
        <ChubTableBodyCell>{sendID}</ChubTableBodyCell>
        <ChubTableBodyCell>{formatDate(insertedDate)}</ChubTableBodyCell>
        <ChubTableBodyCell>
          <div>
            {stoppedStatus ? (
              <StatusText id={`stopped-${host}-${sendID}`}>{stoppedStatus}</StatusText>
            ) : (
              <StopButton id={`stop-file-button-${host}`} onClick={this._onStopButtonClick}>
                Stop
              </StopButton>
            )}
            {message && <ErrorText>{message}</ErrorText>}
          </div>
        </ChubTableBodyCell>
      </ChubTableBodyRow>
    );
  }
}
