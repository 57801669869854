import React, { useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { fetchEnvironments, setReadOnly } from './actions';

import Environments from './EnvironmentsApp';
import undoableEnvironment from './reducers';

const readOnly = false;

const store = createStore(undoableEnvironment, applyMiddleware(thunkMiddleware));

const EnvironmentsEntry = () => {
  useEffect(() => {
    store.dispatch(setReadOnly(readOnly));
    store.dispatch(fetchEnvironments());
  }, []);

  return (
    <>
      <Provider store={store}>
        <Environments readOnly={readOnly} />
      </Provider>
    </>
  );
};

export default EnvironmentsEntry;
