import { EditableFieldViewModel } from './base/EditableFieldViewModel';
import { DeletableEntity } from './base/DeletableEntity';

export class EnvironmentViewModel extends DeletableEntity {
  constructor(jsonModel) {
    super();
    this.environmentGUID = new EditableFieldViewModel(jsonModel.environmentGUID);
    this.environmentName = new EditableFieldViewModel(jsonModel.environmentName);
    this.as2Gateways = new EditableFieldViewModel({
      ...jsonModel.as2Gateways,
      origValue: JSON.stringify(jsonModel?.as2Gateways?.origValue, (k, v) => v ?? undefined),
    });
    this.createDate = jsonModel.createDate;
    this.updateDate = jsonModel.updateDate;
  }

  get hasChanges() {
    return Boolean(
      this.environmentGUID.hasChanges ||
        this.environmentName.hasChanges ||
        this.as2Gateways.hasChanges ||
        super.hasChanges
    );
  }

  get serverMessages() {
    return this.environmentName.serverMessages + this.environmentGUID.serverMessages;
  }
}

export const mapEnvironmentViewModelToServerData = (viewModel) => {
  return {
    ...viewModel,
    as2Gateways: {
      ...viewModel.as2Gateways,
      origValue: viewModel.as2Gateways.origValue ? JSON.parse(viewModel.as2Gateways.origValue) : undefined,
      newValue: viewModel.as2Gateways.newValue ? JSON.parse(viewModel.as2Gateways.newValue) : undefined,
    },
  };
};
