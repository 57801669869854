import React from 'react';
import { useParams } from 'react-router-dom';

import SFTPTransfersResults from './SFTPTransfersResultsApp';

const SFTPTransfersResultsEntry = () => {
  const { partnerId, partnerGUID, numberOfTransfers, environmentName } = useParams();

  return (
    <SFTPTransfersResults
      partnerId={partnerId!}
      partnerGUID={partnerGUID!}
      numberOfTransfers={numberOfTransfers!}
      environmentName={environmentName!}
    />
  );
};

export default SFTPTransfersResultsEntry;
