import {
  TOGGLE_NONDEPLOYED_VERSIONS,
  REQUEST_NONDEPLOYED_VERSIONS_FAILED,
  RESET_STATE,
} from '../ClientOverviewActionTypes';

const initialState = {
  displayNonDeployedVersions: false,
  listError: '',
};

const versionDisplayReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NONDEPLOYED_VERSIONS: {
      const { displayNonDeployedVersions } = state;
      return {
        ...state,
        displayNonDeployedVersions: !displayNonDeployedVersions,
      };
    }
    case REQUEST_NONDEPLOYED_VERSIONS_FAILED: {
      const {
        error: { message: fetchError },
      } = action;
      return {
        ...state,
        listError: fetchError,
      };
    }
    case RESET_STATE: {
      return initialState;
    }
  }
  return state;
};

export default versionDisplayReducer;
