import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { chubStandards, ChubA } from '../../../styles/typography';

const VersionLink = glamorous(ChubA, { displayName: 'VersionLink' })({
  minWidth: '300px',
  display: 'inline-block',
  fontSize: '18px',
  fontWeight: 'bold',
});

const VersionLabel = glamorous('label', { displayName: 'VersionLabel' })({
  minWidth: '300px',
  display: 'inline-block',
  fontSize: '18px',
  fontWeight: 'bold',
});

const SearchFieldData = glamorous('label', { displayName: 'SearchFieldData' })(chubStandards, {
  fontSize: '18px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  minWidth: '300px',
  marginLeft: '12px',
});

const ClientResultDiv = glamorous('div', { displayName: 'ClientResultRow' })(chubStandards, {
  marginBottom: '8px',
});

export default class ClientResultRow extends Component {
  static displayName = 'ClientResultRow';

  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    searchFieldData: PropTypes.string,
    environmentName: PropTypes.string,
    isAdminRecord: PropTypes.bool,
  };

  render() {
    const { id, name, searchFieldData, environmentName, isAdminRecord } = this.props;
    const linkPath = `/client/clientOverview/${id}`;
    return (
      <ClientResultDiv>
        {isAdminRecord ? <VersionLabel>{name}</VersionLabel> : <VersionLink to={linkPath}>{name}</VersionLink>}
        {environmentName && <SearchFieldData>{environmentName}</SearchFieldData>}
        {searchFieldData && <SearchFieldData>{searchFieldData}</SearchFieldData>}
      </ClientResultDiv>
    );
  }
}
