import {
  CONFIGURATION_DATA_REQUEST_FAILED,
  FETCH_CONFIGURATION_VIEW_MODEL_LOADING,
  FETCH_CONFIGURATION_VIEW_MODEL_COMPLETED,
  SAVE_CONFIGURATION_VIEW_MODEL_LOADING,
  SAVE_CONFIGURATION_VIEW_MODEL_COMPLETED,
  RESET_STATE,
} from '../ClientConfigurationActionTypes';

const initialState = {
  isLoading: true,
  isSaving: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONFIGURATION_VIEW_MODEL_LOADING: {
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    }
    case FETCH_CONFIGURATION_VIEW_MODEL_COMPLETED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SAVE_CONFIGURATION_VIEW_MODEL_LOADING: {
      return {
        ...state,
        isSaving: true,
        error: '',
      };
    }
    case SAVE_CONFIGURATION_VIEW_MODEL_COMPLETED: {
      return {
        ...state,
        isSaving: false,
      };
    }
    case CONFIGURATION_DATA_REQUEST_FAILED: {
      const { error } = action;
      return {
        isLoading: false,
        isSaving: false,
        error,
      };
    }
    case RESET_STATE: {
      return initialState;
    }
  }
  return state;
};

export default reducer;
