export function setClientInfo(state, action) {
  const { clientName, clientGUID } = action;
  return {
    ...state,
    clientName,
    clientGUID,
  };
}

export function setCopyableEnvironments(state, action) {
  const { copyableEnvironments, environmentList } = action;
  return {
    ...state,
    copyableEnvironments,
    environmentList,
  };
}

export function setChubSystemIdentifierList(state, action) {
  const { chubSystemIdentifiers } = action;
  return {
    ...state,
    chubSystemIdentifiers,
  };
}

export function setAS2FileRouteList(state, action) {
  const { as2FileRouteTypes: serverAS2FileRouteTypes = [] } = action;
  const as2FileRouteTypes = serverAS2FileRouteTypes.reduce(
    (accum, { description, fileTypeId, application, applicationCode, direction }) => {
      accum[fileTypeId] = {
        text: fileTypeId,
        name: description,
        endpointName: application,
        direction,
        applicationCode,
      };
      return accum;
    },
    {}
  );
  return {
    ...state,
    as2FileRouteTypes,
  };
}

export function setSFTPFileRouteList(state, action) {
  const { sftpFileRouteTypes: serverSFTPFileRouteTypes = [] } = action;
  const sftpFileRouteTypes = serverSFTPFileRouteTypes.reduce(
    (accum, { description, fileTypeId, application, applicationCode, direction, directoryName }) => {
      accum[fileTypeId] = {
        text: fileTypeId,
        name: description,
        endpointName: application,
        applicationCode,
        direction,
        directoryName,
      };
      return accum;
    },
    {}
  );
  return {
    ...state,
    sftpFileRouteTypes,
  };
}

export function setPartnerSFTPFileRouteList(state, action) {
  const { partnerSftpFileRouteTypes: serverPartnerSFTPFileRouteTypes = [] } = action;
  const partnerSftpFileRouteTypes = serverPartnerSFTPFileRouteTypes.reduce(
    (accum, { description, fileTypeId, application, applicationCode, direction }) => {
      accum[fileTypeId] = {
        text: fileTypeId,
        name: description,
        endpointName: application,
        direction,
        applicationCode,
      };
      return accum;
    },
    {}
  );
  return {
    ...state,
    partnerSftpFileRouteTypes,
  };
}

export function setChubSystemCertificateList(state, action) {
  const { chubSystemCertificates } = action;
  return {
    ...state,
    chubSystemCertificates,
  };
}

export function setMicAlgorithmList(state, action) {
  const { micAlgorithms } = action;
  return {
    ...state,
    micAlgorithms,
  };
}
