import React, { Fragment } from 'react';
import DefaultHeaderContainer from './containers/DefaultHeaderContainer';
import ClientSummaryContainer from './containers/ClientSummaryContainer';
import LoadingStateProviderContainer from './containers/LoadingStateProviderContainer';
import ViewControllerContainer from './containers/ViewControllerContainer';

const ClientConfiguration = () => (
  <Fragment>
    <DefaultHeaderContainer />
    <ClientSummaryContainer />
    <LoadingStateProviderContainer>{() => <ViewControllerContainer />}</LoadingStateProviderContainer>
  </Fragment>
);

ClientConfiguration.displayName = 'ClientConfiguration';
export default ClientConfiguration;
