import React from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import PartnerSFTPTransfers from './components/PartnerSFTPTransfers';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import ClientSummary from '../../components/ClientSummary';

const Documentation = glamorous.div({ margin: '0 20px' });

const PartnerSFTPTransfersResultsApp = ({
  partnerId,
  partnerGUID,
  numberOfTransfers,
  environmentName,
}: {
  partnerId: string;
  partnerGUID: string;
  numberOfTransfers: string;
  environmentName: string;
}) => {
  return (
    <>
      <DefaultHeader showSearch={true} />
      <ClientSummary clientName={partnerId} clientGUID={partnerGUID} />
      <Documentation>
        {/*<ChubA to={'/documentation/commonPartnerSFTPErrors'}>Click here for help with common Partner SFTP errors.</ChubA>*/}
      </Documentation>
      <PartnerSFTPTransfers
        partnerId={partnerId}
        defaultNumberOfTransfers={numberOfTransfers}
        defaultEnvironmentName={environmentName}
      />
    </>
  );
};

PartnerSFTPTransfersResultsApp.displayName = 'PartnerSFTPTransfersResultsApp';

PartnerSFTPTransfersResultsApp.propTypes = {
  partnerId: PropTypes.string,
  partnerGUID: PropTypes.string,
  numberOfTransfers: PropTypes.string.isRequired,
  environmentName: PropTypes.string.isRequired,
};

export default PartnerSFTPTransfersResultsApp;
