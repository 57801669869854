import React, { Component, Fragment } from 'react';
import DefaultHeader from '../../components/DefaultHeader';
import VersionSummaryContainer from './containers/VersionSummaryContainer';

export default class ClientOverview extends Component {
  static displayName = 'ClientOverview';

  render() {
    return (
      <Fragment>
        <DefaultHeader showSearch={true} />
        <VersionSummaryContainer />
      </Fragment>
    );
  }
}
