import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import EditableField from '../../../components/EditableField';
import EditableTextInput from '../../../components/EditableTextInput';
import ConfigurationLabel from './ConfigurationLabel';
import { ChubInput } from '../../../styles/typography';

const EditableFieldRow = glamorous('div', { displayName: 'EditableFieldRow' })({});
const UrlOptions = glamorous('div', { displayName: 'UrlOptions' })({
  display: 'inline-block',
  width: '415px',
});

export default class EditableUrl extends Component {
  static displayName = 'EditableUrl';

  static propTypes = {
    url: PropTypes.object,
    https: PropTypes.object,
    onChangeUrl: PropTypes.func,
    onChangePort: PropTypes.func,
    onChangeHttps: PropTypes.func,
    parentId: PropTypes.string,
    port: PropTypes.object,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const {
      https: { origValue },
    } = props;
    this.state = {
      httpsSelected: origValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      https: { origValue, newValue },
    } = nextProps;
    const {
      https: { origValue: currentOrigValue, newValue: currentNewValue },
    } = this.props;
    const { httpsSelected } = this.state;
    if (origValue !== currentOrigValue || newValue !== currentNewValue || newValue !== httpsSelected) {
      this.setState({
        httpsSelected: typeof newValue === 'undefined' ? origValue : newValue,
      });
    }
  }

  _httpsChecked = () => {
    const {
      https: { origValue, newValue },
    } = this.props;
    return typeof newValue === 'undefined' ? origValue : newValue;
  };

  _onChangeHttps = ({ target: { checked }, currentTarget: { id } }) => {
    const { onChangeHttps } = this.props;
    onChangeHttps(checked, id);
  };

  render() {
    const { url, https, port, readOnly, onChangeUrl, onChangePort, parentId } = this.props;
    return (
      <Fragment>
        <EditableFieldRow>
          <EditableField {...{ ...url, readOnly }} id={`url-field-${parentId}`}>
            {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`url-${parentId}`}>URL:</ConfigurationLabel>
                <EditableTextInput
                  debounceWaitMS={1500}
                  id={`url-${parentId}`}
                  {...editableInputProps}
                  onChange={onChangeUrl}
                  width='850'
                />
              </Fragment>
            )}
          </EditableField>
        </EditableFieldRow>
        <UrlOptions>
          <EditableField {...{ ...https, readOnly }} id={`https-field-${parentId}`}>
            {() => (
              <Fragment>
                <ConfigurationLabel for={`https-${parentId}`}>HTTPS:</ConfigurationLabel>
                <ChubInput
                  onChange={this._onChangeHttps}
                  id={`https-${parentId}`}
                  type='checkbox'
                  checked={this._httpsChecked()}
                  disabled={readOnly ? 'disabled' : ''}
                />
              </Fragment>
            )}
          </EditableField>
          <EditableField {...{ ...port, readOnly }} id={`port-field-${parentId}`}>
            {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`port-${parentId}`}>PORT:</ConfigurationLabel>
                <EditableTextInput
                  debounceWaitMS={1500}
                  type='number'
                  id={`port-${parentId}`}
                  {...editableInputProps}
                  onChange={onChangePort}
                  width='100px'
                />
              </Fragment>
            )}
          </EditableField>
        </UrlOptions>
      </Fragment>
    );
  }
}
