import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import FileRouteTable from './fileRoutes/FileRouteTable';
import { PartnerSftpFileRoute } from './fileRoutes/FileRoutes';
import EditableField from '../../../components/EditableField';
import EditableTextInput from '../../../components/EditableTextInput';
import EditableTextAreaInput from '../../../components/EditableTextAreaInput';
import ConnectionToggle from './ConnectionToggle';
import ConfigurationLabel from './ConfigurationLabel';
import { ChubTable, ChubTableHeadCell, ChubTableBody } from '../../../styles/table';
import { PARTNER_SFTP_CONNECTION_TYPE } from '../../../util/commerceHubInfo';
import EditableUrlPartnerSftp from "./EditableUrlPartnerSftp";

const EditableFieldRow = glamorous('div', { displayName: 'EditableFieldRow' })({
  padding: '20px 0',
});

const Configuration = glamorous('div', { displayName: 'Configuration' })({
  margin: '20px',
});

const RouteTable = glamorous(ChubTable, { diplayName: 'RouteTable' })({
  width: '100%',
});

const TableSection = glamorous('div', { displayName: 'TableSection' })({
  marginTop: '20px',
  maxWidth: '1200px',
  overflowX: 'scroll',
  flexGrow: 1,
});

const PartnerSftpConfiguration = (props) => {
  const {
    id,
    readOnly,
    connectionEnabled,
    fileRoutesVisible,
    fileRoutes,
    toggleFileRouteVisibility,
    partnerSftpFileRouteTypes,
    onClickConnectionToggle,
    onChangeFileRouteKey,
    onChangeFileRouteAdditionalRoutingData,
    onChangeUrl,
    onChangePort,
    onChangeUsername,
    onChangePassword,
    onChangeClientAuthCert,
    onChangeClientAuthCertPassword,
    onChangeClientAuthPrivateKeyFile,
    onChangeClientAuthPrivateKeyFilePassword,
    onAddPartnerSFTPFileRoute,
    onDeletePartnerSFTPFileRoute,
    onChangeSubPath,
    onClickAddSubPath,
    onClickDeleteSubPath,
    url,
    port,
    username,
    clientAuthenticationCert,
    clientAuthenticationCertPassword,
    clientAuthenticationPrivateKeyFile,
    clientAuthenticationPrivateKeyFilePassword,
    password,
  } = props;

  return (
    <Configuration>
      <EditableFieldRow>
        <EditableUrlPartnerSftp {...{ url, port, onChangeUrl, onChangePort, readOnly, id }} />
        <EditableField {...{ ...connectionEnabled, readOnly }}>
          {({ ...editableInputProps }) => (
              <ConnectionToggle
                  css={{ float: 'right' }}
                  id={`partner-sftp-connection-toggle-${id}`}
                  connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                  onClick={onClickConnectionToggle}
                  {...editableInputProps}
              />
          )}
        </EditableField>
      </EditableFieldRow>
      <EditableFieldRow>
        <EditableField {...{ ...username, readOnly }} id={`username-field-${id}`}>
          {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`username-${id}`}>Username:</ConfigurationLabel>
                <EditableTextInput
                    connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                    debounceWaitMS={1500}
                    id={`username-${id}`}
                    {...editableInputProps}
                    onChange={onChangeUsername}
                    width='850'
                />
              </Fragment>
          )}
        </EditableField>
      </EditableFieldRow>
      <EditableFieldRow>
        <EditableField {...{ ...password, readOnly }} id={`password-field-${id}`}>
          {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`password-${id}`}>Password:</ConfigurationLabel>
                <EditableTextInput
                    connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                    debounceWaitMS={1500}
                    id={`password-${id}`}
                    type={'password'}
                    {...editableInputProps}
                    onChange={onChangePassword}
                    autocomplete='off'
                    width='850'
                />
              </Fragment>
          )}
        </EditableField>
      </EditableFieldRow>
      <EditableFieldRow>
        <EditableField {...{...clientAuthenticationCert, readOnly }} id={`client-auth-cert-field-${id}`}>
          {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`client-auth-cert-${id}`}>Authentication Cert:</ConfigurationLabel>
                <EditableTextAreaInput
                    connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                    debounceWaitMS={1500}
                    id={`client-auth-cert-${id}`}
                    {...editableInputProps}
                    onChange={onChangeClientAuthCert}
                    autocomplete='off'
                />
              </Fragment>
          )}
        </EditableField>
        <EditableField {...{ ...clientAuthenticationCertPassword, readOnly }} id={`client-auth-cert-password-field-${id}`}>
          {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`client-auth-cert-password-${id}`}>Authentication Cert Password:</ConfigurationLabel>
                <EditableTextInput
                    connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                    debounceWaitMS={1500}
                    id={`client-auth-cert-password-${id}`}
                    type={'password'}
                    {...editableInputProps}
                    onChange={onChangeClientAuthCertPassword}
                    autocomplete='off'
                    medium
                />
              </Fragment>
          )}
        </EditableField>
      </EditableFieldRow>
      <EditableFieldRow>
        <EditableField {...{...clientAuthenticationPrivateKeyFile, readOnly }} id={`client-auth-private-key-file-field-${id}`}>
          {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`client-auth-private-key-file-${id}`}>Authentication Private Key File:</ConfigurationLabel>
                <EditableTextAreaInput
                    connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                    debounceWaitMS={1500}
                    id={`client-auth-private-key-file-${id}`}
                    {...editableInputProps}
                    onChange={onChangeClientAuthPrivateKeyFile}
                    autocomplete='off'
                />
              </Fragment>
          )}
        </EditableField>

        <EditableField {...{ ...clientAuthenticationPrivateKeyFilePassword, readOnly }} id={`client-auth-private-key-file-password-field-${id}`}>
          {({ ...editableInputProps }) => (
              <Fragment>
                <ConfigurationLabel for={`client-auth-private-key-file-password-${id}`}>Authentication Private Key File Password:</ConfigurationLabel>
                <EditableTextInput
                    connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                    debounceWaitMS={1500}
                    id={`client-auth-private-key-file-password-${id}`}
                    type={'password'}
                    {...editableInputProps}
                    onChange={onChangeClientAuthPrivateKeyFilePassword}
                    autocomplete='off'
                    medium
                />
              </Fragment>
          )}
        </EditableField>
      </EditableFieldRow>
      <FileRouteTable
        connectionType={PARTNER_SFTP_CONNECTION_TYPE}
        onAddFileRoute={onAddPartnerSFTPFileRoute}
        {...{
          fileRoutes,
          fileRoutesVisible,
          toggleFileRouteVisibility,
          readOnly,
          partnerSftpFileRouteTypes,
          parentId: id,
        }}
      >
        {() => (
          <TableSection>
            <RouteTable>
              <thead>
                <tr>
                  <ChubTableHeadCell width='90px'>Application</ChubTableHeadCell>
                  <ChubTableHeadCell width='90px'>Application Code</ChubTableHeadCell>
                  <ChubTableHeadCell width='17%'>Name</ChubTableHeadCell>
                  <ChubTableHeadCell width='15%'>Key</ChubTableHeadCell>
                  <ChubTableHeadCell width='10%'>Direction</ChubTableHeadCell>
                  <ChubTableHeadCell width='15%'>Additional Routing Data</ChubTableHeadCell>
                  <ChubTableHeadCell width='15%'>SubPaths</ChubTableHeadCell>
                  <ChubTableHeadCell width='3%' />
                </tr>
              </thead>
              <ChubTableBody>
                {Object.keys(fileRoutes).map(
                  (fileRouteGUID) =>
                    !fileRoutes[fileRouteGUID].deleted && (
                      <PartnerSftpFileRoute
                        key={fileRouteGUID}
                        parentId={id}
                        onDeleteFileRoute={onDeletePartnerSFTPFileRoute}
                        connectionType={PARTNER_SFTP_CONNECTION_TYPE}
                        {...{
                          ...fileRoutes[fileRouteGUID],
                          fileRouteGUID,
                          readOnly,
                          partnerSftpFileRouteTypes,
                          onChangeFileRouteKey,
                          onChangeFileRouteAdditionalRoutingData,
                          onChangeSubPath,
                          onClickAddSubPath,
                          onClickDeleteSubPath,
                        }}
                      />
                    )
                )}
              </ChubTableBody>
            </RouteTable>
          </TableSection>
        )}
      </FileRouteTable>
    </Configuration>
  );
};
export default PartnerSftpConfiguration;
PartnerSftpConfiguration.displayName = 'PartnerSftpConfiguration';
PartnerSftpConfiguration.propTypes = {
  id: PropTypes.string,
  url: PropTypes.object,
  port: PropTypes.object,
  username: PropTypes.object,
  password: PropTypes.object,
  clientAuthenticationCert: PropTypes.object,
  clientAuthenticationCertPassword: PropTypes.object,
  clientAuthenticationPrivateKeyFile: PropTypes.object,
  clientAuthenticationPrivateKeyFilePassword: PropTypes.object,
  environmentGUID: PropTypes.string,
  connectionEnabled: PropTypes.object,
  readOnly: PropTypes.bool,
  partnerSftpFileRouteTypes: PropTypes.object,
  toggleFileRouteVisibility: PropTypes.func,
  fileRoutesVisible: PropTypes.bool,
  fileRoutes: PropTypes.object,
  onClickConnectionToggle: PropTypes.func,
  onChangeFileRouteKey: PropTypes.func,
  onChangeFileRouteRegex: PropTypes.func,
  onChangeFileRouteExtension: PropTypes.func,
  onChangeFileRouteContentType: PropTypes.func,
  onChangeFileRouteAdditionalRoutingData: PropTypes.func,
  onChangeUrl: PropTypes.func,
  onChangeUsername: PropTypes.func,
  onChangePassword: PropTypes.func,
  onChangeClientAuthCert: PropTypes.func,
  onChangeClientAuthCertPassword: PropTypes.func,
  onChangeClientAuthPrivateKeyFile: PropTypes.func,
  onChangeClientAuthPrivateKeyFilePassword: PropTypes.func,
  onAddPartnerSFTPFileRoute: PropTypes.func,
  onDeletePartnerSFTPFileRoute: PropTypes.func,
  currentSection: PropTypes.string,
  canSave: PropTypes.bool,
  headVersionStatus: PropTypes.string,
  clientName: PropTypes.string,
  clientGUID: PropTypes.string,
  onChangeSubPath: PropTypes.func,
  onClickAddSubPath: PropTypes.func,
  onClickDeleteSubPath: PropTypes.func,
};

PartnerSftpConfiguration.defaultProps = {
  readOnly: true,
  fileRoutesVisible: false,
  partnerSftpFileRouteTypes: [],
};
