import React from 'react';
import { connect } from 'react-redux';
import { colors } from 'react-chui';

import ConfigurationBox from '../components/ConfigurationBox';
import SftpConfiguration from '../components/SftpConfiguration';
import PasswordGenerator from '../components/PasswordGenerator';
import {
  fetchGeneratedSftpPasswordUndoable,
  setConnectionEnabled,
  toggleFileRouteVisibility,
  setSFTPUsername,
  setFileRouteKey,
  setFileRouteMd5Verified,
  setFileRouteAdditionalRoutingData,
  setSubPath,
  addSubPath,
  deleteSubPath,
  addSFTPFileRoute,
  deleteFileRoute,
} from '../actions';

const mapDispatchToProps = (dispatch) => {
  return {
    requestGeneratedSftpPassword: (sectionName) => {
      dispatch(fetchGeneratedSftpPasswordUndoable());
    },
    toggleFileRouteVisibility: (type) => {
      dispatch(toggleFileRouteVisibility(type));
    },
    onClickConnectionToggle: (value, connectionType) => {
      dispatch(setConnectionEnabled(value, connectionType));
    },
    onChangeUsername: (username) => {
      dispatch(setSFTPUsername(username));
    },
    onChangeFileRouteKey: (...args) => dispatch(setFileRouteKey(...args)),
    onChangeFileRouteMd5Verified: (...args) => dispatch(setFileRouteMd5Verified(...args)),
    onChangeFileRouteAdditionalRoutingData: (...args) => dispatch(setFileRouteAdditionalRoutingData(...args)),
    onChangeSubPath: (...args) => dispatch(setSubPath(...args)),
    onClickAddSubPath: (...args) => dispatch(addSubPath(...args)),
    onClickDeleteSubPath: (...args) => dispatch(deleteSubPath(...args)),
    onAddSFTPFileRoute: (...args) => dispatch(addSFTPFileRoute()),
    onDeleteSFTPFileRoute: (...args) => dispatch(deleteFileRoute(...args)),
  };
};

const mapStateToProps = (state) => {
  const {
    undoableConfigurationViewModel: {
      present: { sections, currentSection, sftpFileRouteTypes },
    },
  } = state;
  const {
    readOnly,
    sectionName,
    environmentGUID,
    sftpConnection: { username, connectionEnabled, generatedPassword, fileRoutesVisible, fileRoutes },
  } = sections[currentSection];
  const canGeneratePasswords = !readOnly || Boolean(generatedPassword);

  return {
    sectionName: 'SFTP',
    backgroundColor: colors.chyellow,
    textColor: 'white',
    children: ({
      requestGeneratedSftpPassword,
      onChangeUsername,
      toggleFileRouteVisibility,
      onClickConnectionToggle,
      ...otherProps
    }) => (
      <SftpConfiguration
        {...{
          environmentGUID,
          currentSection,
          username,
          connectionEnabled,
          fileRoutesVisible,
          sftpFileRouteTypes,
          fileRoutes,
          onChangeUsername,
          toggleFileRouteVisibility,
          onClickConnectionToggle,
          readOnly,
          ...otherProps,
        }}
      >
        {() => (
          <PasswordGenerator
            {...{ readOnly: !canGeneratePasswords, sectionName, requestGeneratedSftpPassword, generatedPassword }}
          />
        )}
      </SftpConfiguration>
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationBox);
