import React, { Component } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { chubStandards, ChubLinkButton, selectableElement } from '../styles/typography';
import { colors } from 'react-chui';

const CLIENT_OVERVIEW_PAGE_PATH = '/client/clientOverview/';

const ClientSummaryBar = glamorous('div', { displayName: 'ClientSummaryBar' })(chubStandards, {
  width: '100%',
  height: '50px',
  backgroundColor: colors.coolgraylight2,
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.15)',
});

const ClientName = glamorous('h1', { displayName: 'ClientName' })(selectableElement, {
  display: 'inline-block',
  margin: '5px 5px 5px 25px',
});

const OverviewLink = glamorous(ChubLinkButton, { displayName: 'OverviewLink' })({
  float: 'right',
  margin: '7px 25px 0 0',
});

export default class ClientSummary extends Component {
  static displayName = 'ClientSummary';

  static propTypes = {
    clientName: PropTypes.string,
    clientGUID: PropTypes.string,
  };

  render() {
    const { clientName, clientGUID } = this.props;
    return (
      <ClientSummaryBar>
        <ClientName id='client-name'>{clientName}</ClientName>
        {clientGUID && (
          <OverviewLink to={`${CLIENT_OVERVIEW_PAGE_PATH}${clientGUID}`} id='version-link' title='Go To Overview Page'>
            Overview
          </OverviewLink>
        )}
      </ClientSummaryBar>
    );
  }
}
