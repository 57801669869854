import React from 'react';
import As2LookupApp from './As2LookupApp';
import { useParams } from 'react-router-dom';

const As2LookupEntry = () => {
  const { messageId } = useParams();

  return <As2LookupApp messageId={messageId} />;
};

export default As2LookupEntry;
