import { FileRouteTypeViewModel } from '../../../../../viewmodels/fileRouteType/FileRouteTypeViewModel';

export function receiveFileRouteTypes(state, actionArgs) {
  const { fileRouteTypes } = actionArgs;
  return {
    ...state,
    fileRouteTypes,
  };
}

export function receiveSaveResponse(state, { saveResponse }) {
  const { booleanResult, savedFileRouteTypes = [] } = saveResponse;
  if (!booleanResult.success) {
    return {
      ...state,
      saveSuccessful: booleanResult.success,
      serverMessage: booleanResult.message,
    };
  }
  const saveCompleteFileRouteTypes = state.fileRouteTypes;
  const removedDeletedFileRouteTypes = Object.values(saveCompleteFileRouteTypes).filter(({ deleted }) => deleted);
  removedDeletedFileRouteTypes.forEach((fileRouteType) => {
    Reflect.deleteProperty(saveCompleteFileRouteTypes, fileRouteType.fileRouteTypeGUID);
  });

  savedFileRouteTypes.forEach((fileRouteType) => {
    saveCompleteFileRouteTypes[fileRouteType.fileRouteTypeGUID] = new FileRouteTypeViewModel(fileRouteType);
  });

  return {
    ...state,
    fileRouteTypes: saveCompleteFileRouteTypes,
    saveSuccessful: booleanResult.success,
    serverMessage: booleanResult.message,
  };
}
