import React, { useState } from 'react';
import glamorous, { GlamorousComponent } from 'glamorous';
import { ChubButton, ChubH1, chubStandards } from '../../../../styles/typography';
import As2LookupSearchResults from './As2LookupSearchResults';
// @ts-ignore
import { colors } from 'react-chui';

import PropTypes from 'prop-types';

const PageTitle: GlamorousComponent<any, any> = glamorous(ChubH1, { displayName: 'PageTitle' })({
  marginBottom: '50px',
  fontSize: '28px',
  fontWeight: 800,
});

const Contents = glamorous.div({
  marginTop: '40px',
  marginLeft: '15%',
  fontFamily: chubStandards.fontFamily,
});

const Instructions = glamorous.p({
  marginBottom: '15px',
  fontSize: '15px',
  color: colors.darkgray,
  fontWeight: 400,
});

const MainMenuDiv = glamorous.div({
  paddingTop: '20px',
  fontFamily: chubStandards.fontFamily,
});

const MainMenuLink = glamorous.a({
  marginLeft: '15%',
  color: colors.brightblue,
  textDecoration: 'none',
  fontSize: '15px',
  fontWeight: 400,
});

const MessageIdInput = glamorous.input({
  height: '33px',
  width: '400px',
  fontSize: '20px',
  border: '0 none',
  borderRadius: '3px',
  boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25) inset',
  padding: '10px',
  fontFamily: 'inherit',
  boxSizing: 'border-box',
  display: 'block',
  float: 'left',
  '::placeholder': {
    fontStyle: 'italic',
    color: colors.medgray,
  },
});

const lighterBlue = '#1d82ff';
const darkerBlue = '#0069e5';

const LookupButton: GlamorousComponent<any, any> = glamorous(
  ChubButton,
  {}
)({
  marginLeft: '20px',
  fontWeight: 400,
  background: `linear-gradient(to bottom, ${lighterBlue} 0%, ${darkerBlue} 100%)`,
});

async function onInput(
  evt: React.FormEvent<HTMLInputElement>,
  setMessageIdInput: React.Dispatch<React.SetStateAction<string>>
) {
  setMessageIdInput((evt.target as HTMLInputElement).value);
}

async function onDoSearch(
  evt: React.FormEvent<HTMLFormElement>,
  messageIdInput: string,
  setMessageId: React.Dispatch<React.SetStateAction<string>>
) {
  evt.preventDefault();
  await setMessageId(messageIdInput);
}

const instructionsText =
  'This tool will show whether a message ID has been recorded by Harmony and AS2 Forwarder, and whether it has' +
  ' been handed off to an internal service.';

const As2LookupSearch = ({ messageId: defaultMessageId }: { messageId?: string }) => {
  const [messageId, setMessageId] = useState(defaultMessageId || '');
  const [messageIdInput, setMessageIdInput] = useState(defaultMessageId || '');

  return (
    <>
      <Contents>
        <PageTitle id='as2-lookup-search'>AS2 Message ID Lookup</PageTitle>
        <Instructions>{instructionsText}</Instructions>
        <br />
        <form method='post' onSubmit={(evt) => onDoSearch(evt, messageIdInput, setMessageId)}>
          <MessageIdInput
            id='messageIdInput'
            value={messageIdInput}
            onChange={(evt) => onInput(evt, setMessageIdInput)}
            placeholder={'Enter Message ID'}
          />
          <LookupButton id='as2-lookup-submit'>Lookup</LookupButton>
        </form>
        <br />
        {messageId && <As2LookupSearchResults messageId={messageId} />}
      </Contents>
      <MainMenuDiv>
        <MainMenuLink href={'/'}>Main Menu</MainMenuLink>
      </MainMenuDiv>
    </>
  );
};

As2LookupSearch.displayName = 'As2LookupSearch';
As2LookupSearch.propTypes = {
  messageId: PropTypes.string,
};
export default As2LookupSearch;
