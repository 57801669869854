import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { ChubButton, chubStandards } from '../styles/typography';
import { Bubble } from 'react-chui';

const BUBBLE_OPTIONS = {
  attachment: 'bottom center',
  constraints: [],
  targetOffset: '5 0',
};

const ConfirmButton = glamorous(ChubButton, { displayName: 'ConfirmButton' })({
  margin: '20px 15px 3px 15px',
  width: '100px,',
});

const InfoBubble = glamorous(Bubble, { displayName: 'InfoBubble' })({
  width: '300px',
});

const BubbleHeader = glamorous(Bubble.Header, { displayName: 'BubbleHeader' })(chubStandards);
const BubbleFlexBox = glamorous('div', { displayName: 'BubbleFlexBox' })({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
});
const BubbleMessage = glamorous('div', { displayName: 'BubbleMessage' })(chubStandards, {
  margin: '0 20px',
  textAlign: 'center',
  whiteSpace: 'pre-line',
});

export default class ConfirmBubble extends Component {
  static displayName = 'ConfirmBubble';
  static propTypes = {
    onConfirm: PropTypes.func,
    onNoConfirm: PropTypes.func,
    confirmMessage: PropTypes.string,
  };

  static defaultProps = {
    confirmMessage: 'Are you sure?',
  };

  render() {
    const { onConfirm, onNoConfirm, confirmMessage } = this.props;
    return (
      <InfoBubble id='info-bubble' tetherOptions={BUBBLE_OPTIONS} onRequestClose={onNoConfirm}>
        <BubbleHeader>Confirm Save?</BubbleHeader>
        <BubbleFlexBox>
          <BubbleMessage id='bubble-message'>{confirmMessage}</BubbleMessage>
          <ConfirmButton title='Confirm' id='confirm-button' onClick={onConfirm}>
            Confirm
          </ConfirmButton>
        </BubbleFlexBox>
      </InfoBubble>
    );
  }
}
