import { connect } from 'react-redux';

import LoadingStateProvider from '../../../components/LoadingStateProvider';

const mapStateToProps = (state) => {
  const { loading } = state;
  return { ...loading };
};

const LoadingStateProviderContainer = connect(mapStateToProps)(LoadingStateProvider);

export default LoadingStateProviderContainer;
