import { ActionCreators as UndoActionCreators } from 'redux-undo';
import {
  RECEIVE_FILEROUTETYPES,
  REQUEST_FILEROUTETYPES,
  REQUEST_FILEROUTETYPES_FAILED,
  REQUEST_FILEROUTETYPES_COMPLETE,
  LOADING_ERROR,
  SAVE_FILEROUTETYPES_LOADING,
  SAVE_FILEROUTETYPES_COMPLETED,
  RECEIVE_SAVE_RESPONSE,
} from '../FileRouteTypesActionTypes';
import { ADD_FILEROUTETYPE, UPDATE_FILEROUTETYPE, DELETE_FILEROUTETYPE } from '../UndoableFileRouteTypeActionTypes';
import { FileRouteTypeViewModel } from '../../../../viewmodels/fileRouteType/FileRouteTypeViewModel';
import { fetchClient } from '../../../../util/fetchClient';

export function setReadOnly(readOnly) {
  return {
    readOnly,
  };
}

export function updateFileRouteType(key, value, fileRouteTypeGUID, serverMessages = []) {
  return {
    type: UPDATE_FILEROUTETYPE,
    fileRouteTypeGUID,
    key,
    value,
    serverMessages,
  };
}

export function addFileRouteType() {
  return {
    type: ADD_FILEROUTETYPE,
  };
}

export function deleteFileRouteType(fileRouteTypeGUIDToDelete) {
  return {
    type: DELETE_FILEROUTETYPE,
    fileRouteTypeGUIDToDelete,
  };
}

function requestFileRouteTypes() {
  return {
    type: REQUEST_FILEROUTETYPES,
  };
}

function receiveFileRouteTypes(fileRouteTypes = {}) {
  return {
    type: RECEIVE_FILEROUTETYPES,
    fileRouteTypes,
  };
}

function receiveFileRouteTypesComplete() {
  return {
    type: REQUEST_FILEROUTETYPES_COMPLETE,
  };
}

export function fetchFileRouteTypes() {
  return (dispatch) => {
    dispatch(requestFileRouteTypes());

    return fetchClient
      .get('/admin/fileRouteTypes')
      .then((response) => response.data)
      .then((rawFileRouteTypes) => {
        const fileRouteTypes = rawFileRouteTypes.reduce((map, fileRouteType) => {
          map[fileRouteType.fileRouteTypeGUID] = new FileRouteTypeViewModel(fileRouteType);
          return map;
        }, {});
        dispatch(receiveFileRouteTypes(fileRouteTypes));
        dispatch(UndoActionCreators.clearHistory());
        dispatch(receiveFileRouteTypesComplete());
      })
      .catch((error) => dispatch(fetchFileRouteTypesFailed(error)));
  };
}

function fetchFileRouteTypesFailed(error) {
  return {
    type: REQUEST_FILEROUTETYPES_FAILED,
    error,
  };
}

function saveFileRouteTypesLoading() {
  return {
    type: SAVE_FILEROUTETYPES_LOADING,
  };
}

function receiveSaveResponse(saveResponse) {
  return {
    type: RECEIVE_SAVE_RESPONSE,
    saveResponse,
  };
}

function saveFileRouteTypesCompleted() {
  return {
    type: SAVE_FILEROUTETYPES_COMPLETED,
  };
}

export function saveFileRouteTypes() {
  return (dispatch, getState) => {
    dispatch(saveFileRouteTypesLoading());
    const {
      undoableFileRouteTypes: {
        present: { fileRouteTypes },
      },
    } = getState();

    const changedFileRouteTypes = Object.values(fileRouteTypes).filter(({ hasChanges }) => hasChanges);
    return fetchClient
      .post('/admin/fileRouteTypes', changedFileRouteTypes)
      .then((response) => response.data)
      .then((fileRouteTypesResponse) => {
        dispatch(receiveSaveResponse(fileRouteTypesResponse));
        if (fileRouteTypesResponse.booleanResult.success) {
          dispatch(UndoActionCreators.clearHistory());
        }
        dispatch(saveFileRouteTypesCompleted());
      })

      .catch((error) => dispatch(loadingError(error)));
  };
}

function loadingError(error) {
  return {
    type: LOADING_ERROR,
    error,
  };
}
