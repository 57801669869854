import configurationCanSave from './configurationCanSave';
import { DEFAULT_PORT, DEFAULT_HOST, enforceUrlStructure } from '../../util/url-structure';
import { uuidv4 } from '../../../../mockable/uuidv4-wrapper';
import {
  SFTP_CONNECTION_TYPE,
  PARTNER_SFTP_CONNECTION_TYPE,
} from '../../../../util/commerceHubInfo';
import {
  copyAllConnections,
  copyAs2Connections,
  copySftpFileRoutes,
  copySftpConnection,
  copyPartnerSftpFileRoutes,
  copyPartnerSftpConnection } from './copySectionCases';
import { newState } from '../../actions';

export function setVersionName(state, action) {
  const { versionName } = action;
  const { sections, currentSection } = state;
  const editedCurrentSection = {
    ...sections[currentSection],
    versionName: {
      ...sections[currentSection].versionName,
      newValue: versionName,
      changed: versionName !== sections[currentSection].versionName.origValue,
    },
  };

  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function copySection(state, action) {
  const { destinationSectionGUID, destinationSectionName } = action;
  const { sections, currentSection, environmentList } = state;
  const {
    [currentSection]: { sftpConnection: currentSftpConnection, partnerSftpConnection: currentPartnerSftpConnection } = {},
    [destinationSectionName]: {
      as2Connections: destinationAs2Connections,
      sftpConnection: destinationSftpConnection,
      partnerSftpConnection: destinationPartnerSftpConnection
    } = {},
  } = sections;
  const defaultGateway = setDefaultGateway(destinationSectionGUID, environmentList);
  if ((!destinationAs2Connections || destinationAs2Connections.length === 0) &&
      !destinationSftpConnection &&
      (!destinationPartnerSftpConnection || destinationPartnerSftpConnection.length === 0)) {
    const destinationSection = copyAllConnections(
      sections[currentSection],
      sections[destinationSectionName],
      destinationSectionGUID,
      destinationSectionName,
      defaultGateway
    );

    return {
      ...state,
      sections: {
        ...sections,
        [destinationSectionName]: {
          ...destinationSection,
          canSave: configurationCanSave(destinationSection),
          isCopy: true,
        },
      },
    };
  }

  const sectionsAfterCopy = { ...sections };
  sectionsAfterCopy[destinationSectionName] = copyAs2Connections(
    sections[currentSection],
    sectionsAfterCopy[destinationSectionName],
    defaultGateway
  );

  if (currentSftpConnection) {
    if (destinationSftpConnection) {
      sectionsAfterCopy[destinationSectionName] = copySftpFileRoutes(
        sections[currentSection],
        sectionsAfterCopy[destinationSectionName]
      );
    } else {
      sectionsAfterCopy[destinationSectionName] = copySftpConnection(
        sections[currentSection],
        sectionsAfterCopy[destinationSectionName]
      );
    }
  }

  if (currentPartnerSftpConnection) {
    if (destinationPartnerSftpConnection) {
      sectionsAfterCopy[destinationSectionName] = copyPartnerSftpFileRoutes(
          sections[currentSection],
          sectionsAfterCopy[destinationSectionName]
      );
    } else {
      sectionsAfterCopy[destinationSectionName] = copyPartnerSftpConnection(
          sections[currentSection],
          sectionsAfterCopy[destinationSectionName]
      );
    }
  }

  return {
    ...state,
    currentSection: destinationSectionName,
    sections: {
      ...sectionsAfterCopy,
      [destinationSectionName]: {
        ...sectionsAfterCopy[destinationSectionName],
        canSave: configurationCanSave(sectionsAfterCopy[destinationSectionName]),
        isCopy: true,
        readOnly: false,
      },
    },
  };
}

export function setConnectionEnabled(state, action) {
  const { isEnabled, connectionType, guid } = action;
  const { sections, currentSection } = state;
  let editedCurrentSection;
  if (connectionType === SFTP_CONNECTION_TYPE) {
    editedCurrentSection = {
      ...sections[currentSection],
      [connectionType]: {
        ...sections[currentSection][connectionType],
        connectionEnabled: {
          ...sections[currentSection][connectionType].connectionEnabled,
          newValue: isEnabled,
          changed: isEnabled !== sections[currentSection][connectionType].connectionEnabled.origValue,
        },
      },
    };
  } else if (connectionType === PARTNER_SFTP_CONNECTION_TYPE) {
    const partnerSftpConnections = [];
    sections[currentSection].partnerSftpConnections.forEach((connection) => {
      if (connection.accountGUID === guid) {
        partnerSftpConnections.push({
          ...connection,
          connectionEnabled: {
            ...connection.connectionEnabled,
            newValue: isEnabled,
            changed: isEnabled !== connection.connectionEnabled.origValue,
          },
        });
      } else {
        partnerSftpConnections.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      partnerSftpConnections,
    };
  } else {
    const as2Connections = [];
    sections[currentSection].as2Connections.forEach((connection) => {
      if (connection.mailboxGUID === guid) {
        as2Connections.push({
          ...connection,
          connectionEnabled: {
            ...connection.connectionEnabled,
            newValue: isEnabled,
            changed: isEnabled !== connection.connectionEnabled.origValue,
          },
        });
      } else {
        as2Connections.push(connection);
      }
    });
    editedCurrentSection = {
      ...sections[currentSection],
      as2Connections,
    };
  }
  return newState(state, sections, currentSection, editedCurrentSection, guid);
}

export function addAs2Connection(state, action) {
  const { useAs2ForwarderAsDefault } = action;
  const {
    clientName,
    sections,
    currentSection,
    chubSystemIdentifiers: [defaultSystemIdentifier],
    chubSystemCertificates: [defaultChubSystemCertificate],
    environmentList,
  } = state;
  const newAs2Connection = {
    as2Id: {
      origValue: '',
      newValue: clientName,
      changed: true,
    },
    mailboxName: {
      origValue: '',
      newValue: clientName,
      changed: true,
    },
    chubAs2Id: {
      origValue: '',
      newValue: defaultSystemIdentifier.value,
      changed: true,
    },
    chubAs2IdGUID: {
      origValue: uuidv4(),
      newValue: defaultSystemIdentifier.guid,
      changed: true,
    },
    chubCertificate: {
      origValue: '',
      newValue: defaultChubSystemCertificate.value,
      changed: true,
    },
    chubCertificateGUID: {
      origValue: uuidv4(),
      newValue: defaultChubSystemCertificate.guid,
      changed: true,
    },
    clientCertificate: {
      origValue: {
        certificateBytes: [],
      },
    },
    clientSigningCertificateOverride: {
      origValue: {
        certificateBytes: [],
      },
    },
    connectionEnabled: {
      origValue: true,
    },
    https: {
      origValue: false,
    },
    mailboxGUID: uuidv4(),
    port: {
      origValue: 0,
      newValue: DEFAULT_PORT,
      changed: true,
    },
    url: {
      origValue: '',
      newValue: enforceUrlStructure(DEFAULT_HOST),
      changed: true,
    },
    micAlgorithm: {
      origValue: '',
      newValue: 'sha1',
      changed: true,
    },
    fileRoutes: {},
    allowDuplicateMessageIds: {
      origValue: true,
    },
    authenticationCertHref: {},
    authenticationCertPassword: {},
    securityAuthenticationRealm: {},
    securityAuthenticationType: {},
    securityAuthenticationUsername: {},
    securityAuthenticationPassword: {},
    connectionTimeout: {},
    sslAllowLegacyRenegotiation: {},
    sslCipher: {},
    sslMinimumProtocolVersion: {},
    sslMaximumProtocolVersion: {},
    outgoingReceiptAsyncResends: {},
    outgoingReceiptAsyncRetryDelay: {},
    outgoingReceiptAsyncTimeout: {},
    sendOutboundThroughAS2Forwarder: {
      origValue: useAs2ForwarderAsDefault,
    },
    as2Gateway: {
      origValue: setDefaultGateway(sections[currentSection]?.environmentGUID, environmentList),
    },
  };

  const as2Connections = sections[currentSection].as2Connections && [...sections[currentSection].as2Connections];
  //eslint-disable-next-line
  as2Connections && as2Connections.push(newAs2Connection);

  const editedCurrentSection = {
    ...sections[currentSection],
    as2Connections,
    as2ConnectionsListChanged:
      as2Connections && as2Connections.length !== sections[currentSection].as2Connections.length,
  };

  return newState(state, sections, currentSection, editedCurrentSection, newAs2Connection.mailboxGUID);
}

export function addSftpConnection(state) {
  const { clientName, sections, currentSection } = state;
  const editedCurrentSection = {
    ...sections[currentSection],
    sftpConnection: {
      username: {
        origValue: '',
        newValue: clientName,
        changed: true,
      },
      connectionEnabled: {
        origValue: true,
      },
      fileRoutes: {},
    },
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function addPartnerSftpConnection(state) {
  const { clientName, sections, currentSection } = state;
  const newPartnerSftpConnection = {
    accountGUID: uuidv4(),
    connectionEnabled: {
      origValue: true,
    },
    fileRoutes: {},
    host: {
      origValue: '',
      newValue: enforceUrlStructure(DEFAULT_HOST),
      changed: true,
    },
    port: {
      origValue: 0,
      newValue: DEFAULT_PORT,
      changed: true,
    },
    username: {
      origValue: '',
      newValue: clientName,
      changed: true,
    },
    password: {
      origValue: '',
      newValue: '',
      changed: true,
    },
    outbox: {
      origValue: '',
      newValue: '',
      changed: true,
    },
    inbox: {
      origValue: '',
      newValue: '',
      changed: true,
    },
    clientAuthenticationCert: {
      origValue: '',
      newValue: '',
      changed: true,
    },
    clientAuthenticationCertPassword: {
      origValue: '',
      newValue: '',
      changed: true,
    },
    clientAuthenticationPrivateKeyFile: {
      origValue: '',
      newValue: '',
      changed: true,
    },
    clientAuthenticationPrivateKeyFilePassword: {
      origValue: '',
      newValue: '',
      changed: true,
    },
  };

  const partnerSftpConnections = sections[currentSection].partnerSftpConnections && [
    ...sections[currentSection].partnerSftpConnections,
  ];
  //eslint-disable-next-line
  partnerSftpConnections && partnerSftpConnections.push(newPartnerSftpConnection);

  const editedCurrentSection = {
    ...sections[currentSection],
    partnerSftpConnections,
    partnerSftpConnectionsListChanged:
      partnerSftpConnections &&
      partnerSftpConnections.length !== sections[currentSection].partnerSftpConnections.length,
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function deleteAs2Connection(state, action) {
  const { sections, currentSection } = state;
  const { mailboxGUID } = action;
  const as2Connections = sections[currentSection].as2Connections.filter(
    (connection) => connection.mailboxGUID !== mailboxGUID
  );

  const editedCurrentSection = {
    ...sections[currentSection],
    as2Connections,
    as2ConnectionsListChanged: as2Connections.length !== sections[currentSection].as2Connections.length,
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

export function deletePartnerSftpConnection(state, action) {
  const { sections, currentSection } = state;
  const { accountGUID } = action;
  const partnerSftpConnections = sections[currentSection].partnerSftpConnections.filter(
    (connection) => connection.accountGUID !== accountGUID
  );

  const editedCurrentSection = {
    ...sections[currentSection],
    partnerSftpConnections,
    partnerSftpConnectionsListChanged:
      partnerSftpConnections.length !== sections[currentSection].partnerSftpConnections.length,
  };
  return newState(state, sections, currentSection, editedCurrentSection, null);
}

function setDefaultGateway(environmentGUID, environmentList) {
  const environment = environmentList?.find((x) => x.guid === environmentGUID);
  if (!environment || !environment.as2Gateways || environment.as2Gateways.length < 1) {
    return {};
  }

  if (environment.as2Gateways.length < 2) {
    return { ...environment.as2Gateways[0] };
  }

  const preferred = environment.as2Gateways.find((x) => x.preferred);
  return preferred ? { ...preferred } : { ...environment.as2Gateways[0] };
}
