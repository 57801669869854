import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { chubStandards } from '../styles/typography';

const ItemLabel = glamorous('dt', { displayName: 'ItemLabel' })(chubStandards, {
  display: 'inline-block',
  fontWeight: 'bold',
  marginLeft: '0px',
});

const ItemData = glamorous('dd', { displayName: 'ItemData' })(chubStandards, {
  display: 'inline-block',
  marginBottom: '5px',
  marginLeft: '5px',
});

export default class ExportAs2DataItem extends Component {
  static displayName = 'ExportDataItem';

  static propTypes = {
    itemLabel: PropTypes.string,
    itemData: PropTypes.string,
    id: PropTypes.string.isRequired,
  };

  render() {
    const { itemLabel, itemData, id } = this.props;
    return [
      <ItemLabel key={`${id}-label`}>{itemLabel}:</ItemLabel>,
      <ItemData key={`${id}-description`} id={id}>
        {itemData}
      </ItemData>,
      <br key={`${id}-break`} />,
    ];
  }
}
