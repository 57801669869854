import { ActionCreators as UndoActionCreators } from 'redux-undo';
import {
  RECEIVE_ENVIRONMENTS,
  REQUEST_ENVIRONMENTS,
  REQUEST_ENVIRONMENTS_COMPLETE,
  REQUEST_ENVIRONMENTS_FAILED,
  REQUEST_SAVE_ENVIRONMENTS,
  RECEIVE_SAVE_RESULT,
  SAVE_COMPLETE,
  SAVE_FAILED,
  SET_READ_ONLY,
} from '../EnvironmentActionTypes';
import * as UndoableAction from '../EnvironmentsUndoableActionTypes';
import { EnvironmentViewModel, mapEnvironmentViewModelToServerData } from '../../../../viewmodels/EnvironmentViewModel';
import { fetchClient } from '../../../../util/fetchClient';

export function setReadOnly(readOnly) {
  return {
    type: SET_READ_ONLY,
    readOnly,
  };
}

function requestEnvironments() {
  return {
    type: REQUEST_ENVIRONMENTS,
  };
}

function requestEnvironmentsComplete() {
  return {
    type: REQUEST_ENVIRONMENTS_COMPLETE,
  };
}

function receiveEnvironments(environments = []) {
  return {
    type: RECEIVE_ENVIRONMENTS,
    environments,
  };
}

export function fetchEnvironments() {
  return (dispatch) => {
    dispatch(requestEnvironments());

    return fetchClient
      .get('/admin/environments')
      .then((response) => response.data)
      .then((environments) => {
        const environmentsViewModels = environments.map((jsonObject) => {
          return new EnvironmentViewModel(jsonObject);
        });
        dispatch(receiveEnvironments(environmentsViewModels));
        dispatch(UndoActionCreators.clearHistory());
        dispatch(requestEnvironmentsComplete());
      })
      .catch((error) => dispatch(fetchEnvironmentsFailed(error)));
  };
}

function fetchEnvironmentsFailed(error) {
  return {
    type: REQUEST_ENVIRONMENTS_FAILED,
    error,
  };
}

export function addEnvironment(newEnvironment) {
  newEnvironment.added = true;
  return {
    type: UndoableAction.ADD_ENVIRONMENT,
    newEnvironment,
  };
}

export function updateEnvironment(payload) {
  return {
    type: UndoableAction.UPDATE_ENVIRONMENT,
    payload,
  };
}

function requestSaveEnvironments() {
  return {
    type: REQUEST_SAVE_ENVIRONMENTS,
  };
}

function receiveSaveResult(errors, editedEnvironments) {
  return {
    type: RECEIVE_SAVE_RESULT,
    errors,
    editedEnvironments,
  };
}

function saveComplete() {
  return {
    type: SAVE_COMPLETE,
    isSaving: false,
  };
}

function saveFailed(error) {
  return {
    type: SAVE_FAILED,
    isSaving: false,
    error,
  };
}

export function saveEnvironments() {
  return (dispatch, getState) => {
    const {
      undoableEnvironments: {
        present: { environments },
      },
    } = getState();
    const changedEnvironmentsJson = environments
      .filter(({ hasChanges }) => hasChanges)
      .map(mapEnvironmentViewModelToServerData);

    dispatch(requestSaveEnvironments());

    return fetchClient
      .post('/admin/environments', changedEnvironmentsJson)
      .then((response) => response.data)
      .then((responseJson) => {
        const { errors, editedEnvironments: responseEditedEnvironments } = responseJson;
        dispatch(receiveSaveResult(errors, responseEditedEnvironments));
        dispatch(UndoActionCreators.clearHistory());
        dispatch(saveComplete());
      })
      .catch((error) => {
        dispatch(saveFailed(error));
      });
  };
}
